export const getCategories = async () => {
    try {
        const res = await fetch(`/api/categories`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const syncCategories = async (page) => {
    try {
        const res = await fetch(`/api/categories/sync/${page}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSyncMeta = async () => {
    try {
        const res = await fetch(`/api/categories/sync/meta`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}