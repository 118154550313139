
export const createOrderSource = async (newOrderSource) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newOrderSource }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/sources`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getOrderSources = async () => {
    try {
        const res = await fetch(`/api/orders/sources`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getOrderSource = async (id) => {
    try {
        const res = await fetch(`/api/orders/sources/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateOrderSource = async (id, updatedOrderSource) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedOrderSource }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/sources/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteOrderSource = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/sources/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}