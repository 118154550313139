import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Can } from "../../../configs/Ability-context";
import {SecuredSignupSeller,QuickSignUpSeller,verifyEmail,RequestEmailVerificationComponent} from './SecuredSignupSeller';
import {ResendVerificationEmail} from './pages/SellerEmailVerification';

import SignUp from './pages/SignUp';
import EditSeller from './pages/EditSeller';
import AllPersons from '../AllPersons';
import { AllSellerRequests } from './pages/AllSellerRequests';
import NotFound from '../../misc/pages/NotFound'
import { PrivateRoute } from '../../misc/PrivateRoute'


const Sellers = () => {
    return (
        <Switch>
            <PrivateRoute path='/sellers/new'>
                <Can I='create' a='sellers'>
                    <SignUp />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/sellers/edit/:seller'>
                <Can I='update' a='sellerProfile'>
                    <EditSeller key='edit-seller' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/sellers/myProfile'>
                <Can I='update' a='sellerProfile'>
                    <EditSeller key='edit-profile' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/sellers/all'>
                <Can I='read' a='sellers'>
                    <AllPersons persons={'sellers'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/sellers/requests/all'>
                <Can I='read' a='sellerRequests'>
                    <AllSellerRequests />
                </Can>
            </PrivateRoute>

            <Route path="/sellers/signup" component={SecuredSignupSeller} />
            <Route path="/sellers/quicksignup" component={QuickSignUpSeller} />
            <Route path="/sellers/verify_email" component={verifyEmail} />
            <Route path="/sellers/request_email_verification" component={RequestEmailVerificationComponent} />
            <Route path="/sellers/resend_verify_email" component={ResendVerificationEmail} />
            <Route component={NotFound} />
        </Switch>
    )
}


export default Sellers