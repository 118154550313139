import React, { useEffect, useState, useRef, useContext } from 'react'
import LoaderModal from '../../misc/components/LoaderModal'
import { CustomButton } from '../../misc/components/Inputs'
import ImageGallery from '../../misc/components/ImageGallery'
import getOptions from '../../misc/components/Options'
import { Can } from '../../../configs/Ability-context'
import { ProductForm } from './sections/ProductForm'
import { UserContext } from '../../../contexts/UserContext'
import { AppContext } from '../../../contexts/AppContext'
import { useHistory, useParams } from 'react-router-dom'

import { getJsonData } from '../../misc/components/Helpers'
import { getProduct, getProducts, updateProduct } from '../../../services/products/product-service'
import { getCategories } from '../../../services/products/category-service'
import { getBrands } from '../../../services/products/brand-service'
import { getAttributeTerms, getAttributesWithData } from '../../../services/products/attribute-service'
import { getTags } from '../../../services/products/tag-service'
import { getCustomUsers, getCustomChilds, getUserPriceRule } from '../../../services/users/user-service';


let eProduct
let _isMounted
const productTypeOptions = getOptions('productTypes'),
    stockStatusOptions = getOptions('stockStatuses'),
    backordersOptions = getOptions('backorders'),
    productStatusOptions = getOptions('productStatuses')
const INITIAL_STATE = {
    remarks: '',
    name: '',
    selectedType: '',
    update_threshold: 90,
    selectedStatus: '',

    priceRule: null,
    isCostProvided: false,
    regularPrice: '',
    costPrice: '',
    salePrice: '',
    saleFrom: '',
    saleTo: '',
    scheduleSale: false,

    sku: '',
    sourceURL: '',
    manageStock: false,
    stockQuantity: '',
    stockStatus: '',
    backorders: '',
    soldIndividually: false,

    weight: '',
    dimensions: { length: '', width: '', height: '' },

    upSells: [],
    crossSells: [],

    description: '',
    shortDescription: '',
    purchaseNote: '',

    attributes: [],
    variations: [],

    selectedCategories: [],
    selectedTags: [],
    selectedBrand: '',
    selectedSeller: '',
    selectedShopManager: '',

    featuredImage: null,
    images: [],
    showImageGallery: false,
    isMultipleImages: true,

    showSubmitButton: true,
    isLoading: false,
    notSellerProduct: false
}
export const EditProduct = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const [{
        remarks, name, selectedType, update_threshold, selectedStatus,
        priceRule, isCostProvided, regularPrice, costPrice, salePrice, saleFrom, saleTo, scheduleSale,
        sku, sourceURL, manageStock, stockQuantity, stockStatus, backorders, soldIndividually,
        weight, dimensions,
        upSells, crossSells,
        description, shortDescription, purchaseNote,
        attributes, variations,
        selectedCategories, selectedTags,
        selectedBrand, selectedSeller, selectedShopManager,
        featuredImage, images, showImageGallery, isMultipleImages,
        allProducts, allAttributes, allAttributeTerms,
        allProductCategories, allBrands, allSellers, allShopManagers, existingTags,
        isLoading,
        validationMessage, notSellerProduct, showSubmitButton
    }, setState] = useState({
        ...INITIAL_STATE,
        allAttributes: [], allAttributeTerms: [],
        allProducts: [], allProductCategories: [],
        allBrands: [], allSellers: [], allShopManagers: [], existingTags: [],
        validationMessage: '',
    })

    let generalDataForm = useRef(null)
    let attributesDataForm = useRef(null)
    let advancedDataForm = useRef(null)
    let descriptionDataForm = useRef(null)
    let imagesDataForm = useRef(null)
    let inventoryDataForm = useRef(null)
    let linkedDataForm = useRef(null)
    let shippingDataForm = useRef(null)
    let variationsDataForm = useRef(null)
    let isVariableProduct = selectedType.value === 'variable'

    const { appURL } = useContext(AppContext)
    const { isSellerLoggedIn, isShopManagerLoggedIn } = useContext(UserContext)
    let history = useHistory()
    let { product } = useParams()

    useEffect(async () => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getProduct(product)
                if (data) {
                    let Sellers = !isSellerLoggedIn && !isShopManagerLoggedIn ?
                        await getCustomUsers('seller')
                        :
                        []
                    let allSellers = Array.isArray(Sellers) ? [] : Sellers.data

                    //destructuring data
                    let eproduct = await getJsonData(
                        data,
                        [
                            'attributes', 'categories', 'cross_sell_ids', 'default_attributes', 'dimensions', 'images', 'tags', 'upsell_ids',
                            ['variations', ['attributes', 'dimensions', 'images']]
                        ]
                    )
                    eProduct = eproduct
                    let PriceRule = eproduct.seller_id ?
                        await getUserPriceRule(eproduct.seller_id)
                        :
                        null
                    const selectedSeller = eProduct.product_seller ? { key: eProduct.product_seller.id, value: eProduct.product_seller.id, label: eProduct.product_seller.name } : ''
                    const selectedCategories = eProduct.categories.length > 0 && eProduct.categories.map(category =>
                        ({ key: category.id, value: category.id, label: category.name })),
                        selectedTags = eProduct.tags.length > 0 && eProduct.tags.map(tag =>
                            ({ key: tag.id, value: tag.id, label: tag.name })),
                        selectedUpsells = allProducts.filter(product => eProduct.upsell_ids.includes(product.bkt_id)).map(product => ({
                            key: product.id, value: product.bkt_id, label: product.name
                        })),
                        selectedCrossSells = allProducts.filter(product => eProduct.cross_sell_ids.includes(product.bkt_id)).map(product => ({
                            key: product.id, value: product.bkt_id, label: product.name
                        })),
                        attributes = await eProduct.attributes.length ?
                            eProduct.attributes
                                .map(attr => {
                                    attr.options = attr.options.map((option, index) => ({
                                        key: allAttributeTerms.find(term => term.name === option)?.bkt_id,
                                        label: option,
                                        value: allAttributeTerms.find(term => term.name === option)?.bkt_id
                                    }))
                                    return attr
                                })
                            :
                            [],

                        variations = await eProduct.variations.length ?
                            eProduct.variations
                                .map(variation => {
                                    let variationAttributes = variation.attributes
                                    return ({
                                        id: variation.id,
                                        bkt_id: variation.bkt_id,
                                        parent_id: variation.parent_id,

                                        status: variation.status,

                                        regular_price: variation.regular_price,
                                        cost_price: variation.cost_price,
                                        is_cost_provided: variation.is_cost_provided,
                                        sale_price: variation.sale_price,
                                        scheduleSale: (variation.date_on_sale_from || variation.date_on_sale_to) ? true : false,
                                        date_on_sale_from: variation.date_on_sale_from ? new Date(variation.date_on_sale_from) : null,
                                        date_on_sale_to: variation.date_on_sale_to ? new Date(variation.date_on_sale_to) : null,

                                        sku: variation.sku,
                                        manage_stock: variation.manage_stock,
                                        stock_quantity: variation.stock_quantity,
                                        stock_status: stockStatusOptions.find(option => option.value === variation.stock_status),
                                        backorders: backordersOptions.find(option => option.value === variation.backorders),

                                        weight: variation.weight,
                                        dimensions: variation.dimensions,

                                        description: variation.description,

                                        attributes: variationAttributes.length > 0 ? variationAttributes.map((attr, index) => ({
                                            id: attr.id,
                                            key: attr.id,
                                            label: attr.option,
                                            name: attr.name,
                                            value: {
                                                id: attr.id,
                                                name: attr.name,
                                                option: attr.option
                                            }
                                        })) : [],

                                        image: variation.images
                                    })
                                })
                            :
                            []

                    fetchMeta()
                    eproduct && _isMounted && setState(prevState => ({
                        ...prevState,
                        allSellers: allSellers,
                        selectedSeller: selectedSeller,


                        eProduct: eproduct,
                        remarks: eproduct.remarks,
                        name: eproduct.name,
                        selectedType: productTypeOptions.find(option => option.value === eproduct.type),
                        selectedStatus: productStatusOptions.find(option => option.value === eproduct.status),

                        priceRule: PriceRule,
                        isCostProvided: eProduct.is_cost_provided,
                        regularPrice: eproduct.regular_price,
                        costPrice: eProduct.cost_price,
                        salePrice: eproduct.sale_price,
                        saleFrom: new Date(eproduct.date_on_sale_from),
                        saleTo: new Date(eproduct.date_on_sale_to),
                        scheduleSale: eproduct.date_on_sale_to ? true : false,

                        sku: eproduct.sku,
                        sourceURL: eproduct.source_url,
                        update_threshold: eproduct.update_threshold,
                        manageStock: eproduct.manage_stock,
                        stockQuantity: eproduct.stock_quantity,
                        stockStatus: stockStatusOptions.find(option => option.value === eproduct.stock_status),
                        backorders: backordersOptions.find(option => option.value === eproduct.backorders),
                        soldIndividually: eproduct.sold_individually,

                        weight: eproduct.weight,
                        dimensions: eproduct.dimensions,

                        description: eproduct.description,
                        shortDescription: eproduct.short_description,
                        purchaseNote: eproduct.purchase_note,

                        upSells: selectedUpsells,
                        crossSells: selectedCrossSells,

                        attributes: attributes,
                        variations: variations,

                        selectedCategories: selectedCategories,
                        selectedTags: selectedTags,

                        notSellerProduct: false
                    }))
                }
                else {
                    success && changeValue('notSellerProduct', true)
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }

        const fetchMeta = async () => {
            try {
                const Categories = await getCategories()
                const Attributes = await getAttributesWithData()
                const AttributeTerms = await getAttributeTerms()
                const Brands = await getBrands()
                const Tags = await getTags()
                const ShopManagers = isSellerLoggedIn ? await getCustomChilds(0, 'shop_manager') : isShopManagerLoggedIn ? [] : eProduct.seller_id ?
                    await getCustomChilds(eProduct.seller_id, 'shop_manager') : []

                //destructuring data
                let allProductCategories = Categories.data,
                    allAttributes = Attributes.data,
                    allAttributeTerms = AttributeTerms.data,
                    allBrands = Brands.data,
                    existingTags = Tags.data,
                    allShopManagers = Array.isArray(ShopManagers) ? [] : ShopManagers.data,

                    selectedBrand = allBrands.find(b => b.bkt_id === eProduct.brand_id)

                _isMounted && setState(prevState => ({
                    ...prevState,

                    allProductCategories: allProductCategories,
                    allAttributes: allAttributes,
                    allAttributeTerms: allAttributeTerms,
                    allBrands: allBrands,
                    existingTags: existingTags,
                    allShopManagers: allShopManagers,

                    // defaultAttributes: [],
                    selectedBrand: selectedBrand ? { key: selectedBrand.id, value: selectedBrand.bkt_id, label: selectedBrand.name } : '',
                    selectedShopManager: eProduct.shop_manager && { key: eProduct.shop_manager.id, value: eProduct.shop_manager.id, label: eProduct.shop_manager.name },

                    featuredImage: eProduct.images[0],
                    images: eProduct.images.length > 1 ? eProduct.images.filter((image, index) => index !== 0) : [],
                    notSellerProduct: false
                }))
                const Products = await getProducts()
                let allProducts = Products.data
                changeValue('allProducts', allProducts)
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    //setting price-rule and shop-managers when seller changed
    useEffect(() => {
        const setSellerSpecificData = async () => {
            if (selectedSeller) {
                try {
                    let { data } = await getUserPriceRule(selectedSeller.value)
                    changeValue('priceRule', data)
                }
                catch (error) {
                    console.log(error)
                }
                if (!isSellerLoggedIn && !isShopManagerLoggedIn) {
                    try {
                        let { data } = await getCustomChilds(selectedSeller.value, 'shop_manager')
                        changeValue('allShopManagers', data)
                    }
                    catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        setSellerSpecificData()
        let pendingStatus = productStatusOptions.find(o => o.value === 'pending')
        setState(prevState => ({
            ...prevState,
            regularPrice: '',
            salePrice: '',
            costPrice: '',
            saleFrom: null,
            saleTo: null,
            isCostProvided: false,
            selectedStatus: pendingStatus,
            variations: prevState.variations.map(v => ({
                ...v,
                regular_price: '',
                sale_price: '',
                cost_price: '',
                date_on_sale_from: null,
                date_on_sale_to: null,
                is_cost_provided: false,
                status: 'pending'
            }))
        }))
    }, [selectedSeller])


    const changeValue = (name, value) => {
        if (name === 'length' || name === 'width' || name === 'height') {
            _isMounted && setState(prevState => ({
                ...prevState,
                dimensions: {
                    ...prevState.dimensions,
                    [name]: value,
                }
            }))
        }
        else { _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: name === 'validationMessage' ? value : '' })) }
    }

    const checkFormValidity = () => {
        console.log('yes, how can i help ?')
        if (!selectedSeller) {
            setState(prevState => ({
                ...prevState,
                selectedSeller: null,
                validationMessage: 'Please select product seller'
            }))
            setCurrentStep(1)
            return
        }
        if (scheduleSale) {
            if (!saleFrom) {
                changeValue('validationMessage', 'Please select sale from date')
                setCurrentStep(1)
                return
            }
            else if (!saleTo) {
                changeValue('validationMessage', 'Please select sale to date')
                setCurrentStep(1)
                return
            }
        }
        if (!manageStock) {
            if (!stockStatus && !isVariableProduct) {
                setState(prevState => ({
                    ...prevState,
                    stockStatus: null,
                    validationMessage: 'Please select stock status'
                }))
                setCurrentStep(2)
                return
            }
        }
        if (isVariableProduct && !variations.length) {
            changeValue('validationMessage', 'Please add some variations for your product.')
            setCurrentStep(7)
            return
        }
        if (!featuredImage) {
            changeValue('validationMessage', 'Please select a featured image for your product.')
            setCurrentStep(9)
            return
        }

        let forms = [
            generalDataForm, inventoryDataForm, shippingDataForm, descriptionDataForm,
            linkedDataForm, attributesDataForm, variationsDataForm, advancedDataForm, imagesDataForm
        ]
        let isValidated
        for (let i = 0; i < forms.length; i++) {
            console.log(i)
            const form = forms[i];
            if (!form.current.checkValidity()) {
                // console.log(form)
                setCurrentStep(i + 1)
                setTimeout(() => {
                    form.current.classList.add('was-validated')
                }, 250);
                isValidated = false
                break;
            }
            isValidated = true
        }
        if (isValidated)
            handleUpdate()
        else
            return
    }

    const handleUpdate = async () => {
        changeValue('isLoading', true)
        try {
            let updatedProduct = {
                name: name,
                type: selectedType.value,
                update_threshold: update_threshold,
                status: selectedStatus.value,

                is_cost_provided: isCostProvided,
                regular_price: isVariableProduct ? '' : regularPrice,
                cost_price: isVariableProduct ? '' : costPrice,
                sale_price: isVariableProduct ? '' : salePrice,
                date_on_sale_from: scheduleSale ? saleFrom : null,
                date_on_sale_to: scheduleSale ? saleTo : null,

                sku: sku ? sku : null, source_url: sourceURL ? sourceURL : null, manage_stock: manageStock, stock_quantity: manageStock ? stockQuantity : null, stock_status: stockStatus?.value, backorders: backorders?.value,
                sold_individually: soldIndividually,

                weight: weight, dimensions: dimensions,

                upsell_ids: upSells.map(product => product.value),
                cross_sell_ids: crossSells.map(product => product.value),

                description: description,
                short_description: shortDescription,
                purchase_note: purchaseNote,

                attributes: attributes.map(attr => ({
                    ...attr,
                    options: attr.options.map(option => {
                        if (option.label !== 'Select all')
                            return option.label
                        else return null
                    }).filter(option => option)
                    // return attr
                })),
                default_attributes: [],

                categories: selectedCategories.map(category => ({ id: category.value })),
                tags: selectedTags.length > 0 && selectedTags.map(tag => ({ id: tag.value })),
                images: (images.length ? [featuredImage, ...images] : [featuredImage]).map(image => {
                    if (image.type)
                        return { alt: 'image not found', src: `${appURL}images/${image.name}` }
                    else return image
                })
            },
                brandId = selectedBrand ? selectedBrand.value : null,
                sellerId = selectedSeller ? selectedSeller.value : null,
                shopManagerId = selectedShopManager ? selectedShopManager.value : null
            const updatedvariations = variations.map(variation => ({
                ...variation,
                id: variation.bkt_id,
                bkt_id: undefined,
                attributes: variation.attributes.map(attr => attr.value),
                image: variation.image ? variation.image.type ? { alt: 'bucket.pk', src: `${appURL}images/${variation.image.name}` } : variation.image : null,
                stock_status: variation.stock_status?.value,
                backorders: variation.backorders?.value
            }))
            // console.log(updatedProduct, brandId, sellerId, images)
            // console.log(variations)
            const { success, message } = await updateProduct(eProduct.bkt_id, updatedProduct, brandId, sellerId, shopManagerId, updatedvariations)
            // changeValue('validationMessage', message)
            console.log(message)
            if (success) {
                setTimeout(() => {
                    history.push('/products/all')
                }, 750)
            }
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    if (notSellerProduct) {
        return (
            <div className="screen-center color-tertiary font-weight-bold h-100vh h3">Sorry, product not found.</div>
        )
    }
    else return (
        <Can I={'update'} a='products'>
            <div className={`screen-center mt-4 py-5 m-sm-0 py-sm-0 h-sm-100vh`}>
                <h2 className="mb-4 p-0 color-dark font-weight-normal">{`Update product`}</h2>
                <div className='p-d-flex p-flex-column pt-2 w-100 w-lg-75 px-4 px-sm-2 p-lg-0'>
                    <ProductForm
                        //universal
                        _isMounted
                        APO_product={'update'}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        isVariableProduct={isVariableProduct}
                        isSellerLoggedIn={isSellerLoggedIn}
                        isShopManagerLoggedIn={isShopManagerLoggedIn}
                        setCurrentStep={setCurrentStep}
                        setState={setState}
                        showSubmitButton={showSubmitButton}
                        //general
                        allSellers={allSellers}
                        costPrice={costPrice}
                        isCostProvided={isCostProvided}
                        name={name}
                        priceRule={priceRule}
                        productStatusOptions={productStatusOptions}
                        productTypeOptions={productTypeOptions}
                        regularPrice={regularPrice}
                        remarks={remarks}
                        saleFrom={saleFrom}
                        salePrice={salePrice}
                        saleTo={saleTo}
                        scheduleSale={scheduleSale}
                        selectedSeller={selectedSeller}
                        selectedStatus={selectedStatus}
                        selectedType={selectedType}
                        //inventory
                        backorders={backorders}
                        backordersOptions={backordersOptions}
                        manageStock={manageStock}
                        sku={sku}
                        soldIndividually={soldIndividually}
                        stockQuantity={stockQuantity}
                        stockStatus={stockStatus}
                        stockStatusOptions={stockStatusOptions}
                        //shipping
                        dimensions={dimensions}
                        weight={weight}
                        //description
                        description={description}
                        shortDescription={shortDescription}
                        //linked-data
                        allBrands={allBrands}
                        allCategories={allProductCategories}
                        allProducts={allProducts}
                        allShopManagers={allShopManagers}
                        crossSells={crossSells}
                        existingTags={existingTags}
                        selectedBrand={selectedBrand}
                        selectedCategories={selectedCategories}
                        selectedShopManager={selectedShopManager}
                        selectedTags={selectedTags}
                        upSells={upSells}
                        //attributes
                        allAttributes={allAttributes}
                        allAttributeTerms={allAttributeTerms}
                        attributes={attributes}
                        variations={variations}
                        //advanced
                        sourceURL={sourceURL}
                        update_threshold={update_threshold}
                        purchaseNote={purchaseNote}
                        //images
                        featuredImage={featuredImage}
                        images={images}
                        showImageGallery={(isMultiple) => { changeValue('showImageGallery', true); changeValue('isMultipleImages', isMultiple) }}
                        //form-refs
                        generalDataForm={generalDataForm}
                        inventoryDataForm={inventoryDataForm}
                        shippingDataForm={shippingDataForm}
                        descriptionDataForm={descriptionDataForm}
                        linkedDataForm={linkedDataForm}
                        attributesDataForm={attributesDataForm}
                        variationsDataForm={variationsDataForm}
                        advancedDataForm={advancedDataForm}
                        imagesDataForm={imagesDataForm}
                    />
                    <div className='p-grid mt-4 w-100 mx-0'>
                        <div className='p-col-12 p-md-6 text-left p-0'>
                            <label className='p-0 validating-label mt-2'>{validationMessage}&nbsp;</label><br />
                        </div>
                        {showSubmitButton &&
                            <div className='p-col-12 p-md-6 text-right p-0'>
                                <CustomButton
                                    id={'formSubmit'}
                                    onClick={checkFormValidity}
                                    bg='Secondary'
                                    color='tertiary'
                                    label="Update"
                                    type="button"
                                    className='w-50'
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ImageGallery
                addBtnHeading={'Choose'}
                modalHeading={'Add Media'}
                modalShow={showImageGallery}
                reference='product_image'
                isProtected={false}
                handleSelectedFiles={(files) => {
                    let selectedImages
                    if (isMultipleImages) {
                        selectedImages = files.map(file => {
                            delete file.selected
                            return file
                        })
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            images: [...prevState.images, ...selectedImages],
                        }))
                    }
                    else {
                        selectedImages = files[0]
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            featuredImage: selectedImages,
                            validationMessage: ''
                        }))
                    }
                }}
                hideImageGallery={() => { changeValue('showImageGallery', false) }}
                isMultiple={isMultipleImages}
            />
            <LoaderModal
                modalShow={isLoading}
            />
        </Can >
    )
}
