import React from 'react';
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { MDBTooltip } from 'mdbreact'
import getOptions from '../../../../misc/components/Options'

export const LinkedDataStep = (props) => {
    let {
        ability,
        APO_product,
        currentStep,
        handleCreateNewTag,
        handleSelectChange,
        isSellerLoggedIn,

        allBrands,
        allCategories,
        allProducts,
        allShopManagers,
        crossSells,
        existingTags,
        linkedDataForm,
        selectedBrand,
        selectedCategories,
        selectedSeller,
        selectedShopManager,
        selectedTags,
        upSells,
    } = props

    let productOptions = getOptions('products', allProducts),
        categoryOptions = getOptions('productCategories', allCategories),
        brandOptions = getOptions('brands', allBrands),
        shopManagerOptions = getOptions('shopManagers', allShopManagers)

    existingTags.length && existingTags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    let tagOptions = getOptions('tags', existingTags)

    // if (currentStep !== 5) {
    //     return null
    // }
    return (
        <form ref={linkedDataForm} className={`m-0 p-0 ${currentStep !== 5 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                >
                    <div className='mb-3 col-md-6'>
                        <p className="d-block color-dark mb-2">Upsells</p>
                        <MyOwnSelect
                            options={productOptions}
                            optionsHeading={upSells && upSells.length ? 'Upsells' : ''}
                            handleChange={handleSelectChange('upSells')}
                            placeHolder='Search for a product...'
                            selectedValue={upSells}
                            className='border-radius-0'
                            withOutIcon
                            isMulti
                            async
                            loadingMessage={"Searching..."}
                            noOptionsMessage={"No matches found."}
                            minCharLength={3}
                            isDisabled={APO_product === 'read'}
                        />
                    </div>
                    <i>
                        Upsells are products which you recommend instead of the currently viewed product, for example, products that are
                        more profitable or better quality or more expensive.
                            </i>
                </MDBTooltip>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                >
                    <div className='mb-3 col-md-6'>
                        <p className="d-block color-dark mb-2">Cross-sells</p>
                        <MyOwnSelect
                            options={productOptions}
                            optionsHeading={crossSells && crossSells.length ? 'Cross-sells' : ''}
                            handleChange={handleSelectChange('crossSells')}
                            placeHolder='Search for a product...'
                            selectedValue={crossSells}
                            className='border-radius-0 w-100'
                            withOutIcon
                            isMulti
                            async
                            loadingMessage={"Searching..."}
                            noOptionsMessage={"No matches found."}
                            minCharLength={3}
                            isDisabled={APO_product === 'read'}
                        />
                    </div>
                    <i>Cross-sells are products which you promote in the cart, based on the current product.</i>
                </MDBTooltip>
                <div className='mb-3 col-md-6'>
                    <p className="d-block color-dark mb-2">Categories</p>
                    <MyOwnSelect
                        options={categoryOptions}
                        optionsHeading={selectedCategories?.length ? 'Select product categories' : ''}
                        handleChange={handleSelectChange('selectedCategories')}
                        placeHolder='Select product categories'
                        selectedValue={selectedCategories}
                        className='border-radius-0'
                        withOutIcon
                        isMulti
                        isDisabled={APO_product === 'read'}

                    />
                </div>
                <MDBTooltip
                    domElement
                    tag="span"
                    placement="right"
                    className='bg-Primary'
                >
                    <div className='mb-3 col-md-6'>
                        <p className="d-block color-dark mb-2">Tags</p>
                        <MyOwnSelect
                            options={tagOptions}
                            optionsHeading={selectedTags?.length ? 'Select tags' : ''}
                            handleChange={handleSelectChange('selectedTags')}
                            placeHolder={tagOptions && tagOptions.length > 0 ? 'Enter something' : "be sure! you are connected to the internet."}
                            selectedValue={selectedTags}
                            className='border-radius-0'
                            withOutIcon
                            isMulti
                            creatable
                            handleCreate={handleCreateNewTag}
                            isDisabled={APO_product === 'read'}
                        />
                    </div>
                    <i>
                        {`If your desired tag is not in list, enter your tag and click on "create <your tag> option.`}
                    </i>
                </MDBTooltip>
                <div className='mb-3 col-md-6'>
                    <p className="d-block color-dark mb-2">Brand</p>
                    <MyOwnSelect
                        options={brandOptions}
                        optionsHeading={selectedBrand ? 'Select a brand' : ''}
                        handleChange={handleSelectChange('selectedBrand')}
                        placeHolder='Select a brand'
                        selectedValue={selectedBrand}
                        className='border-radius-0'
                        withOutIcon
                        isDisabled={APO_product === 'read'}

                    />
                </div>
                {ability.can('linkShopManagers', 'products') && (isSellerLoggedIn || selectedSeller) &&
                    // {!isShopManagerLoggedIn && (isSellerLoggedIn || selectedSeller) &&
                    <>
                        <div className='mb-3 col-md-6'>
                            <p className="d-block color-dark mb-2">Shop manager</p>
                            <MyOwnSelect
                                options={shopManagerOptions}
                                optionsHeading={selectedShopManager ? 'Select a shop manager' : ''}
                                handleChange={handleSelectChange('selectedShopManager')}
                                placeHolder='Select a shop manager'
                                selectedValue={selectedShopManager}
                                className='border-radius-0'
                                withOutIcon
                                isDisabled={APO_product === 'read'}
                            />
                        </div>
                    </>
                }

            </div>
        </form>
    )
}
