import React, { useState, useEffect, useRef } from 'react';
import { Can } from '../../../../configs/Ability-context'
import LoaderModal from '../../../misc/components/LoaderModal'
import { CustomInput, SubmitButton } from '../../../misc/components/Inputs'
import { setElementDisability, checkUniqueness, capitalize } from '../../../misc/components/Helpers'
import { getPriceRule, createPriceRule, updatePriceRule } from '../../../../services/users/price-rule-service';
import { Dialog } from 'primereact/dialog';
import CustomSelect from '../../../misc/components/CustomSelect'
import CustomTable from '../../../misc/components/CustomTable'

let _isMounted
const INITIAL_STATE = {
    name: '',
    min_price: null,
    max_price: null,
    commission_type: '',
    commission_value: null,
    rules: []
};
const PriceRule = (props) => {
    const [{
        name, min_price, max_price, commission_type, commission_value, rules,
        validationMessage, isLoading
    }, setState] = useState({
        ...INITIAL_STATE, validationMessage: '', isLoading: false
    })

    let detailsForm = useRef(null)
    let nameForm = useRef(null)
    let { rule, APO_rule, modalShow, hideModal } = props

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getPriceRule(rule)
                if (success) {
                    let { name } = data
                    setState(prevState => ({
                        ...prevState,
                        name: name,
                        rules: data.price_rules_details?.map((r, index) => ({
                            key: index,
                            min_price: r.min_price,
                            max_price: r.max_price,
                            commission_type: r.commission_type,
                            commission_value: r.commission_value,
                            price_rule_id: rule
                        }))
                    }));
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        (APO_rule === 'update' || APO_rule === 'read') && modalShow && fetchData()
        return () => {
            setState(prevState => ({
                ...prevState,
                ...INITIAL_STATE,
                validationMessage: ''
            }))
            _isMounted = false
        }
    }, [modalShow])

    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value, })) }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
        changeValue('validationMessage', '')
    }

    const makeStateEmpty = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            ...INITIAL_STATE
        }))
    }

    const handleAddRule = (e) => {
        e.preventDefault();
        let form = detailsForm.current
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        else if (!commission_type) {
            changeValue('validationMessage', 'Please select a commission type')
            return
        }
        else {
            let newRule = {
                key: rules.length,
                price_rule_id: APO_rule === 'update' ? rule : undefined,
                min_price: min_price,
                max_price: max_price,
                commission_type: commission_type,
                commission_value: commission_value,
            }
            let updatedRules = [...rules, newRule]
            _isMounted && setState(prevState => ({
                ...prevState,
                rules: updatedRules,
                min_price: null,
                max_price: null,
                commission_type: '',
                commission_value: null,
            }))
            // console.log('updatedRules', updatedRules)
        }
    }

    const handleSubmit = async (e) => {
        let form = nameForm.current
        if (!form.checkValidity()) {
            changeValue('validationMessage', 'Invalid name')
            form.classList.add('was-validated');
            return
        }
        else if (!rules.length) {
            changeValue('validationMessage', 'Please describe some details for this rule')
            return
        }
        else {
            // console.log('rules', rules)
            let PriceRule = {
                name: name
            }
            try {
                const { success, message } = APO_rule === 'create' ?
                    await createPriceRule(PriceRule, rules.map(r => {
                        delete r.key
                        delete r.price_rule_id
                        return r
                    }))
                    :
                    await updatePriceRule(rule, rules.map(r => {
                        delete r.key
                        return r
                    }))
                changeValue('validationMessage', message)
                if (success) {
                    // if (APO_method === 'create') {
                    // }
                    setTimeout(() => {
                        makeStateEmpty()
                        hideModal(true)
                    }, 750)
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    const nameInput = <CustomInput
        type='text'
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        onFocus={() => { setElementDisability('formSubmit', true, 'p-disabled', true) }}
        onBlur={(e) => { checkUniqueness(e, 'price_rules', 'name', 'formSubmit', 'p-disabled') }}
        invalidMessage={name ? 'Price rule with this name already exists' : 'This field is mandatory'}
        disabled={APO_rule !== 'create'}
        required
    />
    const maxPriceInput = <CustomInput
        type='number'
        label={'Max. price'}
        name={'max_price'}
        value={max_price}
        handleInput={handleInput}
        required
    />
    const minPriceInput = <CustomInput
        type='number'
        label={'Min. price'}
        name={'min_price'}
        value={min_price}
        handleInput={handleInput}
        required
    />
    const commissionTypeInput = <CustomSelect
        value={commission_type}
        options={[{ key: 1, label: 'Percentage', value: 'percentage' }, { key: 2, label: 'Fixed', value: 'fixed' }]}
        handleChange={(e) => changeValue('commission_type', e.value)}
        placeholder={'Commision type*'}
        className={`font-weight-bold color-primary-light mb-4`}
    />
    const commissionValueInput = <CustomInput
        type='number'
        label={'Commission value'}
        name={'commission_value'}
        value={commission_value}
        handleInput={handleInput}
        required
    />
    const addRuleInput = <SubmitButton
        label={'Add'}
        className="w-100"
        color='secondary'
        type="submit"
    />
    const submitBtn = <SubmitButton
        id='formSubmit'
        label={APO_rule}
        className="w-100"
        color='secondary'
        onClick={handleSubmit}
        type="button"
    />
    const validatingLabel = <label className='p-0 m-0 validating-label'>{validationMessage}&nbsp;</label>
    const fieldsToDisplay1 = [minPriceInput, maxPriceInput, commissionTypeInput, commissionValueInput, addRuleInput]


    const onEditorValueChange = (props, value) => {
        let updatedRules = [...props.value];
        updatedRules[props.rowIndex][props.field] = value;
        changeValue('rules', updatedRules)
    }

    const InputNumberEditor = (field, props) => {
        return <CustomInput
            type='number'
            noLabel
            value={props.rowData[field]}
            handleInput={(e) => onEditorValueChange(props, e.target.value)}
        />
    }

    const CommissionTypeEditor = (field, props) => {
        return (
            <CustomSelect
                value={props.rowData[field]}
                options={[{ key: 1, label: 'Percentage', value: 'percentage' }, { key: 2, label: 'Fixed', value: 'fixed' }]}
                handleChange={(e) => onEditorValueChange(props, e.value)}
                placeholder={'Commision type*'}
                className={``}
            />
        );
    }

    const tableColumns = [
        {
            field: 'min_price',
            header: 'Min. price',
            editor: (props) => InputNumberEditor('min_price', props),
            headerStyle: { width: '150px' }
        },
        {
            field: 'max_price',
            header: 'Max. price',
            editor: (props) => InputNumberEditor('max_price', props),
            headerStyle: { width: '150px' }
        },
        {
            field: 'commission_type',
            header: 'Commissino type',
            body: (rowData) => capitalize(rowData.commission_type),
            editor: (props) => CommissionTypeEditor('commission_type', props),
            headerStyle: { width: '150px' }
        },
        {
            field: 'commission_value',
            header: 'Commision value',
            editor: (props) => InputNumberEditor('commission_value', props),
            headerStyle: { width: '150px' }
        }
    ];
    // console.log('rules', rules)
    return (
        <Can I={APO_rule} a='priceRules' >
            <Dialog
                header={<p className='color-tertiary m-0 p-0'>{`${APO_rule === 'create' ? 'New' : 'Update'} Price rule`}</p>}
                modal
                onHide={() => { hideModal(); makeStateEmpty() }}
                style={{ width: '60vw' }}
                breakpoints={{ '960px': '75vw', '768px': '80vw', }}
                visible={modalShow}
            >
                <div className='m-0 px-0 pt-3 p-grid p-align-center'>
                    <form ref={nameForm} className='m-0 p-0 p-grid p-justify-end w-100 color-tertiary' noValidate>
                        <div className='m-0 col-12'>{nameInput}</div>
                    </form>
                    {
                        APO_rule !== 'read' &&
                        <form ref={detailsForm} className='m-0 p-0 p-grid w-100 color-tertiary' onSubmit={handleAddRule} noValidate>
                            {fieldsToDisplay1.map((field, index) => <div key={index + 1} className='m-0 col-sm-6'>{field}</div>)}
                            {rules?.length ?
                                <div className='m-0 col-sm-12'>
                                    <CustomTable
                                        className='p-my-4 p-mx-0'
                                        records={rules}
                                        columns={tableColumns}
                                        setRecords={(records) => changeValue('rules', records)}
                                        deleteButton={APO_rule !== 'read'}
                                        editButton={APO_rule !== 'read'}
                                    />
                                </div>
                                :
                                <></>
                            }
                            <div className='m-0 col-sm-6'>
                                {APO_rule !== 'read' && submitBtn}
                            </div>
                        </form>
                    }
                </div>
                <div className='m-0 p-0 p-grid p-align-center'>
                    <div className='m-0 mb-3 col-sm-6 text-center'>
                        {validatingLabel}
                    </div>
                </div>
            </Dialog>
            <LoaderModal modalShow={isLoading} />
        </Can >
    );
}


export default PriceRule