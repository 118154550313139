import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { Input, ActionButton } from '../../../../misc/components/Inputs'
import { handleNumberInputKeyPress } from '../../../../misc/components/Helpers'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { CustomDatePicker } from '../../../../misc/components/CustomDatePicker'


export const GeneralDataStep = (props) => {

    let {
        APO_order,
        bkt_order_id,
        calculateAmount,
        changeValue,
        currentStep,
        customerNote,
        date,
        discountDetail,
        generalDataForm,
        handleInput,
        handleNumberInputBlur,
        handleSelectChange,
        isMakingAQuery,
        orderDiscount,
        orderNote,
        orderSourceOptions,
        orderStatusOptions,
        orderTypeOptions,
        selectedOrderSource,
        selectedOrderStatus,
        selectedOrderType,
        totalField
    } = props

    const dateInput = <CustomDatePicker
        selectedValue={date}
        handleChange={(value) => {
            handleSelectChange('date')(value);
        }}
        label='Order date'
        isClearable
        isRequired
        isFormField
        isDisabled={APO_order !== 'create'}
    />
    const sourceInput = <MyOwnSelect
        options={orderSourceOptions}
        optionsHeading={selectedOrderSource ? 'Select order source' : ''}
        handleChange={handleSelectChange('selectedOrderSource')}
        placeHolder={'Select order source'}
        selectedValue={selectedOrderSource}
        withOutIcon
        required
        isDisabled={APO_order !== 'create'}
        isOptionDisabled={option => option.title === 'bucket.pk'}
    />
    // const statusInput = <MyOwnSelect
    //     options={orderStatusOptions}
    //     optionsHeading={selectedOrderStatus ? 'Select order status' : ''}
    //     handleChange={handleSelectChange('selectedOrderStatus')}
    //     placeHolder={'Select order status'}
    //     selectedValue={selectedOrderStatus}
    //     withOutIcon
    //     required
    //     isDisabled={APO_order === 'read'}
    //     isOptionDisabled={option => (isMakingAQuery ?
    //         (option.label !== 'Draft') :
    //         (option.label === 'Draft'))}
    // />
    const typeInput = <MyOwnSelect
        options={orderTypeOptions}
        optionsHeading={selectedOrderType ? 'Select order type' : ''}
        handleChange={handleSelectChange('selectedOrderType')}
        placeHolder={'Select order type'}
        selectedValue={selectedOrderType}
        withOutIcon
        required
        isDisabled={APO_order !== 'create'}
        isOptionDisabled={option => option.label === "Return" || option.label === "Replacement"}
    />
    const discountInput = <Input
        type="number"
        label={`Order discount`}
        name={`orderDiscount`}
        value={orderDiscount}
        min={0}
        max={calculateAmount('maxOrderDiscount')}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={handleNumberInputBlur}
        containerClass='mb-3'
        disabled={APO_order === 'read' || isMakingAQuery}
        invalidMessage={`Please enter a valid discount.`}
    />
    const discountDetailInput = <Input
        type={'textarea'}
        label={`Discount detail`}
        name={`discountDetail`}
        value={discountDetail}
        handleInput={handleInput}
        rows={1}
        containerClass='mb-3'
        required={orderDiscount && orderDiscount != 0}
        disabled={APO_order === 'read' || isMakingAQuery}
        invalidMessage={`Please enter discount reason.`}
    />
    const totalDiscountInput = <Input
        label={`Total discount`}
        name={`totalDiscount`}
        value={calculateAmount('totalDiscount')}
        containerClass='mb-3'
        disabled
    />
    const orderIdInput = APO_order === 'create' ?
        <div className='input-group'>
            {selectedOrderSource &&
                <div className="input-group-prepend" style={{ height: '39px' }}>
                    <span className="input-group-text bg-white color-Primary border-radius-0 w-100">
                        {`${selectedOrderSource.title} -`}
                    </span>
                </div>
            }
            <Input
                type='number'
                label='Order id'
                name={`bkt_order_id`}
                value={bkt_order_id}
                handleInput={handleInput}
                onKeyPress={handleNumberInputKeyPress}
                hint={''}
                containerClass={`mb-3 form-control border-radius-0`}
                style={{ height: '37px' }}
                required
                disabled={!selectedOrderSource}
                invalidMessage={`Please enter a valid order id.`}
            />
        </div>
        :
        <Input
            label='Order id'
            name={`bkt_order_id`}
            value={bkt_order_id}
            containerClass='mb-3'
            disabled
        />

    const codInput = <Input
        type="number"
        label="Total amount (COD)"
        value={calculateAmount('totalAmount')}
        containerClass='mb-3'
        setRef={(ref) => { totalField.current = ref }}
        disabled
    />
    const orderNoteInput = <Input
        type={'textarea'}
        label={`Order note`}
        name={`orderNote`}
        value={orderNote}
        handleInput={handleInput}
        rows={2}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
    />
    const customerNoteInput = <Input
        type={'textarea'}
        label="Customer note"
        name={`customerNote`}
        value={customerNote}
        handleInput={handleInput}
        rows={2}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
    />
    return (
        <form ref={generalDataForm} className={`m-0 p-0 ${currentStep === 1 ? 'd-block' : 'd-none'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6'>
                    {dateInput}
                </div>
                <div className='m-0 col-sm-6'>
                    <div className='mb-3 p-0'>
                        {typeInput}
                    </div>
                </div>
                <div className='m-0 col-sm-6'>
                    <div className='mb-3 p-0'>
                        {sourceInput}
                    </div>
                </div>
                <div className='m-0 col-sm-6'>
                    {orderIdInput}
                </div>
                {
                    !isMakingAQuery &&
                    <>
                        <div className='m-0 col-sm-6'>
                            {discountInput}
                        </div>
                        {
                            orderDiscount != 0 &&
                            orderDiscount !== '' &&
                            <div className='m-0 col-sm-6'>
                                {discountDetailInput}
                            </div>
                        }
                        <div className={`m-0 col-sm-6`}>
                            {totalDiscountInput}
                        </div>
                        <div className={`m-0 col-sm-6`}>
                            {codInput}
                        </div>
                    </>
                }
                <div className='m-0 col-12'>
                    {orderNoteInput}
                </div>
                <div className='m-0 col-12'>
                    {customerNoteInput}
                </div>
                {/* {APO_order !== 'create' &&
                        <div className='mb-3 p-0'>
                            {statusInput}
                        </div >
                    } */}
            </div>
        </form>
    )
}
