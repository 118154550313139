import React, { useEffect, useState, useRef, useContext } from 'react'
import { Can } from '../../../configs/Ability-context'
import LoaderModal from '../../misc/components/LoaderModal'
import ImageGallery from '../../misc/components/ImageGallery'
import getOptions from '../../misc/components/Options'
import { CustomButton } from '../../misc/components/Inputs'
import { ProductForm } from './sections/ProductForm'
import { UserContext } from '../../../contexts/UserContext'
import { AppContext } from '../../../contexts/AppContext'
import { useHistory } from 'react-router-dom'

import { createProduct, getProducts } from '../../../services/products/product-service'
import { getCategories } from '../../../services/products/category-service'
import { getBrands } from '../../../services/products/brand-service'
import { getAttributeTerms, getAttributesWithData } from '../../../services/products/attribute-service'
import { getTags } from '../../../services/products/tag-service'
import { getCustomUsers, getCustomChilds, getUserPriceRule } from '../../../services/users/user-service';
import { Toast } from 'primereact/toast';

const productTypeOptions = getOptions('productTypes'),
    stockStatusOptions = getOptions('stockStatuses'),
    backordersOptions = getOptions('backorders'),
    productStatusOptions = getOptions('productStatuses')
let _isMounted

const INITIAL_STATE = {
    name: '',
    selectedType: productTypeOptions[0],
    selectedStatus: productStatusOptions[0],
    update_threshold: 90,
    featured: false,

    priceRule: null,
    isCostProvided: false,
    regularPrice: '',
    costPrice: '',
    salePrice: '',
    saleFrom: '',
    saleTo: '',
    scheduleSale: false,

    sku: '',
    sourceURL: null,
    manageStock: false,
    stockQuantity: '',
    stockStatus: stockStatusOptions[0],
    backorders: backordersOptions[0],
    soldIndividually: false,

    weight: '',
    dimensions: { length: '', width: '', height: '' },

    upSells: [],
    crossSells: [],

    description: '',
    shortDescription: '',
    purchaseNote: '',

    attributes: [],
    variations: [],

    selectedCategories: [],
    selectedTags: [],
    selectedBrand: '',
    selectedSeller: '',
    selectedShopManager: '',

    featuredImage: null,
    images: [],
    showImageGallery: false,
    isMultipleImages: true,

    showSubmitButton: true,
    isLoading: false,
}
export const NewProduct = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const [{
        name, selectedType, update_threshold, selectedStatus, featured,
        priceRule, isCostProvided, regularPrice, costPrice, salePrice, saleFrom, saleTo, scheduleSale,
        sku, sourceURL, manageStock, stockQuantity, stockStatus, backorders, soldIndividually,
        weight, dimensions,
        upSells, crossSells,
        description, shortDescription, purchaseNote,
        attributes, variations,
        selectedCategories, selectedTags,
        selectedBrand, selectedSeller, selectedShopManager,
        featuredImage, images, showImageGallery, isMultipleImages,
        allProducts, allAttributes, allAttributeTerms,
        allProductCategories, allBrands, allSellers, allShopManagers, existingTags,
        isLoading,
        validationMessage, refreshData, showSubmitButton
    }, setState] = useState({
        ...INITIAL_STATE,
        allAttributes: [], allAttributeTerms: [],
        allProducts: [], allProductCategories: [],
        allBrands: [], allSellers: [], allShopManagers: [], existingTags: [],
        validationMessage: '', refreshData: false,
    })

    let generalDataForm = useRef(null)
    let attributesDataForm = useRef(null)
    let advancedDataForm = useRef(null)
    let descriptionDataForm = useRef(null)
    let imagesDataForm = useRef(null)
    let inventoryDataForm = useRef(null)
    let linkedDataForm = useRef(null)
    let shippingDataForm = useRef(null)
    let variationsDataForm = useRef(null)
    let isVariableProduct = selectedType.value === 'variable'

    const { appURL,appName } = useContext(AppContext)
    const { isSellerLoggedIn, isShopManagerLoggedIn } = useContext(UserContext)
    let history = useHistory()
    let toast = useRef(null)

    useEffect(() => {
        _isMounted = true
        const fetchIniialData = async () => {
            changeValue('isLoading', true)
            let Sellers, PriceRule
            try {
                if (isSellerLoggedIn || isShopManagerLoggedIn) {
                    let { data } = await getUserPriceRule()
                    PriceRule = data
                    Sellers = []
                }
                else {
                    let { data } = await getCustomUsers('seller')
                    Sellers = data
                }
                // fetchMeta()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allSellers: Sellers,
                    priceRule: PriceRule
                }))
            } catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchMeta = async () => {
            try {
                const Products = await getProducts()
                const Categories = await getCategories()
                const Attributes = await getAttributesWithData()
                const AttributeTerms = await getAttributeTerms()
                const Brands = await getBrands()
                const Tags = await getTags()
                const ShopManagers = isSellerLoggedIn ?
                    await getCustomChilds(0, 'shop_manager')
                    :
                    []
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allProducts: Products.data,
                    allProductCategories: Categories.data,
                    allAttributes: Attributes.data,
                    allAttributeTerms: AttributeTerms.data,
                    allBrands: Brands.data,
                    existingTags: Tags.data,
                    allShopManagers: Array.isArray(ShopManagers) ?
                        []
                        :
                        ShopManagers.data,
                }))
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchIniialData()
        fetchMeta()
        return () => {
            _isMounted = false
        }
    }, [])

    //setting price-rule and shop-managers when seller changed
    useEffect(() => {
        const setSellerSpecificData = async () => {
            if (selectedSeller) {
                try {
                    let { data } = await getUserPriceRule(selectedSeller.value)
                    // console.log(data)
                    changeValue('priceRule', data)
                }
                catch (error) {
                    console.log(error)
                }
                if (!isSellerLoggedIn && !isShopManagerLoggedIn) {
                    try {
                        let { data } = await getCustomChilds(selectedSeller.value, 'shop_manager')
                        changeValue('allShopManagers', data)
                    }
                    catch (error) {
                        console.log(error)
                    }
                }
            }
        }
        setSellerSpecificData()
        _isMounted && setState(prevState => ({
            ...prevState,
            regularPrice: '',
            salePrice: '',
            costPrice: '',
            saleFrom: null,
            saleTo: null,
            isCostProvided: false,
            variations: prevState.variations.map(v => ({
                ...v,
                regular_price: '',
                sale_price: '',
                cost_price: '',
                date_on_sale_from: null,
                date_on_sale_to: null,
                is_cost_provided: false,
                status: 'pending'
            }))
        }))
    }, [selectedSeller])

    const changeValue = (name, value) => {
        if (name === 'length' || name === 'width' || name === 'height') {
            _isMounted && setState(prevState => ({
                ...prevState,
                dimensions: {
                    ...prevState.dimensions,
                    [name]: value,
                }
            }))
        }
        else { _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: name === 'validationMessage' ? value : '' })) }
    }

    const checkFormValidity = () => {
        // if (!selectedSeller) {
        //     setState(prevState => ({
        //         ...prevState,
        //         selectedSeller: null,
        //         validationMessage: 'Please select product seller'
        //     }))
        //     setCurrentStep(1)
        //     return
        // }
        if (scheduleSale) {
            if (!saleFrom) {
                changeValue('validationMessage', 'Please select sale from date')
                setCurrentStep(1)
                return
            }
            else if (!saleTo) {
                changeValue('validationMessage', 'Please select sale to date')
                setCurrentStep(1)
                return
            }
        }
        if (!manageStock) {
            if (!stockStatus && !isVariableProduct) {
                setState(prevState => ({
                    ...prevState,
                    stockStatus: null,
                    validationMessage: 'Please select stock status'
                }))
                setCurrentStep(2)
                return
            }
        }
        if (isVariableProduct && !variations.length) {
            changeValue('validationMessage', 'Please add some variations for your product.')
            setCurrentStep(7)
            return
        }
        if (!featuredImage) {
            changeValue('validationMessage', 'Please select a featured image for your product.')
            setCurrentStep(9)
            return
        }
        let forms = [
            generalDataForm, inventoryDataForm, shippingDataForm, descriptionDataForm,
            linkedDataForm, attributesDataForm, variationsDataForm, advancedDataForm, imagesDataForm
        ]
        let isValidated
        for (let i = 0; i < forms.length; i++) {
            // console.log(i)
            const form = forms[i];
            // console.log(form)
            if (!form.current.checkValidity()) {
                console.log(form)
                setCurrentStep(i + 1)
                setTimeout(() => {
                    form.current.classList.add('was-validated')
                }, 250);
                isValidated = false
                break;
            }
            isValidated = true
        }
        if (isValidated) {
            createNewProduct()
        }
        else return
    }

    const createNewProduct = async () => {
        changeValue('isLoading', true)
        try {
            let newProduct = {
                name: name, type: selectedType.value, status: selectedStatus.value, featured: featured,

                is_cost_provided: isCostProvided,
                regular_price: isVariableProduct ? '' : regularPrice,
                cost_price: isVariableProduct ? '' : costPrice,
                sale_price: isVariableProduct ? '' : salePrice,
                date_on_sale_from: scheduleSale ? saleFrom : null,
                date_on_sale_to: scheduleSale ? saleTo : null,

                sku: sku ? sku : null, source_url: sourceURL ? sourceURL : null, manage_stock: manageStock, stock_quantity: manageStock ? stockQuantity : null, stock_status: stockStatus.value, backorders: backorders.value,
                sold_individually: soldIndividually,
                update_threshold: update_threshold,

                weight: weight, dimensions: dimensions,

                upsell_ids: upSells.map(product => product.value),
                cross_sell_ids: crossSells.map(product => product.value),

                description: description,
                short_description: shortDescription,
                purchase_note: purchaseNote,

                attributes: attributes.map(attr => ({
                    ...attr,
                    options: attr.options.map(option => {
                        if (option.label !== 'Select all')
                            return option.label
                        else return null
                    }).filter(option => option)
                    // return attr
                })),
                default_attributes: [],

                categories: selectedCategories.map(category => ({ id: category.value })),
                tags: selectedTags.length > 0 && selectedTags.map(tag => ({ id: tag.value })),
                images: (images.length ? [featuredImage, ...images] : [featuredImage]).map(image => (
                    { alt: `${appName}`, src: `${appURL}images/${image.name}` }))
            },
                brandId = selectedBrand ? selectedBrand.value : null,
                sellerId = selectedSeller ? selectedSeller.value : null,
                shopManagerId = selectedShopManager ? selectedShopManager.value : null
            const newVariations = selectedType.value === 'simple' ? [] : variations.map(variation => ({
                ...variation,
                is_cost_provided: variation.is_cost_provided,
                attributes: variation.attributes.map(attr => attr.value),
                image: variation.image ? { alt: `${appName}`, src: `${appURL}images/${variation.image.name}` } : null,
                stock_status: variation.stock_status?.value,
                backorders: variation.backorders?.value
            }))
            // console.log(newProduct, variations)

            const { message, success } = await createProduct(newProduct, brandId, sellerId, shopManagerId, newVariations)
            // console.log("message");
            // console.log(message);
            // changeValue('validationMessage', message)
            if (success) {
                toast.current?.show({ severity: 'success', summary: 'Success', detail: `Product Created Successfully!` })
                setTimeout(() => {
                    history.push('/products/all')
                }, 750)
            }
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    return (
        <Can I={'create'} a='products'>
            {/* h-sm-100vh */}
            <div className={`screen-center mt-4 pt-5 m-sm-0 py-sm-0 `}>
                <h2 className="mb-4 p-0 color-dark font-weight-normal">{`New product`}</h2>
                <div className='p-d-flex p-flex-column pt-2 w-100 w-lg-75 px-4 px-sm-2 p-lg-0'>
                    <ProductForm
                        //universal
                        _isMounted
                        APO_product={'create'}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        isVariableProduct={isVariableProduct}
                        isSellerLoggedIn={isSellerLoggedIn}
                        isShopManagerLoggedIn={isShopManagerLoggedIn}
                        setCurrentStep={setCurrentStep}
                        setState={setState}
                        showSubmitButton={showSubmitButton}
                        refreshData={refreshData}
                        //general
                        allSellers={allSellers}
                        costPrice={costPrice}
                        isCostProvided={isCostProvided}
                        name={name}
                        priceRule={priceRule}
                        productStatusOptions={productStatusOptions}
                        productTypeOptions={productTypeOptions}
                        regularPrice={regularPrice}
                        saleFrom={saleFrom}
                        salePrice={salePrice}
                        saleTo={saleTo}
                        scheduleSale={scheduleSale}
                        selectedSeller={selectedSeller}
                        selectedStatus={selectedStatus}
                        selectedType={selectedType}
                        //inventory
                        backorders={backorders}
                        backordersOptions={backordersOptions}
                        manageStock={manageStock}
                        sku={sku}
                        soldIndividually={soldIndividually}
                        stockQuantity={stockQuantity}
                        stockStatus={stockStatus}
                        stockStatusOptions={stockStatusOptions}
                        //shipping
                        dimensions={dimensions}
                        weight={weight}
                        //description
                        description={description}
                        shortDescription={shortDescription}
                        //linked-data
                        allBrands={allBrands}
                        allCategories={allProductCategories}
                        allProducts={allProducts}
                        allShopManagers={allShopManagers}
                        crossSells={crossSells}
                        existingTags={existingTags}
                        selectedBrand={selectedBrand}
                        selectedCategories={selectedCategories}
                        selectedShopManager={selectedShopManager}
                        selectedTags={selectedTags}
                        upSells={upSells}
                        //attributes
                        allAttributes={allAttributes}
                        allAttributeTerms={allAttributeTerms}
                        attributes={attributes}
                        variations={variations}
                        //advanced
                        sourceURL={sourceURL}
                        update_threshold={update_threshold}
                        purchaseNote={purchaseNote}
                        //images
                        featuredImage={featuredImage}
                        images={images}
                        showImageGallery={(isMultiple) => { changeValue('showImageGallery', true); changeValue('isMultipleImages', isMultiple) }}
                        //form-refs
                        generalDataForm={generalDataForm}
                        inventoryDataForm={inventoryDataForm}
                        shippingDataForm={shippingDataForm}
                        descriptionDataForm={descriptionDataForm}
                        linkedDataForm={linkedDataForm}
                        attributesDataForm={attributesDataForm}
                        variationsDataForm={variationsDataForm}
                        advancedDataForm={advancedDataForm}
                        imagesDataForm={imagesDataForm}
                    />
                    <div className='p-grid mt-4 w-100 mx-0'>
                        <div className='p-col-12 p-md-6 text-left p-0'>
                            <label className='p-0 validating-label mt-2'>{validationMessage}&nbsp;</label><br />
                        </div>
                        {showSubmitButton &&
                            <div className='p-col-12 p-md-6 text-right p-0'>
                                <CustomButton
                                    id={'formSubmit'}
                                    onClick={checkFormValidity}
                                    bg='Secondary'
                                    color='tertiary'
                                    label="Create"
                                    type="button"
                                    className='w-50'
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ImageGallery
                addBtnHeading={'Choose'}
                modalHeading={'Add Media'}
                modalShow={showImageGallery}
                reference='product_image'
                isProtected={false}
                handleSelectedFiles={(files) => {
                    let selectedImages
                    if (isMultipleImages) {
                        selectedImages = files.map(file => {
                            delete file.selected
                            return file
                        })
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            images: [...prevState.images, ...selectedImages],
                        }))
                    }
                    else {
                        selectedImages = files[0]
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            featuredImage: selectedImages,
                            validationMessage: ''
                        }))
                    }
                }}
                hideImageGallery={() => { changeValue('showImageGallery', false) }}
                isMultiple={isMultipleImages}
            />
            <LoaderModal
                modalShow={isLoading}
            />
            <Toast ref={toast} className='p-mt-5 p-mr-3' />
        </Can >
    )
}
