export const getAttributeTerms = async () => {
    try {
        const res = await fetch(`/api/attributes/terms`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getAttributesWithData = async () => {
    try {
        const res = await fetch(`/api/attributes/data`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const syncAttributes = async () => {
    try {
        const res = await fetch(`/api/attributes/sync`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}