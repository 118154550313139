import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext'
import { Switch, Route,Redirect } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute'
import NotFound from './pages/NotFound';
import SecuredLogin from '../auth/SecuredLogin';
import {ResetPassword,ProcessResetPassword,NewPasswordForm} from '../auth/pages/ResetPassword';
import Sellers from '../users/sellers/Sellers';
import Home from '../basic/Home';
import About from '../public/About';
import Contact from '../public/Contact';
import Terms from '../public/Terms';
import Dashboard from '../dashboard/pages/Dashboard';
import SellerDashboard from '../dashboard/pages/SellerDashboard';
import Users from '../users/Users';
import Products from '../products/Products';
import Orders from '../orders/Orders';
// import Vouchers from '../vouchers/Vouchers';
import Payments from '../payments/Payments';
import PriceRules from '../priceRules/PriceRules';
import AppSettings from '../appSettings/AppSettings';




const AllRoutes = () => {
    const { isLoggedIn } = useContext(UserContext)
    const { emailVerified } = useContext(UserContext)

    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/" render={() => <Home isLoggedIn={isLoggedIn} />} />
                <Route path="/home" render={() => <Home isLoggedIn={isLoggedIn} />} />
                <Route exact path="/login" render={() => <SecuredLogin isLoggedIn={isLoggedIn} emailVerified={emailVerified} />} />
                <Route path="/resetpassword" render={() => <ResetPassword />} />
                <Route path="/processresetpassword" component={ProcessResetPassword} />
                <Route path="/newpassword" component={NewPasswordForm} />
                <PrivateRoute path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/seller_dashboard">
                    <SellerDashboard />
                </PrivateRoute>
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/terms" component={Terms} />
                <Route path="/sellers" component={Sellers} />
                <Route path="/users" component={Users} />
                <Route path="/products" component={Products} />
                <Route path="/orders" component={Orders} />
                {/* <Route path="/vouchers" render={( ) => <Vouchers   />} /> */}
                <PrivateRoute path="/price-rules">
                    <PriceRules />
                </PrivateRoute>
                <Route path="/payments" component={Payments} />
                <Route path="/settings" component={AppSettings} />
                <Route component={NotFound} />
            </Switch>
        </React.Fragment>
    )
}


export default AllRoutes