import React from 'react'
import getOptions from '../../../../misc/components/Options'
import { Input, ActionButton } from '../../../../misc/components/Inputs'
import { MDBRow, MDBCol } from 'mdbreact'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'



export default function BankDetailsForm(props) {
    let {
        bankDetailsForm, APO_seller, selectedBank, accountTitle, accountNumber, ibanNumber,
        bankAddress,routingNumber,swiftCode,paypalEmail,
        handleInput, handleNumberInputKeyPress, handleSelectChange,
        goToPrevStep, goToNextStep
    } = props
    const paypalEmailInput = <Input
        label='PayPal Email'
        name={`paypalEmail`}
        value={paypalEmail}
        handleInput={handleInput}
        // invalidMessage={`Please enter your account's IBAN no.`}
    />
    const bankNameSelect = <div className='mb-4 p-0'>
        <MyOwnSelect
            options={getOptions('banks')}
            optionsHeading={selectedBank ? 'Bank name' : ''}
            handleChange={handleSelectChange('selectedBank')}
            placeHolder='Bank name'
            selectedValue={selectedBank}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const accountTitleInput = <Input
        label='A/C title'
        name={`accountTitle`}
        value={accountTitle}
        handleInput={handleInput}
        required
        invalidMessage={'Please enter your account title'}
    />
    const accountIdInput = <Input
        label='A/C no.'
        name={`accountNumber`}
        value={accountNumber}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        pattern="^[0-9]*"
        required
        invalidMessage={`Please enter a valid account #`}
    />
    const ibanIdInput = <Input
        label='IBAN no.'
        name={`ibanNumber`}
        value={ibanNumber}
        handleInput={handleInput}
        invalidMessage={`Please enter your account's IBAN no.`}
    />
    
    const bankAddressInput = <Input
        label='Bank Address'
        name={`bankAddress`}
        value={bankAddress}
        handleInput={handleInput}
        // invalidMessage={`Please enter your account's IBAN no.`}
    />
    const routingNumberInput = <Input
        label='Routing no.'
        name={`routingNumber`}
        value={routingNumber}
        handleInput={handleInput}
        // invalidMessage={`Please enter your account's IBAN no.`}
    />

    const swiftCodeInput = <Input
        label='Swift Code'
        name={`swiftCode`}
        value={swiftCode}
        handleInput={handleInput}
        // invalidMessage={`Please enter your account's IBAN no.`}
    />
    const fieldsToDisplay = [paypalEmailInput, bankNameSelect, accountTitleInput, accountIdInput, ibanIdInput,bankAddressInput,routingNumberInput,swiftCodeInput]

    return (
        <form ref={bankDetailsForm} noValidate>
            <MDBRow center className='m-0 p-0'>
                {fieldsToDisplay.map((field, index) => {
                    return <MDBCol key={index + 1} middle sm={APO_seller === 'update' ? '12' : '8'} className='m-0'>{field}</MDBCol>
                })}
            </MDBRow>
            {APO_seller !== 'update' &&
                <MDBCol middle sm='8' className='d-flex flex-wrap justify-content-between mx-auto'>
                    <ActionButton
                        onClick={goToPrevStep}
                        value="Previous"
                        className="bg-primary-light"
                        type="button"
                    />
                    <ActionButton
                        onClick={goToNextStep}
                        value="Next"
                        className="bg-Secondary"
                        type="button"
                    />
                </MDBCol>
            }
        </form >
    )
}