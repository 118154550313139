import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact'
import { Input } from '../../../../misc/components/Inputs'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { CustomDatePicker } from '../../../../misc/components/CustomDatePicker'


export const PaymentsDataStep = (props) => {

    let {
        APO_order,
        currentStep,
        handleInput,
        handleSelectChange,
        paymentsDataForm,
        paymentMethodOptions,
        selectedPaymentMethod,
        trId,
        trDate,
        trAmount,
        trStatus,
    } = props;


    // const handleClickNext = () => {
    //     if (!selectedPaymentMethod || selectedPaymentMethod === '') {
    //         changeValue('selectedPaymentMethod', null)
    //         setValidationMessage('Please select a payment method.')
    //         return
    //     }
    //     else if (!paymentDetailsForm.current.checkValidity()) {
    //         paymentDetailsForm.current.classList.add('was-validated')
    //         return
    //     }
    //     else {
    //         setValidationMessage('')
    //         handleSubmitOrder()
    //     }
    // }
    const methodInput = <MyOwnSelect
        options={paymentMethodOptions}
        optionsHeading={selectedPaymentMethod ? 'Select payment method' : ''}
        handleChange={handleSelectChange('selectedPaymentMethod')}
        placeHolder={'Select payment method'}
        selectedValue={selectedPaymentMethod}
        withOutIcon
        required
        isDisabled={APO_order !== 'create'}
    />
    const trDateInput = <CustomDatePicker
        selectedValue={trDate}
        handleChange={(value) => {
            handleSelectChange('trDate')(value);
        }}
        label='Tr. date'
        isClearable
        isRequired
        isFormField
        isDisabled={APO_order === 'read'}
    />
    const trIdInput = <Input
        label="Tr. id"
        name={`trId`}
        value={trId || ''}
        handleInput={handleInput}
        containerClass='mb-3'
        required
        disabled={APO_order === 'read'}
        invalidMessage='Please enter transaction id'
    />
    const trAmountInput = <Input
        type='number'
        label="Tr. amount"
        name={`trAmount`}
        value={trAmount || ''}
        handleInput={handleInput}
        containerClass='mb-3'
        required
        disabled={APO_order === 'read'}
        invalidMessage='Please enter transaction amount'
    />
    const trStatusInput = <Input
        label="Tr. status"
        hint={`e.g. "partial" or "complete"`}
        name={`trStatus`}
        value={trStatus || ''}
        handleInput={handleInput}
        containerClass='mb-3'
        required
        disabled={APO_order === 'read'}
        invalidMessage='Please enter transaction status'
    />
    return (
        <form ref={paymentsDataForm} className={`m-0 p-0 ${currentStep === 5 ? 'd-block' : 'd-none'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='mb-3 col-sm-6'>{methodInput}</div>
                {
                    selectedPaymentMethod && selectedPaymentMethod.label.toLowerCase() !== 'cod' &&
                    <>
                        <div className='m-0 col-sm-6'>{trDateInput}</div>
                        <div className='m-0 col-sm-6'>{trIdInput}</div>
                        <div className='m-0 col-sm-6'>{trAmountInput}</div>
                        <div className='m-0 col-sm-6'>{trStatusInput}</div>
                    </>
                }
            </div>
        </form>
    )
}
