import React from 'react'
import { handleNumberInputKeyPress, setElementDisability, checkUniqueness, handleNumberInputBlur } from '../../../../misc/components/Helpers'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { Input, CustomInput } from '../../../../misc/components/Inputs'
// import { MDBTooltip } from 'mdbreact'

export const InventoryDataStep = (props) => {
    let {
        APO_product,
        changeValue,
        currentStep,
        handleInput,
        handleSelectChange,
        isVariableProduct,

        inventoryDataForm,
        backorders,
        backordersOptions,
        manageStock,
        sku,
        soldIndividually,
        stockQuantity,
        stockStatus,
        stockStatusOptions,
    } = props

    //form-fields
    const skuInput = <Input
        id='sku'
        label='SKU'
        name={`sku`}
        value={sku || ''}
        handleInput={handleInput}
        onFocus={() => { setElementDisability('formSubmit', true) }}
        onBlur={(e) => { checkUniqueness(e, 'products', 'sku', 'formSubmit') }}
        containerClass='mb-3'
        disabled={APO_product === 'read'}
        invalidMessage={'SKU already exists'}
    />
    const stockStatusInput = <div className='mb-3 p-0'>
        <MyOwnSelect
            options={stockStatusOptions}
            optionsHeading={stockStatus ? 'Select stock status' : ''}
            handleChange={handleSelectChange('stockStatus')}
            placeHolder='Select stock status'
            selectedValue={stockStatus}
            className='border-radius-0'
            withOutIcon
            required={!manageStock}
            isDisabled={APO_product === 'read'}
        />
    </div>
    const stockQuantityInput = <Input
        type="number"
        label='Stock quantity'
        name={`stockQuantity`}
        value={stockQuantity || ''}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={(e) => handleNumberInputBlur(e, changeValue)}
        containerClass='mb-3'
        required={manageStock}
        disabled={APO_product === 'read'}
        invalidMessage={`Please enter stock quantity.`}
    />
    const backordersInput = <div className='mb-3 p-0'>
        <MyOwnSelect
            options={backordersOptions}
            optionsHeading={backorders ? 'Allow backorders ?' : ''}
            handleChange={handleSelectChange('backorders')}
            placeHolder='Allow backorders ?'
            selectedValue={backorders}
            className='border-radius-0'
            withOutIcon
            isDisabled={APO_product === 'read'}
        />
    </div>
    const manageStockInput = <CustomInput
        type='checkbox'
        id={`manageStock`}
        handleInput={(e) => { changeValue('manageStock', e.checked) }}
        label='Manage stock ?'
        labelClass='mt-1 mb-0 small'
        checked={manageStock}
        tooltip="Enable stock management at product level"
        tooltipOptions={{ position: 'top' }}
        noLabel
        disabled={APO_product === 'read'}
    />
    const soldIndividuallyInput = <CustomInput
        type='checkbox'
        id={`soldIndividually`}
        handleInput={(e) => { changeValue('soldIndividually', e.checked) }}
        label='Sold individually ?'
        labelClass='mt-1 mb-0 small'
        checked={soldIndividually}
        tooltip="Enable this to only allow one of this item to be bought in a single order"
        tooltipOptions={{ position: 'top' }}
        noLabel
        disabled={APO_product === 'read'}
    />

    // if (currentStep !== 2) {
    //     return null
    // }
    return (
        <form ref={inventoryDataForm} className={`m-0 p-0 ${currentStep !== 2 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6 py-0'>
                    {skuInput}
                </div>
                {!manageStock ?
                    !isVariableProduct &&
                    <div className='m-0 col-sm-6 py-0'>
                        {stockStatusInput}
                    </div>
                    :
                    <>
                        <div className='m-0 col-sm-6 py-0'>
                            {stockQuantityInput}
                        </div>
                        <div className='m-0 col-sm-6 py-0'>
                            {backordersInput}
                        </div>
                    </>
                }
                <div className='m-0 col-sm-6 py-0 pt-2'>
                    {manageStockInput}
                </div>
                <div className='m-0 col-sm-6 py-0 pt-2'>
                    {soldIndividuallyInput}
                </div>
            </div>
        </form>
    )
}
