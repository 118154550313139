import React, { useRef, useState, useContext } from "react";
import { CustomInput, CustomButton } from '../../misc/components/Inputs'
import { UserContext } from '../../../contexts/UserContext'
import LoaderModal from '../../misc/components/LoaderModal'
import { Link } from 'react-router-dom'


const LoginForm = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [validationMessage, setValidationMessage] = useState('')
    const loginForm = useRef(null);
    const usernameField = useRef(null);
    const passwordField = useRef(null);

    const { updateRules } = useContext(UserContext)

    const handleInput = (e) => {
        let { name, value } = e.target
        name === 'username' ? setUsername(value) : setPassword(value)
        setValidationMessage('')
    }

    const validate = (e) => {
        e.preventDefault();
        if (!loginForm.current.checkValidity()) {
            loginForm.current.classList.add('was-validated');
            return
        }
        else {
            setIsLoading(true)
            handleLogin();
        }
    }

    const handleLogin = () => {
        let options = {
            method: 'POST',
            body: JSON.stringify({ username: username, password: password }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetch('/api/auth/login', options)
            .then((res) => res.json())
            .then((json) => {
                // console.log(json);
                let { success, message, user, loggedIn } = json
                setValidationMessage(message)
                message === 'incorrect password' && passwordField.current.focus();
                message === `username doesn't exist` && usernameField.current.focus();
                if (success) {
                    // setIsLoggedIn(true);
                    if (typeof (Storage) !== "undefined") {
                        localStorage.setItem('ui', JSON.stringify(user.id))
                    }
                    else {
                        alert('Please use another browser to get it working correctly.')
                    }
                    // props.updateRules(user);
                    updateRules(user, loggedIn);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className='screen-center h-50vh '>
            <img
                src="/logo.jpg"
                // width={'12%'}
                className='p-m-0 p-p-0 form-box-logo'
                style={{ cursor: 'pointer' }}
                onClick={() => { window.open('/home', '_self') }}
            />
            <form
                ref={loginForm}
                // p-m-0 p-px-4 p-p-sm-0  w-100 w-sm-75 w-md-50 w-lg-25
                className='form-box-rounded p-d-flex p-flex-column shadow p-3 pt-5 pb-5'
                onSubmit={validate}
                noValidate
                // style={{ borderRadius: '8px' }}
            >
                <h5 className='p-text-bold color-secondary p-mb-3 p-mb-sm-4'>Sign In</h5>
                <CustomInput
                    label='Email'
                    name={`username`}
                    value={username}
                    handleInput={handleInput}
                    ref={usernameField}
                    required
                />
                <CustomInput
                    type='password'
                    label='Password'
                    name={`password`}
                    value={password}
                    handleInput={handleInput}
                    ref={passwordField}
                    required
                />
                <Link  className="mb-2" to='/resetpassword'><small>Forgot Password?</small></Link>
                <CustomButton
                    label="Login"
                    bg="Secondary"
                    color='tertiary'
                    type="submit"
                    className='w-100 p-ml-auto p-button-rounded p-button-raised'
                />
                <small className="mt-2 mb-2">By continuing, you agree to AMAM Trading's
                    <a href="https://amamtrading.com/term-conditions/"> Terms & Conditions</a>
                </small>
                <Link to="/sellers/quicksignup">
                    <CustomButton
                        label="Register"
                        bg="Primary"
                        color='tertiary'
                        type="submit"
                        className='w-100 p-ml-auto p-button-rounded p-button-raised'
                    />
                </Link>
                <label className='p-mt-4 p-p-0 p-text-center validating-label'>{validationMessage}&nbsp;</label>
            </form>
            <LoaderModal
                modalShow={isLoading}
            />
        </div>
    )
}
export default LoginForm;