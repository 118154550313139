import React from 'react'

export const userProps = {
    isLoggedIn: false,
    emailVerified: false,
    accountApproved: false,
    isSellerLoggedIn: false,
    isShopManagerLoggedIn: false,
    isSellerManagerLoggedIn: false,
}

export const UserContext = React.createContext({
    isLoggedIn: userProps.isLoggedIn,
    emailVerified: userProps.emailVerified,
    accountApproved: userProps.accountApproved,
    isSellerLoggedIn: userProps.isSellerLoggedIn,
    isSellerManagerLoggedIn: userProps.isSellerManagerLoggedIn,
    isShopManagerLoggedIn: userProps.isShopManagerLoggedIn,
    updateRules: () => { },
})