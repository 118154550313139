import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { CustomListing } from '../../../misc/components/CustomListing'
import CustomSelect from '../../../misc/components/CustomSelect'
import { isConfirmed } from '../../../misc/components/Helpers'
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom'
import { deleteOrderStatus, getOrderStatuses } from '../../../../services/orders/status-service'


let _isMounted
const AllOrderStatuses = () => {
    let dt = useRef(null)
    let toast = useRef(null)
    let history = useHistory()

    const [{
        allStatuses, isLoading, refreshData,
        selectedChangeable, selectedUsable, selectedDefault, selectedFinal, selectedEnabled
    }, setState] = useState({
        allStatuses: [], isLoading: false, refreshData: false,
        selectedChangeable: null, selectedUsable: null, selectedDefault: null, selectedFinal: null, selectedEnabled: null
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getOrderStatuses()
                changeValue('allStatuses', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleEdit = (id) => (e) => {
        history.push(`/orders/statuses/edit/${id}`)
    }

    const handleCreate = () => {
        history.push(`/orders/statuses/new`)
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete', e, accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = await deleteOrderStatus(id)
                    // console.log(message)
                    let updatedStatuses = allStatuses.filter(status => status.id !== id)
                    if (success && affectedCount) {
                        toast.current.show(message);
                        changeValue('allStatuses', updatedStatuses)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const sellerChangeableBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Seller changeable ?</span>
                <span className={rowData.seller_changeable ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }

    const sellerUsableBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Seller usable ?</span>
                <span className={rowData.seller_usable ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }
    // const ownerBody = (rowData) => {
    //     return (
    //         <React.Fragment>
    //             <span className='p-column-title'>Owner</span>
    //             <span>{rowData.owner === 'both' ? 'Order, Item' : rowData.owner}</span>
    //         </React.Fragment>
    //     )
    // }
    const isDefaultBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Default ?</span>
                <span className={rowData.is_default ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }
    const isFinalBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Final ?</span>
                <span className={rowData.is_final ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }
    const isEnabledBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Enabled ?</span>
                <span className={rowData.is_enabled ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }


    const changeableFilter = <CustomSelect
        value={selectedChangeable}
        options={[{ label: 'Changeable', value: true }, { label: 'Not changeable', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'seller_changeable', 'equals');
            changeValue('selectedChangeable', e.value)
        }}
        placeholder="Changeable/Not changeable"
        className="p-column-filter"
        showClear
    />
    const usableFilter = <CustomSelect
        value={selectedUsable}
        options={[{ label: 'Usable', value: true }, { label: 'Not usable', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'seller_usable', 'equals');
            changeValue('selectedUsable', e.value)
        }}
        placeholder="Usable/Not usable"
        className="p-column-filter"
        showClear
    />
    // const ownerFilter = <CustomSelect
    //     value={selectedOwner}
    //     options={[{ label: 'Order', value: 'order' }, { label: 'Item', value: 'item' }, { label: 'Order,Item', value: 'both' }]}
    //     handleChange={(e) => {
    //         dt.current.filter(e.value, 'owner', 'equals');
    //         changeValue('selectedOwner', e.value)
    //     }}
    //     placeholder="Select owner"
    //     className="p-column-filter"
    //     showClear
    // />
    const isDefaultfilter = <CustomSelect
        value={selectedDefault}
        options={[{ label: 'Default', value: true }, { label: 'Not default', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'is_default', 'equals');
            changeValue('selectedDefault', e.value)
        }}
        placeholder="Default/Not default"
        className="p-column-filter"
        showClear
    />
    const isEnabledFilter = <CustomSelect
        value={selectedEnabled}
        options={[{ label: 'Enabled', value: true }, { label: 'Not enabled', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'is_enabled', 'equals');
            changeValue('selectedEnabled', e.value)
        }}
        placeholder="Enabled/Not enabled"
        className="p-column-filter"
        showClear
    />
    const isFinalfilter = <CustomSelect
        value={selectedFinal}
        options={[{ label: 'Final', value: true }, { label: 'Not final', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'is_final', 'equals');
            changeValue('selectedFinal', e.value)
        }}
        placeholder="Final/Not final"
        className="p-column-filter"
        showClear
    />

    let columns = [
        {
            field: "seller_changeable",
            header: "Seller changeable ?",
            body: sellerChangeableBody,
            filter: true,
            filterElement: changeableFilter,
            headerStyle: { width: '140px' }
        },
        {
            field: "seller_usable",
            header: "Seller usable ?",
            body: sellerUsableBody,
            filter: true,
            filterElement: usableFilter,
            headerStyle: { width: '120px' }
        },
        // {
        //     field: "owner",
        //     header: "Owner",
        //     body: ownerBody,
        //     filter: true,
        //     filterElement: ownerFilter,
        //     headerStyle: { width: '100px' }
        // },
        {
            field: "threshold_hours",
            header: "Threshold (hours)",
            sortable: true,
            headerStyle: { width: '140px' }
        },
        {
            field: "is_default",
            header: "Default ?",
            body: isDefaultBody,
            filter: true,
            filterElement: isDefaultfilter,
            headerStyle: { width: '100px' }
        },
        {
            field: "is_final",
            header: "Final ?",
            body: isFinalBody,
            filter: true,
            filterElement: isFinalfilter,
            headerStyle: { width: '90px' }
        },
        {
            field: "is_enabled",
            header: "Enabled ?",
            body: isEnabledBody,
            filter: true,
            filterElement: isEnabledFilter,
            headerStyle: { width: '100px' }
        },
        {
            field: "color",
            header: "Color",
            body: (rowData) => <div className='small' style={{ backgroundColor: `#${rowData.color}` }}>{rowData.color ? rowData.title : ''}</div>,
            headerStyle: { width: '120px' }
        },
    ]

    // let defaultStatuses = ['Processing', 'Completed', 'Cancelled', 'Draft', 'On-Hold']

    return (
        <Can I='read' a='orderStatuses'>
            <CustomListing
                allRecords={allStatuses}
                heading={'Order statuses'}
                firstColumn={<Column
                    field="title"
                    header="Title"
                    sortable={true}
                    filter={true}
                    filterPlaceholder="Search by title"
                    filterMatchMode="contains"
                    headerStyle={{ width: '170px' }}
                />}
                columns={columns}
                permissionSubject={'orderStatuses'}
                isLoading={isLoading}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
                editDataKey={'id'}
                handleDelete={true}
                customDeleteButton={(customButton, rowData) => {
                    return (
                        rowData.created_by &&
                        customButton('pi pi-trash', handleDelete(rowData.id))
                    )
                }}
                tableRef={dt}
                toastRef={toast}
                timeStamps={true}
            />
        </Can>
    );
}


export default AllOrderStatuses