import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CustomButton } from './Inputs'

let originalRows = {};
const CustomTable = ({ records, columns, className, setRecords, editButton, deleteButton }) => {

    const onRowEditInit = (e) => {
        originalRows[e.index] = { ...records[e.index] };
    }
    const onRowEditCancel = (e) => {
        let Records = [...records];
        Records[e.index] = originalRows[e.index];
        delete originalRows[e.index];
        setRecords(Records);
    }

    const deleteBody = (rowData) => {
        return (
            <CustomButton
                icon="pi pi-trash"
                variant='text-icon-button'
                color='Secondary'
                className='p-row-editor-delete'
                onClick={(e) => { setRecords(records.filter(r => r.key !== rowData.key)) }}
                title={'remove'}
            />
        )
    }

    return (
        <div className="custom-table">
            <DataTable
                className={`${className ? className : ''}`}
                editMode="row"
                onRowEditInit={onRowEditInit}
                onRowEditCancel={onRowEditCancel}
                value={records}
                scrollable
            >
                {columns.map((col, index) => <Column
                    key={index + 1}
                    field={col.field}
                    header={col.header}
                    editor={col.editor}
                    body={col.body}
                    headerStyle={col.headerStyle}
                    bodyClassName='p-text-center p-text-bold p-px-0 p-py-2'
                />
                )}
                {
                    editButton ?
                        <Column
                            rowEditor
                            headerStyle={{ width: '6rem' }}
                            bodyClassName={`p-px-0 p-py-2 p-text-right`}
                        />
                        :
                        null
                }
                {
                    deleteButton ?
                        <Column
                            headerStyle={{ width: '4rem' }}
                            body={deleteBody} bodyClassName={`p-px-0 p-py-2`}
                        />
                        :
                        null
                }
            </DataTable>
        </div>
    );
}

export default CustomTable