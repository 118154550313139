import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { MDBPopper } from 'mdbreact';
import { UserContext } from "../../../contexts/UserContext";
import { Can, AbilityContext } from "../../../configs/Ability-context";
import getOptions from '../../misc/components/Options'
import { animateSyncIcon, getJsonData, capitalize, isConfirmed } from '../../misc/components/Helpers'
import { CustomListing } from '../../misc/components/CustomListing'
import { ViewProductModal } from './ViewProductModal'
import { getCustomChilds, getCustomUsers } from '../../../services/users/user-service';
import { getProductsWithData, getProductsMetaToSync, getProductsTrash, syncProducts, updateProductField, updateProducts, moveProductToTrash, moveProductsToTrash, restoreProduct, deleteProduct, } from '../../../services/products/product-service';
import { getBrands } from '../../../services/products/brand-service';
import { getCategories } from '../../../services/products/category-service';


let _isMounted
const productStatusOptions = getOptions('productStatuses')
const stockStatusOptions = getOptions('stockStatuses')


const AllProducts = (props) => {

    let dt = useRef(null)
    let toast = useRef(null)
    let contextMenu = useRef(null)
    let fileUpload = useRef(null)
    const { isSellerLoggedIn, isShopManagerLoggedIn, isSellerManagerLoggedIn } = useContext(UserContext)
    const ability = useContext(AbilityContext)
    const { query } = useLocation(); 
    // console.log(query?query.selectedStatus:"All Items");
    const [{
        allProducts, allSellers, allShopManagers, allBrands, allCategories,
        isLoading, refreshData, isSyncing, totalRecords, syncedRecords, syncedPercentage, trashView,
        selectedType, selectedStatus, selectedStockStatus, selectedSeller, selectedShopManager,
        selectedCategory, selectedBrand,
        selectedProduct, isReadingProduct,
        selectedRecord,//for contextMenu
        activeEditor, editorText,
        selectedBulkOption, selectedBulkValueOption, selectedRecords
    }, setState] = useState({
        allProducts: [], allSellers: [], allShopManagers: [], allBrands: [], allCategories: [],
        isLoading: false, refreshData: false, isSyncing: false, syncedRecords: 0, syncedPercentage: 0, trashView: false,
        selectedType: null, selectedStatus: query?query.selectedStatus:null, selectedStockStatus: null, selectedManageStockFilter: null, selectedSeller: null, selectedShopManager: null,
        selectedCategory: null, selectedBrand: null,
        selectedProduct: null, isReadingProduct: false,
        activeEditor: { field: '', id: '' }, editorText: '',
        selectedBulkOption: null, selectedBulkValueOption: null, selectedRecords: []
    })

    let sellerOptions = getOptions('sellers', allSellers)
    let shopManagerOptions = getOptions('shopManagers', allShopManagers)
    let brandOptions = getOptions('brands', allBrands)
    let categoryOptions = getOptions('productCategories', allCategories)

    let bulkOptions = [
        { key: 1, label: 'Change status', value: 'status', permission: 'publish' },
        { key: 2, label: 'Move to trash', value: 'trash', permission: 'delete' },
        { key: 3, label: 'Update threshold', value: 'update_threshold', permission: 'update' },
        { key: 4, label: 'Change brand', value: 'brand_id', permission: 'update' },
        { key: 5, label: 'Change category', value: 'categories', permission: 'update' },
        { key: 6, label: 'Change seller', value: 'seller_id', permission: 'updateSeller' },
        { key: 7, label: 'Change shop manager', value: 'shop_manager_id', permission: 'linkShopManagers' }
    ]
    bulkOptions = !isSellerLoggedIn ? bulkOptions.filter(o => o.value !== 'shop_manager_id') : bulkOptions
    const bulkValueOptions = () => {
        switch (selectedBulkOption) {
            case 'status':
                return productStatusOptions
                break;
            // case 'sale_price':
            //     return <InputText
            //         type='number'
            //         autoFocus
            //         value={selectedBulkValueOption || ''}
            //         onInput={(e) => e.target.value < 99 && changeValue('selectedBulkValueOption', e.target.value)}
            //         placeholder='Enter percentage'
            //     />
            case 'update_threshold':
                return <InputText
                    type='number'
                    autoFocus
                    value={selectedBulkValueOption || ''}
                    onInput={(e) => changeValue('selectedBulkValueOption', e.target.value)}
                    placeholder='Enter value (days)'
                />
            case 'brand_id':
                return brandOptions
                break;
            case 'categories':
                return categoryOptions
                break;
            case 'seller_id':
                return sellerOptions
                break;
            case 'shop_manager_id':
                return shopManagerOptions
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        _isMounted = true
        const fetchInitialData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getProductsWithData(100, 0)
                let productsWithJsons = await getJsonData(data, ['categories'])
                fetchData()
                _isMounted && setState(prevState => ({ ...prevState, allProducts: [...productsWithJsons], trashView: false }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchData = async () => {
            try {
                const { data } = await getProductsWithData(0, 100)
                let productsWithJsons = await getJsonData(data, ['categories'])
                _isMounted && setState(prevState => ({ ...prevState, allProducts: [...prevState.allProducts, ...productsWithJsons], trashView: false }))
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchInitialData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    useEffect(() => {
        _isMounted = true
        const fetchMeta = async () => {
            try {
                let sellers = (isSellerLoggedIn || isShopManagerLoggedIn) ?
                    []
                    :
                    isSellerManagerLoggedIn ?
                        await getCustomChilds(0, 'seller')
                        :
                        await getCustomUsers('seller')

                let shopManagers = isSellerLoggedIn ?
                    await getCustomChilds(0, 'shop_manager')
                    :
                    isShopManagerLoggedIn ?
                        []
                        :
                        await getCustomUsers('shop_manager')
                let brands = await getBrands()
                let categories = await getCategories()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allSellers: (Array.isArray(sellers) || !sellers) ? [] : sellers.data,
                    allShopManagers: (Array.isArray(shopManagers) || !shopManagers) ? [] : shopManagers.data,
                    allBrands: brands.data,
                    allCategories: categories.data
                }))
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchMeta()
        return () => {
            _isMounted = false
        }
    }, [])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleEdit = (id) => () => {
        window.open(`/products/edit/${id}`, '_blank')
        // history.push(`/products/edit/${id}`)
    }

    const handleView = (bktId) => {
        setState(prevState => ({
            ...prevState,
            selectedProduct: bktId,
            isReadingProduct: true,
        }))
    }

    const handleCreate = () => {
        // history.push('/products/new')
        window.open(`/products/new`, '_blank')
    }

    const handleSync = async (e) => {
        animateSyncIcon('start', e)
        changeValue('isSyncing', true)
        try {
            const { success, metaSynced, products, offset, startingPage, pages } = await getProductsMetaToSync()
            if (success) {
                if (metaSynced) {
                    let _totalRecords = products
                    if (Number(_totalRecords) > 0) {
                        //already synced
                        if (_totalRecords == offset) {
                            animateSyncIcon('stop', e)
                            toast.current.show({ severity: 'info', summary: `Products already synced.` })
                            changeValue('isSyncing', false)
                            return
                        }
                        changeValue('totalRecords', _totalRecords)
                        for (let pg = startingPage; pg <= pages; pg++) {
                            // fetching new records and syncing
                            try {
                                const { data } = await syncProducts(pg)
                                //separating new products
                                if (data) {
                                    let dataComing = data.filter(record => record.success)
                                    dataComing = dataComing.map(d => d.data)
                                    const idsOfComing = dataComing.map(p => p.id)
                                    const idsOfAvailable = allProducts.map(p => p.id)
                                    const newIds = idsOfComing.filter(id => !idsOfAvailable.includes(id))
                                    const newProducts = dataComing.filter(p => newIds.includes(p.id))
                                    _isMounted && setState(prevState => ({
                                        ...prevState,
                                        syncedPercentage: ((prevState.allProducts.length + newProducts.length) / _totalRecords) * 100,
                                        syncedRecords: prevState.allProducts.length + newProducts.length,
                                        allProducts: [...prevState.allProducts, ...newProducts].reverse()
                                    }))
                                }
                            }
                            catch (err) {
                                console.log(err)
                                // stoping animation of sync icon
                                animateSyncIcon('error', e)
                                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                                toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
                            }
                        }
                    }
                    else {
                        animateSyncIcon('stop', e) //No product to sync
                        setTimeout(() => { changeValue('isSyncing', false) }, 500)
                        toast.current.show({ severity: 'info', summary: 'Sorry', detail: `There is no product on your store.`, life: 3000 })
                    }
                    animateSyncIcon('stop', e) //synced successfully
                    // setTimeout(() => { changeValue('isSyncing', false) }, 500)
                    setTimeout(() => {
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            isSyncing: false,
                            syncedPercentage: 0,
                            syncedRecords: 0,
                            totalRecords: undefined
                        }))
                    }, 1000)
                }
                else {
                    animateSyncIcon('error', e)
                    setTimeout(() => { changeValue('isSyncing', false) }, 500)
                    toast.current.show({ severity: 'error', summary: 'Before you proceed', detail: `please sync Brands, Tags, Attributes and Product categories.` })
                }
            }
            else {
                animateSyncIcon('error', e)
                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
            }
        }
        catch (err) {
            console.log(err)
            // stoping animation of sync icon
            animateSyncIcon('error', e)
            setTimeout(() => { changeValue('isSyncing', false) }, 500)
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
        }
    }

    const handleBulkAction = () => {
        selectedRecords.length ?
            (() => {
                let _selectedRecords = selectedBulkOption === 'seller_id' ? selectedRecords.map(p => ({ id: p.bkt_id, type: p.type })) : selectedRecords.map(p => p.bkt_id)
                switch (selectedBulkOption) {
                    case 'trash':
                        handleBatchMoveToTrash(_selectedRecords)
                        break;
                    case 'status':
                    case 'brand_id':
                    case 'seller_id':
                    case 'shop_manager_id':
                    case 'categories':
                    case 'update_threshold':
                        handleBatchUpdate(_selectedRecords, selectedBulkOption, selectedBulkValueOption)
                    default:
                        break;
                }
            })()
            :
            alert('Please select some items first.')
    }

    const handleBatchUpdate = async (_selectedRecords, field, value) => {
        let updatingSeller = field === 'seller_id'

        // console.log(selectedBulkValueOption)
        // let updatedSalePrices = selectedBulkOption === 'sale_price' ?
        //     _selectedRecords.map(bktId => {
        //         let _product = allProducts.find(p => p.bkt_id === bktId)
        //         return _product.type === 'simple' ?
        //             {
        //                 id: bktId,
        //                 sale_price: (_product.regular_price - ((_product.regular_price * selectedBulkValueOption) / 100)).toFixed(0),
        //                 seller_id: _product.seller_id
        //             }
        //             :
        //             null
        //     })
        //     : null
        // updatedSalePrices = updatedSalePrices?.filter(p => p)
        // console.log('updatedSalePrices', updatedSalePrices)
        changeValue('isLoading', true)
        try {
            const { message, data, success, affectedCount } = await updateProducts(_selectedRecords, field, value)
            toast.current.show(message)
            if (success && affectedCount) {
                let updatedProducts = allProducts.map(product => {
                    if (_selectedRecords.map(r => r.id).includes(product.bkt_id)) {
                        if (updatingSeller) {
                            product.cost_price = data.cost_price
                            product.date_on_sale_from = data.date_on_sale_from
                            product.date_on_sale_from_gmt = data.date_on_sale_from_gmt
                            product.date_on_sale_to = data.date_on_sale_to
                            product.date_on_sale_to_gmt = data.date_on_sale_to_gmt
                            product.is_cost_provided = data.is_cost_provided
                            product.regular_price = data.regular_price
                            product.remarks = data.remarks
                            product.sale_price = data.sale_price
                            product.seller_id = data.seller_id
                            product.status = data.status
                        }
                        else {
                            product[selectedBulkOption] = data[selectedBulkOption]
                        }
                        return product
                    }
                    else return product
                })
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allProducts: updatedProducts,
                    selectedRecords: [],
                    selectedBulkOption: null,
                    selectedBulkValueOption: null,
                }))
            }
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    const handleBatchMoveToTrash = async (products) => {
        const isConfirmed = window.confirm('Do you really want to move these products to trash ?')
        if (isConfirmed) {
            changeValue('isLoading', true)
            try {
                const { success, } = await moveProductsToTrash(products)

                let updatedProducts = allProducts.filter((product) => !products.includes(product.bkt_id))
                if (success) {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        allProducts: updatedProducts,
                        selectedRecords: [],
                        selectedBulkOption: null,
                        selectedBulkValueOption: null,
                    }))
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
    }

    const handleSubmit = (field, rowData) => e => {
        let updatingStock = field === 'stock_quantity'
        let updatingCost = field === 'cost_price'
        let updatingRetail = field === 'regular_price' || field === 'sale_price'
        let updatingSeller = field === 'seller_id'
        let updatingShopManager = field === 'shop_manager_id'
        let helperProps = (updatingCost || updatingRetail) ?
            {
                seller: rowData.seller_id,
                isCostProvided: rowData.is_cost_provided,
                regularPrice: rowData.regular_price,
                salePrice: rowData.sale_price
            }
            :
            {}
        let value = (updatingSeller || updatingShopManager) ? e.value : editorText
        if (updatingSeller || updatingShopManager) {
            handleBatchUpdate([{ id: rowData.bkt_id, type: rowData.type }], field, value)
        }
        else if ((field === 'sale_price' && value > rowData.regular_price) || (field === 'regular_price' && value < rowData.sale_price)) {
            toast.current.show({ severity: 'warn', summary: 'Invalid price.' })
        }
        else handleUpdate(field, value, rowData, helperProps, updatingStock, updatingCost, updatingRetail)
    }

    const handleUpdate = async (field, value, rowData, helperProps, updatingStock, updatingCost, updatingRetail) => {
        changeValue('isLoading', true)
        try {
            const { message, data, success, affectedCount } = await updateProductField(
                rowData.bkt_id,
                field,
                typeof value !== 'boolean' ? (value || value === 0) ? value : null : value,
                helperProps)
            toast.current.show(message);
            if (success && affectedCount) {
                let updatedProducts = allProducts.map(product => {
                    if (product.bkt_id == rowData.bkt_id) {
                        // if (['manage_stock', 'stock_quantity', 'stock_status'].includes(field)) {
                        if (updatingStock) {
                            product.manage_stock = data.manage_stock
                            product.stock_quantity = data.stock_quantity
                            product.stock_status = data.stock_status
                        }
                        else if (updatingRetail) {
                            if (product.is_cost_provided) {
                                product[field] = data[field]
                            }
                            else {
                                product[field] = data[field]
                                product.cost_price = data.cost_price
                            }
                        }
                        else if (updatingCost) {
                            if (product.is_cost_provided) {
                                product.regular_price = data.regular_price
                                product.sale_price = data.sale_price
                                product.cost_price = data.cost_price
                            }
                        }
                        return product
                    }
                    else return product
                })
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allProducts: updatedProducts,
                    editorText: '',
                    activeEditor: { field: '', id: '' },
                }))
            }
        } catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    const handleMoveToTrash = (bktId) => async (e) => {
        isConfirmed({
            type: 'delete',
            target: selectedRecord ? e.originalEvent.target : e.target,
            message: trashView ? 'Are you sure to permanently delete this record?' : undefined,
            accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message } = trashView ? await deleteProduct(bktId) : await moveProductToTrash(bktId)
                    console.log(message)
                    let updatedProducts = allProducts.filter((product) => product.bkt_id !== bktId)
                    if (success) {
                        toast.current.show(message);
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            allProducts: updatedProducts,
                            selectedRecords: [],
                        }))
                    }
                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Something went wrong' });
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const handleGetTrash = () => {
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getProductsTrash()
                let productsWithJsons = await getJsonData(data, ['categories'])
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allProducts: productsWithJsons,
                    trashView: true
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
    }

    const handleRestore = (bktId) => (e) => {
        // console.log('bktId', bktId)
        console.log(e)
        isConfirmed({
            target: selectedRecord ? e.originalEvent.target : e.target,
            message: 'Are you sure to restore this record?',
            accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success } = await restoreProduct(bktId)
                    let updatedProducts = allProducts.filter((product) => product.bkt_id !== bktId)
                    if (success) {
                        toast.current.show({ severity: 'success', detail: `Product restored successfully.` });
                        changeValue('allProducts', updatedProducts)
                    }
                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Something went wrong' });
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const categoryFilter = <Dropdown
        value={selectedCategory}
        options={categoryOptions}
        onChange={(e) => { dt.current.filter(e.value, 'categories', 'custom'); changeValue('selectedCategory', e.value) }}
        placeholder="Select a category"
        className="p-column-filter"
        showClear
        filter={allCategories.length ? true : false}
        resetFilterOnHide
    />
    const typeFilter = <Dropdown
        value={selectedType}
        options={[{ label: 'Simple', value: 'simple' }, { label: 'Variable', value: 'variable' }]}
        onChange={(e) => { dt.current.filter(e.value, 'type', 'equals'); changeValue('selectedType', e.value) }}
        placeholder="Select a type"
        className="p-column-filter"
        showClear
    />
    // {console.log("selectedStatus"+selectedStatus)}
    const statusFilter = <Dropdown
        value={selectedStatus}
        options={productStatusOptions}
        onChange={(e) => { dt.current.filter(e.value, 'status', 'equals'); changeValue('selectedStatus', e.value) }}
        placeholder="Select a status"
        className="p-column-filter"
        showClear
    />
    const brandFilter = <Dropdown
        value={selectedBrand}
        options={brandOptions}
        onChange={(e) => { dt.current.filter(e.value, 'brand_id', 'equals'); changeValue('selectedBrand', e.value) }}
        placeholder="Select a brand"
        className="p-column-filter"
        showClear
        filter={allBrands.length ? true : false}
        resetFilterOnHide
    />
    const stockStatusFilter = <Dropdown
        value={selectedStockStatus}
        options={stockStatusOptions}
        onChange={(e) => { dt.current.filter(e.value, 'stock_status', 'equals'); changeValue('selectedStockStatus', e.value) }}
        placeholder="Select a status"
        className="p-column-filter"
        showClear
    />
    const sellerFilter = <Dropdown
        value={selectedSeller}
        options={sellerOptions}
        onChange={(e) => { dt.current.filter(e.value, 'seller_id', 'equals'); changeValue('selectedSeller', e.value) }}
        placeholder="Select a seller"
        className="p-column-filter"
        showClear
        filter={allSellers.length ? true : false}
        resetFilterOnHide
    />

    const shopManagerFilter = <Dropdown
        value={selectedShopManager}
        options={shopManagerOptions}
        onChange={(e) => { dt.current.filter(e.value, 'shop_manager_id', 'equals'); changeValue('selectedShopManager', e.value) }}
        placeholder="Select a manager"
        className="p-column-filter"
        showClear
        filter={allShopManagers.length ? true : false}
        resetFilterOnHide
    />

    const setEditor = (field, id) => e => {
        setState(prevState => ({ ...prevState, activeEditor: { field, id } }))
    }

    const handleInput = (e) => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleInputFocus = (value) => e => {
        console.log(value)
        changeValue('editorText', value)
    }

    const handleChange = () => { }

    const filterCategory = (categories, filter) => {
        return categories.find(category => category.id == filter)
    }

    // const filterBrand = (brand, filter) => {
    //     return brand ? brand.bkt_id === filter : false
    // }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                {/* <span className="p-column-title">Id</span> */}
                {rowData.bkt_id}
            </>
        );
    }

    const productNameBody = (rowData) => {
        return (
            <span>
                {capitalize(rowData.name)}
            </span>
        );
    }

    const productStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {productStatusOptions.find(option => option.value === rowData.status)?.label}
            </>
        );
    }

    const stockStatusBody = (rowData) => {
        return (
            <>
                <span className="p-column-title">Stock Status</span>
                {stockStatusOptions.find(option => option.value === rowData.stock_status)?.label}
            </>
        )
    }

    const sellerBodyTemplate = (rowData) => {
        let _seller = rowData.seller_id
        return (
            (ability.can('updateSellers', 'products') && !trashView) ?
                <Dropdown
                    options={sellerOptions}
                    value={_seller}
                    onChange={handleSubmit('seller_id', rowData)}
                />
                :
                <>
                    {sellerOptions?.find(o => o.value === _seller)?.label}
                </>
        )
    }

    const shopManagerBody = (rowData) => {
        let _shopManager = rowData.shop_manager_id
        return (
            (ability.can('linkShopManagers', 'products') && !trashView) ?
                <Dropdown
                    options={shopManagerOptions}
                    value={_shopManager}
                    onChange={handleSubmit('shop_manager_id', rowData)}
                />
                :
                <>
                    {shopManagerOptions?.find(o => o.value === _shopManager)?.label}
                </>
        )
    }

    const categoriesTemplate = (rowData) => {
        let categories = rowData.categories
        let categoriesNames = categories?.length ? categories.map(category => category.name) : null
        categoriesNames = categoriesNames?.toString().replace(/&amp;/g, "&").replace(/,/, ", ")
        return (
            <>
                <span className="p-column-title">Categories</span>
                {categoriesNames}
            </>
        );
    }
    const inlineEditor = (field, rowData, inputType) => {
        const editor = <Inplace
            closable
            active={activeEditor.field === field && activeEditor.id === rowData.id}
            onOpen={setEditor(field, rowData.id)}
            onToggle={handleChange}
            onClose={handleSubmit(field, rowData)}
        // onClose={(e) => {
        //     // (field === 'sale_price' || field === 'regular_price') && !rowData.is_cost_provided && updateCostPrice(rowData, field)
        //     handleUpdate(rowData.bkt_id)(field, editorText, rowData);
        // }}
        >
            <InplaceDisplay>
                <MDBPopper
                    domElement
                    tag="span"
                    placement="right"
                >
                    <span>
                        {rowData[field]}
                    </span>
                    <span>
                        {'Click to edit'}
                    </span>
                </MDBPopper>
            </InplaceDisplay>
            <InplaceContent>
                {getEditor(inputType, rowData, field)}
            </InplaceContent>
        </Inplace >
        const readOnly = (ability.can('update', 'products') && rowData.type !== 'simple') ?
            <MDBPopper
                domElement
                tag="span"
                placement="right"
            >
                <span>
                    {rowData[field]}
                </span>
                <span className='small'>
                    {'Inline edit is not available for variable products'}
                </span>
            </MDBPopper>
            :
            <span>
                {rowData[field]}
            </span>
        if (ability.can('update', 'products') && rowData.type === 'simple') {
            if (field !== 'stock_quantity') {
                if (rowData.is_cost_provided) {
                    switch (field) {
                        case 'cost_price':
                            return (isSellerLoggedIn || isShopManagerLoggedIn) ? editor : readOnly
                        case 'regular_price':
                        case 'sale_price':
                            return (isSellerLoggedIn || isShopManagerLoggedIn) ? readOnly : editor
                        default:
                            break;
                    }
                }
                else {
                    switch (field) {
                        case 'cost_price':
                            return readOnly
                        case 'regular_price':
                        case 'sale_price':
                            return (isSellerLoggedIn || isShopManagerLoggedIn) ? editor : readOnly
                        default:
                            break;
                    }
                }
            }
            else return editor
        }
        else return readOnly
    }
    const getEditor = (inputType, rowData, field) => {
        return <InputText
            // type={inputType}
            autoFocus
            name='editorText'
            value={editorText}
            onInput={handleInput}
            onFocus={handleInputFocus(rowData[field])}
        />
    }

    const imageTemplate = (rowData) => {
        // console.log(rowData);
        return  <img
            src={rowData.images[0] ? rowData.images[0].src : '/image-not-found.png'}
            height={'50px'}
            alt={rowData.name}
            className='my-2 text-muted p-shadow-8'
            style={{
                width: '50px',
                filter: (rowData.images[0]) ? '' : 'grayscale(100%)',
            }}
        />
    }
    let columns = [
        {
            field: "image",
            header: "Main Image",
            body: imageTemplate,
            // sortable: true,
            // filter: true,
            // filterPlaceholder: "Search by name",
            selectedByDefault: true,
            // filterMatchMode: "contains",
            headerStyle: { width: '200px' }
        },
        {
            field: "name",
            header: "Name",
            body: productNameBody,
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by name",
            selectedByDefault: true,
            filterMatchMode: "contains",
            headerStyle: { width: '400px' }
        },
        {
            field: "type",
            header: "Type",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterElement: typeFilter,
            headerStyle: { width: '100px' },
        },
        {
            field: "status",
            header: "Status",
            body: productStatusBodyTemplate,
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterElement: statusFilter,
            headerStyle: { width: '100px' },
        },
        {
            field: "regular_price",
            header: "Regular Price",
            body: (rowData) => { return inlineEditor('regular_price', rowData, 'number') },
            sortable: true,
            selectedByDefault: true,
            headerStyle: { width: '120px', fontSize: '14px' },
        },
        {
            field: "cost_price",
            header: "Cost Price",
            body: (rowData) => { return inlineEditor('cost_price', rowData, 'number') },
            sortable: true,
            headerStyle: { width: '120px', fontSize: '14px' },
            permission: 'readCost'
        },
        {
            field: "sale_price",
            header: "Sale Price",
            body: (rowData) => { return inlineEditor('sale_price', rowData, 'number') },
            sortable: true,
            selectedByDefault: true,
            headerStyle: { width: '120px' },
        },
        {
            field: "stock_status",
            header: "Stock Status",
            body: stockStatusBody,
            filter: true,
            selectedByDefault: true,
            filterElement: stockStatusFilter,
            headerStyle: { width: '120px' },
        },
        {
            field: "stock_quantity",
            header: "Qty.",
            selectedByDefault: true,
            body: (rowData) => { return inlineEditor('stock_quantity', rowData, 'number') },
            // body: (rowData) => {

            // },
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by qty",
            filterMatchMode: "equals",
            headerStyle: { width: '120px' },
        },
        {
            field: "remarks",
            header: "Remarks",
            // body: (rowData) => { return inlineEditor('remarks', rowData, 'text') },
            headerStyle: { width: '270px' },
        },
        {
            field: "brand_id",
            header: "Brand",
            body: (rowData) => <>{brandOptions?.find(o => o.value === rowData.brand_id)?.label}</>,
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterElement: brandFilter,
            // filterFunction: filterBrand,
            headerStyle: { width: '150px' },
        },
        {
            field: "categories",
            header: "Categories",
            body: categoriesTemplate,
            filter: true,
            selectedByDefault: true,
            filterElement: categoryFilter,
            filterFunction: filterCategory,
            headerStyle: { width: '240px' },
        },
        {
            field: "seller_id",
            header: "Seller",
            body: sellerBodyTemplate,
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterElement: sellerFilter,
            permission: 'updateSellers',
            headerStyle: { width: '140px' },
        },
        // {
        //     field: "shop_manager_id",
        //     header: "Shop Manager",
        //     body: shopManagerBody,
        //     sortable: true,
        //     filter: true,
        //     filterElement: shopManagerFilter,
        //     permission: 'linkShopManagers',
        //     headerStyle: { width: '140px' },
        // },
    ]
    let menuModel = trashView ?
        [
            { label: 'Restore this record', icon: 'pi pi-undo color-primary-light lead', command: (e) => { handleRestore(selectedRecord.bkt_id)(e) } },
            { label: 'View product on store', icon: 'pi pi-external-link color-primary-light lead', command: () => { window.open(selectedRecord.permalink, '_blank') } },
            { label: 'Delete this record', icon: 'pi pi-trash color-primary-light lead', command: (e) => { handleMoveToTrash(selectedRecord.bkt_id)(e) } },
        ]
        : [
            { label: 'View product locally', icon: 'pi pi-eye color-primary-light lead', command: () => { handleView(selectedRecord.bkt_id) } },
            { label: 'View product on store', icon: 'pi pi-external-link color-primary-light lead', command: () => { window.open(selectedRecord.permalink, '_blank') } },
            { label: 'Edit product', icon: 'pi pi-pencil color-primary-light lead', command: () => { handleEdit(selectedRecord.id)() } },
            { label: 'Move to trash', icon: 'pi pi-trash color-primary-light lead', command: (e) => { console.log(e); handleMoveToTrash(selectedRecord.bkt_id)(e) } },
        ];

    return (
        <Can I='read' a='products'>
            <CustomListing
                allRecords={allProducts}
                heading={trashView ? 'Products (trash)' : 'Products'}
                // firstColumn={<Column
                //     field="bkt_id"
                //     header="Id"
                //     body={idBodyTemplate}
                //     sortable
                //     filter
                //     filterPlaceholder="Search by id"
                //     filterMatchMode="contains"
                //     headerStyle={{ width: '100px' }}
                // />}
                firstColumn={<Column
                    field="sku"
                    header="SKU"
                    // body={idBodyTemplate}
                    sortable
                    filter
                    filterPlaceholder="Search by sku"
                    filterMatchMode="contains"
                    headerStyle={{ width: '150px' }}
                />}
                columns={columns}
                permissionSubject={'products'}
                isLoading={isLoading}
                isSyncing={isSyncing}
                totalRecords={totalRecords}
                syncedRecords={syncedRecords}
                syncedPercentage={syncedPercentage}
                handleCreate={handleCreate}
                handleSync={trashView ? false : handleSync}
                exportData={trashView ? false : 'products'}
                importData={trashView ? false : 'products'}
                columnsToImport={["id", "bkt_id", "name", "slug", "type", "status", "featured", "regular_price", "sale_price", "date_on_sale_from",
                    "date_on_sale_from_gmt", "date_on_sale_to", "date_on_sale_to_gmt", "sku", "manage_stock", "stock_quantity", "stock_status", "backorders",
                    "sold_individually", "weight", "dimensions", "upsell_ids", "cross_sell_ids", "description", "short_description", "purchase_note",
                    "categories", "attributes", "default_attributes", "tags", "images", "createdAt", "updatedAt", "deletedAt", "userId", "seller_id",
                    "created_by", "last_updated_by", "deleted_by", "parent_id", "brandId", "brand_id"]}
                updateColumns={undefined}
                handleTrash={trashView ? () => changeValue('refreshData', !refreshData) : handleGetTrash}
                trashLabel={trashView ? 'Products' : `Trash`}
                trashIcon={trashView ? 'pi pi-refresh' : 'pi pi-trash'}
                handleRefresh={trashView ? false : () => changeValue('refreshData', !refreshData)}
                handleView={true}
                customViewButton={(customButton, rowData) => {
                    return (
                        <a href={`${rowData.permalink}`} target="_blank" title={'view product on store'}>
                            {customButton('fas fa-share color-primary')}
                        </a>
                    )
                }}
                handleEdit={trashView ? false : handleEdit}
                editDataKey={'id'}
                handleDelete={handleMoveToTrash}
                deleteDataKey={'bkt_id'}
                extraButton={
                    trashView ?
                        (customButton, rowData) => {
                            return ability.can('restore', 'products') ?
                                customButton('fas fa-undo-alt fa-sm color-primary', (e) => { handleRestore(rowData.bkt_id)(e) }, false, 'restore this record')
                                :
                                null
                        }
                        :
                        (customButton, rowData) => {
                            return ability.can('read', 'products') ?
                                customButton('pi pi-eye color-primary-light', (e) => { handleView(rowData.bkt_id) }, false, 'view product locally')
                                :
                                null
                        }
                }
                contextMenuRef={contextMenu}
                contextMenuModel={menuModel}
                contextMenuSelection={selectedRecord}
                rowClassName={(data) => {
                    let lastUpdatedAt = new Date(data.updatedAt)
                    lastUpdatedAt.setDate(lastUpdatedAt.getDate() + data.update_threshold)
                    return {
                        // 'bg-warn': data.stock_status === 'outofstock',
                        // 'bg-Danger': new Date > lastUpdatedAt
                    }
                }}
                customPaginatorLeft={
                    // allProducts.length ?
                    //     <div className="p-d-inline-flex p-flex-column p-flex-sm-row p-flex-wrap p-jc-between p-ai-center">
                    //         <div className='bg-warn px-2 mx-1 color-primary'>Out of stock</div>
                    //         <div className='bg-Danger px-2 mx-1 color-primary'>Outdated</div>
                    //     </div>
                    //     : 
                        null
                }
                selectedRecords={selectedRecords}
                selectionDataKey={'bkt_id'}
                selectedBulkOption={selectedBulkOption}
                selectedBulkValueOption={selectedBulkValueOption}
                bulkOptions={trashView ? false : bulkOptions}
                bulkValueOptions={bulkValueOptions()}
                handleBulkAction={trashView ? false : handleBulkAction}
                changeValue={changeValue}
                tableRef={dt}
                toastRef={toast}
                fileUploadRef={fileUpload}
                timeStamps={true}
            />
            <ViewProductModal
                modalShow={isReadingProduct}
                productBktId={selectedProduct}
                hideModal={() => {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        isReadingProduct: false,
                        selectedProduct: null
                    }))
                }}
            />
        </Can >
    );
}

export default AllProducts