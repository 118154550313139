import React, { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext'
import { AppContext } from '../../contexts/AppContext'
import { Menubar } from 'primereact/menubar'

import { Link,useLocation } from 'react-router-dom'

const Header = () => {
    
    const { isLoggedIn } = useContext(UserContext)
    const { appName, fbURL, instaURL, twitterURL, youtubeURL, storeURL } = useContext(AppContext)
    let location=useLocation()
    let showHeader=false
    let path = location.pathname
    // before we were using hide header option
    // if(path=='/login' || path=='/sellers/quicksignup' || path=='/resetpassword' || path=='/newpassword'){
        // now we are using show header option
    if(path=='/home' || path=='/contact' || path=='/terms' || path=='/' || path=='/about' ){
        showHeader=true
    }
    let itemTemplate = (item, options) => {
        return (
            <>
                <Link
                    to={item.to}
                    className={options.className}
                >
                    {item.label}
                </Link>
            </>
        )
    }
    let socialItemTemplate = (item, options) => {
        return (
            <a
                href={item.url}
                className={`${options.className}`}
                title={item.label}
                target='_blank'
            >
                <i className={`${item.icon} color-tertiary`} />
            </a>
        )
    }
    let menuModel = [
        { label: 'Home',icon: 'pi pi-home', to: '/home', template: itemTemplate, className: `${isLoggedIn ? 'p-d-none' : ''} color-Info` },
        { label: 'About', to: '/about', style: { borderLeft: isLoggedIn ? 'none' : '2px solid #0e5b96' }, template: itemTemplate },
        { label: 'Contact', to: '/contact', style: { borderLeft: '2px solid #0e5b96' }, template: itemTemplate },
        { label: 'Terms & Conditions', to: '/terms', style: { borderLeft: '2px solid #0e5b96' }, template: itemTemplate },
//         
        // { label: 'Login', to: '/login', template: itemTemplate,  className: `${isLoggedIn ? 'p-d-none' : ''} button btn-primary p-button-rounded p-button-raised` },
        // { label: 'Register', to: '/sellers/signup',  template: itemTemplate, className: `${isLoggedIn ? 'p-d-none' : ''} btn-secondary p-button-rounded p-button-raised` },
        { label: 'Follow us on facebook', icon: 'fab fa-facebook-f', url: fbURL, className: fbURL ? '' : 'p-d-none', template: socialItemTemplate },
        { label: 'Follow us on twitter', icon: 'fab fa-twitter', url: twitterURL, className: twitterURL ? '' : 'p-d-none', template: socialItemTemplate },
        { label: 'Follow us on instagram', icon: 'fab fa-instagram', url: instaURL, className: instaURL ? '' : 'p-d-none', template: socialItemTemplate },
        { label: 'Follow us on youtube', icon: 'fab fa-youtube', url: youtubeURL, className: youtubeURL ? '' : 'p-d-none', template: socialItemTemplate },
        { label: 'Visit our store', icon: 'fas fa-globe', url: storeURL, className: storeURL ? '' : 'p-d-none', template: socialItemTemplate },
    ]

    const end = () => {
    return (
        <>
        {
        !(isLoggedIn) ?
        <div className="flex align-items-center gap-2">
            <Link to="/login">
                <button  class="p-button p-component p-button-rounded p-button-raised p-button-sm
                    //  p-py-1 p-px-3 bg-Primary bg-hover-tertiary hover-primary primary-border p-m-2">
                    <span class="p-button-label p-c">Login</span><span class="p-ink"></span>
                </button>
            </Link>
            <Link to='/sellers/quicksignup'>
                <button class="p-button p-component p-button-rounded p-button-raised p-button-sm
                    p-py-1 p-px-3 bg-Secondary bg-hover-tertiary hover-secondary secondary-border p-m-2">
                    <span class="p-button-label p-c">Register</span><span class="p-ink"></span>
                </button>
            </Link>
            
        </div>
        
        :""
        
        }
    
        </>
        )
    };
    return (
        showHeader?
        <Menubar
            model={menuModel}
            // fixed-top
            className='custom-header container border-none border-radius-0  p-px-2 p-px-sm-4 p-py-3 animated fadeInDown'
            start={
                <img
                    src="/logo.jpg"
                    alt={appName}
                    // width="100%"
                    height="100"
                    className='p-m-0 p-p-0'
                    style={{ cursor: 'pointer' }}
                    onClick={() => { window.open('/home', '_self') }}
                />
            }
            end={end}
        />: ''
    );
}

export default Header