import { useEffect, useState, useRef } from 'react'
import { Can } from '../../../../configs/Ability-context'
import { CustomInput, SubmitButton } from '../../../misc/components/Inputs'
import CustomSelect from '../../../misc/components/CustomSelect'
import LoaderModal from '../../../misc/components/LoaderModal'
import getOptions from '../../../misc/components/Options'
import { setElementDisability, checkUniqueness } from '../../../misc/components/Helpers'
import { Dialog } from 'primereact/dialog';
import { createSellerPlan, getPlansFeatures, getSellerPlan, updateSellerPlan } from '../../../../services/users/seller-plan-service';
import { Checkbox } from 'primereact/checkbox';



const INITIAL_STATE = {
    title: '',
    subtitle: '',
    isDefault: false,
    priceMonth: null,
    priceAnnual: null,
    priceOnce: null,
    allFeatures: [],
    selectedFeatures: [],
    validationMessage: '',
    isLoading: false,
}
let _isMounted
export default (props) => {

    let planForm = useRef(null)
    let { APO_plan, selectedPlan, modalShow, hideModal, refreshData } = props

    const [{
        allFeatures, title, subtitle, isDefault, priceAnnual, priceMonth, priceOnce, selectedFeatures,
        isLoading, validationMessage
    }, setState] = useState({
        ...INITIAL_STATE,
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getSellerPlan(selectedPlan)
                let { title, subtitle, is_default, price_annual, price_monthly, price_one_time, plan_features } = data
                _isMounted && setState(prevState => ({
                    ...prevState,
                    title,
                    subtitle,
                    isDefault: is_default,
                    priceMonth: price_monthly,
                    priceAnnual: price_annual,
                    priceOnce: price_one_time,
                    selectedFeatures: plan_features.map(p => p.id)
                }))
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        const fetchMeta = async () => {
            try {
                const { data } = await getPlansFeatures()
                APO_plan !== 'create' && modalShow && fetchData()
                changeValue('allFeatures', data)
            }
            catch (err) {
                console.log(err)
            }
        }
        modalShow && fetchMeta()
        return () => {
            _isMounted = false
        }
    }, [modalShow])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const makeStateEmpty = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            ...INITIAL_STATE
        }))
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
        changeValue('validationMessage', '')
    }

    const handleSelectChange = e => {
        changeValue('selectedFeatures', e.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!planForm.current.checkValidity()) {
            planForm.current.classList.add('was-validated')
            return
        }
        else if (!selectedFeatures || !selectedFeatures.length) {
            changeValue('validationMessage', 'Please choose some features for this plan')
            return
        }
        else {
            changeValue('isLoading', true)
            try {
                let SellerPlan = {
                    title, subtitle, is_default: isDefault, price_monthly: priceMonth, price_annual: priceAnnual, price_one_time: priceOnce,
                }
                let feataureids = selectedFeatures
                const { success, message } = APO_plan === 'create' ? await createSellerPlan(SellerPlan, feataureids) : await updateSellerPlan(selectedPlan, SellerPlan, feataureids)

                changeValue('validationMessage', message)
                if (success) {
                    setTimeout(() => {
                        makeStateEmpty()
                        hideModal()
                        refreshData(success, message)
                    }, 750);
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
    }
    const titleInput = <CustomInput
        label='Title'
        name={`title`}
        value={title}
        handleInput={handleInput}
        onFocus={() => { setElementDisability('formSubmit', true, 'p-disabled', true) }}
        onBlur={(e) => { checkUniqueness(e, 'seller_plans', 'title', 'formSubmit', 'p-disabled') }}
        invalidMessage={title ? 'A plan with this title already exists' : 'This field is mandatory'}
        disabled={APO_plan !== 'create'}
        required
    />
    const subTitleInput = <CustomInput
        label='Subtitle'
        name={`subtitle`}
        value={subtitle}
        handleInput={handleInput}
        disabled={APO_plan === 'read'}
        required
    />
    const priceMonthInput = <CustomInput
        type='number'
        label={'Price/month'}
        name={'priceMonth'}
        value={priceMonth}
        handleInput={handleInput}
        disabled={APO_plan === 'read'}
        required
    />
    const priceAnnualInput = <CustomInput
        type='number'
        label={'Price/year'}
        name={'priceAnnual'}
        value={priceAnnual}
        handleInput={handleInput}
        disabled={APO_plan === 'read'}
        required
    />
    const priceOnceInput = <CustomInput
        type='number'
        label={'Price/one time'}
        name={'priceOnce'}
        value={priceOnce}
        handleInput={handleInput}
        disabled={APO_plan === 'read'}
        required
    />
    let featureOptions = allFeatures.length ? getOptions('planFeatures', allFeatures) : []
    const selectFeaturesInput = <CustomSelect
        className='w-100 mb-4'
        handleChange={handleSelectChange}
        isDisabled={APO_plan === 'read'}
        isMulti
        options={featureOptions}
        placeholder={`Select features..`}
        value={selectedFeatures}
    />
    const isDefaultInput = <CustomInput
        id="binary"
        type='checkbox'
        label={'Default ?'}
        checked={isDefault}
        handleInput={e => changeValue('isDefault', e.checked)}
        disabled={APO_plan === 'read'}
        noLabel
    />
    const submitBtn = APO_plan === 'read' ? null : <SubmitButton
        className="w-100 mb-3"
        color='secondary'
        id='formSubmit'
        label={APO_plan}
        type="submit"
    />
    const validatingLabel = <label className='p-0 m-0 validating-label'>{validationMessage}&nbsp;</label>
    const fieldsToDisplay1 = [titleInput, subTitleInput]
    const fieldsToDisplay2 = [priceMonthInput, priceAnnualInput, priceOnceInput, isDefaultInput, submitBtn, validatingLabel]

    return (
        <Can I={APO_plan} a='sellerPlans'>
            <Dialog
                header={<p className='color-tertiary m-0 p-0'>{`${APO_plan === 'create' ? 'New' : APO_plan === 'update' ? 'Update' : 'View'} Plan`}</p>}
                modal
                onHide={() => { hideModal(); makeStateEmpty() }}
                className='mx-3 m-md-0'
                style={{ width: '50vw' }}
                breakpoints={{ '960px': '75vw', '768px': '100vw', }}
                visible={modalShow}
                contentClassName={`overflow-visible`}
            >
                <form ref={planForm} className='m-0 px-0 pt-4 text-center color-tertiary' onSubmit={handleSubmit} noValidate>
                    <div className='m-0 p-0 p-grid p-align-center'>
                        {fieldsToDisplay1.map((field, index) => <div key={index + 1} className='m-0 col-sm-6'>{field}</div>)}
                        <div className='m-0 col-sm-12'>{selectFeaturesInput}</div>
                        {fieldsToDisplay2.map((field, index) => <div key={index + 1} className='m-0 col-sm-6'>{field}</div>)}
                    </div>
                </form>
            </Dialog>
            <LoaderModal modalShow={isLoading} />
        </Can >
    )
}