
import React from 'react'
import { UserContext } from '../../contexts/UserContext'
import { Route, Redirect } from 'react-router-dom'


export const PrivateRoute = ({ children, ...rest }) => {
    const { isLoggedIn } = React.useContext(UserContext)

    return (
        <Route
            {...rest}
            render={() => isLoggedIn ? children : <Redirect to='/login' />}
        />
    );
}