import React, { useState } from "react";
import { MDBCollapse, MDBCard, MDBCardBody, MDBRow, MDBCol } from "mdbreact";
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import VariationForm from './VariationForm'



const VariationsAccordion = (props) => {
    const [collapseID, setCollapseID] = useState('')
    let {
        variations, APO_product, handleRemoveVariation, handleUpdateVariation, productVariationAttributes,
        stockStatusOptions, backordersOptions, priceRule, remarks } = props

    const toggleCollapse = collapseId => {
        setCollapseID(collapseID !== collapseId ? collapseId : ""
        );
    }

    const handleSelectChange = (attrName, variationIndex) => selectedOption => {
        // console.log(selectedOption)
        // console.log(attrName)
        let Attributes = variations[variationIndex].attributes;
        // console.log(variations[variationIndex])
        // console.log(Attributes)

        let updatedAttributes = Attributes.length < 1 ?
            [selectedOption]
            :
            !Attributes.find(attr => attr.name === attrName) ?
                [...Attributes, selectedOption]
                :
                Attributes.map((attr) => {
                    if (attr.name === attrName) {
                        return selectedOption
                    }
                    return attr
                })

        handleUpdateVariation(variationIndex, 'attributes', updatedAttributes)

    }
    const getVariationAttributeCombinations = (variationIndex) => {
        // console.log(productVariationAttributes)
        let variationAttributeCombinations = productVariationAttributes.map((attr, attrIndex) => {
            let varAttrOptions = attr.options.map(option => ({
                id: attr.id,
                key: attr.id,
                label: option.label,
                name: attr.name,
                value: {
                    id: attr.id,
                    name: attr.name,
                    option: option.label
                }
            }))
            varAttrOptions = [
                { id: `12${attr.name}`, key: 0.12, label: `Any ${attr.name}...`, name: attr.name, value: [] },
                ...varAttrOptions.filter(option => option.label !== 'Select all')
            ]
            return (
                <div key={attrIndex + 2} className="px-2 col" style={{ width: '200px' }}>
                    <MyOwnSelect
                        options={varAttrOptions}
                        handleChange={handleSelectChange(attr.name, variationIndex)}
                        placeHolder={`Select ${attr.name}`}
                        selectedValue={variations[variationIndex].attributes.find(attribute => attribute.name === attr.name)}
                        withOutIcon
                        controlHeight='28px'
                    />
                </div>
            )
        })
        return variationAttributeCombinations
    }
    // console.log('variations', variations)
    return (
        <>
            {variations.map((variation, index) => {
                // console.log(variation)
                return (
                    <MDBCard className="mb-3 border-radius-0 border-none" key={index + 1}>
                        <MDBRow
                            between
                            className="color-primary-light font-weight-bold small m-0 p-2 h-100"
                        >
                            <MDBRow className='m-0 p-0'>
                                <div
                                    className="m-0 py-2"
                                    onClick={() => { toggleCollapse(`${variation.id ? variation.id : variation.fakeId}`) }}
                                    style={{ width: '80px', cursor: 'all-scroll' }}
                                >
                                    {`Variation ${index + 1}`}
                                </div>
                                <MDBCol middle className="m-0 p-0 row">
                                    {getVariationAttributeCombinations(index)}
                                </MDBCol>
                            </MDBRow>
                            <div
                                className="m-0 p-0 d-none d-sm-flex col"
                                onClick={() => { toggleCollapse(`${variation.id ? variation.id : variation.fakeId}`) }}
                                style={{ cursor: 'all-scroll' }}
                            />

                            <MDBCol middle sm='2' className="m-0 p-0">
                                <a
                                    href="#"
                                    onClick={() => { handleRemoveVariation(index) }}
                                    className='small mt-1 text-danger float-right ml-3'
                                >
                                    {'Remove'}
                                </a>
                                <i
                                    className={`${collapseID === `${variation.id ?
                                        variation.id
                                        :
                                        variation.fakeId}` ?
                                        "fa fa-caret-down fa-rotate-180"
                                        :
                                        "fa fa-caret-down"} float-right fa-lg mt-1 cursor-pointer`}
                                    style={{ transition: '0.3s' }}
                                    onClick={() => { toggleCollapse(`${variation.id ? variation.id : variation.fakeId}`) }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBCollapse
                            id={`${variation.id ? variation.id : variation.fakeId}`}
                            isOpen={collapseID}
                        >
                            <MDBCardBody className='m-0 p-0'>
                                <VariationForm
                                    APO_product={APO_product}
                                    backordersOptions={backordersOptions}
                                    handleUpdateVariation={handleUpdateVariation}
                                    index={index}
                                    priceRule={priceRule}
                                    remarks={remarks}
                                    stockStatusOptions={stockStatusOptions}
                                    variation={variation}
                                />
                            </MDBCardBody>
                        </MDBCollapse>

                    </MDBCard>
                )
            })}

        </>
    );
}


export default VariationsAccordion