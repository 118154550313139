
export const createUser = async (newUser, type) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newUser }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/users/${type}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
// import current sellers from the live site
export const syncSellers = async () => {
    try {
        // console.log("syncSellers");
        const res = await fetch(`/api/sellers/sync`)
        // console.log(res)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getUsers = async () => {
    try {
        const res = await fetch(`/api/users`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getUsersWithData = async () => {
    try {
        const res = await fetch(`/api/users/data`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
export const getSellerSummary = async () => {
    try {
        const res = await fetch(`/api/sellers/count`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getCustomUsers = async (type) => {
    try {
        const res = await fetch(`/api/users?type=${type}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getCustomUsersWithData = async (type) => {
    try {
        const res = await fetch(`/api/users/data?type=${type}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getCustomChilds = async (parent, type) => {
    try {
        const res = await fetch(`/api/users/read/${parent}?type=${type}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getUser = async (id) => {
    try {
        const res = await fetch(`/api/users/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getCustomer = async (cell) => {
    try {
        const res = await fetch(`/api/customers/${cell}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateUser = async (id, updatedUser, type) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedUser }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/users/${id}?type=${type}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
export const updatePassword = async (id, updatedUser, type) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedUser }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/users/${id}?type=${type}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
export const verifyUserEmail = async (token) => {
    try {
        const endpoint= `/api/sellers/verify_email?token=${encodeURIComponent(token)}`;
        const res = await fetch(endpoint)
        const json = await res.json()
        // console.log(res)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
export const resendVerificationEmail = async (user_id) => {
    try {
        const endpoint= `/api/sellers/resend_verification_email?user_id=${user_id}`;
        const res = await fetch(endpoint)
        const json = await res.json()
        // console.log(res)
        return json
    }
    catch (error) {
        console.log(error)
    }
}
export const verifyResetPasswordToekn = async (token) => {
    try {
        const endpoint= `/verifypasswordtoken?token=${encodeURIComponent(token)}`;
        const res = await fetch(endpoint)
        const json = await res.json()
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteUser = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/users/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getUserPriceRule = async (user) => {
    try {
        const res = await fetch(`/api/users/priceRule/${user}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}