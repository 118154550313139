import { verifyUserEmail,resendVerificationEmail } from "../../../../services/users/user-service";
import React, { useRef } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom'
import { UserConext } from '../../../../contexts/UserContext'


const SellerEmailVerification = () => {

    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    let toast = useRef(null)
    let history = useHistory()
    // console.log(token);
    // token=searchParams.get("token")
    const verifyEmaiRequest = async () => {
        try {
            const { success, message } = await verifyUserEmail(token)

            if (success) {
                toast.current?.show({ severity: 'success', summary: 'Verified', detail: message + " Login to continue!" })
                history.push('/login')
            }
            else {
                toast.current?.show({ severity: 'error', summary: 'Not Verified', detail: message })
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    verifyEmaiRequest();
    // useEffect(() => {
    //     console.log("email verification in process...");

    // }, []);
    return (
        < Toast ref={toast} className='p-mt-5 p-mr-3' />
    )

}
const ResendVerificationEmail = () => {

    let toast = useRef(null)
    let history = useHistory()
    // console.log(token);
    // token=searchParams.get("token")
    const resendVerificationEmailRequest = async () => {
        let user_id = localStorage.getItem('ui')
        // console.log(user_id);
        try {
            const { success, message } = await resendVerificationEmail(user_id)

            if (success) {
                toast.current?.show({ severity: 'success', summary: 'Done', detail: message })
                history.push('/login')
            }
            else {
                toast.current?.show({ severity: 'error', summary: 'Failed', detail: message })
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    resendVerificationEmailRequest();
    // useEffect(() => {
    //     console.log("email verification in process...");

    // }, []);
    return (
        < Toast ref={toast} className='p-mt-5 p-mr-3' />
    )

}

const RequestEmailVerification = () => {
    let toast = useRef(null)
    return (
        <>

            <div className="screen-center h-50vh">
                <div className="p-m-0 p-px-4 p-p-sm-0 w-100 w-sm-75 w-md-50 w-lg-25 p-d-flex p-flex-column">
                    {/* <div className="center-screen h-50vh"> */}
                    <h6
                        className='bg-tertiary mb-3 text-left mx-3 py-2 px-3 color-dark'
                        style={{ borderLeft: '8px solid #0e5b96', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px' }}
                    >Email is not verified. Please verify email to continue.
                        <Link to='/sellers/resend_verify_email'>Resend Verification Request</Link>
                    </h6>

                </div>
            </div>
            < Toast ref={toast} className='p-mt-5 p-mr-3' />
        </>
    )
}

export {
    SellerEmailVerification, RequestEmailVerification,ResendVerificationEmail
}