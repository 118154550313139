import React from 'react'
import { setElementDisability, checkUniqueness, handleNumberInputBlur, handleNumberInputKeyPress } from '../../../../misc/components/Helpers'
import { MDBTooltip } from 'mdbreact'
import { Input } from '../../../../misc/components/Inputs'

export const AdvancedDataStep = (props) => {
    let {
        APO_product,
        changeValue,
        currentStep,
        handleInput,

        advancedDataForm,
        purchaseNote,
        sourceURL,
        update_threshold
    } = props
    const sourceURLInput = <MDBTooltip
        domElement
        tag="span"
        placement="right"
        className='bg-Primary'
    >
        <div>
            <Input
                id='sourceURL'
                label='Source URL'
                name={`sourceURL`}
                value={sourceURL || ''}
                handleInput={handleInput}
                onFocus={() => { setElementDisability('formSubmit', true) }}
                onBlur={(e) => { checkUniqueness(e, 'products', 'source_url', 'formSubmit') }}
                containerClass='mb-3'
                disabled={APO_product === 'read'}
                invalidMessage={'A product with this URL already exists'}
            />
        </div>
        <i>
            {`If you are taking data for this product from an external source, please provide its URL`}
        </i>
    </MDBTooltip >
    const updateThresholdInput = <MDBTooltip
        domElement
        tag="span"
        placement="right"
        className='bg-Primary'
    >
        <div>
            <Input
                type="number"
                label='Update threshold (days)'
                name={`update_threshold`}
                value={update_threshold || ''}
                handleInput={handleInput}
                onKeyPress={handleNumberInputKeyPress}
                onBlur={(e) => handleNumberInputBlur(e, changeValue)}
                containerClass='mb-3'
                disabled={APO_product === 'read'}
                required
                invalidMessage={`Please enter data update threshold`}
            />
        </div>
        <i>
            {`Number of days after which you will be asked to review your product details`}
        </i>
    </MDBTooltip>
    const purchaseNoteInput = <MDBTooltip
        domElement
        tag="span"
        placement="right"
    >
        <div className='m-0 p-0'>
            <Input
                type='textarea'
                label='Purchase note'
                name={`purchaseNote`}
                value={purchaseNote}
                handleInput={handleInput}
                containerClass='mb-3'
                disabled={APO_product === 'read'}
                rows='3'
            />
        </div>
        <i>
            {`Enter an optional note to send the customer after purchase.`}
        </i>
    </MDBTooltip>
    // if (currentStep !== 8) {
    //     return null
    // }
    return (
        <form ref={advancedDataForm} className={`m-0 p-0 ${currentStep !== 8 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='p-grid m-0'>
                <div className='col-sm-6'>
                    {sourceURLInput}
                </div>
                <div className='col-sm-6'>
                    {updateThresholdInput}
                </div>
                <div className='col-sm-12'>
                    {purchaseNoteInput}
                </div>
            </div>
        </form>
    )
}
