
export const createPaymentMethod = async (newPaymentMethod) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newPaymentMethod }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/payments/methods`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPaymentMethods = async () => {
    try {
        const res = await fetch(`/api/payments/methods`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPaymentMethod = async (id) => {
    try {
        const res = await fetch(`/api/payments/methods/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updatePaymentMethod = async (id, updatedPaymentMethod) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedPaymentMethod }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/payments/methods/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deletePaymentMethod = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/payments/methods/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}