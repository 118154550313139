import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Can } from '../../configs/Ability-context'
import NewUser from './pages/NewUser';
import AllPersons from './AllPersons';
import EditUser from './pages/EditUser';
import Roles from './roles/Roles'
import Permissions from './permissions/Permissions'
import NotFound from '../misc/pages/NotFound'
import { PrivateRoute } from '../misc/PrivateRoute'


const Users = () => {
    return (
        <Switch>
            <PrivateRoute path="/users/new">
                <Can I='create' a='users'>
                    <NewUser />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/users/all">
                <Can I='read' a='users'>
                    <AllPersons persons={'users'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/users/edit/:user">
                <Can I='update' a='userProfile'>
                    <EditUser key={'edit-user'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/users/myProfile">
                <Can I='update' a='userProfile'>
                    <EditUser key={'edit-profile'} />
                </Can>
            </PrivateRoute>
            <Route path="/users/roles" component={Roles} />
            <Route path="/users/permissions" component={Permissions} />
            <Route component={NotFound} />
        </Switch>

    )
}

export default Users