import { Dialog } from "primereact/dialog";
import { useState, useEffect, useRef, useContext } from "react";
import { AbilityContext, Can } from "../../../configs/Ability-context";
import { CustomButton } from "../../misc/components/Inputs";
import { CustomListing } from "../../misc/components/CustomListing";
import { Column } from "primereact/column";
import {
  capitalize,
  formatDate,
  getJsonData,
} from "../../misc/components/Helpers";
import getOptions from "../../misc/components/Options";
import { UserContext } from "../../../contexts/UserContext";
import CustomSelect from "../../misc/components/CustomSelect";
import {
  createSellerOrder,
  createSellerOrderDetails,
  getOrderItemsWithData,
  getProductSeller,
  getSellerOrderItemsWithData,
  updateOrderItemsFields,
} from "../../../services/orders/item-service";
import { CustomDatePicker } from "../../misc/components/CustomDatePicker";
import { Dropdown } from "primereact/dropdown";
let _isMounted;
export const ViewSellerOrderDetailsModal = (props) => {
  const [
    {
      allItems,
      allItemStatuses,
      allOrderStatuses,
      allSellers,
      allShopManagers,
      isReadingOrder,
      isUpdatingOrder,
      isReadingItem,
      isStatusAfterEffects,
      // isLoading,
      refreshData,
      selectedOrder, //to view and edit order
      selectedRecords,
      selectedOrderDate,
      selectedStatus,
      selectedSeller,
      selectedShopManager,
      selectedShippingDate,
      selectedCourier,
      selectedFollowUp, //selected values for column filters
      updatedStatus,
      selectedItem,
      selectedBulkOption,
      selectedBulkValueOption, //when working in bulk
      editorText,
      activeEditor,
      seller,
    },
    setState,
  ] = useState({
    allItems: [],
    allItemStatuses: [],
    allOrderStatuses: [],
    allSellers: [],
    allShopManagers: [],
    isReadingOrder: false,
    isUpdatingOrder: false,
    isReadingItem: false,
    isStatusAfterEffects: false,
    isLoading: false,
    refreshData: false,
    selectedOrder: "", //to view and edit order
    selectedRecords: [],
    selectedOrderDate: null,
    selectedStatus: null,
    selectedSeller: null,
    selectedShopManager: null,
    selectedShippingDate: null,
    selectedCourier: null,
    selectedFollowUp: null,
    updatedStatus: null,
    selectedItem: null,
    selectedBulkOption: null,
    selectedBulkValueOption: null,
    editorText: "",
    activeEditor: { field: "", id: "" },
    seller: null,
  });

  const { orderId } = props;

  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    _isMounted = true;
    const fetchInitialData = async () => {
      setIsLoading(true);
      // changeValue("isLoading", true);
      try {
        const { data } = await getSellerOrderItemsWithData(orderId);
        console.log("data", data);
        // const { data } = await getOrderItemsWithData(0, 100);
        // fetchData();
        let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
        changeValue("allItems", itemsWithJsons);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      // changeValue("isLoading", false);
    };
    // const fetchData = async () => {
    //   try {
    //     const { data } = await getSellerOrderItemsWithData(orderId);
    //     // const { data } = await getOrderItemsWithData(0, 100);
    //     let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
    //     _isMounted &&
    //       setState((prevState) => ({
    //         ...prevState,
    //         allItems: [...prevState.allItems, ...itemsWithJsons],
    //       }));
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    if (orderId) {
      props.modalShow && fetchInitialData();
    }
    // setModalShow(true);
    setModalShow(props.modalShow);
    return () => {
      _isMounted = false;
    };
    // setCurrentStep(1);
  }, [props.modalShow, refreshData]);
  const orderDateFilter = (
    <CustomDatePicker
      value={selectedOrderDate}
      // handleChange={onOrderDateChange}
      placeholder="Select order date"
      isClearable
    />
  );
  const changeValue = (name, value) => {
    _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
  };
  let itemStatusOptions = allItemStatuses.length
    ? getOptions("itemStatuses", allItemStatuses)
    : [];
  let itemStatusOptionsForSeller = allItemStatuses.length
    ? getOptions("sellerItemStatuses", allItemStatuses)
    : [];
  const ability = useContext(AbilityContext);
  const handleViewProduct = (productBktId) => {
    setState((prevState) => ({
      ...prevState,
      selectedItem: productBktId,
      isReadingItem: true,
    }));
  };
  const statusFilter = (
    <Dropdown
      value={selectedStatus}
      options={allItemStatuses.map((status) => ({
        label: status.title,
        value: status.slug,
      }))}
      onChange={(e) => {
        dt.current.filter(e.value, "status", "equals");
        changeValue("selectedStatus", e.value);
      }}
      placeholder="Select a status"
      className="p-column-filter"
      showClear
    />
  );
  const idBody = (rowData) => {
    return (
      <>
        <span className="p-column-title">Order Id</span>
        <CustomButton
          label={rowData.order_detail.bkt_order_id}
          variant="text-button"
          color="primary-light"
          onClick={() => {
            // handleView(rowData.order_id);
          }}
          title={"view order"}
        />
      </>
    );
  };
  const dateBody = (rowData) => {
    let date = formatDate(new Date(rowData.order_detail.date));
    return (
      <>
        <span className="p-column-title">Order Date</span>
        {date}
      </>
    );
  };
  const TotalPriceBody = (rowData) => {
    let TotalPrice =
      Number(rowData.order_detail.qty) * Number(rowData.order_detail.price);
    return (
      <>
        <span className="p-column-title">Total Price</span>
        {TotalPrice}
      </>
    );
  };
  const productNameBody = (rowData) => {
    let _permalink = rowData.order_detail.product
      ? rowData.order_detail.product.permalink
      : undefined;
    let itemName = rowData.order_detail ? rowData.order_detail.name : undefined;
    return (
      <CustomButton
        label={itemName}
        title={_permalink ? "view product on store" : undefined}
        color="primary-light"
        variant="text-button"
        tooltip={
          _permalink
            ? undefined
            : "Looks like product has been deleted from store."
        }
        onClick={
          _permalink
            ? () => {
                let newTab = window.open(_permalink, "_blank");
                newTab.focus();
              }
            : null
        }
      />
    );
  };
  const statusBody = (rowData) => {
    let _status = allOrderStatuses.find(
      (s) => s.slug === rowData.order_detail.status
    );
    let options = isSellerLoggedIn
      ? itemStatusOptionsForSeller
      : itemStatusOptions;
    options = options.map((option) => ({
      ...option,
      disabled:
        (option.entity.changeable_at_item && !option.entity.usable_at_item) ||
        (isSellerLoggedIn && !option.entity.seller_usable),
    }));
    return (isSellerLoggedIn &&
      !_status?.seller_usable &&
      !_status?.seller_changeable) ||
      // (order_status && !_status) ||
      ability.cannot("updateStatus", "orderItems") ||
      !_status?.changeable_at_item ? (
      // _status?.entity.is_final
      _status?.title
    ) : (
      <CustomSelect
        options={options}
        optionLabel="label"
        value={rowData.status}
        // handleChange={(e) => {
        //   handleChangeStatus(rowData, e.value, rowData.status);
        // }}
        filter
        resetFilterOnHide
      />
    );
  };

  let { rows } = props;
  let dt = useRef(null);
  let toast = useRef(null);
  // let contextMenu = useRef(null)
  const { isSellerLoggedIn } = useContext(UserContext);
  // const handleChange = () => {};
  let columns = [
    {
      field: "date",
      header: "Order Date",
      body: dateBody,
      sortable: true,
      filter: true,
      filterElement: orderDateFilter,
      filterFunction: "filterDate",
      selectedByDefault: true,
      headerStyle: { width: "120px" },
    },
    {
      field: "name",
      header: "Product",
      body: productNameBody,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by product",
      selectedByDefault: true,
      filterMatchMode: "contains",
      headerStyle: { width: "400px" },
    },
    {
      field: "order_detail.status",
      header: "Item Status",
      // body: statusBody,
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterElement: statusFilter,
    },
    {
      field: "order_detail.qty",
      header: "Qty.",
      sortable: true,
      selectedByDefault: true,
      headerStyle: { width: "50px" },
    },
    {
      field: "order_detail.price",
      header: "Price",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by price",
      filterMatchMode: "equals",
      selectedByDefault: true,
      headerStyle: { width: "100px" },
    },
    {
      // field: "totalPrice",
      header: "Total Price",
      body: TotalPriceBody,
      // sortable: true,
      // filter: true,
      // filterPlaceholder: "Search by price",
      // filterMatchMode: "equals",
      selectedByDefault: true,
      headerStyle: { width: "100px" },
    },
  ];
  return (
    <Dialog
      header={
        <h2 className="m-0 p-0 color-tertiary font-weight-normal">{`View order`}</h2>
      }
      position="right"
      onHide={() => {
        setModalShow(false);
        setTimeout(() => {
          props.hideModal();
        }, 200);
      }}
      className="mx-4 ml-sm-5 m-lg-0 bg-Primary h-75"
      style={{ width: "75vw" }}
      breakpoints={{ "960px": "100vw" }}
      visible={modalShow}
      contentClassName="h-75"
    >
      <CustomListing
        allRecords={allItems}
        // heading={heading}
        heading="Order Items"
        rows={rows}
        firstColumn={
          <Column
            field="bkt_order_id"
            header="Order Id"
            body={idBody}
            sortable
            filter
            filterPlaceholder="Search by order-id"
            filterMatchMode="contains"
            headerStyle={{ width: "90px" }}
          />
        }
        columns={columns}
        permissionSubject={"orderItems"}
        isLoading={isLoading}
        handleRefresh={() => changeValue("refreshData", !refreshData)}
        // extraButton={(customButton, rowData) => {
        //     let _seller = sellerOptions && sellerOptions.length && sellerOptions.filter(option => option.value === rowData.seller_id)
        //     return (
        //         customButton('fab fa-whatsapp', () => { sendDetailsToSeller(rowData) }, !_seller, 'send details to seller')
        //     )
        // }}
        // handleView={true}
        // customViewButton={(customButton, rowData) => {
        //   let _order = rowData.order;
        //   return _order && _order.href && !isSellerLoggedIn ? (
        //     <a href={_order.href} target="_blank" title="edit order on store">
        //       {customButton("fas fa-share color-primary")}
        //     </a>
        //   ) : null;
        // }}
        readPermissionSubject={() => "orders"}
        // handleEdit={handleEdit}
        editDataKey={"order_id"}
        editBtnTitle={"edit order"}
        updatePermissionSubject={() => "orders"}
        // handleDelete={true}
        // customDeleteButton={(customButton, rowData) => {
        //   return rowData.product_bkt_id
        //     ? customButton(
        //         "pi pi-eye color-primary-light",
        //         () => {
        //           handleViewProduct(rowData.product_bkt_id);
        //         },
        //         false,
        //         "view product locally"
        //       )
        //     : null;
        // }}
        rowClassName={(rowData) => {
          let itemStatus = allItemStatuses.find(
            (s) => s.slug === rowData.status
          );
          if (itemStatus) {
            let lastUpdatedAt = new Date(rowData.updatedAt);
            lastUpdatedAt.setHours(
              lastUpdatedAt.getHours() + itemStatus?.threshold_hours
            );
            return {
              "bg-warn": new Date() > lastUpdatedAt,
              [`bg-${rowData.status}`]: true,
            };
          }
          return {
            [`bg-${rowData.status}`]: true,
          };
        }}
        // customPaginatorLeft={
        //     allItems.length ?
        //         <div className="p-d-inline-flex p-flex-column p-flex-sm-row p-flex-wrap p-jc-between p-ai-center">
        //             <div className='bg-warn px-2 mx-1 color-primary'>Status outdated</div>
        //         </div>
        //         : null
        // }
        selectedRecords={selectedRecords}
        selectionDataKey={"id"}
        selectedBulkOption={selectedBulkOption}
        selectedBulkValueOption={selectedBulkValueOption}
        // bulkOptions={bulkOptions}
        // bulkValueOptions={bulkValueOptions()}
        // handleBulkAction={handleBulkAction}
        changeValue={changeValue}
        handleExport={() => {
          dt.current.exportCSV();
        }}
        sortField="on_follow_up"
        tableRef={dt}
        toastRef={toast}
        timeStamps={true}
      />
    </Dialog>
  );
};

// export default ViewSellerOrderDetailsModal;

// import getOptions from "../../misc/components/Options";
// import LoaderModal from "../../misc/components/LoaderModal";
// import { Dialog } from "primereact/dialog";
// import { getPaymentMethods } from "../../../services/payments/method-service";
// import { getOrderSources } from "../../../services/orders/source-service";
// import { getOrder } from "../../../services/orders/order-service";
// import { OrderForm } from "./sections/OrderForm";
// import { UserContext } from "../../../contexts/UserContext";
// import { ViewOrderModal } from "./ViewOrderModal";
// // import { EditOrderModal } from './EditOrderModal'
// import { ViewProductModal } from "../../products/pages/ViewProductModal";
// import StatusAfterEffectsModal from "./sections/StatusAfterEffectsModal";
// import { CustomListing } from "../../misc/components/CustomListing";
// import { CustomDatePicker } from "../../misc/components/CustomDatePicker";
// import CustomSelect from "../../misc/components/CustomSelect";
// import {
//   getJsonData,
//   formatDate,
//   capitalize,
//   isNextItemStatusAllowed,
// } from "../../misc/components/Helpers";

// import { InputText } from "primereact/inputtext";
// import { Dropdown } from "primereact/dropdown";
// import { Column } from "primereact/column";
// import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
// import { InputSwitch } from "primereact/inputswitch";
// import {
//   createSellerOrder,
//   createSellerOrderDetails,
//   getOrderItemsWithData,
//   getProductSeller,
//   getSellerOrderItemsWithData,
//   updateOrderItemsFields,
// } from "../../../services/orders/item-service";
// import { getOrderStatuses } from "../../../services/orders/status-service";
// import {
//   getCustomChilds,
//   getCustomUsers,
// } from "../../../services/users/user-service";

// const orderTypeOptions = getOptions("orderTypes"),
//   courierOptions = getOptions("couriers"),
//   countryOptions = getOptions("countries");
// let _isMounted;

// export const ViewSellerOrderDetailsModal = (props) => {
//   const [modalShow, setModalShow] = useState(false);
//   const [currentStep, setCurrentStep] = useState(1);
//   const [orderSourceOptions, setOrderSourceOptions] = useState([]);
//   const [orderStatusOptions, setOrderStatusOptions] = useState([]);
//   const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
//   const [order, setOrder] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   let generalDataForm = useRef(null),
//     customerDataForm = useRef(null),
//     queryDataForm = useRef(null),
//     productsDataForm = useRef(null),
//     shippingDataForm = useRef(null),
//     paymentsDataForm = useRef(null),
//     totalField = useRef(null);
//   let dt = useRef(null);
//   let toast = useRef(null);
//   // let contextMenu = useRef(null)
//   const { isSellerLoggedIn } = useContext(UserContext);
//   const handleChange = () => {};
//   const [
//     {
//       allItems,
//       allItemStatuses,
//       // allOrderStatuses,
//       allSellers,
//       allShopManagers,
//       isReadingOrder,
//       isUpdatingOrder,
//       isReadingItem,
//       isStatusAfterEffects,
//       // isLoading,
//       refreshData,
//       selectedOrder, //to view and edit order
//       selectedRecords,
//       selectedOrderDate,
//       selectedStatus,
//       selectedSeller,
//       selectedShopManager,
//       selectedShippingDate,
//       selectedCourier,
//       selectedFollowUp, //selected values for column filters
//       updatedStatus,
//       selectedItem,
//       selectedBulkOption,
//       selectedBulkValueOption, //when working in bulk
//       editorText,
//       activeEditor,
//       seller,
//     },
//     setState,
//   ] = useState({
//     allItems: [],
//     allItemStatuses: [],
//     allOrderStatuses: [],
//     allSellers: [],
//     allShopManagers: [],
//     isReadingOrder: false,
//     isUpdatingOrder: false,
//     isReadingItem: false,
//     isStatusAfterEffects: false,
//     isLoading: false,
//     refreshData: false,
//     selectedOrder: "", //to view and edit order
//     selectedRecords: [],
//     selectedOrderDate: null,
//     selectedStatus: null,
//     selectedSeller: null,
//     selectedShopManager: null,
//     selectedShippingDate: null,
//     selectedCourier: null,
//     selectedFollowUp: null,
//     updatedStatus: null,
//     selectedItem: null,
//     selectedBulkOption: null,
//     selectedBulkValueOption: null,
//     editorText: "",
//     activeEditor: { field: "", id: "" },
//     seller: null,
//   });
//   let itemStatusOptions = allItemStatuses.length
//     ? getOptions("itemStatuses", allItemStatuses)
//     : [];
//   let itemStatusOptionsForSeller = allItemStatuses.length
//     ? getOptions("sellerItemStatuses", allItemStatuses)
//     : [];
//   let sellerOptions = allSellers.length
//     ? getOptions("sellers", allSellers)
//     : [];
//   let shopManagerOptions = allShopManagers.length
//     ? getOptions("shopManagers", allShopManagers)
//     : [];
//   let { heading, rows } = props;
//   const courierOptions = getOptions("couriers");

//   const handleViewProduct = (productBktId) => {
//     setState((prevState) => ({
//       ...prevState,
//       selectedItem: productBktId,
//       isReadingItem: true,
//     }));
//   };
//   const { orderId, allOrderStatuses } = props;
//   const changeValue = (name, value) => {
//     _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
//   };
//   // let sellerid = 4;
//   // useEffect(() => {
//   //   _isMounted = true;
//   //   const fetchInitialData = async () => {
//   //     changeValue("isLoading", true);
//   //     try {
//   //       const { data } = await getSellerOrderItemsWithData(orderId);
//   //       console.log("data", data);
//   //       // const { data } = await getOrderItemsWithData(0, 100);
//   //       fetchData();
//   //       let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
//   //       changeValue("allItems", itemsWithJsons);
//   //     } catch (error) {
//   //       console.log(error);
//   //     }
//   //     changeValue("isLoading", false);
//   //   };
//   //   const fetchData = async () => {
//   //     try {
//   //       const { data } = await getSellerOrderItemsWithData(orderId);
//   //       // const { data } = await getOrderItemsWithData(0, 100);
//   //       let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
//   //       _isMounted &&
//   //         setState((prevState) => ({
//   //           ...prevState,
//   //           allItems: [...prevState.allItems, ...itemsWithJsons],
//   //         }));
//   //     } catch (error) {
//   //       console.log(error);
//   //     }
//   //   };
//   //   fetchInitialData();
//   //   return () => {
//   //     _isMounted = false;
//   //   };
//   // }, [refreshData]);
//   // useEffect(() => {
//   //   const fetchMeta = async () => {
//   //     try {
//   //       const Statuses = await getOrderStatuses();
//   //       const statusesWithJson = await getJsonData(Statuses.data, [
//   //         "input_needed",
//   //       ]);
//   //       let sellers =
//   //         isSellerLoggedIn || isShopManagerLoggedIn
//   //           ? []
//   //           : isSellerManagerLoggedIn
//   //           ? await getCustomChilds(0, "seller")
//   //           : await getCustomUsers("seller");

//   //       // let shopManagers = isSellerLoggedIn
//   //       //   ? await getCustomChilds(0, "shop_manager")
//   //       //   : isShopManagerLoggedIn
//   //       //   ? []
//   //       //   : await getCustomUsers("shop_manager");
//   //       _isMounted &&
//   //         setState((prevState) => ({
//   //           ...prevState,
//   //           allItemStatuses: statusesWithJson.filter(
//   //             (s) => s.usable_at_item || s.changeable_at_item
//   //           ),
//   //           allOrderStatuses: statusesWithJson,
//   //           allSellers: Array.isArray(sellers) || !sellers ? [] : sellers.data,
//   //           // allShopManagers:
//   //           //   Array.isArray(shopManagers) || !shopManagers
//   //           //     ? []
//   //           //     : shopManagers.data,
//   //         }));
//   //     } catch (err) {
//   //       console.log(err);
//   //     }
//   //   };
//   //   fetchMeta();
//   // }, []);

//   useEffect(() => {
//     _isMounted = true;
//     const fetchInitialData = async () => {
//       setIsLoading(true);
//       // changeValue("isLoading", true);
//       try {
//         const { data } = await getSellerOrderItemsWithData(orderId);
//         console.log("data", data);
//         // const { data } = await getOrderItemsWithData(0, 100);
//         // fetchData();
//         let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
//         changeValue("allItems", itemsWithJsons);
//       } catch (error) {
//         console.log(error);
//       }
//       setIsLoading(false);
//       // changeValue("isLoading", false);
//     };
//     // const fetchData = async () => {
//     //   try {
//     //     const { data } = await getSellerOrderItemsWithData(orderId);
//     //     // const { data } = await getOrderItemsWithData(0, 100);
//     //     let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
//     //     _isMounted &&
//     //       setState((prevState) => ({
//     //         ...prevState,
//     //         allItems: [...prevState.allItems, ...itemsWithJsons],
//     //       }));
//     //   } catch (error) {
//     //     console.log(error);
//     //   }
//     // };
//     if (orderId) {
//       props.modalShow && fetchInitialData();
//     }
//     // setModalShow(true);
//     setModalShow(props.modalShow);
//     setCurrentStep(1);
//   }, [props.modalShow, refreshData, orderId]);

//   //apply colors on rows
//   useEffect(() => {
//     const getRule = (status) => {
//       return `.bg-${status.slug}{
//                 background-color:#${
//                   status.color ? status.color : "ffffff"
//                 } !important;
//             }`;
//     };
//     var style = document.createElement("style");
//     for (let i = 0; i < allOrderStatuses.length; i++) {
//       let status = allOrderStatuses[i];
//       style.innerHTML = `${style.innerHTML}${getRule(status)}`;
//     }
//     // console.log(style)
//     document.body.appendChild(style);
//   }, [allOrderStatuses]);

//   // useEffect(() => {
//   //   _isMounted = true;
//   //   const fetchData = async () => {
//   //     setIsLoading(true);
//   //     try {
//   //       const { data } = await getSellerOrderItemsWithData(0, 100, orderId);

//   //       // let orderStatusOptions = getOptions("orderStatuses", allOrderStatuses);
//   //       // fetchMeta();
//   //       // setOrderStatusOptions(orderStatusOptions);
//   //       // setOrder(_order);
//   //     } catch (err) {
//   //       console.log(err);
//   //     }
//   //     setIsLoading(false);
//   //   };
//   //   const fetchMeta = async () => {
//   //     try {
//   //       // const { data: allPaymentMethods } = await getPaymentMethods();
//   //       // const { data: allOrderSources } = await getOrderSources();
//   //       // let paymentMethodOptions = getOptions(
//   //       //   "paymentMethods",
//   //       //   allPaymentMethods
//   //       // );
//   //       // let orderSourceOptions = getOptions("orderSources", allOrderSources);
//   //       // setPaymentMethodOptions(paymentMethodOptions);
//   //       // setOrderSourceOptions(orderSourceOptions);
//   //     } catch (err) {
//   //       console.log(err);
//   //     }
//   //   };
//   //   if (orderId) {
//   //     props.modalShow && fetchData();
//   //   }
//   //   setModalShow(props.modalShow);
//   //   setCurrentStep(1);
//   //   return () => {
//   //     _isMounted = false;
//   //   };
//   // }, [props.modalShow]);
//   const handleUpdateField = async (item, field, value) => {
//     let itemsToUpdate = item ? [item] : selectedRecords;
//     let itemsIdsToUpdate = itemsToUpdate.map((item) => item.id);
//     let updatedColumn = field ? field : selectedBulkOption,
//       updatedValue =
//         value || typeof value === "boolean" ? value : selectedBulkValueOption;
//     let updatedItem = { [updatedColumn]: updatedValue };
//     updatedItem =
//       field === "status" && item.status === "on_hold"
//         ? { ...updatedItem, remarks: "" }
//         : updatedItem;
//     changeValue("isLoading", true);
//     try {
//       const { success, affectedCount, message, data } =
//         await updateOrderItemsFields(itemsIdsToUpdate, updatedItem);
//       toast.current.show(message);
//       if (success && affectedCount > 0) {
//         let updatedItems = allItems;
//         await data.map((d) => {
//           updatedItems.map((i) => {
//             if (i.id === d.id) {
//               Object.keys(d).map((key) => {
//                 i[key] = d[key];
//               });
//             }
//             return i;
//           });
//         });
//         item
//           ? _isMounted &&
//             setState((prevState) => ({
//               ...prevState,
//               allItems: updatedItems,
//               editorText: "",
//               activeEditor: { field: "", id: "" },
//             }))
//           : _isMounted &&
//             setState((prevState) => ({
//               ...prevState,
//               allItems: updatedItems,
//               selectedRecords: [],
//               selectedBulkOption: null,
//               selectedBulkValueOption: null,
//             }));
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     changeValue("isLoading", false);
//   };
//   const handleUpdateItem = async (item, updatedDetails) => {
//     let itemsToUpdate = item ? [item] : selectedRecords;
//     let itemsIdsToUpdate = itemsToUpdate.map((item) => item.id);
//     let updatedItem =
//       Object.keys(updatedDetails).includes("status") &&
//       item.status === "on_hold"
//         ? { ...updatedDetails, remarks: "" }
//         : updatedDetails;
//     changeValue("isLoading", true);
//     try {
//       const { success, affectedCount, message, data } =
//         await updateOrderItemsFields(itemsIdsToUpdate, updatedItem);
//       toast.current.show(message);
//       if (success && affectedCount > 0) {
//         let updatedItems = allItems;
//         await data.map((d) => {
//           updatedItems.map((i) => {
//             if (i.id === d.id) {
//               Object.keys(d).map((key) => {
//                 i[key] = d[key];
//               });
//             }
//             return i;
//           });
//         });
//         item
//           ? handleChange()
//           : _isMounted &&
//             setState((prevState) => ({
//               ...prevState,
//               allItems: updatedItems,
//               selectedRecords: [],
//               selectedBulkOption: null,
//               selectedBulkValueOption: null,
//             }));
//       }
//     } catch (err) {
//       console.log(err);
//     }
//     changeValue("isLoading", false);
//   };

//   const handleChangeStatus = (item, nextStatus, previousStatus) => {
//     if (isNextItemStatusAllowed(nextStatus, previousStatus)) {
//       let _status = allItemStatuses.find((s) => s.slug === nextStatus);
//       // console.log(item)
//       if (_status.question_message || _status.input_needed) {
//         // toggleStatusAfterEffectsModal({ modalShow: true, _status, item });
//       } else {
//         handleUpdateField(item, "status", nextStatus);
//       }
//     } else
//       toast.current.show({
//         severity: "warn",
//         summary: `You can't go to this status.`,
//       });
//   };
//   if (order) {
//     // console.log(order)
//     // var {
//     //   bkt_order_id,
//     //   date,
//     //   type,
//     //   order_discount,
//     //   discount_detail,
//     //   shipping_method,
//     //   courier,
//     //   tracking_id,
//     //   shipping_date,
//     //   shipping_amount,
//     //   shipping_name,
//     //   shipping_cell,
//     //   shipping_address,
//     //   shipping_city,
//     //   shipping_state,
//     //   shipping_country,
//     //   shipping_postcode,
//     //   customer_note,
//     //   order_note,
//     //   payment_method_id,
//     //   tr_id,
//     //   tr_date,
//     //   tr_amount,
//     //   tr_status,
//     //   query_product,
//     //   query_status,
//     //   query_detail,
//     //   customer,
//     //   order_details,
//     //   source_id,
//     //   status,
//     // } = order;
//     // var paymentMethod = paymentMethodOptions.find(
//     //   (option) => option.value === payment_method_id
//     // );
//     // var selectedOrderType = orderTypeOptions.find(
//     //   (option) => option.value === type
//     // );
//     // var APO_order = "read";
//     // var isMakingAQuery = selectedOrderType.label === "Query" ? true : false;
//     // var shippingSameAsBilling = false;
//     // var orderItems = order_details.map((item) => ({
//     //   bkt_order_id: item.bkt_order_id,
//     //   date: new Date(item.date),
//     //   name: item.name,
//     //   rate: item.rate,
//     //   qty: item.qty,
//     //   subtotal: item.subtotal,
//     //   discount: item.discount,
//     //   discount_detail: item.discount_detail,
//     //   price: item.price,
//     //   remarks: item.remarks,
//     //   extra_added: item.extra_added,
//     //   customer_status: item.customer_status,
//     //   order_id: item.order_id,
//     //   product_bkt_id: item.product_bkt_id,
//     //   variation_bkt_id: item.variation_bkt_id,
//     //   status: item.status,
//     // }));
//     // var cStateOptions = getOptions("states", customer?.country);
//     // var dStateOptions = getOptions("states", shipping_country);
//   }

//   const ability = useContext(AbilityContext);
//   // console.log(cStateOptions);
//   // console.log(customer?.country);
//   // console.log(customer?.state);
//   // console.log(cStateOptions?.find((s) => s.value === customer?.state));
//   const dateBody = (rowData) => {
//     let date = formatDate(new Date(rowData.date));
//     return (
//       <>
//         <span className="p-column-title">Order Date</span>
//         {date}
//       </>
//     );
//   };
//   const productNameBody = (rowData) => {
//     let _permalink = rowData.product ? rowData.product.permalink : undefined;
//     return (
//       <CustomButton
//         label={capitalize(rowData.name)}
//         title={_permalink ? "view product on store" : undefined}
//         color="primary-light"
//         variant="text-button"
//         tooltip={
//           _permalink
//             ? undefined
//             : "Looks like product has been deleted from store."
//         }
//         onClick={
//           _permalink
//             ? () => {
//                 let newTab = window.open(_permalink, "_blank");
//                 newTab.focus();
//               }
//             : null
//         }
//       />
//     );
//   };
//   const statusBody = (rowData) => {
//     let _status = allOrderStatuses.find((s) => s.slug === rowData.status);
//     let options = isSellerLoggedIn
//       ? itemStatusOptionsForSeller
//       : itemStatusOptions;
//     options = options.map((option) => ({
//       ...option,
//       disabled:
//         (option.entity.changeable_at_item && !option.entity.usable_at_item) ||
//         (isSellerLoggedIn && !option.entity.seller_usable),
//     }));
//     return (isSellerLoggedIn &&
//       !_status?.seller_usable &&
//       !_status?.seller_changeable) ||
//       // (order_status && !_status) ||
//       ability.cannot("updateStatus", "orderItems") ||
//       !_status?.changeable_at_item ? (
//       // _status?.entity.is_final
//       _status?.title
//     ) : (
//       <CustomSelect
//         options={options}
//         optionLabel="label"
//         value={rowData.status}
//         handleChange={(e) => {
//           handleChangeStatus(rowData, e.value, rowData.status);
//         }}
//         filter
//         resetFilterOnHide
//       />
//     );
//   };

//   //column filters
//   const orderDateFilter = (
//     <CustomDatePicker
//       value={selectedOrderDate}
//       // handleChange={onOrderDateChange}
//       placeholder="Select order date"
//       isClearable
//     />
//   );

//   const statusFilter = (
//     <Dropdown
//       value={selectedStatus}
//       options={allItemStatuses.map((status) => ({
//         label: status.title,
//         value: status.slug,
//       }))}
//       onChange={(e) => {
//         dt.current.filter(e.value, "status", "equals");
//         changeValue("selectedStatus", e.value);
//       }}
//       placeholder="Select a status"
//       className="p-column-filter"
//       showClear
//     />
//   );
//   let columns = [
//     {
//       field: "date",
//       header: "Order Date",
//       body: dateBody,
//       sortable: true,
//       filter: true,
//       filterElement: orderDateFilter,
//       filterFunction: "filterDate",
//       selectedByDefault: true,
//       headerStyle: { width: "120px" },
//     },
//     {
//       field: "name",
//       header: "Product",
//       body: productNameBody,
//       sortable: true,
//       filter: true,
//       filterPlaceholder: "Search by product",
//       selectedByDefault: true,
//       filterMatchMode: "contains",
//       headerStyle: { width: "400px" },
//     },
//     {
//       field: "qty",
//       header: "Qty.",
//       sortable: true,
//       selectedByDefault: true,
//       headerStyle: { width: "50px" },
//     },
//     {
//       field: "price",
//       header: "Price",
//       sortable: true,
//       filter: true,
//       filterPlaceholder: "Search by price",
//       filterMatchMode: "equals",
//       selectedByDefault: true,
//       headerStyle: { width: "100px" },
//     },
//     {
//       field: "totalPrice",
//       header: "Total Price",
//       sortable: true,
//       filter: true,
//       // filterPlaceholder: "Search by price",
//       // filterMatchMode: "equals",
//       selectedByDefault: true,
//       headerStyle: { width: "100px" },
//     },
//     {
//       field: "status",
//       header: "Item Status",
//       body: statusBody,
//       sortable: true,
//       filter: true,
//       selectedByDefault: true,
//       filterElement: statusFilter,
//     },
//     // {
//     //   field: "seller_id",
//     //   header: "Seller",
//     //   body: sellerBody,
//     //   // sortable: true,
//     //   // filter: true,
//     //   selectedByDefault: true,
//     //   filterElement: statusFilter,
//     // },
//   ];
//   const idBody = (rowData) => {
//     return (
//       <>
//         <span className="p-column-title">Order Id</span>
//         <CustomButton
//           label={rowData.bkt_order_id}
//           variant="text-button"
//           color="primary-light"
//           onClick={() => {
//             // handleView(rowData.order_id);
//           }}
//           title={"view order"}
//         />
//       </>
//     );
//   };
//   if (order)
//     return (
//       <Can I={"read"} a="orders">
//         <Dialog
//           position="right"
//           header={
//             <h2 className="m-0 p-0 color-tertiary font-weight-normal">{`View order`}</h2>
//           }
//           modal
//           onHide={() => {
//             setModalShow(false);
//             setTimeout(() => {
//               props.hideModal();
//             }, 200);
//           }}
//           className="mx-4 ml-sm-5 m-lg-0 bg-Primary h-75"
//           style={{ width: "75vw" }}
//           breakpoints={{ "960px": "100vw" }}
//           visible={modalShow}
//           contentClassName="h-75"
//           footer={
//             <div className="p-grid m-0 p-justify-end">
//               <div className="col-12 col-sm-6 col-md-4 col-lg-3 pr-4">
//                 <CustomButton
//                   label={"Close"}
//                   onClick={() => {
//                     setModalShow(false);
//                     setTimeout(() => {
//                       props.hideModal();
//                     }, 200);
//                   }}
//                   bg="Secondary"
//                   color="tertiary"
//                   className="w-100 my-3"
//                 />
//               </div>
//             </div>
//           }
//         >
//           <Can I="read" a={"orderItems"}>
//             <CustomListing
//               allRecords={allItems}
//               // heading={heading}
//               heading="Order Items"
//               rows={rows}
//               firstColumn={
//                 <Column
//                   field="bkt_order_id"
//                   header="Order Id"
//                   body={idBody}
//                   sortable
//                   filter
//                   filterPlaceholder="Search by order-id"
//                   filterMatchMode="contains"
//                   headerStyle={{ width: "90px" }}
//                 />
//               }
//               columns={columns}
//               permissionSubject={"orderItems"}
//               isLoading={isLoading}
//               handleRefresh={() => changeValue("refreshData", !refreshData)}
//               // extraButton={(customButton, rowData) => {
//               //     let _seller = sellerOptions && sellerOptions.length && sellerOptions.filter(option => option.value === rowData.seller_id)
//               //     return (
//               //         customButton('fab fa-whatsapp', () => { sendDetailsToSeller(rowData) }, !_seller, 'send details to seller')
//               //     )
//               // }}
//               handleView={true}
//               customViewButton={(customButton, rowData) => {
//                 let _order = rowData.order;
//                 return _order && _order.href && !isSellerLoggedIn ? (
//                   <a
//                     href={_order.href}
//                     target="_blank"
//                     title="edit order on store"
//                   >
//                     {customButton("fas fa-share color-primary")}
//                   </a>
//                 ) : null;
//               }}
//               readPermissionSubject={() => "orders"}
//               // handleEdit={handleEdit}
//               editDataKey={"order_id"}
//               editBtnTitle={"edit order"}
//               updatePermissionSubject={() => "orders"}
//               handleDelete={true}
//               customDeleteButton={(customButton, rowData) => {
//                 return rowData.product_bkt_id
//                   ? customButton(
//                       "pi pi-eye color-primary-light",
//                       () => {
//                         handleViewProduct(rowData.product_bkt_id);
//                       },
//                       false,
//                       "view product locally"
//                     )
//                   : null;
//               }}
//               rowClassName={(rowData) => {
//                 let itemStatus = allItemStatuses.find(
//                   (s) => s.slug === rowData.status
//                 );
//                 if (itemStatus) {
//                   let lastUpdatedAt = new Date(rowData.updatedAt);
//                   lastUpdatedAt.setHours(
//                     lastUpdatedAt.getHours() + itemStatus?.threshold_hours
//                   );
//                   return {
//                     "bg-warn": new Date() > lastUpdatedAt,
//                     [`bg-${rowData.status}`]: true,
//                   };
//                 }
//                 return {
//                   [`bg-${rowData.status}`]: true,
//                 };
//               }}
//               // customPaginatorLeft={
//               //     allItems.length ?
//               //         <div className="p-d-inline-flex p-flex-column p-flex-sm-row p-flex-wrap p-jc-between p-ai-center">
//               //             <div className='bg-warn px-2 mx-1 color-primary'>Status outdated</div>
//               //         </div>
//               //         : null
//               // }
//               selectedRecords={selectedRecords}
//               selectionDataKey={"id"}
//               selectedBulkOption={selectedBulkOption}
//               selectedBulkValueOption={selectedBulkValueOption}
//               // bulkOptions={bulkOptions}
//               // bulkValueOptions={bulkValueOptions()}
//               // handleBulkAction={handleBulkAction}
//               changeValue={changeValue}
//               handleExport={() => {
//                 dt.current.exportCSV();
//               }}
//               sortField="on_follow_up"
//               tableRef={dt}
//               toastRef={toast}
//               timeStamps={true}
//             />
//           </Can>
//           <ViewProductModal
//             modalShow={isReadingItem}
//             productBktId={selectedItem}
//             hideModal={() => {
//               _isMounted &&
//                 setState((prevState) => ({
//                   ...prevState,
//                   isReadingItem: false,
//                   selectedItem: null,
//                 }));
//             }}
//           />
//           {/* <ViewOrderModal
//             allOrderStatuses={allOrderStatuses}
//             modalShow={isReadingOrder}
//             orderId={selectedOrder}
//             hideModal={() => {
//               _isMounted &&
//                 setState((prevState) => ({
//                   ...prevState,
//                   isReadingOrder: false,
//                   selectedOrder: null,
//                 }));
//             }}
//           /> */}
//           {/* <EditOrderModal
//                 allOrderStatuses={allOrderStatuses}
//                 modalShow={isUpdatingOrder}
//                 orderId={selectedOrder}
//                 hideModal={() => {
//                     _isMounted && setState(prevState => ({
//                         ...prevState,
//                         isUpdatingOrder: false,
//                         selectedOrder: null
//                     }))
//                 }}
//                 refreshData={() => { }}
//             /> */}
//           <StatusAfterEffectsModal
//             modalShow={isStatusAfterEffects}
//             status={updatedStatus}
//             handleResponse={({ isConfirmed, input1, input2, input3 }) => {
//               if (isConfirmed) {
//                 if (updatedStatus.slug === "item_not_available") {
//                   toast.current.show({
//                     severity: "info",
//                     summary: "Ok",
//                     detail: "please send alternate details to seller support.",
//                     life: 3000,
//                   });
//                 } else {
//                   if (updatedStatus.input_needed) {
//                     let updatedItem;
//                     switch (updatedStatus.input_needed[0]) {
//                       case "Tr. date": //refund_transaction
//                         updatedItem = {
//                           status: updatedStatus.slug,
//                           refund_tr_date: input1,
//                           refund_tr_id: input2,
//                           refund_tr_amount: input3,
//                         };
//                         break;
//                       case "schedule list #": //cancel reason
//                         updatedItem = {
//                           status: updatedStatus.slug,
//                           pickup_list_id: input1,
//                         };
//                         break;
//                       case "Hold till": //remarks hold period and reason
//                         updatedItem = {
//                           status: updatedStatus.slug,
//                           remarks: `Hold till: ${new Date(
//                             input1
//                           ).toDateString()}, Reason: ${input2}`,
//                         };
//                         break;
//                       case "Cancel reason": //cancel reason
//                         updatedItem = {
//                           status: updatedStatus.slug,
//                           cancel_reason: input1,
//                         };
//                         break;
//                       default:
//                         break;
//                     }
//                     handleUpdateItem(selectedItem, updatedItem);
//                   } else {
//                     handleUpdateField(
//                       selectedItem,
//                       "status",
//                       updatedStatus.slug
//                     );
//                   }
//                 }
//               } else {
//                 updatedStatus.slug === "item_not_available" &&
//                   handleUpdateField(selectedItem, "status", updatedStatus.slug);
//               }
//               // toggleStatusAfterEffectsModal({
//               //   modalShow: false,
//               //   _status: null,
//               //   item: null,
//               // });
//             }}
//           />
//           {/* <div className="p-d-flex p-flex-column pt-2 h-100">
//             <OrderForm
//               //universal
//               position="right"
//               _isMounted={_isMounted}
//               APO_order={APO_order}
//               currentStep={currentStep}
//               isMakingAQuery={isMakingAQuery}
//               setCurrentStep={setCurrentStep}
//               //general
//               bkt_order_id={bkt_order_id}
//               customerNote={customer_note}
//               date={new Date(date)}
//               discountDetail={discount_detail}
//               orderDiscount={order_discount}
//               orderNote={order_note}
//               orderSourceOptions={orderSourceOptions}
//               orderStatusOptions={orderStatusOptions}
//               orderTypeOptions={orderTypeOptions}
//               selectedOrderSource={orderSourceOptions.find(
//                 (option) => option.value === source_id
//               )}
//               selectedOrderStatus={orderStatusOptions.find(
//                 (option) => option.value === status
//               )}
//               selectedOrderType={selectedOrderType}
//               totalField={totalField}
//               //customer
//               cAddress={customer?.address}
//               cCell={customer?.cell}
//               cCity={{
//                 label: customer?.city.toUpperCase(),
//                 value: customer?.city.toUpperCase(),
//               }}
//               cCountry={countryOptions.find(
//                 (c) => c.value === customer?.country
//               )}
//               cEmail={customer?.email}
//               cName={customer?.name}
//               cPostcode={customer?.postcode}
//               cStateOptions={cStateOptions}
//               cState={cStateOptions?.find((s) => s.value === customer?.state)}
//               //query
//               queryDetail={query_detail}
//               queryProduct={query_product}
//               queryStatus={query_status}
//               //products
//               itemStatusOptions={orderStatusOptions}
//               orderItems={orderItems}
//               //shipping
//               courierOptions={courierOptions}
//               dAddress={shipping_address}
//               dAmount={shipping_amount}
//               dCell={shipping_cell}
//               dCity={{
//                 label: shipping_city.toUpperCase(),
//                 value: shipping_city.toUpperCase(),
//               }}
//               dName={shipping_name}
//               dStateOptions={dStateOptions}
//               dState={dStateOptions?.find((s) => s.value === shipping_state)}
//               dCountry={countryOptions.find(
//                 (c) => c.value === shipping_country
//               )}
//               dPostcode={shipping_postcode}
//               selectedCourier={courierOptions.find(
//                 (option) => option.value === courier
//               )}
//               shippingDate={shipping_date ? new Date(shipping_date) : ""}
//               shippingMethod={shipping_method}
//               shippingSameAsBilling={shippingSameAsBilling}
//               trackingId={tracking_id}
//               //payments
//               paymentMethodOptions={paymentMethodOptions}
//               selectedPaymentMethod={paymentMethod}
//               trAmount={tr_amount}
//               trDate={tr_date ? new Date(tr_date) : ""}
//               trId={tr_id}
//               trStatus={tr_status}
//               //form-refs
//               customerDataForm={customerDataForm}
//               generalDataForm={generalDataForm}
//               paymentsDataForm={paymentsDataForm}
//               productsDataForm={productsDataForm}
//               queryDataForm={queryDataForm}
//               shippingDataForm={shippingDataForm}
//             />
//           </div> */}
//         </Dialog>
//         <LoaderModal modalShow={isLoading} />
//       </Can>
//     );
//   else return null;
// };
