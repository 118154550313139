import React, { useState, useEffect, useRef, useContext } from "react";
import { Can, AbilityContext } from "../../../configs/Ability-context";
import { CustomListing } from "../../misc/components/CustomListing";
import { Column } from "primereact/column";
import {
  getSettings,
  updateSetting,
} from "../../../services/misc/setting-service";
import { SettingModal } from "./sections/SettingModal";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
import PasswordConfirmationModal from "../../misc/components/PasswordConfirmationModal";
import CustomSelect from "../../misc/components/CustomSelect";
import { InputSwitch } from "primereact/inputswitch";
const AllSettings = () => {
  const dt = useRef(null);
  const [
    {
      allSettings,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    allSettings: [],
    isLoading: false,
    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });

  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState({ id: null, value: "" });
  const ability = useContext(AbilityContext);
  const settingForm = useRef(null);
  let SettingTypes = allSettings.length ? ["app_setting", "admin_setting"] : [];
  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getSettings();
        setState((prevState) => ({
          ...prevState,
          allSettings: data,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
  }, [refreshData]);

  const handleUpdate = async (id, newValue) => {
    try {
      const response = await updateSetting(id, { value: newValue });
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id ? { ...setting, value: newValue } : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateSettingType = async (id, setting_type) => {
    try {
      const response = await updateSetting(id, {
        // value: newValue,
        setting_type: setting_type,
      });
      console.log("setting_type", setting_type);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id
              ? { ...setting, setting_type: setting_type }
              : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdatePasswordProtection = async (id, passwordProtection) => {
    try {
      const response = await updateSetting(id, {
        // value: newValue,
        is_password_protected: passwordProtection,
      });
      console.log("passwordProtection", passwordProtection);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id
              ? { ...setting, is_password_protected: passwordProtection }
              : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData) => {
    let form = settingForm.current;
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    }

    if (rowData.is_password_protected) {
      setPendingUpdate({ id: rowData.id, value: editorText });
      setIsConfirmingPassword(true);
    } else {
      handleUpdate(rowData.id, editorText);
      setState((prevState) => ({
        ...prevState,
        activeEditor: { field: "", id: "" },
        editorText: "",
      }));
    }
  };

  const handlePasswordConfirm = (verified) => {
    if (verified) {
      handleUpdate(pendingUpdate.id, pendingUpdate.value);
      setState((prevState) => ({
        ...prevState,
        activeEditor: { field: "", id: "" },
        editorText: "",
      }));
    }
    setIsConfirmingPassword(false);
    setPendingUpdate({ id: null, value: "" });
  };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const getEditor = (rowData, field) => (
    <InputText
      autoFocus
      value={editorText}
      onChange={handleInputChange}
      onBlur={() => handleInlineUpdate(rowData)}
    />
  );

  const inlineEditor = (field, rowData) => (
    <Inplace
      closable
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="right">
          <span>{rowData[field]}</span>
          <span>{" Click to edit"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );
  const SettingTypeBody = (rowData) => {
    // let _seller = allSellers.find((s) => s.id === rowData.seller_id);
    let settingType = rowData?.setting_type?.toUpperCase();
    console.log("Setting Type:", settingType);
    return (
      ability.can("manage", "all") && (
        <div className="custom-select-container" style={{}}>
          <CustomSelect
            options={SettingTypes}
            value={rowData?.setting_type}
            style={{
              textTransform: "uppercase",
            }}
            placeholder="Select seller"
            handleChange={(e) => {
              handleUpdateSettingType(rowData.id, e.value);
            }}
          />
        </div>
      )
    );
  };
  const PasswordProtectionBody = (rowData) => {
    // const [checked, setChecked] = useState(false);

    return (
      ability.can("manage", "all") && (
        <div
          className="card flex justify-content-center"
          style={{ marginLeft: "170px" }}
        >
          <InputSwitch
            checked={rowData.is_password_protected}
            onChange={(e) =>
              handleUpdatePasswordProtection(rowData.id, e.value)
            }
          />
        </div>
      )
    );
  };
  const columns = [
    {
      field: "value",
      header: "Value",
      filter: true,
      body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },

    {
      field: "setting_type",
      header: "Setting Type",
      body: SettingTypeBody,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Setting Type",
      filterMatchMode: "contains",
      selectedByDefault: true,
      // filterElement: statusFilter,
    },
    {
      field: "is_password_protected",
      header: "Password Protection",
      body: PasswordProtectionBody,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Setting Type",
      filterMatchMode: "contains",
      selectedByDefault: true,
      // filterElement: statusFilter,
    },
    // {
    //   field: "setting_type",
    //   header: "Setting Type",
    //   sortable: true,
    //   filter: true,
    //   filterPlaceholder: "Search by Setting Type",
    //   filterMatchMode: "contains",
    //   selectedByDefault: true,
    //   headerStyle: { width: "140px" },
    // },
  ];

  return (
    <Can I="read" a="appSettings">
      <form ref={settingForm} className="p-m-0 p-pt-4" noValidate>
        <CustomListing
          allRecords={allSettings}
          heading={"App settings"}
          firstColumn={
            <Column
              field="key"
              header="Name"
              sortable={true}
              filter={true}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          permissionSubject={"appSettings"}
          isLoading={isLoading}
          handleRefresh={() =>
            setState((prevState) => ({
              ...prevState,
              refreshData: !prevState.refreshData,
            }))
          }
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
      <SettingModal
        modalShow={isUpdatingSetting}
        settingId={selectedSetting}
        hideModal={() =>
          setState((prevState) => ({
            ...prevState,
            isUpdatingSetting: false,
            selectedSetting: null,
          }))
        }
      />
      <PasswordConfirmationModal
        modalShow={isConfirmingPassword}
        handleVerifyStatus={handlePasswordConfirm}
        hideModal={() => setIsConfirmingPassword(false)}
      />
    </Can>
  );
};

export default AllSettings;

// import React, { useState, useEffect, useRef, useContext } from "react";
// import { Can, AbilityContext } from "../../../configs/Ability-context";
// import { CustomListing } from "../../misc/components/CustomListing";
// import { Column } from "primereact/column";
// import {
//   getSettings,
//   updateSetting,
// } from "../../../services/misc/setting-service";
// import { SettingModal } from "./sections/SettingModal";
// import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
// import { InputText } from "primereact/inputtext";
// import { MDBPopper } from "mdbreact";

// let _isMounted;
// const AllSettings = () => {
//   let dt = useRef(null);
//   const [
//     {
//       allSettings,
//       isLoading,
//       refreshData,
//       isUpdatingSetting,
//       selectedSetting,
//       activeEditor,
//       editorText,
//     },
//     setState,
//   ] = useState({
//     allSettings: [],
//     isLoading: false,
//     refreshData: false,
//     isUpdatingSetting: false,
//     selectedSetting: null,
//     activeEditor: { field: "", id: "" },
//     editorText: "",
//   });

//   const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
//   const [requiresPassword, setRequiresPassword] = useState(false);
//   const ability = useContext(AbilityContext);
//   let settingForm = useRef(null);
//   useEffect(() => {
//     _isMounted = true;
//     const fetchData = async () => {
//       changeValue("isLoading", true);
//       try {
//         const { success, data } = await getSettings();
//         changeValue("allSettings", data);
//       } catch (error) {
//         console.log(error);
//       }
//       changeValue("isLoading", false);
//     };
//     fetchData();
//     return () => {
//       _isMounted = false;
//     };
//   }, [refreshData]);

//   const changeValue = (name, value) => {
//     _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleEdit = (id) => (e) => {
//     _isMounted &&
//       setState((prevState) => ({
//         ...prevState,
//         selectedSetting: id,
//         isUpdatingSetting: true,
//       }));
//   };
//   const handleUpdate = async (rowData, field) => {
//     let updatedSetting = {
//       value: field,
//     };
//     try {
//       const { success, message } = await updateSetting(
//         // rowData.settingId,
//         selectedSetting,
//         updatedSetting
//       );
//       //   setValidationMessage(message);
//       //   success &&
//       //     setTimeout(() => {
//       //       props.hideModal();
//       //     }, 1250);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleSubmit = (field, rowData) => {
//     let form = settingForm.current;
//     if (!form.checkValidity()) {
//       form.classList.add("was-validated");
//       return;
//     }
//     if (requiresPassword) {
//       setIsConfirmingPassword(true);
//     } else {
//       handleUpdate(); //We Will Directly update if password is not required
//     }
//     // setIsConfirmingPassword(true);
//   };
//   //   const handleSubmit = (field, rowData) => (e) => {
//   //     // let updatingStock = field === "stock_quantity";
//   //     // let updatingCost = field === "cost_price";
//   //     // let updatingRetail = field === "regular_price" || field === "sale_price";
//   //     // let updatingSeller = field === "seller_id";
//   //     // let updatingShopManager = field === "shop_manager_id";
//   //     // let helperProps =
//   //     //   updatingCost || updatingRetail
//   //     //     ? {
//   //     //         seller: rowData.seller_id,
//   //     //         isCostProvided: rowData.is_cost_provided,
//   //     //         regularPrice: rowData.regular_price,
//   //     //         salePrice: rowData.sale_price,
//   //     //       }
//   //     //     : {};
//   //     // let value = updatingSeller || updatingShopManager ? e.value : editorText;
//   //     // if (updatingSeller || updatingShopManager) {
//   //     //   handleBatchUpdate(
//   //     //     [{ id: rowData.bkt_id, type: rowData.type }],
//   //     //     field,
//   //     //     value
//   //     //   );
//   //     // } else if (
//   //     //   (field === "sale_price" && value > rowData.regular_price) ||
//   //     //   (field === "regular_price" && value < rowData.sale_price)
//   //     // ) {
//   //     //   //   toast.current.show({ severity: "warn", summary: "Invalid price." });
//   //     // } else
//   //     // handleInlineUpdate(field, rowData);
//   //     handleUpdate(field, rowData);
//   //   };
//   const handleInlineUpdate = async (options) => {
//     let updatedSetting = { value: options.value };
//     try {
//       console.log(updatedSetting);
//       const { success, message } = await (options.rowData.id, updatedSetting);
//       if (success) {
//         setState((prevState) => ({
//           ...prevState,
//           allSettings: prevState.allSettings.map((setting) =>
//             setting.id === options.rowData.id
//               ? { ...setting, value: options.value }
//               : setting
//           ),
//         }));
//       }
//       console.log(message);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleInput = (e) => {
//     let { name, value } = e.target;
//     changeValue(name, value);
//   };
//   const handleChange = () => {};
//   const setEditor = (field, id) => (e) => {
//     setState((prevState) => ({ ...prevState, activeEditor: { field, id } }));
//   };

//   const handleInputFocus = (value) => (e) => {
//     console.log(value);
//     changeValue("editorText", value);
//   };

//   const getEditor = (inputType, rowData, field) => {
//     return (
//       <InputText
//         // type={inputType}
//         autoFocus
//         name="editorText"
//         value={editorText}
//         onInput={handleInput}
//         onFocus={handleInputFocus(rowData[field])}
//       />
//     );
//   };
//   const inlineEditor = (field, rowData, inputType) => {
//     const editor = (
//       <Inplace
//         closable
//         active={activeEditor.field === field && activeEditor.id === rowData.id}
//         onOpen={setEditor(field, rowData.id)}
//         onToggle={handleChange}
//         onClose={handleSubmit(field, rowData)}
//         // onClose={(e) => {
//         //     // (field === 'sale_price' || field === 'regular_price') && !rowData.is_cost_provided && updateCostPrice(rowData, field)
//         //     handleUpdate(rowData.bkt_id)(field, editorText, rowData);
//         // }}
//       >
//         <InplaceDisplay>
//           <MDBPopper domElement tag="span" placement="right">
//             <span>{rowData[field]}</span>
//             <span>{"Click to edit"}</span>
//           </MDBPopper>
//         </InplaceDisplay>
//         <InplaceContent>{getEditor(inputType, rowData, field)}</InplaceContent>
//       </Inplace>
//     );
//     const readOnly =
//       ability.can("update", "products") && rowData.type !== "simple" ? (
//         <MDBPopper domElement tag="span" placement="right">
//           <span>{rowData[field]}</span>
//           <span className="small">
//             {"Inline edit is not available for variable products"}
//           </span>
//         </MDBPopper>
//       ) : (
//         <span>{rowData[field]}</span>
//       );
//     return editor;
//   };
//   let columns = [
//     {
//       field: "value",
//       header: "Value",
//       filter: true,

//       body: (rowData) => {
//         return inlineEditor("value", rowData, "string");
//       },
//       selectedByDefault: true,
//       filterPlaceholder: "Search by value",
//       filterMatchMode: "contains",
//       headerStyle: { width: "290px" },
//     },
//   ];
//   return (
//     <Can I="read" a="appSettings">
//       <form ref={settingForm} className="p-m-0 p-pt-4" noValidate>
//         <CustomListing
//           allRecords={allSettings}
//           heading={"App settings"}
//           firstColumn={
//             <Column
//               field="key"
//               header="Name"
//               sortable={true}
//               filter={true}
//               filterPlaceholder="Search by name"
//               filterMatchMode="contains"
//               headerStyle={{ width: "170px" }}
//             />
//           }
//           columns={columns}
//           permissionSubject={"appSettings"}
//           isLoading={isLoading}
//           handleEdit={handleEdit}
//           handleRefresh={() => changeValue("refreshData", !refreshData)}
//           editDataKey={"id"}
//           tableRef={dt}
//           timeStamps={true}
//         />
//       </form>
//       <SettingModal
//         modalShow={isUpdatingSetting}
//         settingId={selectedSetting}
//         hideModal={() => {
//           _isMounted &&
//             setState((prevState) => ({
//               ...prevState,
//               isUpdatingSetting: false,
//               selectedSetting: null,
//             }));
//         }}
//       />
//     </Can>
//   );
// };

// export default AllSettings;

// ----------------------------------------------------------------------------
// import React, { useState, useEffect, useRef } from "react";
// import { Can } from "../../../configs/Ability-context";
// import { CustomListing } from "../../misc/components/CustomListing";
// import { Column } from "primereact/column";
// import { getSettings } from "../../../services/misc/setting-service";
// import { SettingModal } from "./sections/SettingModal";

// let _isMounted;
// const AllSettings = () => {
//   let dt = useRef(null);
//   const [
//     { allSettings, isLoading, refreshData, isUpdatingSetting, selectedSetting },
//     setState,
//   ] = useState({
//     allSettings: [],
//     isLoading: false,
//     refreshData: false,
//     isUpdatingSetting: false,
//     selectedSetting: null,
//   });

//   useEffect(() => {
//     _isMounted = true;
//     const fetchData = async () => {
//       changeValue("isLoading", true);
//       try {
//         const { success, data } = await getSettings();
//         changeValue("allSettings", data);
//       } catch (error) {
//         console.log(error);
//       }
//       changeValue("isLoading", false);
//     };
//     fetchData();
//     return () => {
//       _isMounted = false;
//     };
//   }, [refreshData]);

//   const changeValue = (name, value) => {
//     _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleEdit = (id) => (e) => {
//     _isMounted &&
//       setState((prevState) => ({
//         ...prevState,
//         selectedSetting: id,
//         isUpdatingSetting: true,
//       }));
//   };

//   let columns = [
//     {
//       field: "value",
//       header: "Value",
//       filter: true,
//       selectedByDefault: true,
//       filterPlaceholder: "Search by value",
//       filterMatchMode: "contains",
//       headerStyle: { width: "290px" },
//     },
//   ];
//   return (
//     <Can I="read" a="appSettings">
//       <CustomListing
//         allRecords={allSettings}
//         heading={"App settings"}
//         firstColumn={
//           <Column
//             field="key"
//             header="Name"
//             sortable={true}
//             filter={true}
//             filterPlaceholder="Search by name"
//             filterMatchMode="contains"
//             headerStyle={{ width: "170px" }}
//           />
//         }
//         columns={columns}
//         permissionSubject={"appSettings"}
//         isLoading={isLoading}
//         handleEdit={handleEdit}
//         handleRefresh={() => changeValue("refreshData", !refreshData)}
//         editDataKey={"id"}
//         tableRef={dt}
//         timeStamps={true}
//       />
//       <SettingModal
//         modalShow={isUpdatingSetting}
//         settingId={selectedSetting}
//         hideModal={() => {
//           _isMounted &&
//             setState((prevState) => ({
//               ...prevState,
//               isUpdatingSetting: false,
//               selectedSetting: null,
//             }));
//         }}
//       />
//     </Can>
//   );
// };

// export default AllSettings;
