import React, { useState, useEffect, useRef } from 'react';
import { Can } from '../../../../../configs/Ability-context'
import { useHistory, useParams } from 'react-router-dom'
import { Input, CustomButton } from '../../../../misc/components/Inputs'
import { getOrderStatus, createOrderStatus, updateOrderStatus } from '../../../../../services/orders/status-service'
import LoaderModal from '../../../../misc/components/LoaderModal'
import CustomSelect from '../../../../misc/components/CustomSelect';
import { Toast } from 'primereact/toast';
import { ColorPicker } from 'primereact/colorpicker';

const INITIAL_STATE = {
    eStatus: '',
    title: '',
    question: '',
    positiveResponse: '',
    negativeResponse: '',
    isFinal: false,
    isDefault: false,
    isEnabled: false,
    thresholdHours: '',
    sellerEditable: false,
    sellerUsable: false,
    // selectedOwner: '',
    color: ''
};
// const ownerOptions = [
//     { key: 1, label: 'Order', value: 'order' },
//     { key: 2, label: 'Item', value: 'item' },
//     { key: 3, label: 'Order,Item', value: 'both' }
// ]

let _isMounted
const OrderStatus = (props) => {
    const [{
        eStatus, title, question, positiveResponse, negativeResponse, thresholdHours,
        isFinal, isDefault, isEnabled, sellerEditable, sellerUsable, color, validationMessage, isLoading }, setState] = useState({
            ...INITIAL_STATE, validationMessage: '', isLoading: false
        })

    let orderStatusForm = useRef(null)
    let toast = useRef(null)
    let { APO_status } = props
    let { status } = useParams()
    let history = useHistory()

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, status: data } = await getOrderStatus(status)
                if (success) {
                    let {
                        title, question_message, positive_response, negative_response, seller_changeable, seller_usable,
                        is_default, is_enabled, threshold_hours, is_final, color
                    } = data
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        eStatus: data,
                        title: title,
                        question: question_message,
                        positiveResponse: positive_response,
                        negativeResponse: negative_response,
                        isFinal: is_final,
                        isDefault: is_default,
                        isEnabled: is_enabled,
                        sellerEditable: seller_changeable,
                        sellerUsable: seller_usable,
                        // selectedOwner: owner,
                        thresholdHours: threshold_hours,
                        color
                    }));
                }
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        status && fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }));
    }

    const handleCheckboxChange = name => (e) => {
        changeValue(name, e.target.checked)
    }

    // const handleSelectChange = selectedOption => {
    //     changeValue('selectedOwner', selectedOption.value)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = orderStatusForm.current
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        // else if (!selectedOwner || selectedOwner === '') {
        //     changeValue('selectedOwner', null)
        //     changeValue('validationMessage', 'Please select at least one owner.')
        //     return
        // }
        else {
            let orderStatus = {
                title, question_message: question, positive_response: positiveResponse, negative_response: negativeResponse, threshold_hours: thresholdHours,
                is_final: isFinal,
                is_default: isDefault, is_enabled: isEnabled,
                seller_changeable: sellerEditable, seller_usable: sellerUsable,
                // owner: selectedOwner,
                color,
            }
            try {
                const { success, message } = APO_status === 'create' ? await createOrderStatus(orderStatus) : await updateOrderStatus(status, orderStatus)
                toast.current.show(message)
                if (success) {
                    if (APO_status === 'create') {
                        _isMounted && setState(prevState => ({ ...prevState, ...INITIAL_STATE }))
                    }
                    form.classList.remove('was-validated')
                    setTimeout(() => {
                        history.push('/orders/statuses/all')
                    }, 500)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }
    // let defaultStatuses = ['Processing', 'Completed', 'Cancelled', 'Draft', 'On-Hold']

    return (
        <Can I={APO_status} a='orderStatuses'>
            <div className='screen-center h-100vh'>
                <form ref={orderStatusForm} className='m-0 p-0 w-100 w-md-75 w-lg-50 text-center color-tertiary mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='font-weight-bold mb-3 mb-sm-4'>{APO_status === 'create' ? 'New Order Status' : 'Edit Order Status'}</h5>
                    <div className='p-grid m-0 p-0'>
                        <div className='p-col-12 p-sm-6 px-4'>
                            <Input
                                label={'Title'}
                                name={`title`}
                                value={title}
                                handleInput={handleInput}
                                required
                                disabled={!eStatus.createdBy}
                                // disabled={APO_status === 'update' && defaultStatuses.includes(title)}
                                invalidMessage={`Please enter status title`}
                            />
                            {/* <div className='mb-4 p-0'>
                                <CustomSelect
                                    value={selectedOwner}
                                    options={ownerOptions}
                                    handleChange={handleSelectChange}
                                    placeholder={'Select owner'}
                                    className='color-primary-light'
                                />
                            </div> */}
                            <Input
                                label={'Question message'}
                                name={`question`}
                                value={question || ''}
                                handleInput={handleInput}
                            />
                            <Input
                                label={'Positive response'}
                                name={`positiveResponse`}
                                value={positiveResponse || ''}
                                handleInput={handleInput}
                                required={question !== '' && question}
                                invalidMessage={`Please enter positive response`}
                            />
                            <Input
                                label={'Negative response'}
                                name={`negativeResponse`}
                                value={negativeResponse || ''}
                                handleInput={handleInput}
                                required={question !== '' && question}
                                invalidMessage={`Please enter negative response`}
                            />
                            <Input
                                type='number'
                                label={'Threshold (hours)'}
                                name={`thresholdHours`}
                                value={thresholdHours}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter threshold (hours)`}
                            />
                        </div>
                        <div className='p-col-12 p-sm-6 px-4'>
                            <div className="custom-control mb-3 custom-checkbox text-left">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={'isDefault'}
                                    id={`isDefaultCheckbox`}
                                    checked={isDefault}
                                    onChange={handleCheckboxChange('isDefault')}
                                />
                                <label className="custom-control-label" htmlFor={`isDefaultCheckbox`}>Default status ?</label>
                            </div>
                            <div className="custom-control mb-3 custom-checkbox text-left">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={'sellerEditable'}
                                    id={`sellerEditableCheckbox`}
                                    checked={sellerEditable}
                                    onChange={handleCheckboxChange('sellerEditable')}
                                />
                                <label className="custom-control-label" htmlFor={`sellerEditableCheckbox`}>Seller Changeable ?</label>
                            </div>
                            <div className="custom-control mb-3 custom-checkbox text-left">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={'sellerUsable'}
                                    id={`sellerUsableCheckbox`}
                                    checked={sellerUsable}
                                    onChange={handleCheckboxChange('sellerUsable')}
                                />
                                <label className="custom-control-label" htmlFor={`sellerUsableCheckbox`}>Seller Usable ?</label>
                            </div>
                            <div className="custom-control mb-3 custom-checkbox text-left">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={'isFinal'}
                                    id={`isFinalCheckbox`}
                                    checked={isFinal}
                                    onChange={handleCheckboxChange('isFinal')}
                                />
                                <label className="custom-control-label" htmlFor={`isFinalCheckbox`}>Final status ?</label>
                            </div>
                            <div className="custom-control mb-3 custom-checkbox text-left">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={'isEnabled'}
                                    id={`isEnabledCheckbox`}
                                    checked={isEnabled}
                                    onChange={handleCheckboxChange('isEnabled')}
                                />
                                <label className="custom-control-label" htmlFor={`isEnabledCheckbox`}>Enabled ?</label>
                            </div>
                            <div className='mb-4 p-0 text-left'>
                                <h6>Color</h6>
                                <ColorPicker
                                    value={color}
                                    defaultColor={'ffffff'}
                                    format='hex'
                                    className='float-left'
                                    onChange={(e) => changeValue('color', e.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='p-d-flex p-jc-around p-flex-wrap m-0 mt-2 px-2 px-sm-4'>
                        <label className='p-0 validating-label'>{validationMessage}&nbsp;</label>
                        <CustomButton
                            label={APO_status === 'create' ? "Create" : 'Update'}
                            color='tertiary'
                            bg='Secondary'
                            type="submit"
                            className='px-5'
                        />
                    </div>
                </form>
            </div>
            <Toast ref={toast} className='mt-5' />
            <LoaderModal modalShow={isLoading} />
        </Can >
    );
}


export default OrderStatus