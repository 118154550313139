import React from 'react';
import { Input } from '../../../../misc/components/Inputs'


export const QueryDataStep = (props) => {
    let {
        APO_order,
        currentStep,
        handleInput,
        queryDataForm,
        queryProduct,
        queryStatus,
        queryDetail,
    } = props;

    return (
        <form ref={queryDataForm} className={`m-0 p-0 ${currentStep === 3 ? 'd-block' : 'd-none'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6'>
                    <Input
                        label={`Product name`}
                        name={`queryProduct`}
                        value={queryProduct}
                        handleInput={handleInput}
                        containerClass='mb-3'
                        required
                        disabled={APO_order === 'read'}
                        invalidFullWidth
                        invalidMessage={`Please enter product name which you want to query about`}
                    />
                </div>
                <div className='m-0 col-sm-6'>
                    <Input
                        label={`Query status`}
                        name={`queryStatus`}
                        value={queryStatus}
                        handleInput={handleInput}
                        containerClass='mb-3'
                        required
                        disabled={APO_order === 'read'}
                        invalidFullWidth
                        invalidMessage={`Please enter query status. e.g. 'sent' or 'pending'`}
                    />
                </div>
                <div className='m-0 col-sm-6'>
                    <Input
                        label={`Query details`}
                        name={`queryDetail`}
                        value={queryDetail}
                        handleInput={handleInput}
                        containerClass='mb-3'
                        required
                        disabled={APO_order === 'read'}
                        invalidFullWidth
                        invalidMessage={`Please enter query details.`}
                    />
                </div>
            </div>
        </form>
    )
}
