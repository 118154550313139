export const createBrand = async (newBrand) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newBrand }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/brands`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getBrands = async () => {
    try {
        const res = await fetch(`/api/brands`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getBrand = async (id) => {
    try {
        const res = await fetch(`/api/brands/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getBrandsWithData = async () => {
    try {
        const res = await fetch(`/api/brands/data`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getTrash = async () => {
    try {
        const res = await fetch(`/api/brands/trash`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateBrand = async (id, updatedBrand) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedBrand }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/brands/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const moveToTrash = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/brands/trash/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const moveBatchToTrash = async (ids) => {
    try {
        const options = {
            method: 'DELETE',
            body: JSON.stringify({ ids }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/brands/trash/batch`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const restoreBrand = async (id) => {
    try {
        const res = await fetch(`/api/brands/restore/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteBrand = async (bktId) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/brands/delete/${bktId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const syncBrands = async () => {
    try {
        const res = await fetch(`/api/brands/sync`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const publishBrand = async (id) => {
    try {
        const res = await fetch(`/api/brands/publish/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

// export const publishBrands = async (ids) => {
//     try {
//         const options = {
//             method: 'PUT',
//             body: JSON.stringify({ ids }),
//             headers: { 'Content-Type': 'application/json' }
//         }
//         const res = await fetch(`/api/brands/publish/batch`, options)
//         const json = await res.json()
//         console.log(json)
//         return json
//     }
//     catch (error) {
//         console.log(error)
//     }
// }

