import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NewProduct } from './pages/NewProduct';
import { EditProduct } from './pages/EditProduct';
import AllProducts from './pages/AllProducts';
import ProductCategories from './categories/ProductCategories';
import Brands from './brands/Brands';
import Tags from './tags/Tags';
import Attributes from './attributes/Attributes';
import NotFound from '../misc/pages/NotFound'
import { PrivateRoute } from '../misc/PrivateRoute'
import { Can } from '../../configs/Ability-context'


const Products = () => {

    return (
        <Switch>
            <PrivateRoute path='/products/new'>
                <Can I='create' a='products'>
                    <NewProduct />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/products/all'>
                <Can I='read' a='products'>
                    <AllProducts />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/products/edit/:product'>
                <Can I='update' a='products'>
                    <EditProduct />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/products/categories'>
                <ProductCategories />
            </PrivateRoute>
            <PrivateRoute path='/products/brands'>
                <Brands />
            </PrivateRoute>
            <PrivateRoute path='/products/tags'>
                <Tags />
            </PrivateRoute>
            <PrivateRoute path='/products/attributes'>
                <Attributes />
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default Products