import { useEffect, useState, useRef, useContext } from 'react'
import { Can, AbilityContext } from '../../../../configs/Ability-context'
import { UserContext } from '../../../../contexts/UserContext'
import { Input, SubmitButton } from '../../../misc/components/Inputs'
import { RadioButton } from 'primereact/radiobutton';
import LoaderModal from '../../../misc/components/LoaderModal'
import getOptions from '../../../misc/components/Options'
import { setElementDisability, checkUniqueness } from '../../../misc/components/Helpers'
import ImageGallery from '../../../misc/components/ImageGallery'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import CustomEditor from '../../../misc/components/CustomEditor'
import CustomSelect from '../../../misc/components/CustomSelect'
import { getBrand, createBrand, updateBrand } from '../../../../services/products/brand-service';
import { getCustomUsers } from '../../../../services/users/user-service';

const INITIAL_STATE = {
    name: '',
    description: '',
    isVerified: false,
    remarks: '',
    type: 'owner',
    selectedPerson: null,
    logo: '',
    validationMessage: ''
}
let _isMounted
const BrandModal = (props) => {

    let brandForm = useRef(null)
    const ability = useContext(AbilityContext)
    const { isSellerLoggedIn } = useContext(UserContext);
    let { APO_brand, selectedBrand, hideModal, refreshData } = props

    const [{
        allSellers, name, description, isVerified, remarks, type, selectedPerson, logo,
        showImageGallery, isLoading, validationMessage
    }, setState] = useState({
        allSellers: [], ...INITIAL_STATE,
        showImageGallery: false, isLoading: false,
    })
    const [modalShow, setModalShow] = useState(false)

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getBrand(selectedBrand)
                let { name, description, is_verified, remarks, type, person, logo, image_url } = data
                _isMounted && setState(prevState => ({
                    ...prevState,
                    name,
                    description,
                    isVerified: is_verified,
                    remarks,
                    type,
                    selectedPerson: person ? { label: person.name, value: person.id } : null,
                    logo: logo ? logo : image_url,
                }))
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        const fetchMeta = async () => {
            try {
                const { data } = await getCustomUsers('seller')
                changeValue('allSellers', data)
            }
            catch (err) {
                console.log(err)
            }
        }
        if (APO_brand === 'update') {
            if (props.modalShow) {
                fetchData()
                !isSellerLoggedIn && fetchMeta()
            }
            else {
                _isMounted = false
            }
            setModalShow(m => props.modalShow)
        }
        if (APO_brand === 'create') {
            if (props.modalShow) {
                !isSellerLoggedIn && fetchMeta()
            }
            else {
                _isMounted = false
            }
            setModalShow(props.modalShow)
        }
        return () => {
            _isMounted = false
        }
    }, [props.modalShow])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const makeStateEmpty = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            ...INITIAL_STATE
        }))
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleSelectChange = name => (selectedOption) => {
        changeValue(name, selectedOption.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!brandForm.current.checkValidity()) {
            brandForm.current.classList.add('was-validated')
            return
        }
        // else if (!logo) {
        //     changeValue('validationMessage', 'Please choose a brand logo')
        //     return
        // }
        // else if (!isSellerLoggedIn && !selectedPerson) {
        //     changeValue('validationMessage', `Please select brand ${type}`)
        //     return
        // }
        else {
            // changeValue('isLoading', true)
            try {
                let brandToSave = {
                    name, description, is_verified: isVerified, remarks, type, logo_id: logo.id, person_id: selectedPerson
                }
                const { success, message } = APO_brand === 'create' ? await createBrand(brandToSave) : await updateBrand(selectedBrand, brandToSave)

                changeValue('validationMessage', message)
                if (success) {
                    makeStateEmpty()
                    hideModal()
                    refreshData(success, message)
                }
            }
            catch (error) {
                console.log(error)
            }
            // changeValue('isLoading', false)
        }
    }

    const nameInput = <Input
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        onFocus={() => { setElementDisability('formSubmit', true, 'p-disabled', true) }}
        onBlur={(e) => { checkUniqueness(e, 'brands', 'name', 'formSubmit', 'p-disabled') }}
        containerClass='mb-0'
        invalidMessage={name ? 'A brand with this name already exists' : 'This field is mandatory'}
        disabled={APO_brand === 'update'}
        required
    />
    const remarksInput = <Input
        type="textarea"
        rows={1}
        label='Remarks'
        name={`remarks`}
        value={remarks}
        containerClass='mb-0'
        handleInput={handleInput}
        disabled={ability.cannot('verify', 'brands')}
    />
    const descriptionInput = <CustomEditor
        value={description}
        handleChange={(html) => changeValue('description', html)}
        editorClassName="bg-tertiary color-primary px-2 border-radius-0"
        wrapperClassName="color-primary border-radius-0"
        initialStateFromValue={APO_brand === 'update'}
        toolbar={{
            options: [
                'inline', 'blockType', 'fontSize', 'fontFamily', 'list',
                'textAlign', 'colorPicker', 'history'
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
        }}
        placeholder="Description..."
    />
    const logoInput = <div className='text-left'>
        {logo && <img
            src={logo.name ? `/api/resources/${logo.name}` : logo}
            width='100%'
            alt='Logo'
            style={{ borderRadius: '3px', border: '1px solid white', height: '221px', backgroundColor: 'white' }}
        />}
        {/* <Button
            label={logo === '' || !logo ? 'Choose image' : 'Change'}
            icon={logo ? 'pi pi-refresh' : "pi pi-plus"}
            type='button'
            className="p-button-sm w-100 py-1 px-3 bg-tertiary bg-hover-primary color-primary hover-tertiary font-weight-bold tertiary-border border-radius-0"
            onClick={() => setState(prevState => ({ ...prevState, showImageGallery: true, logo: '' }))}
        /> */}
    </div>
    // const selectPersonInput = <Can I='verify' a='brands'>
    //     <CustomSelect
    //         options={getOptions('clients', allSellers)}
    //         handleChange={handleSelectChange('selectedPerson')}
    //         label={`Select ${type}`}
    //         value={selectedPerson}
    //         isFormField
    //     />
    // </Can>
    // const verifyInput = <Can I='verify' a='brands'>
    //     <div className='m-0 mb-3 p-0 p-field-radiobutton'>
    //         <RadioButton
    //             name='isVerified'
    //             inputId='verify'
    //             onChange={(e) => changeValue('isVerified', e.checked)}
    //             checked={isVerified}
    //         />
    //         <label htmlFor="verify" className='my-0'>Verified ?</label>
    //     </div>
    // </Can>
    // const brandType =
    //     <div className='m-0 mb-3 p-0 d-flex flex-wrap'>
    //         <div className='p-field-radiobutton my-1 mx-0 small'>
    //             <RadioButton
    //                 value="owner"
    //                 name="new"
    //                 inputId='type1'
    //                 onChange={(e) => changeValue('type', e.value)}
    //                 checked={type === 'owner'}
    //             />
    //             <label htmlFor="type1" className='my-0'>I own this brand (Verification required)</label>
    //         </div>
    //         <div className='p-field-radiobutton my-1 mx-0 small'>
    //             <RadioButton
    //                 value="seller"
    //                 name="existing"
    //                 inputId='type2'
    //                 className=''
    //                 onChange={(e) => changeValue('type', e.value)}
    //                 checked={type === 'seller'}
    //             />
    //             <label htmlFor="type2" className='my-0'>I want to sell this brand (Authority letter required)</label>
    //         </div>
    //     </div>
    const submitBtn = <SubmitButton
        id='formSubmit'
        label={APO_brand}
        className="w-100"
        color='secondary'
        type="submit"
    />
    const validatingLabel = <label className='p-0 m-0 validating-label'>{validationMessage}&nbsp;</label>
    const fieldsToDisplay1 = [nameInput, remarksInput]

    return (
        <Can I={APO_brand} a='brands'>
            <Dialog
                header={<p className='color-tertiary m-0 p-0'>{`${APO_brand === 'create' ? 'New' : 'Update'} brand`}</p>}
                modal
                onHide={() => { setModalShow(false); setTimeout(() => { hideModal(); makeStateEmpty() }, 200) }}
                className='mx-3 m-md-0'
                style={{ width: '50vw', height: '' }}
                breakpoints={{ '960px': '75vw', '768px': '100vw', }}
                visible={modalShow}
            >
                <form ref={brandForm} className='m-0 px-0 pt-4 text-center color-tertiary' onSubmit={handleSubmit} noValidate>
                    <div className='m-0 p-0 p-grid p-justify-center p-align-top'>
                        {fieldsToDisplay1.map((field, index) => <div key={index + 1} className='m-0 mb-3 col-sm-6'>{field}</div>)}
                        <div className='mb-3 col-sm-12'>
                            {descriptionInput}
                        </div>
                        <div className='mb-3 col-sm-6'>
                            {logoInput}
                        </div>
                        <div className='mb-3 col-sm-6'>
                            {/* {!isSellerLoggedIn && selectPersonInput}
                            {brandType}
                            {verifyInput} */}
                            {submitBtn}
                        </div>
                    </div>
                    {validatingLabel}
                </form>
            </Dialog>
            <ImageGallery
                addBtnHeading={'Choose'}
                modalHeading={'Add Media'}
                modalShow={showImageGallery}
                reference='brand_logo'
                isProtected={true}
                handleSelectedFiles={(file) => {
                    delete file[0].selected
                    changeValue('logo', file[0])
                }}
                hideImageGallery={() => { changeValue('showImageGallery', false) }}
            />
            <LoaderModal modalShow={isLoading} />
        </Can >
    )
}
export default BrandModal