
import { useState, useEffect, useRef } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getPage, updatePage } from '../../../../services/misc/page-service'
import LoaderModal from '../../../misc/components/LoaderModal'
import CustomEditor from '../../../misc/components/CustomEditor'

let _isMounted
export const UpdatePageModal = (props) => {
    let toast = useRef(null)
    let { pageToUpdate } = props

    const [{ isVisible, pageId, content, isLoading }, setstate] = useState({ isVisible: props.modalShow, pageId: null, content: '', isLoading: false })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getPage(pageToUpdate)
                setstate(prevState => ({
                    ...prevState,
                    pageId: data?.id,
                    content: data?.content,
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const setModalShow = () => {
            changeValue('isVisible', props.modalShow)
        }
        props.modalShow && fetchData()
        setModalShow()
        return () => {
            _isMounted = false
        }
    }, [props.modalShow])

    const changeValue = (name, value) => {
        _isMounted && setstate(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleUpdate = async () => {
        let updatedPage = { content: content }
        try {
            const { success } = await updatePage(pageId, updatedPage)
            success ?
                toast.current.show({ severity: 'success', summary: 'Success', detail: `page updated.` })
                :
                toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
        }
        catch (error) {
            console.log(error)
        }

    }

    const footer = (
        <div className='p-d-flex p-flex-wrap p-jc-end'>
            <a
                href={`/${pageToUpdate === 'About' ? 'about' : pageToUpdate === 'Contact' ? 'contact' : pageToUpdate === 'Terms & Conditions' ? 'terms' : ''}`}
                target='_blank'
                className='p-mx-2 p-mt-1'
            >
                Preview page
            </a>
            <Button
                label="Update"
                icon="pi pi-check"
                className='p-button-sm p-button-rounded p-button-raised'
                onClick={() => { handleUpdate() }}
            />
        </div>
    );

    return (
        <Dialog
            header={<p className='color-tertiary p-m-0 p-p-0'>{`Customize Your ${pageToUpdate} Page`}</p>}
            footer={footer}
            visible={isVisible}
            modal
            className='p-mt-5 p-mx-4 p-mx-sm-5'
            style={{ width: '75vw' }}
            breakpoints={{ '960px': '100vw', }}
            closable
            onHide={() => { props.hideModal() }}
        >
            <Toast ref={toast} className='p-mt-5' />
            <CustomEditor
                height={window.innerHeight - 300}
                value={content}
                handleChange={(html) => changeValue('content', html)}
                editorClassName='bg-transparent color-tertiary'
            />
            <LoaderModal modalShow={isLoading} />
        </Dialog>
    )
}
