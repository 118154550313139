import React from 'react';
import { MDBCloseIcon } from 'mdbreact'
import { CustomButton } from '../../../../misc/components/Inputs'

export const ImagesDataStep = (props) => {

    let {
        APO_product,
        changeValue,
        currentStep,
        imagesDataForm,
        removeImage,
        showImageGallery,

        featuredImage,
        images,
    } = props

    let imageGallery = []
    images.forEach((image, index) => {
        // console.log(image)
        imageGallery.push(
            <div key={index + 1} className='m-0 pr-0 pl-2 py-2 col-xs-6 col-sm-6 col-lg-4' style={{ height: '150px' }}>
                {APO_product !== 'read' &&
                    <MDBCloseIcon
                        className='closeIcon'
                        onClick={() => { removeImage(index) }}
                    />
                }
                {/* {console.log(image.type)} */}
                {image.type ?
                    <img
                        src={`/api/resources/${image.name}`} alt='Image not found.'
                        className='w-100 h-100 rounded shadow'
                        style={{ border: '1px solid #669999' }}
                    />
                    :
                    <img
                        src={image.src} alt={image.alt}
                        className='w-100 h-100 rounded shadow'
                        style={{ border: '1px solid #669999' }}
                    />
                }
            </div >
        )
    })


    // if (currentStep !== 9) {
    //     return null
    // }
    return (
        <form ref={imagesDataForm} className={`m-0 p-0 ${currentStep !== 9 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='p-grid m-0'>
                <div className='m-0 col-sm-6 col-md-4'>
                    <p className="d-block color-Primary mb-2">Featured image</p>
                    {/* <p className="d-none d-sm-block color-Primary mb-2">Featured image</p> */}
                    {/* {console.log(featuredImage)} */}
                    {featuredImage ?
                        (
                            <div className='m-0 px-0 py-2' style={{ height: '150px' }}>
                                {APO_product !== 'read' &&
                                    <MDBCloseIcon
                                        className='closeIcon'
                                        onClick={() => { changeValue('featuredImage', null) }}
                                    />
                                }
                                {featuredImage.type ?
                                    <img
                                        src={`/api/resources/${featuredImage.name}`} alt='Image not found.'
                                        className='w-100 h-100 rounded shadow'
                                        style={{ border: '1px solid #669999' }}
                                    />
                                    :
                                    <img
                                        src={featuredImage.src} alt={featuredImage.alt}
                                        className='w-100 h-100 rounded shadow'
                                        style={{ border: '1px solid #669999' }}
                                    />
                                }
                            </div >
                        )
                        :
                        <CustomButton
                            variant='with-icon'
                            onClick={() => { showImageGallery(false) }}
                            label='Set product image'
                            bg='primary-light'
                            color='tertiary'
                            icon='pi pi-plus'
                            className='px-5 px-sm-2 px-lg-4 w-100'
                        />
                    }
                    {APO_product !== 'read' &&
                        <CustomButton
                            variant='with-icon'
                            onClick={() => { showImageGallery(true) }}
                            label='Add product gallery images'
                            bg='Primary'
                            color='tertiary'
                            icon='pi pi-images'
                            className='px-5 px-sm-2 px-lg-4 w-100 my-3'
                        />
                    }
                </div>
                {imageGallery.length ?
                    <div className='m-0 col-sm-6 col-md-8'>
                        {/* <small className="d-block d-sm-none small mb-3">Product gallery</small> */}
                        <p className="mb-2">Product gallery</p>
                        <div className="row mx-0 mb-3 p-0">
                            {imageGallery}
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </form>
    )
}
