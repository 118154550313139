import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WYSIWYGeditor = ({
    handleChange, initialStateFromValue, value, height, editorClassName,
    wrapperClassName, toolbar, placeholder, disabled
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        const contentBlock = convertFromHTML(`${value}`);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            var initial_editor_state = EditorState.createWithContent(contentState);
        }
        initialStateFromValue &&
            value !== draftToHtml(convertToRaw(editorState.getCurrentContent())) &&
            setEditorState(initial_editor_state)

    }, [value])

    return (
        <Editor
            editorState={editorState}
            wrapperClassName={wrapperClassName ? wrapperClassName : "p-mb-2 color-primary border-radius-0"}
            toolbar={toolbar}
            toolbarClassName='border-radius-0 p-mb-0 small'
            wrapperStyle={height ? { height: `${height}px` } : undefined}
            toolbarStyle={height ? { height: '100px' } : undefined}
            editorStyle={height ? { height: `${height - 100}px` } : undefined}
            editorClassName={editorClassName ? editorClassName : `bg-tertiary color-primary p-px-2 border-radius-0`}
            onEditorStateChange={(state) => {
                setEditorState(state);
                handleChange(draftToHtml(convertToRaw(state.getCurrentContent())))
            }}
            placeholder={placeholder ? placeholder : "Enter something..."}
            readOnly={disabled}
        />
    );
}

export default WYSIWYGeditor
