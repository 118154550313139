import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log(error, errorInfo);
    }

    // componentDidCatch(error, errorInfo) {

    //     // this._isMounted &&
    //     this.setState({
    //         error: error,
    //         errorInfo: errorInfo
    //     });
    //     // console.log(this.props.children);
    // }

    render() {
        if (this.state.hasError) {

            //if error render this
            return (
                <>
                    <MDBRow style={{ marginTop: '72px' }} className='m-0 p-0'>
                        <MDBCol md='12' className='text-center float-md-none mx-auto'>
                            <img
                                src="/404_mdb.png"
                                alt="Error 404"
                                className="img-fluid animated"
                                style={{ animationName: 'shake', animationIterationCount: 1, animationDelay: '0.4s' }} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-5">
                        <MDBCol md='12' className="text-center mb-5">
                            <h2
                                className="h2-responsive mb-4 animated fontRaleway"
                                data-wow-delay="0.2s"
                                style={{ fontWeight: 'bold', animationName: 'zoomIn', animationIterationCount: 1, animationDelay: '0.9s' }}
                            >
                                Oops! Something went wrong.
                            </h2>
                        </MDBCol>
                    </MDBRow >
                </>
            );
        }

        // Normally, just render children
        else return this.props.children;
    }
}


export default ErrorBoundary