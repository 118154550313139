
import XLSX from 'xlsx';
import { capitalize, validateDataFormat, arraysToObject, getJsonData } from './Helpers'

export const handleExportData = (t, setIsLoading, appName) => {
    setIsLoading(true)
    fetch(`/api/data/export/${t}`)
        .then(res => res.json())
        .then(json => {
            console.log(json)
            if (json.success) {
                const wb = XLSX.utils.book_new();                     // create workbook
                const ws = XLSX.utils.aoa_to_sheet(json.data);            // convert data to work-sheet
                XLSX.utils.book_append_sheet(wb, ws, `All ${capitalize(t)}`)  // add sheet to workbook
                const filename = `${appName}-${t}-${new Date().toLocaleDateString()}.xlsx`;
                const wb_opts = { bookType: 'xlsx', type: 'binary' };   // workbook options
                XLSX.writeFile(wb, filename, wb_opts);                // write workbook file
            }
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err);
            setIsLoading(false)
        })
}

export const handleImportData = async (e, setIsLoading, columnNames, t, toast, fileUpload, panel, updateColumns, storeURL, refreshData) => {
    setIsLoading(true)
    let file = e.files[0]
    let reader = new FileReader();
    reader.onload = async (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
        var data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });
        let testColumns = data[0]
        // console.log(validateDataFormat(testColumns, columnNames))
        if (validateDataFormat(testColumns, columnNames)) {
            let rowsToBeSave = []
            for (let i = 1; i < data.length; i++) {
                let valuesArray = data[i];
                if (valuesArray[1] !== undefined) {
                    rowsToBeSave.push(arraysToObject(valuesArray, columnNames))
                }
            }
            // console.log('rows', rowsToBeSave)
            if (t === 'users') {
                rowsToBeSave = await getJsonData(rowsToBeSave.filter(r => r.username), ['bank_details', 'account_details', 'shop_details'])
                rowsToBeSave = rowsToBeSave.map(r => {
                    if (r.account_details) {
                        let { title, id, iban_id } = r.account_details
                        r.ac_title = title
                        r.ac_id = id ? Number(id) : null
                        r.iban_id = iban_id
                    }
                    if (r.bank_details) {
                        let { name } = r.bank_details
                        r.bank_name = name
                    }
                    if (r.shop_details) {
                        let { name, address, cell } = r.shop_details
                        r.shop_name = name
                        r.shop_address = address
                        r.shop_cell = cell
                    }
                    if (r.ac_title || r.ac_id || r.iban_id || r.bank_name || r.shop_name || r.shop_address || r.shop_cell) {
                        r.role_id = 4
                    }
                    delete r.account_details
                    delete r.bank_details
                    delete r.shop_details
                    delete r.cost_price_percent
                    delete r.retail_price_percent
                    return r
                })
            }
            else if (t = 'products') {
                rowsToBeSave = rowsToBeSave.map(r => {
                    r.permalink = `${storeURL}product/${r.slug}`
                    r.brand_id = r.brand_id ? Number(r.brand_id) : null
                    r.sku = r.sku === '' ? null : r.sku
                    delete r.slug
                    delete r.id
                    return r
                })
                rowsToBeSave = await getJsonData(
                    rowsToBeSave,
                    ['attributes', 'categories', 'dimensions', 'images', 'cross_sell_ids', 'upsell_ids', 'default_attributes', 'tags']
                )
            }
            console.log(rowsToBeSave)
            let options = {
                method: 'POST',
                body: JSON.stringify({ data: rowsToBeSave, update: updateColumns }),
                headers: { 'Content-Type': 'application/json' }
            }
            fetch(`/api/data/import/${t}`, options)
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    if (json.success) {
                        toast.current.show({
                            severity: 'success',
                            summary: `Success`,
                            detail: 'Data successfully imported.',
                            life: 3000
                        });
                        refreshData()
                        fileUpload.current?.clear()
                        panel.current.hide()
                    }
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                    toast.current.show({
                        severity: 'error',
                        summary: `Sorry`,
                        detail: 'Something went wrong.',
                        life: 2500
                    });
                    console.log(err)
                })
        }
        else {
            setIsLoading(false)
            toast.current.show({
                severity: 'error',
                summary: `Invalid format`,
                detail: 'Please follow format from sample file.',
                life: 4000
            });
            fileUpload.current.clear()
        }
    };
    reader.readAsArrayBuffer(file);
}

   // let brands = rowsToBeSave.map(r => r.brand_id)
                // brands = brands.filter(r => r)
                // let sb1 = [...new Set(brands)];
                // sb1.sort(function (a, b) { return a - b })
                // // console.log('brands', brands)
                // console.log('sb1', sb1)
                // await fetch('/getAllBrands')
                //     .then(res => res.json())
                //     .then(async json => {
                //         console.log(json)
                //         let brands = json.data.map(b => b.bkt_id)
                //         let sb = [...new Set(brands.filter(r => r))];
                //         sb.sort(function (a, b) { return a - b })
                //         // console.log('brands', brands)
                //         console.log('sb2', sb)
                //         console.log(sb1.every(b => sb.includes(b)))
                //         sb1.map(b => {
                //             console.log(sb.includes(b) ? 'true' : b)
                //         })
                //     })
                //     .catch(err => {
                //         console.log(err)
                //     })