

export const createSellerPlan = async (newSellerPlan, featureIds) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newSellerPlan, featureIds }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSellerPlansWithData = async () => {
    try {
        const res = await fetch(`/api/sellers/plans/data`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSellerPlans = async () => {
    try {
        const res = await fetch(`/api/sellers/plans`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSellerPlan = async (id) => {
    try {
        const res = await fetch(`/api/sellers/plans/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateSellerPlan = async (id, updatedSellerPlan, featureIds) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedSellerPlan, featureIds }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteSellerPlan = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const createPlanFeature = async (newFeature) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newFeature }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans/features`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPlansFeatures = async () => {
    try {
        const res = await fetch(`/api/sellers/plans/features`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPlansFeaturesWithData = async () => {
    try {
        const res = await fetch(`/api/sellers/plans/features/data`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPlanFeature = async (id) => {
    try {
        const res = await fetch(`/api/sellers/plans/features/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updatePlanFeature = async (id, updatedFeature) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedFeature }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans/features/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deletePlanFeature = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/sellers/plans/features/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}


