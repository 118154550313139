import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Can } from "../../../configs/Ability-context";
import OrderSource from './pages/sections/OrderSource';
import AllOrderSources from './pages/AllOrderSources';
import NotFound from '../../misc/pages/NotFound';
import { PrivateRoute } from '../../misc/PrivateRoute';


const OrderSources = () => {
    return (
        <Switch>
            <PrivateRoute path='/orders/sources/new'>
                <Can I='create' a='orderSources'>
                    <OrderSource APO_source='create' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/orders/sources/edit/:source'>
                <Can I='update' a='orderSources'>
                    <OrderSource APO_source='update' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/orders/sources/all'>
                <Can I='read' a='orderSources'>
                    <AllOrderSources />
                </Can>
            </PrivateRoute>
            <Route component={NotFound} />

        </Switch>

    )
}


export default OrderSources