import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Can, AbilityContext } from "../../configs/Ability-context";
import { CustomButton } from "../misc/components/Inputs";

import { AiOutlineShop } from "react-icons/ai";
// import { BiReceipt } from 'react-icons/bi';
import {
  FaUsers,
  FaUserCircle,
  FaFunnelDollar,
  FaGripHorizontal,
} from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiCubes, GiPterodactylus, GiShutRose, GiZipper } from "react-icons/gi";
import {
  RiSecurePaymentFill,
  RiBarChartFill,
  RiDashboard3Line,
  RiMicrosoftFill,
  RiSettings5Line,
  RiSettings3Fill,
  RiSettings6Fill,
  RiSettings5Fill,
} from "react-icons/ri";

const Aside = ({
  logOut,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const ability = useContext(AbilityContext);
  const {
    isSellerLoggedIn,
    isSellerManagerLoggedIn,
    emailVerified,
    accountApproved,
  } = useContext(UserContext);
  // console.log("accountApproved")
  // console.log(accountApproved)

  const tabs = {
    users: {
      title: isSellerLoggedIn ? "my shop managers" : "users",
      label: isSellerLoggedIn ? "My shop managers" : "Users",
      path: "/users/all",
    },
    roles: {
      title: "roles",
      label: "Roles",
      path: "/users/roles/all",
    },
    permissions: {
      title: "permissions",
      label: "Permissions",
      path: "/users/permissions/all",
    },
    products: {
      title: isSellerLoggedIn ? "my products" : "products",
      label: isSellerLoggedIn ? "My products" : "Products",
      path: "/products/all",
    },
    myorders: {
      // title: isSellerLoggedIn ? "my orders" : "orders",
      // label: isSellerLoggedIn ? "My Orders" : "Orders",
      title: isSellerLoggedIn ? "my orders" : "seller orders",
      label: isSellerLoggedIn ? "My Orders" : "Seller Orders",
      path: "/orders/seller/all",
    },
    myordersdetail: {
      title: isSellerLoggedIn ? "my orders detail" : "orders detail",
      label: isSellerLoggedIn ? "My Orders Detail" : "Orders Detail",
      path: "/orders/detail/seller/all",
    },
    productCategories: {
      title: "product categories",
      label: "Product categories",
      path: "/products/categories/all",
    },
    brands: {
      title: "brands",
      label: "Brands",
      path: "/products/brands/all",
    },
    tags: {
      title: "tags",
      label: "Tags",
      path: "/products/tags/all",
    },
    attributes: {
      title: "attributes",
      label: "Attributes",
      path: "/products/attributes/all",
    },
    orders: {
      title: "orders",
      label: "Orders",
      path: "/orders/all",
    },
    orderItems: {
      title: isSellerLoggedIn ? "my orders" : "order items",
      label: isSellerLoggedIn ? "My Orders" : "Order items",
      path: "/orders/items/all",
    },
    exportOrders: {
      title: "export data",
      label: "Export data",
      path: "/orders/export/data",
    },
    orderSources: {
      title: "order sources",
      label: "Order sources",
      path: "/orders/sources/all",
    },
    orderStatuses: {
      title: "order statuses",
      label: "Order statuses",
      path: "/orders/statuses/all",
    },
    // 'printSlips': {
    //     title: 'print local slips',
    //     label: 'Print local slips',
    //     path: '/orders/slips/local'
    // },
    sellers: {
      title: isSellerManagerLoggedIn ? "my sellers" : "sellers",
      label: isSellerManagerLoggedIn ? "My sellers" : "Sellers",
      path: "/sellers/all",
    },
    sellerRequests: {
      title: isSellerLoggedIn ? "my requests" : "seller requests",
      label: isSellerLoggedIn ? "My requests" : "Seller requests",
      path: "/sellers/requests/all",
    },
    paymentMethods: {
      title: "payment methods",
      label: "Payment methods",
      path: "/payments/methods/all",
    },
    priceRules: {
      title: "price rules",
      label: "Price rules",
      path: "/price-rules/all",
    },
    appSettings: {
      title: "settings",
      label: "Settings",
      path: "/settings/options",
    },
    profile: {
      title: "my profile",
      label: "My profile",
      path: isSellerLoggedIn ? "/sellers/myProfile" : "/users/myProfile",
    },
    dashboard: {
      title: "dashboard",
      label: "Dashboard",
      path: "/dashboard",
    },
    sellerDashboard: {
      title: "dashboard",
      label: "Seller Dashboard",
      path: "/seller_dashboard",
    },
    adminSettings: {
      title: "admin setting",
      label: "Admin Settings",
      path: "/settings/admin/all",
    },
  };

  const getLabel = (tab) => tabs[tab].label;
  const getTitle = (tab) => tabs[tab].title;
  const getPath = (tab) => tabs[tab].path;

  const getMenuItem = (tab, icon) => {
    return (
      <MenuItem icon={icon ? icon : null}>
        <Link to={getPath(tab)} title={getTitle(tab)}>
          {getLabel(tab)}
        </Link>
      </MenuItem>
    );
  };

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="sm"
      onToggle={handleToggleSidebar}
      // image="/sidebarBG.jpg"
      className="print-hide"
    >
      <CustomButton
        variant="with-icon"
        icon={
          collapsed ? "pi pi-angle-double-right" : "pi pi-angle-double-left"
        }
        bg="primary-light"
        color="tertiary"
        id="toogle-collapse-button-sidebar"
        className="p-ml-1 animated fadeInDown"
        style={{ left: collapsed ? "50px" : "201px", width: "20px" }}
        onClick={() => {
          handleCollapsedChange(!collapsed);
        }}
      />
      <CustomButton
        variant="with-icon"
        icon={"pi pi-angle-double-right"}
        bg="primary-light"
        color="tertiary"
        id="btn-toggle"
        className={`animated fadeInDown ${toggled && "p-d-none"}`}
        style={{ width: "20px" }}
        onClick={() => {
          handleToggleSidebar(true);
        }}
      />
      <SidebarHeader className="border-none text-center">
        {!collapsed && (
          <img
            src="/logo_small.png"
            // alt={appName}
            width="50%"
            height="90%"
            // className=''
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("/home", "_self");
            }}
          />
        )}
      </SidebarHeader>

      <SidebarContent>
        {emailVerified ? (
          <Menu iconShape="circle" popperArrow={false}>
            <Can
              I="update"
              a={isSellerLoggedIn ? "sellerProfile" : "userProfile"}
            >
              {getMenuItem("profile", <FaUserCircle size="1.5em" />)}
            </Can>
          </Menu>
        ) : (
          ""
        )}
        {emailVerified && accountApproved ? (
          <Menu iconShape="circle" popperArrow={false}>
            <Can I="read" a="adminDashboard">
              {getMenuItem("dashboard", <RiMicrosoftFill size="1.75em" />)}
            </Can>
            {/* <Can I='read' a='sellerDashboard'>
                            {getMenuItem('sellerDashboard', <RiDashboard3Line size='1.75em' />)}
                        </Can> */}

            {ability.can("read", "users") ? (
              <SubMenu
                title={
                  <Link to={getPath("users")} title={getTitle("users")}>
                    {getLabel("users")}
                  </Link>
                }
                icon={<FaUsers size="1.5em" />}
              >
                {collapsed && (
                  <Can I="read" a="users">
                    {getMenuItem("users")}
                  </Can>
                )}
                <Can I="read" a="roles">
                  {getMenuItem("roles")}
                </Can>
                <Can I="read" a="permissions">
                  {getMenuItem("permissions")}
                </Can>
              </SubMenu>
            ) : (
              <></>
            )}

            {ability.can("read", `products`) ? (
              <SubMenu
                title={
                  // getLabel('products')
                  <Link to={getPath("products")} title={getTitle("products")}>
                    {getLabel("products")}
                  </Link>
                }
                icon={<GiCubes size="1.6em" />}
              >
                {collapsed && (
                  <Can I="read" a={"products"}>
                    {getMenuItem("products")}
                  </Can>
                )}
                <Can I="read" a="productCategories">
                  {getMenuItem("productCategories")}
                </Can>
                <Can I="read" a="brands">
                  {getMenuItem("brands")}
                </Can>
                <Can I="read" a="tags">
                  {getMenuItem("tags")}
                </Can>
                <Can I="read" a="attributes">
                  {getMenuItem("attributes")}
                </Can>
              </SubMenu>
            ) : (
              <></>
            )}
            {/* ------------------------------------------------------------------------------------------------------------- */}
            {ability.can("read", `sellerOrders`) ? (
              <SubMenu
                title={
                  // getLabel('products')
                  <Link to={getPath("myorders")} title={getTitle("myorders")}>
                    {getLabel("myorders")}
                  </Link>
                }
                icon={<FaGripHorizontal size="1.6em" />}
              >
                {collapsed && (
                  <Can I="read" a={"sellerOrders"}>
                    {getMenuItem("myorders")}
                  </Can>
                )}

                {/* <Can I="read" a="productCategories">
                  {getMenuItem("productCategories")}
                </Can>
                <Can I="read" a="brands">
                  {getMenuItem("brands")}
                </Can>
                <Can I="read" a="tags">
                  {getMenuItem("tags")}
                </Can>
                <Can I="read" a="attributes">
                  {getMenuItem("attributes")}
                </Can> */}
              </SubMenu>
            ) : (
              <></>
            )}

            {/* --------------------------------------------------------------------------------------------------------------- */}
            {
              // isSellerLoggedIn ?""
              // <Can I='read' a='orderItems'>
              //     {getMenuItem('orderItems', <RiBarChartFill size='1.6em' />)}
              // </Can>
              // :
              ability.can("read", `orders`) ? (
                <SubMenu
                  title={
                    <Link to={getPath("orders")} title={getTitle("orders")}>
                      {getLabel("orders")}
                    </Link>
                  }
                  icon={<RiBarChartFill size="1.6em" />}
                >
                  {collapsed && (
                    <Can I="read" a={`orders`}>
                      {getMenuItem("orders")}
                    </Can>
                  )}
                  <Can I="read" a="orderItems">
                    {getMenuItem("orderItems")}
                  </Can>
                  {/* <Can I='export' a='orders'>
                                        {getMenuItem('exportOrders')}
                                    </Can> */}
                  {/* <Can I='read' a='orderSources'>
                                        {getMenuItem('orderSources')}
                                    </Can> */}
                  {/* <Can I='read' a='orderStatuses'>
                                        {getMenuItem('orderStatuses')}
                                    </Can> */}
                  {/* <Can I='printSlips' a='orders'>
                                        {getMenuItem('printSlips')}
                                    </Can> */}
                </SubMenu>
              ) : (
                <></>
              )
            }
            {ability.can("read", `sellers`) ? (
              <SubMenu
                title={
                  <Link to={getPath("sellers")} title={getTitle("sellers")}>
                    {getLabel("sellers")}
                  </Link>
                }
                icon={<AiOutlineShop size="1.6em" />}
              >
                {collapsed && (
                  <Can I="read" a={`sellers`}>
                    {getMenuItem("sellers")}
                  </Can>
                )}
                <Can I="read" a="sellerRequests">
                  {getMenuItem("sellerRequests")}
                </Can>
              </SubMenu>
            ) : (
              <Can I="read" a="sellerRequests">
                {getMenuItem(
                  "sellerRequests",
                  <RiDashboard3Line size="1.6em" />
                )}
              </Can>
            )}
            {/* <Can I='read' a='sellers'>
                        <MenuItem icon={<AiOutlineShop size='1.6em' />}>
                            <Link to='/sellers/all' title='Sellers'>Sellers</Link>
                        </MenuItem>
                    </Can> */}

            {/* {ability.can('read', `vouchers`) ?
                        <SubMenu
                            title={<Link to="/vouchers/all" title='Vouchers'>Vouchers</Link>}
                            icon={<BiReceipt size='1.6em' />}
                        >
                            {collapsed &&
                                <Can I='read' a='vouchers'>
                                    <MenuItem>
                                        <Link to="/vouchers/all">Vouchers</Link>
                                    </MenuItem>
                                </Can>
                            }
                            <Can I='read' a='companies'>
                                <MenuItem>
                                    <Link to='/vouchers/companies/all'>Companies</Link>
                                </MenuItem>
                            </Can>
                        </SubMenu>
                        :
                        <></>
                    } */}

            {
              /* <Can I='read' a='paymentMethods'>
                        {getMenuItem('paymentMethods', <RiSecurePaymentFill size='1.6em' />)}
                    </Can>

                    <Can I='read' a='priceRules'>
                        {getMenuItem('priceRules', <FaFunnelDollar size='1.6em' />)}
                    </Can>
                        */
              <Can I="read" a="appSettings">
                {getMenuItem("appSettings", <RiSettings5Line size="1.6em" />)}
              </Can>
            }
            {
              <Can I="read" a="adminSettings">
                {getMenuItem("adminSettings", <RiSettings5Fill size="1.6em" />)}
              </Can>
            }

            {/* <MenuItem icon={<FiLogOut size='1.55em' />}>
                            <Link onClick={() => { logOut() }} to="/home" title='Log out'>Log out</Link>
                        </MenuItem> */}
          </Menu>
        ) : (
          ""
        )}

        <Menu iconShape="circle" popperArrow={false} className="p-pt-5">
          <MenuItem icon={<FiLogOut size="1.55em" />}>
            <Link
              onClick={() => {
                logOut();
              }}
              to="/home"
              title="Log out"
            >
              Log out
            </Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter
        className={`p-text-center p-p-0`}
        style={{
          lineHeight: collapsed ? "12px" : "25px",
          height: "25px",
          border: "none",
        }}
      >
        <p className="small p-mb-0 color-tertiary">
          {collapsed ? "V" : "Version"} 1.0.33
        </p>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
