import React from 'react';
import { useHistory } from 'react-router-dom'
import { CustomButton } from '../../../../misc/components/Inputs'
import { Dialog } from 'primereact/dialog'



const OrderConfirmationPopup = (props) => {
    const { bktOrderId, toggle } = props;
    let history = useHistory()
    return (
        <Dialog
            header={<h4 className="m-0 p-0 color-tertiary font-weight-normal">{`New order created !`}</h4>}
            modal
            onHide={toggle}
            className='mx-4 ml-sm-5 m-lg-0 bg-Primary'
            style={{ width: '25rem' }}
            // visible={true}
            visible={props.popupShow}
            id='confirmation-popup'
            footer={
                <div className='text-center mb-3'>
                    <CustomButton
                        icon={'pi pi-thumbs-up'}
                        label={''}
                        className='px-5'
                        variant="with-icon"
                        onClick={() => { toggle(); history.push('/orders/all') }}
                    />
                </div>
            }
            contentClassName='pb-3'
        >
            <h5 className="text-center text-info mb-0"> You successfully placed an order.<br />
                <span style={{lineHeight:'40px'}}><b>Order-id</b>&nbsp;:&nbsp;</span>
                <b>{`${bktOrderId && bktOrderId.toString()}`}</b>
            </h5>
        </Dialog>
    )
}



export default OrderConfirmationPopup