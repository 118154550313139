import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './styles/index.css';
import './styles/App.css';
import './styles/App.scss';
import './styles/index.scss';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import PrimeReact from 'primereact/api';
// import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/misc/pages/ErrorBoundary'


PrimeReact.ripple = true;


ReactDOM.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </ErrorBoundary>,
  document.getElementById('root')
);
if (module.hot) {
  module.hot.accept(function (err) {
    console.log('An error occurred while accepting new version');
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
