import React, { useContext } from 'react'
import { ListBox } from 'primereact/listbox'
import { GeneralDataStep } from './form-steps/GeneralDataStep'
import { CustomerDataStep } from './form-steps/CustomerDataStep'
import { QueryDataStep } from './form-steps/QueryDataStep'
import { ProductsDataStep } from './form-steps/ProductsDataStep'
import { ShippingDataStep } from './form-steps/ShippingDataStep'
import { PaymentsDataStep } from './form-steps/PaymentsDataStep'
import { AbilityContext } from '../../../../configs/Ability-context'
import { getUniqueOrderId } from '../../../../services/orders/order-service'
import { CustomInput } from '../../../misc/components/Inputs'

const commonSteps = [
    { label: 'General' },
    { label: 'Customer' },
]
const querySteps = [
    { label: 'Query' },
]
const orderSteps = [
    { label: 'Products' },
    { label: 'Shipping' },
    { label: 'Payments' },
]

export const OrderForm = (props) => {
    const ability = useContext(AbilityContext)

    let {
        //universal
        _isMounted,
        APO_order,
        currentStep,
        changeValue,
        cityOptions,
        countryOptions,
        defaultStatus,
        isMakingAQuery,
        setCurrentStep,
        setIsLoading,
        setState,
        //general
        allOrderStatuses,
        bkt_order_id,
        customerNote,
        date,
        discountDetail,
        orderDiscount,
        orderNote,
        orderSourceOptions,
        orderStatusOptions,
        orderTypeOptions,
        selectedOrderSource,
        selectedOrderStatus,
        selectedOrderType,
        totalField,
        //customer
        cAddress,
        cCell,
        cCity,
        cCountry,
        cEmail,
        cName,
        cPostcode,
        cState,
        cStateOptions,
        customer_id,
        //query
        queryDetail,
        queryProduct,
        queryStatus,
        //products
        customerStatus,
        extraAdded,
        itemDiscount,
        itemDiscountDetail,
        itemStatusOptions,
        orderId,
        orderItems,
        orderProductOptions,
        rate,
        qty,
        remarks,
        selectedItemStatus,
        selectedProduct,
        selectedVariation,
        updatingOrderItems,
        //shipping
        courierOptions,
        dAddress,
        dAmount,
        dCell,
        dCity,
        dCountry,
        dName,
        dPostcode,
        dState,
        dStateOptions,
        selectedCourier,
        shippingDate,
        shippingMethod,
        shippingSameAsBilling,
        trackingId,
        //payments
        paymentMethodOptions,
        selectedPaymentMethod,
        trAmount,
        trDate,
        trId,
        trStatus,
        //form-refs
        customerDataForm,
        generalDataForm,
        paymentsDataForm,
        productsDataForm,
        queryDataForm,
        shippingDataForm,
    } = props
    const _steps = isMakingAQuery ? [...commonSteps, ...querySteps] : [...commonSteps, ...orderSteps]
    const stepOptions = _steps.map((step, index) => ({ key: index + 1, label: step.label, value: index + 1 }))


    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }
    const handleSelectChange = name => async (selectedOption) => {
        switch (name) {
            case 'selectedOrderSource':
                setIsLoading(true)
                try {
                    const { id } = await getUniqueOrderId(selectedOption.value)
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        [name]: selectedOption,
                        bkt_order_id: id,
                    }))
                }
                catch (err) {
                    console.log(err)
                }
                finally {
                    changeValue(name, selectedOption)
                }
                setIsLoading(false)
                break;
            case 'selectedOrderType':
                let draftStatus = allOrderStatuses.find(s => s.slug === 'draft')
                _isMounted && setState(prevState => ({
                    ...prevState,
                    [name]: selectedOption,
                    selectedOrderStatus: selectedOption.label === 'Query' ?
                        {
                            key: draftStatus?.id,
                            label: draftStatus?.title,
                            value: draftStatus?.slug,
                            entity: draftStatus,
                        }
                        :
                        prevState.selectedOrderType.label === 'Query' ?
                            defaultStatus
                            :
                            prevState.selectedOrderStatus
                }))
                break;
            case 'selectedProduct':
                _isMounted && setState(prevState => ({
                    ...prevState,
                    [name]: selectedOption,
                    selectedVariation: ''
                }))
                break;
            case 'date':
                _isMounted && setState(prevState => ({
                    ...prevState,
                    [name]: selectedOption,
                    orderItems: []
                }))
                break;
            case 'cCountry':
                _isMounted && setState(prevState => ({
                    ...prevState,
                    [name]: selectedOption,
                    cState: '',
                }))
                break;
            case 'dCountry':
                _isMounted && setState(prevState => ({
                    ...prevState,
                    [name]: selectedOption,
                    dState: ''
                }))
                break;
            case 'selectedPaymentMethod':
                if (selectedOption.label === 'COD') {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        [name]: selectedOption,
                        trId: "",
                        trDate: null,
                        trAmount: 0,
                        trStatus: "",
                    }))
                }
            default:
                changeValue(name, selectedOption)
                break;
        }
    }
    const handleCheckboxChange = (e) => {
        let { name, checked } = e.target
        if (name === 'shippingSameAsBilling') {
            _isMounted && setState(prevState => ({
                ...prevState,
                [name]: !checked,
                dName: '',
                dCell: '',
                dAddress: '',
                dCity: '',
                dState: '',
                dCountry: '',
                dPostcode: '',
            }))
        }
        else changeValue(name, checked)
    }
    const handleNumberInputBlur = e => {
        let { name, value } = e.target
        if (value == '') {
            (name === 'qty' || name === 'rate') ? changeValue(name, 1) : changeValue(name, 0)
        }
    }
    const calculateAmount = (required) => {
        let totalAmount = 0, maxOrderDiscount = 0, totalDiscount = 0
        orderItems?.map(item => {
            totalAmount += Number(item.price)
            maxOrderDiscount += Number(item.price)
            totalDiscount += Number(item.discount)
        })
        // console.log(totalAmount)
        if (required === 'totalAmount') {
            let total = ((totalAmount + Number(dAmount)) - (trAmount)) - orderDiscount
            return total
        }
        if (required === 'maxOrderDiscount') {
            return maxOrderDiscount
        }
        if (required === 'totalDiscount') {
            return totalDiscount + Number(orderDiscount)
        }
    }

    return (
        <>
            <div className='w-100 p-grid p-m-0' style={{ minHeight: '450px' }}>
                <div className='col-sm-4 col-md-3 col-lg-2 mb-4 m-sm-0 p-0'>
                    <ListBox
                        className='border-radius-0 m-0 p-0 custom-listbox h-100'
                        onChange={(e) => { e.value && setCurrentStep(e.value) }}
                        options={stepOptions}
                        value={currentStep}
                    />
                </div>
                <div className='bg-grey border-radius-0 px-0 col-sm-8 col-md-9 col-lg-10 pt-4'>
                    <GeneralDataStep
                        APO_order={APO_order}
                        bkt_order_id={bkt_order_id}
                        calculateAmount={calculateAmount}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        customerNote={customerNote}
                        date={date}
                        discountDetail={discountDetail}
                        generalDataForm={generalDataForm}
                        handleInput={handleInput}
                        handleNumberInputBlur={handleNumberInputBlur}
                        handleSelectChange={handleSelectChange}
                        isMakingAQuery={isMakingAQuery}
                        orderDiscount={orderDiscount}
                        orderNote={orderNote}
                        orderSourceOptions={orderSourceOptions}
                        orderStatusOptions={orderStatusOptions}
                        orderTypeOptions={orderTypeOptions}
                        selectedOrderSource={selectedOrderSource}
                        // selectedOrderStatus={selectedOrderStatus}
                        selectedOrderType={selectedOrderType}
                        totalField={totalField}
                    />
                    <CustomerDataStep
                        _isMounted={_isMounted}
                        APO_order={APO_order}
                        currentStep={currentStep}

                        cAddress={cAddress}
                        cCell={cCell}
                        cCity={cCity}
                        cCountry={cCountry}
                        cEmail={cEmail}
                        changeValue={changeValue}
                        cityOptions={cityOptions}
                        cName={cName}
                        countryOptions={countryOptions}
                        cPostcode={cPostcode}
                        cState={cState}
                        cStateOptions={cStateOptions}
                        customerDataForm={customerDataForm}
                        customer_id={customer_id}
                        handleInput={handleInput}
                        handleSelectChange={handleSelectChange}
                        isMakingAQuery={isMakingAQuery}
                        setState={setState}
                    />
                    {
                        isMakingAQuery ?
                            <QueryDataStep
                                APO_order={APO_order}
                                currentStep={currentStep}
                                handleInput={handleInput}
                                queryDataForm={queryDataForm}
                                queryDetail={queryDetail}
                                queryProduct={queryProduct}
                                queryStatus={queryStatus}
                            />
                            :
                            <ProductsDataStep
                                _isMounted={_isMounted}
                                APO_order={APO_order}
                                bkt_order_id={bkt_order_id}
                                changeValue={changeValue}
                                currentStep={currentStep}
                                customerStatus={customerStatus}
                                defaultStatus={defaultStatus}
                                extraAdded={extraAdded}
                                handleCheckboxChange={handleCheckboxChange}
                                handleInput={handleInput}
                                handleNumberInputBlur={handleNumberInputBlur}
                                handleSelectChange={handleSelectChange}
                                itemDiscount={itemDiscount}
                                itemDiscountDetail={itemDiscountDetail}
                                itemStatusOptions={itemStatusOptions}
                                orderDate={date}
                                orderId={orderId}
                                orderItems={orderItems}
                                orderProductOptions={orderProductOptions}
                                productsDataForm={productsDataForm}
                                rate={rate}
                                qty={qty}
                                remarks={remarks}
                                selectedItemStatus={selectedItemStatus}
                                selectedProduct={selectedProduct}
                                selectedVariation={selectedVariation}
                                setState={setState}
                                updatingOrderItems={updatingOrderItems}
                            />
                    }
                    <ShippingDataStep
                        APO_order={APO_order}
                        courierOptions={courierOptions}
                        currentStep={currentStep}

                        cityOptions={cityOptions}
                        countryOptions={countryOptions}
                        dAddress={dAddress}
                        dAmount={dAmount}
                        dCell={dCell}
                        dCity={dCity}
                        dCountry={dCountry}
                        dName={dName}
                        dPostcode={dPostcode}
                        dState={dState}
                        dStateOptions={dStateOptions}
                        handleCheckboxChange={handleCheckboxChange}
                        handleNumberInputBlur={handleNumberInputBlur}
                        handleInput={handleInput}
                        handleSelectChange={handleSelectChange}
                        shippingSameAsBilling={shippingSameAsBilling}
                        selectedCourier={selectedCourier}
                        shippingDataForm={shippingDataForm}
                        shippingDate={shippingDate}
                        shippingMethod={shippingMethod}
                        trackingId={trackingId}
                    />
                    <PaymentsDataStep
                        APO_order={APO_order}
                        currentStep={currentStep}
                        handleInput={handleInput}
                        handleSelectChange={handleSelectChange}
                        paymentsDataForm={paymentsDataForm}
                        paymentMethodOptions={paymentMethodOptions}
                        selectedPaymentMethod={selectedPaymentMethod}
                        trId={trId}
                        trDate={trDate}
                        trAmount={trAmount}
                        trStatus={trStatus}
                    />
                </div>
            </div>
            {currentStep !== 1 &&
                <>
                    <div className='p-grid p-mt-2 p-pt-3 bg-tertiary p-mx-0' style={{ lineHeight: '30px' }}>
                        <div className='p-col-12 p-md-4 p-py-0 p-px-4'>
                            <CustomInput
                                label={`Order discount`}
                                name={`orderNote`}
                                value={orderDiscount}
                                disabled
                                className='p-pl-3'
                                marginBottom={3}
                            />
                        </div>
                        <div className='p-col-12 p-md-4 p-py-0 p-px-4'>
                            <CustomInput
                                label={`Total discount`}
                                name={`totalDiscount`}
                                value={calculateAmount('totalDiscount')}
                                disabled
                                className='p-pl-3'
                                marginBottom={3}
                            />
                        </div>
                        <div className='p-col-12 p-md-4 p-py-0 p-px-4'>
                            <CustomInput
                                label={`Total amount`}
                                name={`totalAmount`}
                                value={calculateAmount('totalAmount')}
                                disabled
                                className='p-pl-3'
                                marginBottom={3}
                            />
                        </div>
                    </div>
                    <div className='p-d-flex p-flex-column p-justify-around color-primary-light p-px-4 bg-tertiary p-text-bold'>
                        <CustomInput
                            type={'textarea'}
                            label={`Order note`}
                            name={`orderNote`}
                            value={orderNote}
                            rows={1}
                            disabled
                            marginBottom={1}
                        />
                        <CustomInput
                            type={'textarea'}
                            label={`Customer note`}
                            name={`customerNote`}
                            value={customerNote}
                            rows={1}
                            disabled
                            marginBottom={1}
                        />
                    </div>
                </>
            }
        </>
    )
}
