import React, { useState, useEffect } from 'react';
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact'
import { ActionButton } from '../../misc/components/Inputs'
import { useHistory } from 'react-router-dom'

const WelcomeSellerModal = (props) => {

    const [modalShow, setModalShow] = useState(false)
    const { seller } = props
    let history = useHistory()

    useEffect(() => {
        setModalShow(props.modalShow)
    }, [props.modalShow])

    const handleClick = () => {
        setModalShow(false);
        history.push('/login')
    }


    return (
        <MDBModal
            size="md"
            centered
            backdrop={true}
            // isOpen={true}
            isOpen={modalShow}
            toggle={() => { }}
        >
            <MDBModalHeader className='text-center bg-Primary color-tertiary'>
                Welcome {seller?.name}&nbsp;!
                </MDBModalHeader>
            <MDBModalBody className="text-center">
                <h6> You have been registered successfully as a
                    <b className='color-Primary'>
                        {' Seller'}
                    </b>
                    {'.'}
                    <br />
                    {'Your'}
                    <b className='color-Primary'>
                        {' Seller-Id'}
                    </b>
                    {' is'}
                </h6>
                <h3>
                    <strong>
                        <b className='color-Primary m-0 p-0'>
                            {seller?.seller_id}
                        </b>
                    </strong>
                </h3>
                {seller.plan !== 'Basic' &&
                    <section className='pl-2'>
                        <h6 className='text-left'>
                            {'You choose '}
                            <b className='color-Primary'>
                                {`${seller?.plan?.title}-Pricing-Plan `}
                            </b>
                            {`. As soon as you will pay amount, your seller-account will be further processed otherwise, it will be on-hold.`}<br />
                        </h6>
                        <section className="text-left">
                            {`You can deposit in following accounts,`}
                            <h6 className='color-primary font-weight-bold'>{'JazzCash: '}
                                <small className='text-muted'>0306 5619198</small><br />
                            </h6>
                            <h6 className='color-primary font-weight-bold'>{'Meezan bank: '}
                                <small className='text-muted'>0268 0103 287 165</small><br />
                            </h6>
                        </section>
                    </section>
                }
                <section className='text-left'>
                    <p className='small mb-0'><b className='color-Primary pl-2'>Note:</b></p>
                    <ul className='m-0 py-0'>
                        <li>
                            <p className='small mb-0'>
                                Please save your seller-id (mentioned above {seller?.seller_id}) as you will be needing this as reference for future communications.
                            </p>
                        </li>
                        {seller.plan !== 'Basic' &&
                            <li>
                                <p className='small mb-0'>
                                    Please call us at +92 306 5619198 after depositing the amount.
                            </p>
                            </li>
                        }
                    </ul>
                </section>
            </MDBModalBody>
            <MDBModalFooter className='justify-content-center'>
                <ActionButton
                    onClick={handleClick}
                    value="Ok"
                    className="bg-Primary w-25"
                    type="button"
                />
            </MDBModalFooter>
        </MDBModal >
    )
}


export default WelcomeSellerModal