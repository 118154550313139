import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Can, AbilityContext } from "../../../configs/Ability-context";
import { ViewOrderModal } from "./ViewOrderModal";
// import { EditOrderModal } from './EditOrderModal'
// import { NewOrderModal } from './NewOrder'
import getOptions from "../../misc/components/Options";
import { CustomListing } from "../../misc/components/CustomListing";
import StatusAfterEffectsModal from "./sections/StatusAfterEffectsModal";
import { CustomDatePicker } from "../../misc/components/CustomDatePicker";
import CustomSelect from "../../misc/components/CustomSelect";
import { CustomButton } from "../../misc/components/Inputs";
import {
  animateSyncIcon,
  formatDate,
  getJsonData,
  isNextOrderStatusAllowed,
} from "../../misc/components/Helpers";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import {
  getOrdersMetaToSync,
  getOrdersWithData,
  getSellerOrdersWithData,
  syncOrders,
  updateOrdersFields,
} from "../../../services/orders/order-service";
import { getOrderStatuses } from "../../../services/orders/status-service";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ViewSellerOrderDetailsModal } from "./ViewSellerOrderDetailsModal";
import { UserContext } from "../../../contexts/UserContext";

let _isMounted;
const AllSellerOrders = () => {
  let dt = useRef(null);
  let toast = useRef(null);
  let history = useHistory();
  const ability = useContext(AbilityContext);
  const {
    isSellerLoggedIn,
    // isSellerManagerLoggedIn,
    // emailVerified,
    // accountApproved,
  } = useContext(UserContext);

  const [
    {
      allOrders,
      allOrderStatuses,
      isReadingOrder,
      isUpdatingOrder,
      isCreatingOrder,
      selectedOrder,
      selectedRecords,
      refreshData,
      isLoading,
      isSyncing,
      syncedPercentage,
      syncedRecords,
      totalRecords,
      isStatusAfterEffects,
      updatedStatus,
      selectedOrderDate,
      selectedShippingDate,
      selectedReturnDate,
      //  selectedType,
      selectedStatus,
      selectedCourier,
      selectedBulkOption,
      selectedBulkValueOption,
      editorText,
      activeEditor,
    },
    setState,
  ] = useState({
    allOrders: [],
    allOrderStatuses: [],
    isReadingOrder: false,
    isUpdatingOrder: false,
    isCreatingOrder: false,
    selectedOrder: "",
    selectedRecords: [],
    refreshData: false,
    isLoading: false,
    isSlipsPrinting: false,
    isSyncing: false,
    syncedRecords: 0,
    syncedPercentage: 0,
    isStatusAfterEffects: false,
    updatedStatus: null,
    selectedOrderDate: null,
    selectedShippingDate: null,
    selectedReturnDate: null,
    selectedStatus: null,
    selectedCourier: null,
    selectedBulkOption: null,
    selectedBulkValueOption: null,
    editorText: "",
    activeEditor: { field: "", id: "" },
  });
  const [isSellerDetailsShowing, setIsSellerDetailsShowing] = useState(false);
  let orderStatusOptions = getOptions(
    "orderStatuses",
    allOrderStatuses.filter((s) => s.usable_at_order || s.changeable_at_order)
  );
  const courierOptions = getOptions("couriers");
  // const sellerId = localStorage.getItem("ui");
  useEffect(() => {
    _isMounted = true;
    const fetchInitialData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getSellerOrdersWithData(100, 0);
        fetchData();
        changeValue("allOrders", data);
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    const fetchData = async () => {
      try {
        const { data } = await getSellerOrdersWithData(0, 100);
        _isMounted &&
          setState((prevState) => ({
            ...prevState,
            allOrders: [...prevState.allOrders, ...data],
          }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchInitialData();
    return () => {
      _isMounted = false;
    };
  }, [refreshData]);
  useEffect(() => {
    const fetchMeta = async () => {
      try {
        const { data } = await getOrderStatuses();
        const dataWithJson = await getJsonData(data, ["input_needed"]);
        changeValue("allOrderStatuses", dataWithJson);
      } catch (err) {
        console.log(err);
      }
    };
    fetchMeta();
  }, []);
  useEffect(() => {
    const getRule = (status) => {
      return `.bg-${status.slug}{
                background-color:#${
                  status.color ? status.color : "ffffff"
                } !important;
            }`;
    };
    var style = document.createElement("style");
    for (let i = 0; i < allOrderStatuses.length; i++) {
      let status = allOrderStatuses[i];
      style.innerHTML = `${style.innerHTML}${getRule(status)}`;
    }
    // console.log(style)
    document.body.appendChild(style);
  }, [allOrderStatuses]);

  const changeValue = (name, value) => {
    _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleView = (orderId) => {
    _isMounted &&
      setState((prevState) => ({
        ...prevState,
        selectedOrder: orderId,
        isReadingOrder: true,
      }));
  };

  const toggleStatusAfterEffectsModal = ({ modalShow, _status, order }) => {
    _isMounted &&
      setState((prevState) => ({
        ...prevState,
        updatedStatus: _status,
        selectedOrder: order,
        isStatusAfterEffects: modalShow,
      }));
  };
  const handleChangeStatus = (order, nextStatus, previousStatus) => {
    if (isNextOrderStatusAllowed(nextStatus, previousStatus)) {
      let _status = allOrderStatuses.find((s) => s.slug === nextStatus);
      // console.log(order)
      if (_status.question_message || _status.input_needed) {
        toggleStatusAfterEffectsModal({ modalShow: true, _status, order });
      } else {
        // handleUpdateField(order, "status", nextStatus);
      }
    } else
      toast.current.show({
        severity: "warn",
        summary: `You can't go to this status.`,
      });
  };

  const handleSync = async (e) => {
    animateSyncIcon("start", e);
    changeValue("isSyncing", true);
    // fetching total orders and pages
    try {
      const { success, metaSynced, orders, offset, startingPage, pages } =
        await getOrdersMetaToSync();
      if (success) {
        if (metaSynced) {
          let _totalRecords = orders;
          if (Number(_totalRecords) > 0) {
            //already synced
            if (_totalRecords == offset) {
              animateSyncIcon("stop", e);
              toast.current.show({
                severity: "info",
                summary: `Orders already synced.`,
              });
              changeValue("isSyncing", false);
              return;
            }
            changeValue("totalRecords", _totalRecords);
            for (let pg = startingPage; pg <= pages; pg++) {
              // fetching new records and syncing
              try {
                const { data } = await syncOrders(pg);
                //separating new products
                if (data) {
                  let dataComing = data.filter((record) => record.success);
                  dataComing = dataComing.map((d) => d.data);
                  const idsOfComing = dataComing.map((o) => o.id);
                  const idsOfAvailable = allOrders.map((o) => o.id);
                  const newIds = idsOfComing.filter(
                    (id) => !idsOfAvailable.includes(id)
                  );
                  const newOrders = dataComing.filter((o) =>
                    newIds.includes(o.id)
                  );
                  _isMounted &&
                    setState((prevState) => ({
                      ...prevState,
                      syncedPercentage:
                        ((prevState.allOrders.length + newOrders.length) /
                          _totalRecords) *
                        100,
                      syncedRecords:
                        prevState.allOrders.length + newOrders.length,
                      allOrders: [...newOrders, ...prevState.allOrders],
                    }));
                }
              } catch (err) {
                console.log(err);
                // stoping animation of sync icon
                animateSyncIcon("error", e);
                setTimeout(() => {
                  changeValue("isSyncing", false);
                }, 500);
                toast.current.show({
                  severity: "error",
                  summary: "Sorry",
                  detail: `something went wrong.`,
                });
              }
            }
          } else {
            animateSyncIcon("stop", e); //No order to sync
            setTimeout(() => {
              changeValue("isSyncing", false);
            }, 500);
            toast.current.show({
              severity: "info",
              summary: "Sorry",
              detail: `There is no order on your store.`,
              life: 3000,
            });
          }

          //stoping animation of sync icon
          animateSyncIcon("stop", e); //synced successfully
          setTimeout(() => {
            _isMounted &&
              setState((prevState) => ({
                ...prevState,
                isSyncing: false,
                syncedPercentage: 0,
                syncedRecords: 0,
                totalRecords: undefined,
              }));
          }, 1000);
        } else {
          animateSyncIcon("error", e);
          setTimeout(() => {
            changeValue("isSyncing", false);
          }, 500);
          toast.current.show({
            severity: "warn",
            summary: "Before you proceed",
            detail: `please sync products.`,
          });
        }
      } else {
        animateSyncIcon("error", e);
        setTimeout(() => {
          changeValue("isSyncing", false);
        }, 500);
        toast.current.show({
          severity: "error",
          summary: "Sorry",
          detail: `something went wrong.`,
        });
      }
    } catch (err) {
      console.log(err);
      // stoping animation of sync icon
      animateSyncIcon("error", e);
      setTimeout(() => {
        changeValue("isSyncing", false);
      }, 500);
      toast.current.show({
        severity: "error",
        summary: "Sorry",
        detail: `something went wrong.`,
      });
    }
  };

  const onDateChange = (value, field, stateVariable) => {
    dt.current.filter(value, field, "custom");
    changeValue(stateVariable, value);
  };
  const setEditor = (field, id) => (e) => {
    _isMounted &&
      setState((prevState) => ({ ...prevState, activeEditor: { field, id } }));
  };
  const handleChange = () => {};
  const handleInput = (e) => {
    let { name, value } = e.target;
    changeValue(name, value);
  };
  const handleInputFocus = (value) => (e) => {
    changeValue("editorText", value);
  };

  const dateFilter = (value, field, stateVariable, placeholder) => {
    return (
      <CustomDatePicker
        selectedValue={value}
        handleChange={(value) => {
          onDateChange(value, field, stateVariable);
        }}
        placeholder={placeholder}
        isClearable
      />
    );
  };
  const simpleFilter = (
    value,
    options,
    field,
    filterMatchMode,
    stateVariable,
    placeholder
  ) => {
    return (
      <Dropdown
        value={value}
        options={options}
        onChange={(e) => {
          dt.current.filter(e.value, field, filterMatchMode);
          changeValue(stateVariable, e.value);
        }}
        placeholder={placeholder}
        className="p-column-filter"
        showClear
        filter
      />
    );
  };
  const inlineEditor = (field, rowData, type) => {
    const editor = (
      <Inplace
        closable
        className="custom-inline-editor"
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id)}
        // onToggle={handleChange}
        onClose={() => {
          // handleUpdateField(rowData, field, editorText);
        }}
      >
        <InplaceDisplay>{rowData[field]}</InplaceDisplay>
        <InplaceContent>
          {/* {type === 'date' ?
                    <CustomDatePicker
                        panelClassName='bg-tertiary'
                        autoFocus
                        name='editorText'
                        selectedValue={editorText || ''}
                        handleChange={(value) => handleInput({ target: { name: 'editorText', value } })}
                        onFocus={handleInputFocus(rowData[field])}
                    />
                    : */}
          <InputText
            type={type}
            autoFocus
            keyfilter={type === "number" ? "pnum" : null}
            name="editorText"
            value={editorText || ""}
            onInput={handleInput}
            onFocus={handleInputFocus(rowData[field])}
          />
          {/* } */}
        </InplaceContent>
      </Inplace>
    );
    const readOnly = <span>{rowData[field]}</span>;
    return ability.can("update", "orders") ? editor : readOnly;
  };
  let columns = [
    {
      field: "date",
      header: "Order Date",
      body: (rowData) => {
        return dateTemplate("date", rowData);
      },
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterElement: dateFilter(
        selectedOrderDate,
        "date",
        "selectedOrderDate",
        "Select order date"
      ),
      filterFunction: "filterDate",
      headerStyle: { width: "120px" },
    },
    // {
    //     field: "type",
    //     header: "Type",
    //     sortable: true,
    //     headerStyle: { width: '80px' }
    // },
    {
      field: "status",
      header: "Order Status",
      body: (rowData) => {
        let _status = allOrderStatuses.find((o) => o.slug === rowData.status);
        let options = orderStatusOptions?.map((option) => ({
          ...option,
          disabled:
            option.entity.changeable_at_order && !option.entity.usable_at_order,
        }));
        return ability.cannot("update", "orders") ||
          !_status?.changeable_at_order ? (
          <>
            <span className="p-column-title">Order status</span>
            {_status?.title}
          </>
        ) : (
          <CustomSelect
            options={options}
            value={rowData.status}
            handleChange={(e) => {
              handleChangeStatus(rowData, e.value, rowData.status);
            }}
            filter
            resetFilterOnHide
          />
        );
      },
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterElement: simpleFilter(
        selectedStatus,
        orderStatusOptions,
        "status",
        "equals",
        "selectedStatus",
        "Select a status"
      ),
      headerStyle: { width: "150px" },
    },
    // {
    //     field: "shipping_date",
    //     header: "Shipping Date",
    //     body: (rowData) => { return dateTemplate('shipping_date', rowData) },
    //     // body: (rowData) => { return inlineEditor('shipping_date', rowData, 'date') },
    //     sortable: true,
    //     filter: true,
    //     filterElement: dateFilter(selectedShippingDate, 'shipping_date', 'selectedShippingDate', "Select shipping date"),
    //     filterFunction: 'filterDate',
    //     headerStyle: { width: '120px' }
    // },
    // {
    //     field: "courier",
    //     header: "Courier",
    //     body: (rowData) => {
    //         return ability.can('update', 'orders') ?
    //             <CustomSelect
    //                 options={courierOptions}
    //                 value={rowData.courier}
    //                 handleChange={(e) => {
    //                     handleUpdateField(rowData, 'courier', e.value)
    //                 }}
    //             />
    //             :
    //             <>
    //                 <span className="p-column-title">Courier</span>
    //                 {courierOptions.find(o => o.value === rowData.courier)?.label}
    //             </>
    //     },
    //     // body: (rowData) => { return <>{courierOptions.find(o => o.value === rowData.courier)?.label}</> },
    //     sortable: true,
    //     filter: true,
    //     filterElement: simpleFilter(selectedCourier, courierOptions, 'courier', 'equals', 'selectedCourier', 'Select courier'),
    //     headerStyle: { width: '140px' }
    // },
    // {
    //     field: "shipping_amount",
    //     header: "DC",
    //     body: (rowData) => { return inlineEditor('shipping_amount', rowData, 'number') },
    //     sortable: true,
    //     headerStyle: { width: '80px' }
    // },
    // {
    //     field: "tr_amount",
    //     header: "Advance Paid",
    //     // body: (rowData) => { return inlineEditor('tr_amount', rowData, 'number') },
    //     sortable: true,
    //     filter: true,
    //     filterPlaceholder: "Search by advance paid",
    //     filterMatchMode: "equals",
    //     selectedByDefault: true,
    //     headerStyle: { width: '110px' }
    // },
    {
      field: "total",
      header: "Amount",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Amount",
      filterMatchMode: "equals",
      selectedByDefault: true,
      headerStyle: { width: "100px" },
    },
    {
      field: "customer.name",
      header: "Customer",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by customer",
      filterMatchMode: "contains",
      selectedByDefault: true,
      headerStyle: { width: "140px" },
    },
    // {
    //     field: "customer.cell",
    //     header: "Contact",
    //     filter: true,
    //     filterPlaceholder: "Search by phone",
    //     filterMatchMode: "contains",
    //     headerStyle: { width: '140px' }
    // },
    // {
    //     field: "order_note",
    //     header: "Order Note",
    //     body: (rowData) => { return inlineEditor('order_note', rowData) },
    //     filter: true,
    //     filterPlaceholder: "Search by order note",
    //     filterMatchMode: "contains",
    //     headerStyle: { width: '220px' }
    // },
    // {
    //     field: "return_reason",
    //     header: "Return reason",
    //     body: (rowData) => { return inlineEditor('return_reason', rowData) },
    //     headerStyle: { width: '250px' }
    // },
    // {
    //     field: "return_date",
    //     header: "Return date",
    //     body: (rowData) => { return dateTemplate('return_date', rowData) },
    //     sortable: true,
    //     filter: true,
    //     filterElement: dateFilter(selectedReturnDate, 'return_date', 'selectedReturnDate', "Select return date"),
    //     filterFunction: 'filterDate',
    //     headerStyle: { width: '120px' }
    // }
  ];

  return (
    <>
      {/* <Can I='create' a={'orders'}>
                <NewOrderModal
                    modalShow={isCreatingOrder}
                    allOrderStatuses={allOrderStatuses}
                    hideModal={() => { changeValue('isCreatingOrder', false) }}
                    // refreshData={() => { changeValue('refreshData', !refreshData) }}
                    refreshData={() => { }}
                />
            </Can> */}
      {/* <Can I='update' a={'orders'}>
                <EditOrderModal
                    allOrderStatuses={allOrderStatuses}
                    modalShow={isUpdatingOrder}
                    orderId={selectedOrder}
                    hideModal={() => {
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            isUpdatingOrder: false,
                            selectedOrder: null
                        }))
                    }}
                    refreshData={() => { }}
                />
            </Can> */}
      <Can I="read" a={"sellerOrders"}>
        <ViewSellerOrderDetailsModal
          modalShow={isReadingOrder}
          allOrderStatuses={allOrderStatuses}
          orderId={selectedOrder}
          hideModal={() => {
            _isMounted &&
              setState((prevState) => ({
                ...prevState,
                isReadingOrder: false,
                selectedOrder: null,
              }));
          }}
        />
        {/* <ViewOrderModal
          allOrderStatuses={allOrderStatuses}
          modalShow={isReadingOrder}
          orderId={selectedOrder}
          hideModal={() => {
            _isMounted &&
              setState((prevState) => ({
                ...prevState,
                isReadingOrder: false,
                selectedOrder: null,
              }));
          }}
        /> */}
        <CustomListing
          allRecords={allOrders}
          heading={isSellerLoggedIn ? "My Orders" : "All Seller Orders"}
          firstColumn={
            <Column
              field="bkt_order_id"
              header="Order Id"
              body={(rowData) => idTemplate(rowData, handleView)}
              sortable
              filter
              filterPlaceholder="Search by order id"
              filterMatchMode="contains"
              headerStyle={{ width: "90px" }}
            />
          }
          columns={columns}
          permissionSubject={"orders"}
          isLoading={isLoading}
          isSyncing={isSyncing}
          totalRecords={totalRecords}
          syncedRecords={syncedRecords}
          syncedPercentage={syncedPercentage}
          // handleCreate={handleCreate}
          handleSync={handleSync}
          // handleView={true}
          // customViewButton={(customButton, rowData) => {
          //   return rowData.href ? (
          //     <a
          //       href={rowData.href}
          //       target="_blank"
          //       title="edit order on store"
          //     >
          //       {customButton("fas fa-share color-primary")}
          //     </a>
          //   ) : null;
          // }}
          // handleEdit={handleEdit}
          editDataKey={"id"}
          handleRefresh={() => changeValue("refreshData", !refreshData)}
          // extraToolbarButton={printSlipsButton(history)}
          selectedRecords={selectedRecords}
          selectionDataKey={"id"}
          selectedBulkOption={selectedBulkOption}
          selectedBulkValueOption={selectedBulkValueOption}
          // bulkOptions={bulkOptions}
          // bulkValueOptions={bulkValueOptions()}
          // handleBulkAction={handleBulkAction}
          changeValue={changeValue}
          rowClassName={(rowData) => {
            // let orderStatus = allOrderStatuses.find(s => s.id === rowData.status_id)
            // if (orderStatus) {
            return {
              [`bg-${rowData.status}`]: true,
            };
            // }
          }}
          tableRef={dt}
          toastRef={toast}
          timeStamps={true}
        />
      </Can>

      <StatusAfterEffectsModal
        modalShow={isStatusAfterEffects}
        status={updatedStatus}
        handleResponse={({ isConfirmed, input1, input2, input3 }) => {
          if (isConfirmed) {
            if (updatedStatus.input_needed) {
              let updatedOrder;
              switch (updatedStatus.input_needed[0]) {
                case "Tr. date": //refund_transaction
                  updatedOrder = {
                    status: updatedStatus.slug,
                    refund_tr_date: input1,
                    refund_tr_id: input2,
                    refund_tr_amount: input3,
                  };
                  break;
                case "Schedule list # (SL123-1)": //schedule list-Id and courier
                  updatedOrder = {
                    status: updatedStatus.slug,
                    tracking_id: input1,
                    courier: input2,
                  };
                  break;
                case "CN #": //tracking-Id and courier
                  updatedOrder = {
                    status: updatedStatus.slug,
                    tracking_id: input1,
                    courier: input2,
                  };
                  break;
                case "Return date": //return date aND REASON
                  updatedOrder = {
                    status: updatedStatus.slug,
                    return_date: input1,
                    return_reason: input2,
                  };
                  break;
                case "Hold till": //remarks hold period and reason
                  updatedOrder = {
                    status: updatedStatus.slug,
                    order_note: `Hold till: ${new Date(
                      input1
                    ).toDateString()}, Reason: ${input2}`,
                  };
                  break;
                case "Cancel reason": //cancel reason
                  updatedOrder = {
                    status: updatedStatus.slug,
                    cancel_reason: input1,
                  };
                  break;
                default:
                  break;
              }
              // handleUpdateOrderDetails(selectedOrder, updatedOrder);
            } else {
              // handleUpdateField(selectedOrder, "status", updatedStatus.slug);
            }
          }
          toggleStatusAfterEffectsModal({
            modalShow: false,
            _status: null,
            order: null,
          });
        }}
      />
    </>
  );
};

const idTemplate = (rowData, handleView) => {
  return (
    <>
      <span className="p-column-title">Order id</span>
      <CustomButton
        label={rowData.bkt_order_id}
        variant="text-button"
        color="primary-light"
        onClick={() => {
          handleView(rowData.id);
        }}
        title={"view order"}
      />
    </>
  );
};
const printSlipsButton = (history) => {
  return (
    <Can I={"printSlips"} a={"orders"}>
      <CustomButton
        label={"Print local slips"}
        icon={"pi pi-print"}
        isRounded
        variant={"with-icon"}
        color="tertiary"
        bg="Secondary"
        className="m-2"
        onClick={() => history.push("/orders/slips/local")}
      />
    </Can>
  );
};
const dateTemplate = (field, rowData) => {
  let date = rowData[field] ? formatDate(new Date(rowData[field])) : "";
  return <>{date}</>;
};

export default AllSellerOrders;
