import React, { useRef, useState } from "react";
import { CustomInput, CustomButton } from '../../misc/components/Inputs'
import LoaderModal from '../../misc/components/LoaderModal'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { verifyResetPasswordToekn } from "../../../services/users/user-service";

const ResetPassword = () => {
    const [email, setemail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [validationMessage, setValidationMessage] = useState('')
    const resetPasswordForm = useRef(null);
    const emailField = useRef(null);
    let toast = useRef(null)


    const handleInput = (e) => {
        let { name, value } = e.target
        setemail(value)
        setValidationMessage('')
    }

    const validate = (e) => {
        e.preventDefault();
        if (!resetPasswordForm.current.checkValidity()) {
            resetPasswordForm.current.classList.add('was-validated');
            return
        }
        else {
            setIsLoading(true)
            handleResetPassword();
        }
    }

    const handleResetPassword = () => {
        let options = {
            method: 'POST',
            body: JSON.stringify({ email: email }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetch('/api/auth/resetpassword', options)
            .then((res) => res.json())
            .then((json) => {
                // console.log(json);
                let { success, message } = json
                // setValidationMessage(message)
                // message === `email doesn't exist` && emailField.current.focus();
                toast.current.show({ severity: success ? 'success' : 'warn', summary: success ? 'Success' : 'Sorry', detail: message });

            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
        <div className='screen-center h-50vh'>
            <img
                src="/logo.jpg"
                // width={'12%'}
                className='p-m-0 p-p-0 form-box-logo'
                style={{ cursor: 'pointer' }}
                onClick={() => { window.open('/home', '_self') }}
            />
            <form
                ref={resetPasswordForm}
                // className='p-m-0 p-px-4 p-p-sm-0 w-100 w-sm-75 w-md-50 w-lg-25 p-d-flex p-flex-column'
                className='form-box-rounded p-d-flex p-flex-column shadow p-3 pt-5 pb-5'
                onSubmit={validate}
                noValidate
            >
                {/* <h5 className='p-text-bold text-center color-secondary p-mb-3 p-mb-sm-4'>Reset Password</h5> */}
                <h5 className='p-text-bold color-secondary p-mb-3 p-mb-sm-4'>Reset Password</h5>
                <CustomInput
                    label='Email'
                    name={`email`}
                    value={email}
                    handleInput={handleInput}
                    ref={emailField}
                    required
                />
               
                <CustomButton
                    label="Send"
                    bg="Secondary"
                    color='tertiary'
                    type="submit"
                    className='w-100 p-ml-auto p-button-rounded p-button-raised'
                />
                 <small>
                    <Link to='/login'>Back to Login</Link>
                </small>
                <label className='p-mt-4 p-p-0 p-text-center validating-label'>{validationMessage}&nbsp;</label>
            </form>
            <LoaderModal
                modalShow={isLoading}
            />
            <Toast ref={toast} className='p-mt-5 p-mr-3' />
        </div>
        </>
    )
}

const NewPasswordForm = () => {
    const [newPassword, setnewPassword] = useState('')
    const newPasswordForm = useRef(null);
    const newPasswordField = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation();
    const user_id = location.state?.user_id
    let toast = useRef(null)
    let history = useHistory()

    const handleInput = (e) => {
        let { name, value } = e.target
        setnewPassword(value)
    }

    const validate = (e) => {
        e.preventDefault();
        // if (!newPasswordForm.current.checkValidity()) {
        //     newPasswordForm.current.classList.add('was-validated');
        //     return
        // }
        // else {
        setIsLoading(true)
        saveNewPassword();
        // }
    }

    const saveNewPassword = () => {


        let options = {
            method: 'POST',
            body: JSON.stringify({ password: newPassword, user_id: user_id }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetch('/api/auth/savenewpassword', options)
            .then((res) => res.json())
            .then((json) => {
                let { success, message } = json
                toast.current.show({ severity: success ? 'success' : 'warn', summary: success ? 'Success' : 'Sorry', detail: message });
                history.push('/login');

            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false)
            })
    }
    return (
        <>
            <div className='screen-center h-50vh'>
                <img
                    src="/logo.jpg"
                    // width={'12%'}
                    className='p-m-0 p-p-0 form-box-logo'
                    style={{ cursor: 'pointer' }}
                    onClick={() => { window.open('/home', '_self') }}
                />
                <form
                    ref={newPasswordForm}
                    // className='p-m-0 p-px-4 p-p-sm-0 w-100 w-sm-75 w-md-50 w-lg-25 p-d-flex p-flex-column'
                    className='form-box-rounded p-d-flex p-flex-column shadow p-3 pt-5 pb-5'
                    onSubmit={validate}
                    noValidate
                >
                    {/* <h5 className='p-text-bold text-center color-secondary p-mb-3 p-mb-sm-4'>Reset Password</h5> */}
                    <h5 className='p-text-bold color-secondary p-mb-3 p-mb-sm-4'>Reset Password</h5>
                    <CustomInput
                        label='New Password'
                        name={`password`}
                        value={newPassword}
                        handleInput={handleInput}
                        ref={newPasswordField}
                        required
                        type='password'
                    />

                    {/* <Link to='/login'>Back to Login</Link> */}

                    <CustomButton
                        label="Reset Now"
                        bg="Secondary"
                        color='tertiary'
                        type="submit"
                        // className='w-50 w-sm-25 p-ml-auto'
                        className='w-100 p-ml-auto p-button-rounded p-button-raised'
                    />
                </form>
                <LoaderModal
                    modalShow={isLoading}
                />
                <Toast ref={toast} className='p-mt-5 p-mr-3' />
            </div>
        </>
    )
}

const ProcessResetPassword = () => {
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token');
    let toast = useRef(null)
    let history = useHistory()
    const verifyResetToken = async () => {
        try {
            const { success, message, user_id } = await verifyResetPasswordToekn(token)
            if (success) {
                history.push('/newpassword', { user_id: user_id })
            }
            else {
                toast.current?.show({ severity: 'error', summary: 'Not Verified', detail: message })
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    verifyResetToken();
    return (
        < Toast ref={toast} className='p-mt-5 p-mr-3' />
    )

}
export { ProcessResetPassword, ResetPassword, NewPasswordForm };