import React, { useEffect, useState, useRef, useContext } from 'react'
import LoaderModal from '../../misc/components/LoaderModal'
import { CustomButton } from '../../misc/components/Inputs'
import getOptions from '../../misc/components/Options'
import { Dialog } from 'primereact/dialog'
import { Can } from '../../../configs/Ability-context'
import { ProductForm } from './sections/ProductForm'
import { UserContext } from '../../../contexts/UserContext'

import { getJsonData } from '../../misc/components/Helpers'
import { getProductByBktId, getProducts } from '../../../services/products/product-service'
import { getCategories } from '../../../services/products/category-service'
import { getBrands } from '../../../services/products/brand-service'
import { getAttributeTerms, getAttributesWithData } from '../../../services/products/attribute-service'
import { getTags } from '../../../services/products/tag-service'
import { getCustomUsers, getCustomChilds, getUserPriceRule } from '../../../services/users/user-service';

let eProduct
let _isMounted
const productTypeOptions = getOptions('productTypes'),
    stockStatusOptions = getOptions('stockStatuses'),
    backordersOptions = getOptions('backorders'),
    productStatusOptions = getOptions('productStatuses')
const INITIAL_STATE = {
    remarks: '',
    name: '',
    selectedType: productTypeOptions[0],
    selectedStatus: productStatusOptions[0],

    priceRule: null,
    isCostProvided: false,
    regularPrice: '',
    costPrice: '',
    salePrice: '',
    saleFrom: '',
    saleTo: '',
    scheduleSale: false,

    sku: '',
    sourceURL: '',
    manageStock: false,
    stockQuantity: 1,
    stockStatus: stockStatusOptions[0],
    backorders: backordersOptions[0],
    soldIndividually: false,
    update_threshold: 90,
    weight: '',
    dimensions: { length: '', width: '', height: '' },

    upSells: [],
    crossSells: [],

    description: '',
    shortDescription: '',
    purchaseNote: '',

    attributes: [],
    variations: [],

    selectedCategories: [],
    selectedTags: [],
    selectedBrand: '',
    selectedSeller: '',
    selectedShopManager: '',

    featuredImage: null,
    images: [],
    showImageGallery: false,
    isMultipleImages: true,

    isLoading: false,
}
export const ViewProductModal = (props) => {
    const { isSellerLoggedIn, isShopManagerLoggedIn } = useContext(UserContext)

    const [modalShow, setModalShow] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)
    const [{
        remarks, name, selectedType, selectedStatus,
        priceRule, isCostProvided, regularPrice, costPrice, salePrice, saleFrom, saleTo, scheduleSale,
        sku, sourceURL, manageStock, stockQuantity, stockStatus, backorders, soldIndividually, update_threshold,
        weight, dimensions,
        upSells, crossSells,
        description, shortDescription, purchaseNote,
        attributes, variations,
        selectedCategories, selectedTags,
        selectedBrand, selectedSeller, selectedShopManager,
        featuredImage, images,
        isLoading,
        allProducts, allAttributes, allAttributeTerms,
        allProductCategories, allBrands, allSellers, allShopManagers, existingTags,
    }, setState] = useState({
        ...INITIAL_STATE,
        allAttributes: [], allAttributeTerms: [],
        allProducts: [], allProductCategories: [],
        allBrands: [], allSellers: [], allShopManagers: [], existingTags: [],
    })

    let generalDataForm = useRef(null)
    let attributesDataForm = useRef(null)
    let advancedDataForm = useRef(null)
    let descriptionDataForm = useRef(null)
    let imagesDataForm = useRef(null)
    let inventoryDataForm = useRef(null)
    let linkedDataForm = useRef(null)
    let shippingDataForm = useRef(null)
    let isVariableProduct = selectedType.value === 'variable'

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getProductByBktId(props.productBktId)
                if (data) {
                    let Sellers = !isSellerLoggedIn && !isShopManagerLoggedIn ?
                        await getCustomUsers('seller')
                        :
                        []
                    let allSellers = Array.isArray(Sellers) ? [] : Sellers.data

                    //destructuring data
                    let eproduct = await getJsonData(
                        data,
                        [
                            'attributes', 'categories', 'cross_sell_ids', 'default_attributes', 'dimensions', 'images', 'tags', 'upsell_ids',
                            ['variations', ['attributes', 'dimensions', 'images']]
                        ]
                    )
                    eProduct = eproduct
                    let PriceRule = eproduct.seller_id ?
                        await getUserPriceRule(eproduct.seller_id)
                        :
                        null
                    const selectedSeller = eProduct.product_seller ? { key: eProduct.product_seller.id, value: eProduct.product_seller.id, label: eProduct.product_seller.name } : ''
                    const selectedCategories = eProduct.categories.length > 0 && eProduct.categories.map(category =>
                        ({ key: category.id, value: category.id, label: category.name })),
                        selectedTags = eProduct.tags.length > 0 && eProduct.tags.map(tag =>
                            ({ key: tag.id, value: tag.id, label: tag.name })),
                        selectedUpsells = allProducts.filter(product => eProduct.upsell_ids.includes(product.bkt_id)).map(product => ({
                            key: product.id, value: product.bkt_id, label: product.name
                        })),
                        selectedCrossSells = allProducts.filter(product => eProduct.cross_sell_ids.includes(product.bkt_id)).map(product => ({
                            key: product.id, value: product.bkt_id, label: product.name
                        })),
                        attributes = await eProduct.attributes.length ?
                            eProduct.attributes
                                .map(attr => {
                                    attr.options = attr.options.map((option, index) => ({
                                        key: allAttributeTerms.find(term => term.name === option)?.bkt_id,
                                        label: option,
                                        value: allAttributeTerms.find(term => term.name === option)?.bkt_id
                                    }))
                                    return attr
                                })
                            :
                            [],

                        variations = await eProduct.variations.length ?
                            eProduct.variations
                                .map(variation => {
                                    let variationAttributes = variation.attributes
                                    return ({
                                        id: variation.id,
                                        bkt_id: variation.bkt_id,
                                        parent_id: variation.parent_id,

                                        status: variation.status,

                                        regular_price: variation.regular_price,
                                        cost_price: variation.cost_price,
                                        is_cost_provided: variation.is_cost_provided,
                                        sale_price: variation.sale_price,
                                        scheduleSale: (variation.date_on_sale_from || variation.date_on_sale_to) ? true : false,
                                        date_on_sale_from: variation.date_on_sale_from ? new Date(variation.date_on_sale_from) : null,
                                        date_on_sale_to: variation.date_on_sale_to ? new Date(variation.date_on_sale_to) : null,

                                        sku: variation.sku,
                                        manage_stock: variation.manage_stock,
                                        stock_quantity: variation.stock_quantity,
                                        stock_status: stockStatusOptions.find(option => option.value === variation.stock_status),
                                        backorders: backordersOptions.find(option => option.value === variation.backorders),

                                        weight: variation.weight,
                                        dimensions: variation.dimensions,

                                        description: variation.description,

                                        attributes: variationAttributes.length > 0 ? variationAttributes.map((attr, index) => ({
                                            id: attr.id,
                                            key: attr.id,
                                            label: attr.option,
                                            name: attr.name,
                                            value: {
                                                id: attr.id,
                                                name: attr.name,
                                                option: attr.option
                                            }
                                        })) : [],

                                        image: variation.images
                                    })
                                })
                            :
                            []

                    fetchMeta()
                    eproduct && _isMounted && setState(prevState => ({
                        ...prevState,
                        allSellers: allSellers,
                        selectedSeller: selectedSeller,


                        eProduct: eproduct,
                        remarks: eproduct.remarks,
                        name: eproduct.name,
                        selectedType: productTypeOptions.find(option => option.value === eproduct.type),
                        selectedStatus: productStatusOptions.find(option => option.value === eproduct.status),

                        priceRule: PriceRule,
                        isCostProvided: eProduct.is_cost_provided,
                        regularPrice: eproduct.regular_price,
                        costPrice: eProduct.cost_price,
                        salePrice: eproduct.sale_price,
                        saleFrom: new Date(eproduct.date_on_sale_from),
                        saleTo: new Date(eproduct.date_on_sale_to),
                        scheduleSale: eproduct.date_on_sale_to ? true : false,

                        sku: eproduct.sku,
                        sourceURL: eproduct.source_url,
                        update_threshold: eproduct.update_threshold,
                        manageStock: eproduct.manage_stock,
                        stockQuantity: eproduct.stock_quantity,
                        stockStatus: stockStatusOptions.find(option => option.value === eproduct.stock_status),
                        backorders: backordersOptions.find(option => option.value === eproduct.backorders),
                        soldIndividually: eproduct.sold_individually,

                        weight: eproduct.weight,
                        dimensions: eproduct.dimensions,

                        description: eproduct.description,
                        shortDescription: eproduct.short_description,
                        purchaseNote: eproduct.purchase_note,

                        upSells: selectedUpsells,
                        crossSells: selectedCrossSells,

                        attributes: attributes,
                        variations: variations,

                        selectedCategories: selectedCategories,
                        selectedTags: selectedTags,

                        notSellerProduct: false
                    }))
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }

        const fetchMeta = async () => {
            try {
                const Categories = await getCategories()
                const Attributes = await getAttributesWithData()
                const AttributeTerms = await getAttributeTerms()
                const Brands = await getBrands()
                const Tags = await getTags()
                const ShopManagers = isSellerLoggedIn ? await getCustomChilds(0, 'shop_manager') : isShopManagerLoggedIn ? [] : eProduct.seller_id ?
                    await getCustomChilds(eProduct.seller_id, 'shop_manager') : []

                //destructuring data
                let allProductCategories = Categories.data,
                    allAttributes = Attributes.data,
                    allAttributeTerms = AttributeTerms.data,
                    allBrands = Brands.data,
                    existingTags = Tags.data,
                    allShopManagers = Array.isArray(ShopManagers) ? [] : ShopManagers.data,

                    selectedBrand = allBrands.find(b => b.bkt_id === eProduct.brand_id)

                _isMounted && setState(prevState => ({
                    ...prevState,

                    allProductCategories: allProductCategories,
                    allAttributes: allAttributes,
                    allAttributeTerms: allAttributeTerms,
                    allBrands: allBrands,
                    existingTags: existingTags,
                    allShopManagers: allShopManagers,

                    // defaultAttributes: [],
                    selectedBrand: selectedBrand ? { key: selectedBrand.id, value: selectedBrand.bkt_id, label: selectedBrand.name } : '',
                    selectedShopManager: eProduct.shop_manager && { key: eProduct.shop_manager.id, value: eProduct.shop_manager.id, label: eProduct.shop_manager.name },

                    featuredImage: eProduct.images[0],
                    images: eProduct.images.length > 1 ? eProduct.images.filter((image, index) => index !== 0) : [],
                }))
                const Products = await getProducts()
                let allProducts = Products.data
                changeValue('allProducts', allProducts)
            }
            catch (error) {
                console.log(error)
            }
        }
        if (props.productBktId) {
            props.modalShow && fetchData()
        }
        setModalShow(props.modalShow)
        setCurrentStep(1)
        return () => {
            _isMounted = false
        }
    }, [props.modalShow])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <Can I={'read'} a='products'>
            <Dialog
                header={<h2 className="m-0 p-0 color-tertiary font-weight-normal">{`View product`}</h2>}
                modal
                onHide={() => { setModalShow(false); setTimeout(() => { props.hideModal() }, 200) }}
                className='mx-4 ml-sm-5 m-lg-0 bg-Primary h-75'
                style={{ width: '75vw' }}
                breakpoints={{ '960px': '100vw', }}
                visible={modalShow}
                contentClassName='h-75'
                footer={
                    <div className='p-grid m-0 p-justify-end'>
                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 pr-4'>
                            <CustomButton
                                label={'Close'}
                                onClick={() => { setModalShow(false); setTimeout(() => { props.hideModal() }, 200) }}
                                bg='Secondary'
                                color='tertiary'
                                className='w-100 my-3'
                            />
                        </div>
                    </div>
                }
            >
                <div className='p-d-flex p-flex-column pt-2 h-100'>
                    <ProductForm
                        //universal
                        _isMounted
                        APO_product={'read'}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        isVariableProduct={isVariableProduct}
                        isSellerLoggedIn={isSellerLoggedIn}
                        isShopManagerLoggedIn={isShopManagerLoggedIn}
                        setCurrentStep={setCurrentStep}
                        setState={setState}
                        //general
                        allSellers={allSellers}
                        costPrice={costPrice}
                        isCostProvided={isCostProvided}
                        name={name}
                        priceRule={priceRule}
                        productStatusOptions={productStatusOptions}
                        productTypeOptions={productTypeOptions}
                        regularPrice={regularPrice}
                        remarks={remarks}
                        saleFrom={saleFrom}
                        salePrice={salePrice}
                        saleTo={saleTo}
                        scheduleSale={scheduleSale}
                        selectedSeller={selectedSeller}
                        selectedStatus={selectedStatus}
                        selectedType={selectedType}
                        //inventory
                        backorders={backorders}
                        backordersOptions={backordersOptions}
                        manageStock={manageStock}
                        sku={sku}
                        soldIndividually={soldIndividually}
                        stockQuantity={stockQuantity}
                        stockStatus={stockStatus}
                        stockStatusOptions={stockStatusOptions}
                        //shipping
                        dimensions={dimensions}
                        weight={weight}
                        //description
                        description={description}
                        shortDescription={shortDescription}
                        //linked-data
                        allBrands={allBrands}
                        allCategories={allProductCategories}
                        allProducts={allProducts}
                        allShopManagers={allShopManagers}
                        crossSells={crossSells}
                        existingTags={existingTags}
                        selectedBrand={selectedBrand}
                        selectedCategories={selectedCategories}
                        selectedShopManager={selectedShopManager}
                        selectedTags={selectedTags}
                        upSells={upSells}
                        //attributes
                        allAttributes={allAttributes}
                        allAttributeTerms={allAttributeTerms}
                        attributes={attributes}
                        variations={variations}
                        //advanced
                        purchaseNote={purchaseNote}
                        sourceURL={sourceURL}
                        update_threshold={update_threshold}
                        //images
                        featuredImage={featuredImage}
                        images={images}
                        showImageGallery={(isMultiple) => { changeValue('showImageGallery', true); changeValue('isMultipleImages', isMultiple) }}
                        //form-refs
                        advancedDataForm={advancedDataForm}
                        attributesDataForm={attributesDataForm}
                        descriptionDataForm={descriptionDataForm}
                        generalDataForm={generalDataForm}
                        imagesDataForm={imagesDataForm}
                        inventoryDataForm={inventoryDataForm}
                        linkedDataForm={linkedDataForm}
                        shippingDataForm={shippingDataForm}
                    />
                </div>
            </Dialog>
            <LoaderModal
                modalShow={isLoading}
            />
        </Can >
    )
}
