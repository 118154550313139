
export const createOrderStatus = async (newOrderStatus) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newOrderStatus }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/statuses`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getOrderStatuses = async () => {
    try {
        const res = await fetch(`/api/orders/statuses`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getOrderStatus = async (id) => {
    try {
        const res = await fetch(`/api/orders/statuses/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateOrderStatus = async (id, updatedOrderStatus) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedOrderStatus }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/statuses/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteOrderStatus = async (id) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/orders/statuses/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}