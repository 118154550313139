import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import LoaderModal from './LoaderModal'
import { CustomInput, CustomButton } from './Inputs'

const INITIAL_STATE = {
    name: '', detail: '', isLoading: false, validationMessage: ''
}

const SellerRequestModal = (props) => {
    let requestForm = useRef(null)

    const [{ name, detail, isLoading, validationMessage }, setState] = useState(INITIAL_STATE)

    const changeValue = (name, value) => {
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!requestForm.current.checkValidity()) {
            requestForm.current.classList.add('was-validated')
            return
        }
        else {
            changeValue('isLoading', true)
            let newRequest = {
                name, detail, entity: props.entity, status: 'Pending Review'
            }
            let options = {
                method: 'POST',
                body: JSON.stringify(newRequest),
                headers: { 'Content-Type': 'application/json' }
            }
            fetch('/api/seller_requests', options)
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    changeValue('validationMessage', json.message)
                    json.success && setTimeout(() => {
                        props.hideModal()
                        setState(INITIAL_STATE)
                    }, 500);
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    changeValue('isLoading', false)
                })
        }
    }

    const nameInput = <CustomInput
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        required
    />
    const detailInput = <CustomInput
        type="textarea"
        rows={2}
        label='Reason'
        placeholder={'Explain why do you want this to be created ?'}
        name={`detail`}
        value={detail}
        handleInput={handleInput}
        required
    />
    const submitBtn = <CustomButton
        label={'Submit'}
        bg='Secondary'
        color='tertiary'
        type='submit'
        className='p-mb-1'
    />
    const validatingLabel = <label className='p-p-0 p-mt-0 validating-label'>{validationMessage}&nbsp;</label>

    return (
        <Dialog
            visible={props.modalShow}
            header={<p className='color-tertiary p-m-0 p-p-0'>{`Request a new ${props.entity}`}</p>}
            modal
            className='p-text-center p-ml-6 p-ml-md-0'
            style={{ width: '40vw' }}
            breakpoints={{ '960px': '60vw', '768px': '85vw', }}
            closable
            onHide={() => { props.hideModal() }}
        >
            <form ref={requestForm} className='p-m-0 p-px-0 p-pt-4 p-text-center color-tertiary' onSubmit={handleSubmit} noValidate>
                <div className='p-m-0 p-p-0 row'>
                    <div className='p-m-0 p-col-12 p-text-right'>
                        {nameInput}
                        {detailInput}
                        {submitBtn}
                    </div>
                    <div className='p-m-0 p-col-12 p-text-right'>
                        {validatingLabel}
                    </div>
                </div>
            </form>
            <LoaderModal modalShow={isLoading} />
        </Dialog>
    )
}


export default SellerRequestModal