import { useState, useEffect } from 'react'
import { Ability } from "@casl/ability";
import { AbilityContext } from "./configs/Ability-context";
import { UserContext, userProps } from './contexts/UserContext'
import { AppContext, appProps } from './contexts/AppContext'
import { BrowserRouter,useLocation } from 'react-router-dom';
import Layout from './components/basic/Layout'
import InitialSystemSettings from './components/basic/InitialSystemSettings'
import LoaderModal from './components/misc/components/LoaderModal'


const INITIAL_STATE = {
  isUsingDemoDB: false,
  isLoggedIn: false,
  emailVerified: false,
  accountApproved: false,
  user: null,
  isLoading: true,
}

const App = () => {

  const UpdateRules = async (user, loggedIn, settings) => {
    let appSettings,
      storeURL = '',
      appURL = '',
      appName = '',
      fbURL = '',
      instaURL = '',
      twitterURL = '',
      youtubeURL = ''
    //updating contexts
    settings && settings.length && settings.forEach(setting => {
      switch (setting.key) {
        case 'store_url':
          storeURL = setting.value
          break;
        case 'app_url':
          appURL = setting.value
          break;
        case 'app_name':
          appName = setting.value
          break;
        case 'fb_url':
          fbURL = setting.value
          break;
        case 'insta_url':
          instaURL = setting.value
          break;
        case 'twitter_url':
          twitterURL = setting.value
          break;
        case 'youtube_url':
          youtubeURL = setting.value
          break;
        default:
          break;
      }
    })
    appSettings = { storeURL, appURL, appName, fbURL, instaURL, twitterURL, youtubeURL }
    let isSellerLoggedIn = user && user.role.name.toLowerCase() === 'seller'
    let isShopManagerLoggedIn = user && user.role.name.toLowerCase() === 'shop_manager'
    let isSellerManagerLoggedIn = user && user.role.name.toLowerCase() === 'seller_manager'
    let isEmailVerified = user && user.email_verified
    let isAccountApproved = user && user.account_status === 'approved' ? true : false
    // console.log("isEmailVerified");
    // console.log(isEmailVerified);
    settings && settings.length ?
      setState(prevState => ({
        ...prevState,
        isSellerLoggedIn,
        isShopManagerLoggedIn,
        isSellerManagerLoggedIn,
        user,
        isLoggedIn: loggedIn,
        emailVerified: isEmailVerified,
        accountApproved: isAccountApproved,
        isLoading: false,
        appSettings: appSettings
      }))
      :
      setState(prevState => ({
        ...prevState,
        isSellerLoggedIn,
        isShopManagerLoggedIn,
        isSellerManagerLoggedIn,
        user,
        isLoggedIn: loggedIn,
        emailVerified: isEmailVerified,
        accountApproved: isAccountApproved,
        isLoading: false
      }))
  }

  const [{
    isUsingDemoDB, isLoggedIn, emailVerified, accountApproved, user, isLoading,
    isSellerLoggedIn, isShopManagerLoggedIn, isSellerManagerLoggedIn, updateRules, appSettings
  }, setState] = useState({
    ...INITIAL_STATE,
    isSellerLoggedIn: userProps.isSellerLoggedIn,
    isShopManagerLoggedIn: userProps.isShopManagerLoggedIn,
    isSellerManagerLoggedIn: userProps.isSellerManagerLoggedIn,
    updateRules: UpdateRules,
    appSettings: appProps.AppSettings
  })
  useEffect(() => {
    const checkUserAuth = () => {
      fetch('/api/isAuth')
        .then(res => res.json())
        .then(json => {
          let { success,
            // isUsingDemoDB,
            user, loggedIn, settings } = json
          console.log(json);
          if (success &&
            // !isUsingDemoDB&& 
            user !== undefined && user !== null && loggedIn) {
            localStorage.setItem('ui', JSON.stringify(user.id))
          }
          else {
            !loggedIn && clearLocalStorage()
          }
          updateRules(user, loggedIn, settings);
        })
        .catch(err => {
          console.log(err)
          changeValue('isLoading', false)
        })
    }
    checkUserAuth()
  }, [])

  const changeValue = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })) }

  //logging out user
  const logOut = () => {
    fetch('/api/auth/logout')
      .then((res) => res.json())
      .then((json) => {
        // console.log(json)
        clearLocalStorage()
        setState(prevState => ({
          ...prevState,
          isLoggedIn: false,
          emailVerified: false,
          accountApproved: false,
          isSellerLoggedIn: false,
          isShopManagerLoggedIn: false
        }))
      })
      .catch((err) => console.log(err))
  }


  // console.log(user)
  const rules = (user && isLoggedIn) ? (user.role && user.role.permissions.filter(p => p.enabled).map(p => { delete p.enabled; return p })) : []
  // console.log(rules)
  // console.log(appSettings)
  appSettings && (document.title = appSettings.appName)

  return (
    <BrowserRouter>
      <AbilityContext.Provider value={new Ability(rules)}>
        <UserContext.Provider value={{ isLoggedIn, isSellerLoggedIn, isShopManagerLoggedIn, isSellerManagerLoggedIn, emailVerified, accountApproved, updateRules }}>
          <AppContext.Provider value={appSettings}>
            {/* {isLoggedIn === false &&
            <Redirect to={window.location.pathname === '/sellers/signup' ? '/sellers/signup' : '/login' ? '/login' : '/home' ? '/home' : '/home'} />
          } */}
            {isLoading ?
              <LoaderModal modalShow={isLoading} />
              :
              isUsingDemoDB ?
                <InitialSystemSettings modalShow={isUsingDemoDB} changeValue={changeValue} />
                :
                <Layout
                  isLoggedIn={isLoggedIn}
                  logOut={logOut}
                />
            }
          </AppContext.Provider>
        </UserContext.Provider>
      </AbilityContext.Provider>
    </BrowserRouter >
  )
}

const clearLocalStorage = () => {
  localStorage.getItem('ui') && localStorage.removeItem('ui')
}

export default App;
