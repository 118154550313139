import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import { Can } from "../../configs/Ability-context";
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { CustomListing } from '../misc/components/CustomListing'
import getOptions from '../misc/components/Options'
import { getUsersWithData, deleteUser, getCustomUsersWithData, getCustomUsers, syncSellers, getSellerSummary } from '../../services/users/user-service'
import { getSellerPlans } from '../../services/users/seller-plan-service'
import { getPriceRules } from '../../services/users/price-rule-service'
import { animateSyncIcon, isConfirmed, checkUserRole } from '../misc/components/Helpers';
import { AppContext } from "../../contexts/AppContext";
import { Card } from 'primereact/card';
import { MDBRow, MDBCol } from 'mdbreact'




const countriesList = getOptions('countries')
const sellerTypeOptions = getOptions('sellerTypes')
const accountStatusOptions = getOptions('accountStatuses')
let _isMounted

const AllPersons = (props) => {

    const [{
        allPersons, allPlans, allSellerManagers, allPriceRules, isLoading, refreshData, sellerSummary,
        selectedRole, selectedPlan, selectedSellerType, selectedStatus, selectedPriceRule, selectedManager
    }, setState] = useState({
        allPersons: [], allPlans: [], allSellerManagers: [], allPriceRules: [], sellerSummary: {}, isLoading: false, refreshData: false,
        selectedRole: null, selectedPlan: null, selectedSellerType: null, selectedStatus: null, selectedPriceRule: null, selectedManager: null
    })

    let dt = useRef(null)
    let toast = useRef(null)
    let fileUpload = useRef(null)
    let { persons } = props
    let history = useHistory()
    const { storeURL } = useContext(AppContext)

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = persons === 'users' ? await getUsersWithData() : await getCustomUsersWithData('seller')
                changeValue('allPersons', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchSellerSummary = async () => {
            changeValue('isLoading', true)
            try {
                const data = await getSellerSummary()
                changeValue('sellerSummary', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchMeta = async () => {
            const sellerPlans = await getSellerPlans()
            changeValue('allPlans', sellerPlans.data)
            const priceRules = await getPriceRules()
            changeValue('allPriceRules', priceRules.data)
            const sellerManagers = await getCustomUsers('seller_manager')
            changeValue('allSellerManagers', sellerManagers.data)
        }
        fetchData()
        persons === 'sellers' && fetchMeta()
        persons === 'sellers' && fetchSellerSummary()
        console.log(sellerSummary);
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleEdit = (user) => (e) => {
        checkUserRole(user, 'seller') ? history.push(`/sellers/edit/${user.id}`)
            : history.push(`/users/edit/${user.id}`)
    }

    const handleCreate = () => {
        persons === 'users' ? history.push('/users/new') : history.push('/sellers/new')
    }
    const handleSync = async (e) => {
        animateSyncIcon('start', e)
        changeValue('isSyncing', true)
        try {
            const { message } = await syncSellers()
            // console.log(message)
            toast.current.show({ severity: 'success', summary: 'Success', detail: message })
            animateSyncIcon('stop', e)
        }
        catch (err) {
            // console.log(err)
            // stoping animation of sync icon
            animateSyncIcon('error', e)
            setTimeout(() => { changeValue('isSyncing', false) }, 500)
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
        }
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete', e, accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedRows } = await deleteUser(id)
                    console.log(message)
                    let updatedPersons = allPersons.filter((person) => person.id !== id)
                    if (success && affectedRows) {
                        toast.current.show({ severity: 'success', summary: `${persons === 'users' ? 'User' : 'Seller'} deleted successfully.` });
                        changeValue('allPersons', updatedPersons)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    let allRoles = []
    allPersons.map(person => { allRoles.push(person.role) })
    let roleOptions = allRoles.length ? getOptions('rolesForFilter', allRoles.filter(r => r)) : []
    const roleFilter = <Dropdown
        value={selectedRole}
        options={roleOptions}
        onChange={(e) => { dt.current.filter(e.value, 'role', 'custom'); changeValue('selectedRole', e.value) }}
        placeholder="Select a role"
        className="p-column-filter"
        showClear
    />

    const planFilter = <Dropdown
        value={selectedPlan}
        options={getOptions('sellerPlans', allPlans)}
        onChange={(e) => { dt.current.filter(e.value, 'plan', 'custom'); changeValue('selectedPlan', e.value) }}
        placeholder="Select a plan"
        className="p-column-filter"
        showClear
    />

    const sellerTypeFilter = <Dropdown
        value={selectedSellerType}
        options={sellerTypeOptions}
        onChange={(e) => { dt.current.filter(e.value, 'seller_type', 'equals'); changeValue('selectedSellerType', e.value) }}
        placeholder="Select a type"
        className="p-column-filter"
        showClear
    />

    const statusFilter = <Dropdown
        value={selectedStatus}
        options={accountStatusOptions}
        onChange={(e) => { dt.current.filter(e.value, 'account_status', 'equals'); changeValue('selectedStatus', e.value) }}
        placeholder="Select a status"
        className="p-column-filter"
        showClear
    />

    const sellerManagerFilter = <Dropdown
        value={selectedManager}
        options={getOptions('sellerManagers', allSellerManagers)}
        onChange={(e) => { dt.current.filter(e.value, 'parent_id', 'custom'); changeValue('selectedManager', e.value) }}
        placeholder="Select a manager"
        className="p-column-filter"
        showClear
    />

    const priceRuleFilter = <Dropdown
        value={selectedPriceRule}
        options={getOptions('priceRules', allPriceRules)}
        onChange={(e) => { dt.current.filter(e.value, 'price_rule', 'custom'); changeValue('selectedPriceRule', e.value) }}
        placeholder="Select a rule"
        className="p-column-filter"
        showClear
    />

    const countryBodyTemplate = (rowData) => {

        let country = countriesList.find(option => option.value === rowData.country)
        return (
            <React.Fragment>
                <span className="p-column-title">Country</span>
                {country?.label}
            </React.Fragment>
        );
    }

    const roleBodyTemplate = (rowData) => {
        let role = rowData.role && rowData.role.name
        return (
            <React.Fragment>
                <span className="p-column-title">Role</span>
                {role}
            </React.Fragment>
        );
    }

    const planBodyTemplate = (rowData) => {
        let plan = rowData.plan && rowData.plan.title
        return (
            <React.Fragment>
                <span className="p-column-title">Plan</span>
                {plan}
            </React.Fragment>
        );
    }

    const sellerTypeBody = (rowData) => {
        let sellerType = sellerTypeOptions.find(option => option.value === rowData.seller_type)
        return (
            <React.Fragment>
                <span className="p-column-title">Type</span>
                {sellerType?.label}
            </React.Fragment>
        );
    }

    const accountStatusBody = (rowData) => {
        let accountStatus = accountStatusOptions.find(option => option.value === rowData.account_status)
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                {accountStatus?.label}
            </React.Fragment>
        );
    }

    const sellerManagerBody = (rowData) => {
        let manager = rowData.parent && rowData.parent.name
        return (
            <React.Fragment>
                <span className="p-column-title">Manager</span>
                {manager}
            </React.Fragment>
        );
    }

    const priceRuleBody = (rowData) => {
        let rule = rowData.price_rule && rowData.price_rule.name
        return (
            <React.Fragment>
                <span className="p-column-title">Price rule</span>
                {rule}
            </React.Fragment>
        );
    }

    const filterRole = (role, filter) => {
        return role?.id == filter
    }

    const filterPlan = (plan, filter) => {
        return plan?.id == filter
    }

    const filterRule = (rule, filter) => {
        return rule?.id == filter
    }

    const filterManager = (manager, filter) => {
        return manager?.id == filter
    }
    const imageTemplate = (rowData) => {
        return <img
            src={rowData.profile_picture_url ? `/images/${rowData.profile_picture_url}` : '/image-not-found.png'}
            height={'70px'}
            alt={rowData.name}
            className='my-2 text-muted p-shadow-8'
            style={{
                width: '70px',
                filter: (rowData.profile_picture_url) ? '' : 'grayscale(100%)',
                borderRadius:'50%'
            }}
        />
    }
    const storeNameBody = (rowData) => {
        return <a href={`${storeURL}seller/${rowData.store_url}`} target="_blank" title='view on store'>
            {rowData.shop_name}
        </a>
    }

    let commonColumns = [
        {
            field: "profile_picture_url",
            header: "Profile Picture",
            filter: false,
            body: imageTemplate,
            selectedByDefault: true,
            headerStyle: { width: '100px' }
        },
        {
            field: "seller_id",
            header: "Seller Id",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by seller-id",
            filterMatchMode: "contains",
        },
        // {
        //     field: "createdAt",
        //     header: "Registration Date",
        //     sortable: true,
        //     // filter: true,
        //     // body: dateTemplate,
        //     selectedByDefault: true,
        //     // filterPlaceholder: "Search by cell",
        //     // filterMatchMode: "contains",
        // },
        {
            field: "email",
            header: "Email",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by email",
            filterMatchMode: "contains",
        },
        {
            field: "shop_name",
            header: "Store Name",
            body: storeNameBody,
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by Store",
            filterMatchMode: "contains",
        },
        {
            field: "cell",
            header: "Cell",
            sortable: true,
            filter: true,
            // selectedByDefault: true,
            filterPlaceholder: "Search by cell",
            filterMatchMode: "contains",
        },
        {
            field: "address",
            header: "Address",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by address",
            filterMatchMode: "contains",
        },
        {
            field: "city",
            header: "City",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by city",
            filterMatchMode: "contains",
        },
        {
            field: "state",
            header: "State",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by state",
            filterMatchMode: "contains",
        },
        {
            field: "country",
            header: "Country",
            body: countryBodyTemplate,
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by country",
            filterMatchMode: "contains",
        },
        {
            field: "postcode",
            header: "Postcode",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by postcode",
            filterMatchMode: "contains",
        },
        {
            field: "username",
            header: "Username",
            sortable: true,
            filter: true,
            // selectedByDefault: true,
            filterPlaceholder: "Search by username",
            filterMatchMode: "contains",
        },
        {
            field: "cnic",
            header: "CNIC",
            filter: true,
            filterPlaceholder: "Search by CNIC",
            filterMatchMode: "contains",

        },

    ]

    let personsColumns = persons === 'users' ?
        [
            {
                field: "role",
                header: "Role",
                body: roleBodyTemplate,
                sortable: true,
                filter: true,
                filterElement: roleFilter,
                filterPlaceholder: "Search by role",
                filterFunction: filterRole
            }
        ]
        :
        [


            // {
            //     field: "plan",
            //     header: "Plan",
            //     body: planBodyTemplate,
            //     sortable: true,
            //     filter: true,
            //     filterElement: planFilter,
            //     filterFunction: filterPlan
            // },
            {
                field: "market_place",
                header: "Market",
                filter: true,
                filterPlaceholder: "Search by market",
            },
            {
                field: "shop_cell",
                header: "Shop Cell",
                filter: true,
                filterPlaceholder: "Search by shop-cell",
            },
            {
                field: "bank_name",
                header: "Bank",
                filter: true,
                filterPlaceholder: "Search by bank",
            },
            {
                field: "ac_title",
                header: "A/C Title",
                filter: true,
                filterPlaceholder: "Search by A/C title",
            },
            {
                field: "ac_id",
                header: "A/C #",
                filter: true,
                filterPlaceholder: "Search by A/C #",
            },
            {
                field: "seller_type",
                header: "Type",
                body: sellerTypeBody,
                filter: true,
                filterElement: sellerTypeFilter,
            },
            {
                field: "account_status",
                header: "Status",
                body: accountStatusBody,
                filter: true,
                selectedByDefault: true,
                filterElement: statusFilter
            },
            // {
            //     field: "parent",
            //     header: "Manager",
            //     body: sellerManagerBody,
            //     filter: true,
            //     filterElement: sellerManagerFilter,
            //     filterFunction: filterManager
            // },
            // {
            //     field: "price_rule",
            //     header: "Price rule",
            //     body: priceRuleBody,
            //     filter: true,
            //     filterElement: priceRuleFilter,
            //     filterFunction: filterRule
            // }
        ]

    let columns = [...commonColumns, ...personsColumns]

    return (
        <Can I='read' a={persons}>
            <CustomListing

                allRecords={allPersons}
                viewSummaryHeader={persons === 'sellers' ? true : false}
                summaryHeader={
                    <MDBRow className='container-fluid'>
                        <MDBCol>
                            <Card className="mt-1 ml-1">
                                <h3>{sellerSummary.sellers_count}</h3>
                                <i className="pi pi-users" /> Total Sellers
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1 ml-1">
                                <h3>{sellerSummary.pending}</h3>
                                <i className="pi pi-ellipsis-h" /> Pending Approval
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1 ml-1">
                                <h3>{sellerSummary.rejected}</h3>
                                <i className="pi pi-exclamation-circle" /> Rejected
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1 ml-1 mb-1">
                                <h3>{sellerSummary.approved}</h3>
                                <i className="pi pi-check-circle" /> Approved
                            </Card>
                        </MDBCol>
                    </MDBRow>
                }
                heading={persons === 'users' ? 'Users' : 'Sellers List'}
                // firstColumn={<Column
                //     field="name"
                //     header="Name"
                //     sortable={true}
                //     filter={true}
                //     filterPlaceholder="Search by name"
                //     filterMatchMode="contains"
                //     headerStyle={{ width: '170px' }}
                // />}
                columns={columns}
                permissionSubject={persons}
                isLoading={isLoading}
                handleCreate={handleCreate}
                handleSync={persons === 'sellers' ? handleSync : false}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                // handleView={true}
                // customViewButton={persons === 'sellers' ? (customButton, rowData) => {

                //     return rowData.store_url ? (

                //         <a href={`${storeURL}seller/${rowData.store_url}`} target="_blank" title='view on store'>
                //             {customButton('fas fa-share color-primary')}
                //         </a>
                //     )
                //         : null
                // } : false}
                deleteDataKey={'id'}
                updatepermissionSubject={persons === 'users' ? (rowData) => checkUserRole(rowData, 'super_admin') ? 'superAdmin' : 'userProfile' : 'sellerProfile'}
                deletepermissionSubject={persons === 'users' ? (rowData) => checkUserRole(rowData, 'super_admin') ? 'superAdmin' : 'users' : 'sellers'}
                exportData={'users'}
                importData={'users'}
                columnsToImport={["id", "name", "email", "cell", "address", "cnic", "username", "password", "shop_details", "bank_details",
                    "account_details", "plan", "cost_price_percent", "retail_price_percent", "seller_id", "createdAt", "updatedAt",
                    "deletedAt", "created_by", "last_updated_by", "deleted_by"]}
                // columnsToImport={["id", "name", "email", "email_verified", "cell", "cell_verified", "address", "city", "state",
                //     "country", "postcode", "cnic", "cnic_verified", "username", "password", "shop_name", "shop_cell", "shop_address",
                //     "market_place", "seller_type", "bank_name", "ac_id", "ac_title", "iban_id", "seller_id", "account_status", "created_by",
                //     "last_updated_by", "deleted_by", "createdAt", "updatedAt", "deletedAt", "parent_id", "roleId", "role_id", "priceRuleId",
                //     "price_rule_id", "sellerPlanId", "plan_id"]}
                updateColumns={['email', 'cnic', 'cell', 'username', 'password']}
                tableRef={dt}
                toastRef={toast}
                fileUploadRef={fileUpload}
                timeStamps={true}
            />
        </Can>
    );
}

export default AllPersons