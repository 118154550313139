import React, { useContext } from 'react'
import { ListBox } from 'primereact/listbox'
import { AdvancedDataStep } from './form-steps/AdvancedDataStep'
import { AttributesDataStep } from './form-steps/AttributesDataStep'
import { DescriptionDataStep } from './form-steps/DescriptionDataStep'
import { GeneralDataStep } from './form-steps/GeneralDataStep'
import { ImagesDataStep } from './form-steps/ImagesDataStep'
import { InventoryDataStep } from './form-steps/InventoryDataStep'
import { LinkedDataStep } from './form-steps/LinkedDataStep'
import { ShippingDataStep } from './form-steps/ShippingDataStep'
import { VariationsDataStep } from './form-steps/VariationsDataStep'
import { AbilityContext } from '../../../../configs/Ability-context'
import { createTag } from '../../../../services/products/tag-service'

const stepOptions = [
    { key: 1, label: 'General', value: 1 },
    { key: 2, label: 'Inventory', value: 2 },
    { key: 3, label: 'Shipping', value: 3 },
    { key: 4, label: 'Description', value: 4 },
    { key: 5, label: 'Linked data', value: 5 },
    { key: 6, label: 'Attributes', value: 6 },
    { key: 8, label: 'Advanced', value: 8 },
    { key: 9, label: 'Images', value: 9 },
]


export const ProductForm = (props) => {
    const ability = useContext(AbilityContext)

    let {
        //universal
        _isMounted,
        APO_product,
        changeValue,
        currentStep,
        isVariableProduct,
        isSellerLoggedIn,
        isShopManagerLoggedIn,
        setCurrentStep,
        setState,
        validationMessage,
        showSubmitButton,
        refreshData,
        //general
        allSellers,
        costPrice,
        isCostProvided,
        name,
        priceRule,
        productStatusOptions,
        productTypeOptions,
        regularPrice,
        remarks,
        saleFrom,
        salePrice,
        saleTo,
        selectedStatus,
        selectedType,
        selectedSeller,
        scheduleSale,
        //inventory
        backorders,
        backordersOptions,
        manageStock,
        sku,
        soldIndividually,
        stockQuantity,
        stockStatus,
        stockStatusOptions,
        //shipping
        dimensions,
        weight,
        //description
        description,
        shortDescription,
        //linked-data
        allBrands,
        allCategories,
        allProducts,
        allShopManagers,
        crossSells,
        existingTags,
        selectedBrand,
        selectedCategories,
        selectedShopManager,
        selectedTags,
        upSells,
        //attributes & variations
        allAttributes,
        allAttributeTerms,
        attributes,
        variations,
        //advanced
        sourceURL,
        update_threshold,
        purchaseNote,
        //images
        featuredImage,
        images,
        //form-refs
        generalDataForm,
        inventoryDataForm,
        shippingDataForm,
        descriptionDataForm,
        linkedDataForm,
        attributesDataForm,
        variationsDataForm,
        advancedDataForm,
        imagesDataForm,
    } = props
    isVariableProduct && stepOptions[6].label !== 'Variations' && stepOptions.splice(6, 0, { key: 7, label: 'Variations', value: 7 });

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }
    const handleSelectChange = name => async (selectedOption) => {
        changeValue(name, selectedOption)
        handleChange()
    }
    const handleCheckboxChange = (e) => {
        let { name, checked } = e.target
        if (name === 'scheduleSale') {
            if (checked) {
                let date = new Date()
                date.setDate(date.getDate() + 1)
                _isMounted && setState(prevState => ({
                    ...prevState,
                    saleFrom: new Date(),
                    saleTo: date
                }))
            }
            else {
                _isMounted && setState(prevState => ({
                    ...prevState,
                    saleFrom: '',
                    saleTo: ''
                }))
            }
        }
        else if (name === 'manageStock') {
            if (!checked) {
                changeValue('stockStatus', '')
            }
        }
        changeValue(name, checked)
    }
    const handleDateChange = name => value => {
        changeValue(name, value)
        handleChange()
    }
    const handleCreateNewTag = async (tagName) => {
        let newTag;
        try {
            const { data } = await createTag({ name: tagName })
            newTag = { key: data.id, label: data.name, value: data.id }
            _isMounted && setState(prevState => ({
                ...prevState,
                selectedTags: [...prevState.selectedTags, newTag],
                existingTags: [...prevState.existingTags, data]
            }))
            handleChange()
        }
        catch (error) {
            console.log(error)
        }
        return newTag
    }
    const handleChange = e => {
        validationMessage !== '' && changeValue('validationMessage', '')
    }
    const removeImage = (comingIndex) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            images: prevState.images.filter((image, index) => {
                return index !== comingIndex
            }),
        }))
    }

    const remarksLabel = <div className='mb-3 p-0'>
        {/* <h6
            className={`bg-tertiary color-primary text-left py-2 px-3 m-0`}
            style={{ borderLeft: '10px solid #0e5b96', fontWeight: 400 }}
        >
            <b>Remarks:</b> {remarks}
        </h6> */}
    </div>

    return (
        <>
            {APO_product !== 'create' && remarksLabel}
            <div className='w-100 p-d-flex p-flex-column p-flex-sm-row h-100'>
                <div className='col-sm-4 col-md-3 col-lg-2 mb-4 m-sm-0 p-0'>
                    <ListBox
                        className='border-radius-0 m-0 p-0 custom-listbox h-100'
                        onChange={(e) => { e.value && setCurrentStep(e.value) }}
                        options={stepOptions}
                        value={currentStep}
                    />
                </div>
                <div className='bg-grey border-radius-0 px-0 col-sm-8 col-md-9 col-lg-10 pt-3'>
                    <GeneralDataStep
                        ability={ability}
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        handleCheckboxChange={handleCheckboxChange}
                        handleDateChange={handleDateChange}
                        handleInput={handleInput}
                        handleSelectChange={handleSelectChange}
                        setState={setState}
                        isVariableProduct={isVariableProduct}
                        isSellerLoggedIn={isSellerLoggedIn}
                        isShopManagerLoggedIn={isShopManagerLoggedIn}

                        allSellers={allSellers}
                        costPrice={costPrice}
                        generalDataForm={generalDataForm}
                        isCostProvided={isCostProvided}
                        name={name}
                        priceRule={priceRule}
                        productStatusOptions={productStatusOptions}
                        productTypeOptions={productTypeOptions}
                        regularPrice={regularPrice}
                        remarks={remarks}
                        selectedSeller={selectedSeller}
                        selectedStatus={selectedStatus}
                        selectedType={selectedType}
                        saleFrom={saleFrom}
                        salePrice={salePrice}
                        saleTo={saleTo}
                        scheduleSale={scheduleSale}
                    />
                    <InventoryDataStep
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        handleInput={handleInput}
                        handleSelectChange={handleSelectChange}
                        isVariableProduct={isVariableProduct}

                        backorders={backorders}
                        backordersOptions={backordersOptions}
                        inventoryDataForm={inventoryDataForm}
                        manageStock={manageStock}
                        sku={sku}
                        soldIndividually={soldIndividually}
                        stockQuantity={stockQuantity}
                        stockStatus={stockStatus}
                        stockStatusOptions={stockStatusOptions}
                    />
                    <ShippingDataStep
                        APO_product={APO_product}
                        currentStep={currentStep}
                        handleInput={handleInput}

                        dimensions={dimensions}
                        shippingDataForm={shippingDataForm}
                        weight={weight}
                    />
                    <DescriptionDataStep
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}

                        description={description}
                        descriptionDataForm={descriptionDataForm}
                        shortDescription={shortDescription}
                    />
                    <LinkedDataStep
                        ability={ability}
                        APO_product={APO_product}
                        currentStep={currentStep}
                        handleCreateNewTag={handleCreateNewTag}
                        handleSelectChange={handleSelectChange}
                        isSellerLoggedIn={isSellerLoggedIn}

                        allBrands={allBrands}
                        allCategories={allCategories}
                        allProducts={allProducts}
                        allShopManagers={allShopManagers}
                        crossSells={crossSells}
                        existingTags={existingTags}
                        linkedDataForm={linkedDataForm}
                        selectedBrand={selectedBrand}
                        selectedCategories={selectedCategories}
                        selectedSeller={selectedSeller}
                        selectedShopManager={selectedShopManager}
                        selectedTags={selectedTags}
                        upSells={upSells}
                    />
                    <AttributesDataStep
                        _isMounted
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        isVariableProduct={isVariableProduct}
                        showSubmitButton={showSubmitButton}
                        refreshData={refreshData}

                        allAttributes={allAttributes}
                        allAttributeTerms={allAttributeTerms}
                        attributes={attributes}
                        attributesDataForm={attributesDataForm}
                        variations={variations}
                    />
                    <VariationsDataStep
                        _isMounted
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        isVariableProduct={isVariableProduct}
                        showSubmitButton={showSubmitButton}
                        refreshData={refreshData}

                        attributes={attributes}
                        backordersOptions={backordersOptions}
                        priceRule={priceRule}
                        remarks={remarks}
                        stockStatusOptions={stockStatusOptions}
                        variations={variations}
                        variationsDataForm={variationsDataForm}
                    />
                    <AdvancedDataStep
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        handleInput={handleInput}

                        advancedDataForm={advancedDataForm}
                        purchaseNote={purchaseNote}
                        sourceURL={sourceURL}
                        update_threshold={update_threshold}
                    />
                    <ImagesDataStep
                        APO_product={APO_product}
                        changeValue={changeValue}
                        currentStep={currentStep}
                        imagesDataForm={imagesDataForm}
                        removeImage={removeImage}

                        featuredImage={featuredImage}
                        images={images}
                        showImageGallery={(isMultiple) => { changeValue('showImageGallery', true); changeValue('isMultipleImages', isMultiple) }}

                    />
                </div>
            </div>
        </>
    )
}
