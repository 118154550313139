import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllTags from './pages/AllTags';
import NotFound from '../../misc/pages/NotFound'
import { PrivateRoute } from '../../misc/PrivateRoute'


const Tags = () => {

    return (
        <Switch>
            <PrivateRoute path="/products/tags/all">
                <AllTags />
            </PrivateRoute >
            <Route component={NotFound} />
        </Switch>

    )
}


export default Tags