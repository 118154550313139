import { useEffect, useRef } from 'react';
import { getPage } from '../../services/misc/page-service'

const About = () => {
    let page = useRef(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { success, data } = await getPage('About')
                if (success) {
                    setPage(data.content)
                }
                else {
                    setPage()
                }
            }
            catch (error) {
                setPage()
                console.log(error)
            }
        }
        fetchData()
    }, [])

    const setPage = (html) => {
        const comingSoon = `<b class='color-tertiary'>Coming soon...</b>`
        page.current.innerHTML = html ? html : comingSoon
    }

    return (
        <div className="screen-center h-100vh pt-5">
            <div ref={page}>
            </div>
        </div>
    )
}

export default About