import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllPlans from './pages/AllPlans';
import NotFound from '../misc/pages/NotFound'
import { Can } from '../../configs/Ability-context'
import { PrivateRoute } from '../misc/PrivateRoute'

const AppSettings = () => {

    return (
        <Switch>
            <PrivateRoute path="/settings/plans/all">
                <Can I='read' a='sellerPlans'>
                    <AllPlans />
                </Can>
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default AppSettings