import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../configs/Ability-context";
import { getSellerPlansWithData, getPlansFeaturesWithData, deletePlanFeature, deleteSellerPlan } from '../../../services/users/seller-plan-service'
import { CustomListing } from '../../misc/components/CustomListing'
import PlanModal from './sections/PlanModal'
import FeatureModal from './sections/FeatureModal'
import { isConfirmed } from '../../misc/components/Helpers'
import CustomSelect from '../../misc/components/CustomSelect';


let _isMounted
export default () => {
    let dt = useRef(null)
    let toast = useRef(null)

    const [{
        allRecords, refreshData, featureView,
        selectedPlan, selectedFeature, selectedIsDefault,
        isLoading, featureModalShow, planModalShow, APO_plan, APO_feature
    }, setState] = useState({
        allRecords: [], refreshData: false, featureView: false,
        selectedPlan: null, selectedFeature: null, selectedIsDefault: null,
        isLoading: false, featureModalShow: false, planModalShow: false, APO_plan: '', APO_feature: ''
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getSellerPlansWithData()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allRecords: data,
                    featureView: false
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleCreate = () => {
        featureView ?
            setState(prevState => ({
                ...prevState,
                featureModalShow: true,
                APO_feature: 'create',
            }))
            :
            setState(prevState => ({
                ...prevState,
                APO_plan: 'create',
                planModalShow: true,
            }))
    }

    const handleEdit = (id) => (e) => {
        featureView ?
            setState(prevState => ({
                ...prevState,
                featureModalShow: true,
                APO_feature: 'update',
                selectedFeature: id,
            }))
            :
            setState(prevState => ({
                ...prevState,
                selectedPlan: id,
                APO_plan: 'update',
                planModalShow: true,
            }))
    }

    const handleView = (planId) => {
        setState(prevState => ({
            ...prevState,
            selectedPlan: planId,
            APO_plan: 'read',
            planModalShow: true,
        }))
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete',
            e,
            message: 'Are you sure to permanently delete this record?',
            accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = featureView ? await deletePlanFeature(id) : await deleteSellerPlan(id)
                    console.log(message)
                    let updatedRecords = allRecords.filter(r => r.id !== id)
                    if (success && affectedCount) {
                        toast.current.show({ severity: 'success', summary: message });
                        changeValue('allRecords', updatedRecords)
                    }
                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Something went wrong' });
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const handleGetFeatures = () => {
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getPlansFeaturesWithData()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allRecords: data,
                    featureView: true
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
    }

    const isDefaultFilter = <CustomSelect
        value={selectedIsDefault}
        options={[{ label: 'Default', value: true }, { label: 'Not default', value: false }]}
        handleChange={(e) => { dt.current.filter(e.value, 'is_default', 'equals'); changeValue('selectedIsDefault', e.value) }}
        placeholder="Default/Not default"
        className="p-column-filter"
        showClear
    />

    //     <Dropdown
    //     value={selectedIsDefault}
    //     options={[{ label: 'Default', value: true }, { label: 'Not default', value: false }]}
    //     onChange={(e) => { dt.current.filter(e.value, 'is_default', 'equals'); changeValue('selectedIsDefault', e.value) }}
    //     placeholder="Default/Not default"
    //     className="p-column-filter"
    //     showClear
    // />

    const isDefaultBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Default ?</span>
                <span className={`pi ${rowData.is_default ? 'pi-check' : 'pi-times'}`} />
            </React.Fragment>
        )
    }

    let columns = featureView ?
        [
            {
                field: "name",
                header: "Name",
                sortable: true,
                filter: true,
                filterPlaceholder: 'Search by name',
                headerStyle: { width: '120px' }
            },
        ]
        :
        [
            {
                field: "title",
                header: "Title",
                sortable: true,
                filter: true,
                filterPlaceholder: 'Search by title',
                headerStyle: { width: '120px' }
            },
            {
                field: "subtitle",
                header: "Subtitle",
                sortable: true,
                headerStyle: { width: '120px' }
            },
            {
                field: "is_default",
                header: "Default ?",
                body: isDefaultBody,
                filter: true,
                filterElement: isDefaultFilter
            },
            {
                field: "price_monthly",
                header: "Price/month",
                sortable: true,
                headerStyle: { width: '100px' }
            },
            {
                field: "price_annual",
                header: "Price/year",
                sortable: true,
                headerStyle: { width: '100px' }
            },
            {
                field: "price_one_time",
                header: "Price/once",
                sortable: true,
                headerStyle: { width: '100px' }
            },
        ]

    return (
        <>
            <Can I='read' a={featureView ? 'planFeatures' : 'sellerPlans'} >
                <CustomListing
                    allRecords={allRecords}
                    heading={featureView ? 'Plan features' : 'Seller plans'}
                    columns={columns}
                    permissionSubject={featureView ? 'planFeatures' : 'sellerPlans'}
                    isLoading={isLoading}
                    handleCreate={handleCreate}
                    handleView={featureView ? false : handleView}
                    viewDataKey={'id'}
                    handleEdit={handleEdit}
                    editDataKey={'id'}
                    handleDelete={handleDelete}
                    deleteDataKey={'id'}
                    handleRefresh={featureView ? false : () => changeValue('refreshData', !refreshData)}
                    handleTrash={featureView ? () => changeValue('refreshData', !refreshData) : handleGetFeatures}
                    trashLabel={featureView ? 'Plans' : `Features`}
                    trashPermission={`read`}
                    trashIcon={featureView ? 'pi pi-book' : 'pi pi-list'}
                    changeValue={changeValue}
                    tableRef={dt}
                    toastRef={toast}
                    timeStamps={true}
                />
            </Can>
            <PlanModal
                modalShow={planModalShow}
                APO_plan={APO_plan}
                selectedPlan={selectedPlan}
                hideModal={() => { changeValue('planModalShow', false) }}
                refreshData={(success, message) => {
                    toast.current.show({ severity: success ? 'success' : 'warn', summary: success ? 'Success' : 'Sorry', detail: message });
                    changeValue('refreshData', !refreshData)
                }}
            />
            <FeatureModal
                modalShow={featureModalShow}
                APO_feature={APO_feature}
                selectedFeature={selectedFeature}
                hideModal={() => { changeValue('featureModalShow', false) }}
                refreshData={(success, message) => {
                    toast.current.show({ severity: success ? 'success' : 'warn', summary: success ? 'Success' : 'Sorry', detail: message });
                    handleGetFeatures()
                }}
            />
        </>
    );
}
