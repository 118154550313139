import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import CustomSelect from '../../misc/components/CustomSelect'
import { CustomButton } from '../../misc/components/Inputs'
import LoaderModal from '../../misc/components/LoaderModal'
import getOptions from '../../misc/components/Options'
import { formatDateWithoutDashes } from '../../misc/components/Helpers'
import { getOrderStatuses } from '../../../services/orders/status-service'
import { getOrdersWithSpecificStatus, printLocalSlips } from '../../../services/orders/order-service';

let _isMounted
const INITIAL_STATE = {
    allStatuses: [],
    allOrders: [],
    selectedStatuses: [],
    selectedOrders: [],
    selectedData: [],
    isLoading: false
}

const PrintLocalSlips = () => {
    const [{ allStatuses, allOrders, selectedStatuses, selectedOrders, selectedData, isLoading, validationMessage }, setState] = useState({ ...INITIAL_STATE, validationMessage: '' })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getOrderStatuses()
                let defaultSelectedStatus = getOptions('orderStatuses', data.filter(s => s.usable_at_order)).find(status => status.entity.title === 'RTD')
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allStatuses: data.filter(s => s.usable_at_order),
                    selectedStatuses: defaultSelectedStatus ? [defaultSelectedStatus.value] : [],
                }))
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    useEffect(() => {
        const fetchMeta = async () => {
            try {
                const { success, data } = await getOrdersWithSpecificStatus(selectedStatuses)
                changeValue('allOrders', data)
                if (data.length)
                    changeValue('selectedOrders', data.map(o => o.bkt_order_id))
                else {
                    changeValue('selectedOrders', null)
                    selectedStatuses.length ?
                        changeValue('validationMessage', 'No order found for selected details')
                        :
                        changeValue('validationMessage', 'Please select atleast one status')
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchMeta()
    }, [selectedStatuses])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: name === 'validationMessage' ? value : '' }))
    }

    const handleSelectChange = name => e => {
        changeValue(name, e.value)
    }

    const generateLabels = async () => {
        if (!selectedOrders || !selectedOrders.length) {
            changeValue('validationMessage', 'Please select atleast one order')
            return
        }
        // changeValue('isLoading', true)
        try {
            const { success, data, message } = await printLocalSlips(selectedOrders)
            success ?
                changeValue('selectedData', data)
                :
                changeValue('validationMessage', message)
        }
        catch (err) {
            console.log(err)
        }
        // changeValue('isLoading', false)
    }
    let className = classNames(`py-5 px-0 screen-center`,
        { 'h-sm-100vh': !selectedData.length },
    )
    let orderNumberOptions = allOrders.map((o, ind) => ({ key: ind + 1, label: o.bkt_order_id, value: o.bkt_order_id }))
    let orderStatusOptions = getOptions('orderStatuses', allStatuses)
    return (
        <>
            <div className={className}>
                <h5 className='font-weight-bold text-center color-tertiary print-hide mt-5 mb-4'>Print Local Slips</h5>
                <div className='m-0 w-100 w-sm-75 w-md-50 p-grid p-justify-center print-hide'>
                    <div className='p-col-11 p-sm-9 p-md-7'>
                        <CustomSelect
                            options={orderStatusOptions}
                            value={selectedStatuses}
                            handleChange={handleSelectChange('selectedStatuses')}
                            placeholder={`Select statuses..`}
                            isMulti
                            className='w-100 mb-3'
                        />
                    </div>
                    {allOrders.length ?
                        <div className='p-col-11 p-sm-9 p-md-7'>
                            <CustomSelect
                                options={orderNumberOptions}
                                value={selectedOrders}
                                handleChange={handleSelectChange('selectedOrders')}
                                placeholder={`Select orders..`}
                                isMulti
                                className='w-100 mb-3'
                            />
                        </div>
                        :
                        null
                    }
                    <div className='p-col-11 p-sm-9 p-md-7 text-right'>
                        <CustomButton
                            label="Generate"
                            className=' mx-2'
                            bg='Secondary'
                            color='tertiary'
                            onClick={generateLabels}
                        />
                        <CustomButton
                            label="Print"
                            bg='primary-light'
                            color='tertiary'
                            disabled={!selectedData.length}
                            onClick={printLabels}
                        />
                    </div>
                </div>
                <label className='validating-label mt-3'>{validationMessage}</label>
                <div id='capture' className='bg-tertiary'>
                    <div className='m-0 p-grid py-0 px-3'>
                        {selectedData.map((order, index) => {
                            return (
                                <React.Fragment key={index + 1}>
                                    <ShippingLabel
                                        order={order}
                                    />
                                    {index % 2 !== 0 &&
                                        <>
                                            <div className='p-col-6 m-0 px-0 py-2 text-right text-dark'>--------------------------------------------------------------------------------</div>
                                            <div className='p-col-6 m-0 px-0 py-2 text-left text-dark'>--------------------------------------------------------------------------------</div>
                                        </>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
            <LoaderModal modalShow={isLoading} />
        </>
    );

}

export default PrintLocalSlips

const ShippingLabel = ({ order }) => {
    const { consignee, weight, orderDate, city, orderId, cod, destAddress, remarks, product, cell, index } = order
    return <div className={`p-col-6 m-0 py-4 ${index % 2 === 0 ? 'pl-0 pr-3' : 'pr-0 pl-3'}`}>
        <table className='table table-sm shipping-label m-0 p-0'>
            <tbody>
                <tr>
                    <td className='w-25' rowSpan='2' colSpan='2'>
                        <img
                            src='/bExpress.png'
                            // width='100%'
                            // height='20%'
                            className='img-fluid'
                            alt='your-delivery-service-logo'
                        />
                    </td>
                    <td className='text-muted key text-center' colSpan='4'>Customer Copy</td>
                </tr>
                <tr>
                    <td className='key'>Shipper</td>
                    <td className='value'>Bucket.pk</td>
                    <td className='key'>Consignee</td>
                    <td colSpan='2' className='value'>{consignee}</td>
                </tr>
                <tr>
                    <td className='key'>Weight</td>
                    <td className='value'>{weight > 0 ? weight : 1} Kg</td>
                    <td className='key'>Booking Date</td>
                    <td colSpan='3' className='value'>{new Date(orderDate).toLocaleString()}</td>
                </tr>
                <tr>
                    <td className='key'>Origin City</td>
                    <td className='value'>LHE</td>
                    <td className='key'>Dest. City</td>
                    <td className='value'>{city ? city : 'LHR'}</td>
                    <td className='key' colSpan='2'>CN#{`${formatDateWithoutDashes(new Date())}B${orderId}`}</td>
                </tr>
                <tr>
                    <td className='key'>Order#</td>
                    <td className='value'>{orderId}</td>
                    <td className='key'>COD</td>
                    <td colSpan='3' className='value'>Rs. {cod}/-</td>
                </tr>
                <tr>
                    <td className='key'>Consignee Contact:</td>
                    <td colSpan='5' className='value'>{cell}</td>
                </tr>
                <tr>
                    <td className='key'>Dest. Address:</td>
                    <td colSpan='5' className='value'>{destAddress}</td>
                </tr>
                <tr>
                    <td className='key'>Shipper Address:</td>
                    <td colSpan='5' className='value'>339-1-C2, Township, Lahore, Pakistan</td>
                </tr>
                <tr>
                    <td className='key'>Special Instructions:</td>
                    <td colSpan='5' className='value'>{remarks}</td>
                </tr>
                <tr>
                    <td className='key'>Product:</td>
                    <td colSpan='5' className='value' style={{ fontWeight: '500', fontSize: '13px' }}>{product}</td>
                </tr>
                <tr>
                    <td colSpan='6' className='value text-right'>
                        <span className='key'>Website: </span>
                         www.bucket.pk
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}

const printLabels = () => {
    const printArea = document.getElementById('capture').innerHTML;
    const wholeContent = document.body.innerHTML;
    document.body.innerHTML = printArea;
    window.print();
    document.body.innerHTML = wholeContent;
    setTimeout(() => { window.location.reload() }, 300)
}