import React from 'react';
import { Link } from 'react-router-dom'

const Home = ({ isLoggedIn }) => {
    return (
        // <>
        <div className='container'>
            {
                isLoggedIn ?
                    <img
                        src="/home_banner.jpg"
                        className="p-m-0 p-p-0 img-fluid object-fit-scale"
                        alt="home_banner"
                    />
                    :
                    <Link to='/sellers/quicksignup'>
                        <img
                            src="/home_banner.jpg"
                            alt="home_banner"
                            className="p-m-0 p-p-0 img-fluid object-fit-scale"
                            // width="100%"
                            // height="100%"
                            // style={{ position: 'absolute', opacity: 0.8 }}
                        />
                    </Link>
            }
            </div>
        // </>
    )
}

export default Home