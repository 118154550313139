import { confirmPopup } from 'primereact/confirmpopup';

//validating data is same
export const validateDataFormat = (testData, standardData) => {
    let isArray = (data) => Array.isArray(data)
    let result = (JSON.stringify(testData) === JSON.stringify(standardData)) ||
        (isArray(testData) && isArray(standardData) && (testData.length === standardData.length) && testData.every(e => standardData.includes(e)))
    return result
}

//capitalizing strings
export const capitalize = str => str.toLowerCase().replace(/\b./g, a => a.toUpperCase())

// making object from arras of keys and values
export const arraysToObject = (valuesArray, keysArray) => {
    let result = {}
    for (let i = 0; i < valuesArray.length; i++) {
        result[keysArray[i]] = valuesArray[i]
    }
    return result
}

//confirming something from user
export const isConfirmed = ({ type, e, target, message, header, icon, acceptClassName, rejectClassName, accept, reject, dismissable }) => {
    type === 'delete' ?
        confirmPopup({
            target: target ? target : e.target,
            message: message ? message : 'Are you sure to delete this record?',
            header: header ? header : 'Confirmation',
            icon: icon ? icon : 'pi pi-info-circle color-primary',
            acceptClassName: acceptClassName ? acceptClassName : 'p-button-danger py-0 px-3',
            rejectClassName: rejectClassName ? rejectClassName : 'py-0 px-3',
            accept: () => { accept() },
            reject: () => { reject ? reject() : console.log('not confirmed') },
            dismissable: dismissable ? dismissable : false
        })
        :
        confirmPopup({
            target: target ? target : e.target,
            message: message ? message : 'Are you confirm?',
            header: header ? header : 'Confirmation',
            icon: icon ? icon : 'pi pi-info-circle color-primary',
            acceptClassName: acceptClassName ? acceptClassName : 'p-button-danger py-0 px-3',
            rejectClassName: rejectClassName ? rejectClassName : 'py-0 px-3',
            accept: () => { accept() },
            reject: () => { reject ? reject() : console.log('not confirmed') },
            dismissable: dismissable ? dismissable : false
        })
}
//checking user role is same or not
export const checkUserRole = (user, role) => {
    let isSame = user && user.role?.name === role
    return isSame
}

//animating sync icon on listing screens
export const animateSyncIcon = (animationState, e) => {
    try {
        // console.log(e)
        let syncIcon = e.target.childNodes[0]
        let syncBtn = syncIcon.parentElement
        // console.log(syncIcon)
        // console.log(syncBtn)
        if (syncBtn && syncIcon)
            switch (animationState) {
                case 'start':
                    syncBtn.disabled = true
                    syncBtn.classList.add('p-disabled')
                    if (syncIcon.classList?.contains('pi-check') || syncIcon.classList?.contains('pi-exclamation-triangle')) {
                        syncIcon.classList?.remove('pi-check', 'pi-exclamation-triangle')
                        syncIcon.classList?.add('pi-spinner', 'pi-spin')
                    }
                    else { syncIcon.classList?.add('pi-spin') }
                    break;
                case 'stop':
                    syncBtn && (syncBtn.disabled = false)
                    syncBtn && (syncBtn.classList?.remove('p-disabled'))
                    syncIcon && syncIcon.classList?.remove('pi-spin', 'pi-spinner')
                    syncIcon && syncIcon.classList?.add('pi-check')
                    break;
                case 'error':
                    syncBtn && (syncBtn.disabled = false)
                    syncBtn && (syncBtn.classList?.remove('p-disabled'))
                    syncIcon && syncIcon.classList?.remove('pi-spin', 'pi-spinner')
                    syncIcon && syncIcon.classList?.add('pi-exclamation-triangle')
                    break;
                default:
                    break;
            }
    }
    catch (err) {
        console.log(err)
    }
}

//converting some data to json format
const getJson = (str) => {
    if (typeof str !== 'string') return str
    try { return JSON.parse(str); }
    catch (e) { return str; }
}
export const getJsonData = async (data, columns) => {
    if (data) {
        if (Array.isArray(data)) {
            for (let eachDataElement of data) {
                eachDataElement = await getJsonData(eachDataElement, columns)
            }
        }
        else {
            for (let col of columns) {
                if (Array.isArray(col)) {
                    data[col[0]] = await getJsonData(data[col[0]], col[1])
                }
                else {
                    data[col] = typeof data[col] !== 'object' ? getJson(data[col]) : data[col]
                }
            }
        }
    }
    else return null
    return data
}

//checking  a field's niqueness and enabling/disabling formsubmit accordingly
export const setElementDisability = (elId, disabled, className, addClass) => {
    let el = document.getElementById(elId)
    el.disabled = disabled
    className &&
        addClass ? el.classList.add(className) : el.classList.remove(className)
}
export const checkUniqueness = async (e, n, f, elId, className) => {
    if (e.target.value)
        try {
            const res = await fetch(`/api/isUnique?n=${n}&f=${f}&c=${e.target.value}`)
            const json = await res.json()
            console.log(json)
            json.isUnique ?
                e.target.setCustomValidity('')
                :
                e.target.setCustomValidity("Already exists")
            setElementDisability(elId, false, className, false)
        }
        catch (error) {
            console.log(error)
            setElementDisability(elId, false, className, false)
        }
    else
        setElementDisability(elId, false, className, false)
}

//minus percentage from a number
export const minusPercent = (number, percent) => {
    return Number(number) - (number * (percent / 100));
}

//add percentage from a number
export const addPercent = (number, percent) => {
    return Number(number) + (number * (percent / 100));
}

//calculate percent of a number 
export const getPercent = (denominator, numerator) => {
    return ((numerator / denominator) * 100)
}

//handling number input
export const handleNumberInputBlur = (e, changeValue) => {
    let { name, value } = e.target
    if (value == '') {
        changeValue(name, 0)
    }
}
export const handleNumberInputKeyPress = (e) => {
    // let { name, value } = e.target
    // if (value == 0) { changeValue(name, '') }
    let array = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    if (array.includes(e.which)) { return }
    else { e.preventDefault() }
}

//calculating product's cost/regular price
export const calculatePrice = async (priceRule, price, field) => {
    let range = await priceRule.price_rules_details.find(r => (r.min_price <= Number(price) && r.max_price >= Number(price)))
    let resultedPrice = range ?
        range.commission_type === 'percentage' ?
            field === 'cost_price' ?
                addPercent(price, range.commission_value)
                :
                minusPercent(price, range.commission_value)
            :
            field === 'cost_price' ?
                price + range.commission_value
                :
                price - range.commission_value
        : price
    return resultedPrice
}

//formating dates
export const formatDate = (date) => {
    return (date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '-'))
}
export const formatDateWithoutDashes = (date) => {
    return (date.toLocaleDateString('en-GB', { day: '2-digit', month: 'numeric', year: 'numeric' }).replace(/\//g, ''))
}

//checkimg allowed item statuses
export const isNextItemStatusAllowed = (nextStatus, previousStatus) => {
    let allowedStatuses
    let universalAllowedStatuses = [
        'on_hold',
        'cancelled',
        'refunded',
    ]
    switch (previousStatus) {
        case 'new_order':
            allowedStatuses = [
                'pickup_done',
                'awaiting_seller_response',
                'prepare_order',
            ]
            break;
        case 'awaiting_seller_response':
            allowedStatuses = [
                'item_not_available',
                'item_available',
            ]
            break;
        case 'item_available':
            allowedStatuses = [
                'item_not_available',
                'prepare_order',
            ]
            break;
        case 'prepare_order':
            allowedStatuses = [
                'awaiting_pickup',
                'awaiting_dispatch',
            ]
            break;
        case 'awaiting_pickup':
            allowedStatuses = [
                'pickup_in_process',
            ]
            break;
        case 'pickup_in_process':
            allowedStatuses = [
                'pickup_done',
            ]
            break;
        case 'slips_sent_to_seller':
            allowedStatuses = [
                'dispatched',
            ]
            break;
        case 'item_not_available':
            allowedStatuses = [
                'item_available',
            ]
            break;
        case 'awaiting_dispatch':
        case 'pickup_done':
        case 'dispatched':
        case 'cancelled':
        case 'refunded':
            allowedStatuses = []
            break;
        case 'on_hold':
            allowedStatuses = [nextStatus]
            break;
    }

    return allowedStatuses.includes(nextStatus) || universalAllowedStatuses.includes(nextStatus)
}
//checkimg allowed order statuses
export const isNextOrderStatusAllowed = (nextStatus, previousStatus) => {
    let allowedStatuses
    let universalAllowedStatuses = [
        'on_hold',
        'cancelled',
        'refunded',
    ]
    switch (previousStatus) {
        case 'new_order':
            allowedStatuses = [
                'rts',
                'rtd',
                'awaiting_dispatch',
            ]
            break;
        case 'awaiting_dispatch':
            allowedStatuses = [
                'slips_sent_to_seller',
            ]
            break;
        case 'slips_sent_to_seller':
            allowedStatuses = [
                'dispatched',
            ]
            break;
        case 'rtd':
            allowedStatuses = [
                'out_for_delivery',
            ]
            break;
        case 'rts':
            allowedStatuses = [
                'shipped',
            ]
            break;
        case 'shipped':
        case 'dispatched':
        case 'out_for_delivery':
            allowedStatuses = [
                'delivered',
                'delivery_failed',
            ]
            break;
        case 'delivered':
            allowedStatuses = [
                'completed',
            ]
            break;
        case 'delivery_failed':
            allowedStatuses = [
                'reforwarded',
                'refunded',
            ]
            break;
        case 'reforwarded':
            allowedStatuses = [
                'delivered',
                'delivery_failed',
            ]
            break;
        case 'completed':
        case 'cancelled':
        case 'refunded':
            allowedStatuses = []
            break;
        case 'on_hold':
            allowedStatuses = [nextStatus]
            break;
    }

    return allowedStatuses.includes(nextStatus) || universalAllowedStatuses.includes(nextStatus)
}