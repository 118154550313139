import React, { useState, useEffect, useRef, useContext } from 'react';
// import { useHistory } from 'react-router-dom'
import { Can } from "../../../../configs/Ability-context";
import { AppContext } from "../../../../contexts/AppContext";
import { animateSyncIcon, isConfirmed } from '../../../misc/components/Helpers'
import { getBrandsWithData, moveToTrash, moveBatchToTrash, restoreBrand, deleteBrand, publishBrand, syncBrands, getTrash, } from '../../../../services/products/brand-service'
import { CustomListing } from '../../../misc/components/CustomListing'
import BrandModal from './BrandModal'


let _isMounted
const AllBrands = () => {
    let dt = useRef(null)
    let toast = useRef(null)
    const { storeURL } = useContext(AppContext)
    // let history = useHistory()

    const [{
        allBrands, refreshData, trashView,
        selectedRecords, selectedBrand,
        isLoading, modalShow, APO_brand, selectedBulkOption,
    }, setState] = useState({
        allBrands: [], refreshData: false, trashView: false,
        selectedRecords: [], selectedBrand: null,
        isLoading: false, modalShow: false, APO_brand: '', selectedBulkOption: null,
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getBrandsWithData()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allBrands: data,
                    trashView: false
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleCreate = () => {
        setState(prevState => ({
            ...prevState,
            APO_brand: 'create',
            modalShow: true,
        }))
    }

    const handleEdit = (brandId) => (e) => {
        setState(prevState => ({
            ...prevState,
            selectedBrand: brandId,
            APO_brand: 'update',
            modalShow: true,
        }))
    }

    const handleDelete =  (id) => async (e) =>{
        isConfirmed({
            type: 'delete',
            e,
            message: trashView ? 'Are you sure to permanently delete this record? This will also delete brand from the store' : undefined,
            accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = trashView ? await deleteBrand(id) : await moveToTrash(id)
                    console.log(message)
                    let updatedBrands = allBrands.filter((brand) => brand.id !== id)
                    if (success && affectedCount) {
                        toast.current.show({ severity: 'warn', summary: message });
                        changeValue('allBrands', updatedBrands)
                    }
                }
                catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Something went wrong' });
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const handleBatchDelete = async (_selectedRecords) => {
        const { success, affectedCount, message } = await moveBatchToTrash(_selectedRecords)
        if (success && affectedCount) {
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Moved to Trash.'
            })
            changeValue('isLoading', false)
            window.location.reload()
        }
        else {
            toast.current.show({
                severity: 'error',
                summary: 'Sorry',
                detail: message
            })
        }
    }

    const handlePublish = async (id) => {
        try {
            const { success, message  } = await publishBrand(id)
            success && changeValue('refreshData', !refreshData)
            if (success)
            {
                toast.current.show({ severity: 'success', summary: message });
            }
            else 
            {
                toast.current.show({ severity: 'error', summary: message });
            }
        }
        catch (err) {
            // console.log(err)
        }
    }

    const handleSync = async (e) => {
        animateSyncIcon('start', e)
        // fetching new records and syncing
        try {
            const { success, message } = await syncBrands()
            if (success)
                changeValue('refreshData', !refreshData)    //to sync new data came from store
            else {
                animateSyncIcon('error', e)
                toast.current.show({ severity: 'error', summary: message.summary, detail: message.detail })
            }
        }
        catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: 'Something went wrong.' })
            animateSyncIcon('error', e)
        }
        animateSyncIcon('stop', e)
    }

    const handleBulkAction = () => {
        selectedRecords.length ?
            (() => {
                changeValue('isLoading', true)
                let _selectedRecords = selectedRecords.map(b => b.bkt_id)
                switch (selectedBulkOption) {
                    case 'trash':
                        handleBatchDelete(_selectedRecords)
                        break;
                    default:
                        break;
                }
            })()
            :
            alert('Please select some items first.')
    }

    const handleTrash = () => {
        // history.push('/products/brands/trash')
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getTrash()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allBrands: data,
                    trashView: true
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
    }

    const handleRestore = async (id) => {
        try {
            const { success, data } = await restoreBrand(id)
            if (success && data) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Brand restored successfully'
                })
                let updatedTrash = allBrands.filter(b => b.id !== id)
                changeValue('allBrands', updatedTrash)
            }
            else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Sorry',
                    detail: 'Something went wrong'
                })
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const verifiedTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Verified ?</span>
                <span className={`pi ${rowData.is_verified ? 'pi-check' : 'pi-times'}`} />
            </React.Fragment>
        )
    }

    const imageTemplate = (rowData) => {
        return <img
            src={rowData.image_url ? rowData.image_url : rowData.logo ? `/getImage/${rowData.logo.name}` : '/image-not-found.png'}
            height={'40px'}
            alt={rowData.name}
            className='my-2 text-muted p-shadow-8'
            style={{
                width: '80px',
                filter: (rowData.image_url || rowData.logo?.name) ? '' : 'grayscale(100%)',
            }}
        />
    }

    let columns = [
        {
            field: "name",
            header: "Name",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by name",
            filterMatchMode: "contains",
            selectedByDefault: true,
            headerStyle: { width: '120px' }
        },
        {
            field: "status",
            header: "Status",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by status",
            filterMatchMode: "contains",
            headerStyle: { width: '100px' }
        },
        {
            field: "is_verified",
            header: "Verified ?",
            body: verifiedTemplate,
            selectedByDefault: true,
            headerStyle: { width: '100px' }
        },
        {
            field: "count",
            header: "Product count",
            sortable: true,
            selectedByDefault: true,
            headerStyle: { width: '100px' }
        },
        // {
        //     field: "image",
        //     header: "Logo",
        //     body: imageTemplate,
        //     selectedByDefault: true,
        //     headerStyle: { width: '100px' }
        // },
    ]

    const bulkOptions = [
        { key: 1, label: 'Move to Trash', value: 'trash', permission: 'delete' }
    ]

    return (
        <>
            <Can I='read' a='brands' >
                <CustomListing
                    allRecords={allBrands}
                    heading={trashView ? 'Brands (trash)' : 'Brands'}
                    columns={columns}
                    permissionSubject={'brands'}
                    isLoading={isLoading}
                    handleSync={trashView ? false : handleSync}
                    handleCreate={handleCreate}
                    handleView={true}
                    customViewButton={(customButton, rowData) => {
                        return rowData.slug ? (
                            <a href={`${storeURL}product-brand/${rowData.slug}`} target="_blank" title='view on store'>
                                {customButton('fas fa-share color-primary')}
                            </a>
                        )
                            : null
                    }}
                    handleEdit={trashView ? false : handleEdit}
                    editDataKey={'id'}
                    handleDelete={handleDelete}
                    deleteDataKey={trashView ? 'bkt_id' : 'id'}
                    // deleteDataKey={'id'}
                    extraButton={trashView ?
                        (customButton, rowData) => {
                            return (
                                customButton('pi pi-undo color-primary-light', () => { handleRestore(rowData.id) }, false, 'restore')
                            )
                        }
                        :
                        (customButton, rowData) => {
                            return !rowData.bkt_id && (
                                customButton('pi pi-upload color-primary', () => { handlePublish(rowData.id) }, false, 'publish to store')
                            )
                        }
                    }
                    handleRefresh={() => changeValue('refreshData', !refreshData)}
                    handleTrash={trashView ? false : handleTrash}
                    changeValue={changeValue}
                    selectedRecords={selectedRecords}
                    selectedBulkOption={selectedBulkOption}
                    bulkOptions={trashView ? false : bulkOptions}
                    handleBulkAction={trashView ? false : handleBulkAction}
                    selectionDataKey={'id'}
                    tableRef={dt}
                    toastRef={toast}
                    timeStamps={true}
                />
            </Can>
            <BrandModal
                modalShow={modalShow}
                APO_brand={APO_brand}
                selectedBrand={selectedBrand}
                hideModal={() => { changeValue('modalShow', false) }}
                refreshData={(success, message) => {
                    toast.current.show({ severity: success ? 'success' : 'warn', summary: success ? 'Success' : 'Sorry', detail: message });
                    changeValue('refreshData', !refreshData)
                }}
            />
        </>
    );
}


export default AllBrands
