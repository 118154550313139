
export const getSettings = async (id) => {
    try {
        const res = await fetch(`/api/settings`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSetting = async (id) => {
    try {
        const res = await fetch(`/api/settings/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateSetting = async (id, updatedSetting) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedSetting }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/settings/${id}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

