import { useState, useRef, useEffect } from 'react'
import { Can } from '../../../../configs/Ability-context'
import { CustomInput, SubmitButton } from '../../../misc/components/Inputs'
import LoaderModal from '../../../misc/components/LoaderModal'
import { setElementDisability, checkUniqueness } from '../../../misc/components/Helpers'
import { Dialog } from 'primereact/dialog';
import { createPlanFeature, getPlanFeature, updatePlanFeature } from '../../../../services/users/seller-plan-service';


let _isMounted
export default (props) => {

    let featureForm = useRef(null)
    let { modalShow, hideModal, refreshData, APO_feature, selectedFeature } = props

    const [{
        name,
        isLoading, validationMessage
    }, setState] = useState({
        name: '',
        isLoading: false, validationMessage: ''
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getPlanFeature(selectedFeature)
                _isMounted && setState(prevState => ({
                    ...prevState,
                    name: data.name
                }))
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        APO_feature === 'update' && modalShow && fetchData()
        return () => {
            _isMounted = false
        }
    }, [modalShow])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const makeStateEmpty = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            name: '',
            validationMessage: ''
        }))
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
        changeValue('validationMessage', '')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!featureForm.current.checkValidity()) {
            featureForm.current.classList.add('was-validated')
            return
        }
        else {
            changeValue('isLoading', true)
            try {
                let PlanFeature = { name }
                const { success, message } = APO_feature === 'create' ? await createPlanFeature(PlanFeature) : await updatePlanFeature(selectedFeature, PlanFeature)
                changeValue('validationMessage', message)
                if (success) {
                    setTimeout(() => {
                        makeStateEmpty()
                        hideModal()
                        refreshData(success, message)
                    }, 750);
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
    }

    const nameInput = <CustomInput
        type='text'
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        onFocus={() => { setElementDisability('formSubmit', true, 'p-disabled', true) }}
        onBlur={(e) => { checkUniqueness(e, 'plan_features', 'name', 'formSubmit', 'p-disabled') }}
        invalidMessage={name ? 'A feature with this name already exists' : 'This field is mandatory'}
        required
    />
    const submitBtn = <SubmitButton
        id='formSubmit'
        label={APO_feature}
        className="w-50 p-ml-auto p-mr-auto"
        color='secondary'
        type="submit"
    />
    const validatingLabel = <label className='p-0 mt-1 p-ml-auto p-mr-auto validating-label'>{validationMessage}&nbsp;</label>

    return (
        <Can I={APO_feature} a='planFeatures'>
            <Dialog
                header={<p className='color-tertiary m-0 p-0'>{`${APO_feature === 'create' ? 'New' : 'Update'} feature`}</p>}
                modal
                onHide={() => { hideModal(); makeStateEmpty() }}
                className='mx-2 m-md-0'
                style={{ width: '50vw' }}
                breakpoints={{  '960px': '75vw','768px': '100vw', }}
                visible={modalShow}
            >
                <form ref={featureForm} className='pt-3 p-d-flex p-flex-column p-jc-center color-tertiary' onSubmit={handleSubmit} noValidate>
                    <div className='m-0 p-0 mb-0 w-50 p-ml-auto p-mr-auto'>
                        {nameInput}
                    </div>
                    {submitBtn}
                    {validatingLabel}
                </form>
            </Dialog>
            <LoaderModal modalShow={isLoading} />
        </Can >
    )
}