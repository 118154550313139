import { OverlayPanel } from 'primereact/overlaypanel';
import { CustomFileUpload } from './CustomFileUpload'

const ImportDataPanel = (props) => {
    let { panelRef, fileUploadRef, toastRef, handleImport } = props
    return (
        <OverlayPanel
            ref={panelRef}
            showCloseIcon
            className='import-data-panel ml-sm-5 ml-md-0'
            dismissable={false}
        >
            <CustomFileUpload
                fileUploadRef={fileUploadRef}
                toastRef={toastRef}
                accept=".xlsx"
                maxSize={30000000}
                chooseLabel="Choose file"
                validExtensions={['xlsx']}
                handleUpload={handleImport}
                customUpload={true}
                multiple={false}
            />
        </OverlayPanel>
    )
}

export default ImportDataPanel