import React from 'react';
import { MDBInput, MDBIcon, MDBBtn } from 'mdbreact';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import classNames from 'classnames';

export const Input = ({
    id, type, rows, hint, label, name, value, handleInput, onBlur, onKeyPress, onFocus, setRef, pattern, maxLength, min, max, labelClass, className,
    containerClass, style, required, disabled, autoComplete, invalidClass, invalidFullWidth, invalidMessage, children,
}) => {
    return (
        <MDBInput
            id={id}
            type={type ? type : "text"}
            rows={rows}
            hint={hint}
            label={<small className='font-weight-bold'>{label}{required ? '*' : ''}</small>}
            name={name}
            value={value}
            onInput={handleInput}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            inputRef={setRef}
            pattern={pattern}
            maxLength={maxLength}
            min={min}
            max={max}
            labelClass={`color-primary-light border-radius-3 z-index-3 ${labelClass ? labelClass : ''}`}
            className={`bg-tertiary border-radius-5 m-0 shadow ${className ? className : ''}`}
            containerClass={`mt-0 mx-0 p-0 outline-none ${containerClass ? containerClass : 'mb-4'}`}
            style={{ ...style, height: type !== 'textarea' && '38px' }}
            required={required}
            disabled={disabled}
            autoComplete="off"
            onChange={handleChange}
            group
            validate
            outline
        >
            <div style={{ marginLeft: invalidFullWidth ? '0px' : '25%', lineHeight: '13px' }}
                className={`invalid-tooltip text-left border-radius-0 ${invalidClass ? invalidClass : ''} ${invalidFullWidth ? 'w-100' : 'w-75'}`}>
                <small>{invalidMessage ? invalidMessage : 'This field is mandatory'}</small>
            </div>
            {children}
        </MDBInput>
    )
}

export const SubmitButton = ({ id, className, label, type, onClick, disabled, color, style }) => {
    return (
        <Button
            id={id}
            label={label}
            className={`p-button-raised submit-button bg-hover-tertiary p-m-0 p-px-5 ${className ? className : ''} ${color === 'secondary' ? 'bg-Secondary hover-secondary secondary-border' : ''}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
            style={style}
        />
    )
}

export const CustomButton = (props) => {
    const { id, variant, className, label, type, onClick, disabled, color, bg, style, isRounded, icon, title, tooltip } = props
    let commonClasses = classNames(`custom-button p-button-sm p-py-1`, className,
        { 'p-button-raised': (!(variant === 'text-button' || variant === 'text-icon-button')) },
        { 'p-button-rounded': isRounded },
        { 'p-button-outlined': variant === 'outlined-button' },
        { 'p-button-text': variant === 'text-button' || variant === 'text-icon-button' },
        { 'p-button-icon-only': variant === 'text-icon-button' },
        // { 'bg-hover-transparent': (!variant || variant === 'with-icon') && color !== 'tertiary' },
        { [`color-${color}`]: color },
        { [`color-tertiary`]: !color },
        { [`hover-${bg}`]: bg && variant !== 'text-button' && variant !== 'outlined-button' },
        { [`hover-Primary`]: variant === 'text-button' },
        { [`hover-tertiary`]: variant === 'outlined-button' },
        { [`bg-${bg}`]: bg && variant !== 'text-button' && variant !== 'text-icon-button' },
        { [`bg-hover-${color}`]: (color && variant === 'outlined-button' || (!variant || variant === 'with-icon')) },
        { [`bg-hover-transparent`]: (variant !== 'text-button' && !color) || (bg === 'tertiary' && color !== 'Primary') },
        { [`${bg}-border`]: bg && variant !== 'text-button' && variant !== 'text-icon-button' },
        { [`${color}-border`]: color && variant === 'outlined-button' },
        // { [`bg-${color}`]: (!variant || variant === 'with-icon') },
        // { [`bg-hover-Primary`]: color === 'tertiary' },
    )
    const originalButtonProps = {
        label: label,
        icon: icon
    }
    variant !== 'with-icon' && delete originalButtonProps.icon
    const textButtonProps = {
        label: label
    }
    const textIconButtonProps = {
        icon: icon
    }

    const commonProps = {
        id: id,
        onClick: onClick,
        type: type ? type : 'button',
        disabled: disabled,
        style: style,
        className: commonClasses,
        title: title,
        tooltip: tooltip
    }
    !tooltip && delete commonProps.tooltip
    const specificProps = variant === 'text-button' ?
        textButtonProps
        :
        variant === 'text-icon-button' ?
            textIconButtonProps
            :
            originalButtonProps
    const buttonProps = {
        ...commonProps,
        ...specificProps
    }
    return <Button {...buttonProps} />

}

export const ActionButton = ({ id, type, icon, customIcon, value, name, onClick, className, iconId, iconClass, innerRef, style, disabled }) => {
    return (
        <button
            id={id}
            type={type}
            className={`p-m-0 action-button p-py-1 p-px-4 ${className ? className : ''}`}
            onClick={onClick}
            style={style}
            disabled={disabled}
        >
            {/* {value} */}
            {name !== 'next' && value}
            {(customIcon || icon) &&
                <>
                    {customIcon
                        ?
                        <i id={iconId} className={`${name !== 'next' ? 'p-ml-3' : 'p-mr-3'} ${customIcon} ${iconClass ? iconClass : ''}`} />
                        :
                        <MDBIcon
                            icon={icon}
                            className={`${name !== 'next' ? 'p-ml-3' : 'p-mr-3'} ${iconClass ? iconClass : ''}`}
                        />
                    }
                </>

            }
            {name === 'next' && value}
        </button>
    )
}

export const CustomInput = React.forwardRef((props, ref) => {
    let {
        type, id, label, labelClass, value, checked, name, handleInput, className, pattern, maxLength, required, disabled, rows, placeholder,
        onFocus, onBlur, onKeyPress,
        tooltip, tooltipOptions,
        invalidMessage, noLabel, marginBottom
    } = props
    let commonProps = {
        autoComplete: 'off',
        className: `${className ? className : ''} custom-input w-100 border-radius-0`,
        disabled,
        id,
        name,
        onBlur,
        onFocus,
        onKeyPress,
        ref,
        required,
        value,
        pattern,
        placeholder,
        maxLength
    }
    let numberProps = {
        inputClassName: 'h-100 border-radius-0',
        // onChange: handleInput,
        onValueChange: handleInput,
    }
    let textProps = {
        onChange: handleInput,
        type: type,
        rows: rows
    }

    const customInput = type === 'number' ?
        <InputNumber
            {...commonProps}
            {...numberProps}
        />
        :
        type === 'checkbox' ?
            <div className="p-field-checkbox custom-checkbox">
                <Checkbox
                    inputId={id}
                    checked={checked}
                    value={value}
                    tooltip={tooltip}
                    tooltipOptions={tooltipOptions}
                    onChange={handleInput}
                    disabled={disabled}
                    className={className}
                />
                <label className={`${labelClass ? labelClass : ''} p-mt-2`} htmlFor={id}>{label}</label>
            </div>
            :
            type === 'textarea' ?
                <InputTextarea
                    {...commonProps}
                    {...textProps}
                />
                :
                <InputText
                    {...commonProps}
                    {...textProps}
                />
    const floatLabel = <label
        htmlFor={id}
        className={`float-label ${labelClass ? labelClass : ''}`}
        style={(value || value === 0) ? {
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            fontSize: '11px ',
            backgroundColor: 'white',
            marginTop: '0px',
            paddingTop: '4px',
            paddingRight: '4px',
            paddingBottom: '0px',
            paddingLeft: '4px',
        } : undefined}
    >
        {`${label}${required ? '*' : ''}`}
    </label>
    return (
        noLabel ?
            <>
                {customInput}
            </>
            :
            <>
                <span className={`p-float-label ${marginBottom ? `p-mb-${marginBottom}` : 'p-mb-4'}`}>
                    {customInput}
                    {floatLabel}
                    <div style={{ marginLeft: '25%', lineHeight: '13px' }}
                        className={`invalid-tooltip p-text-left border-radius-0 w-75 p-my-0`}>
                        <small>{invalidMessage ? invalidMessage : 'This field is mandatory'}</small>
                    </div>
                </span>
            </>
    )
})

const handleChange = () => { }