import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import WelcomeSellerModal from '../WelcomeSellerModal'
// import PersonalDetailsForm from './sections/PersonalDetailsForm'
import QuickSignupForm from './sections/QuickSignupForm'
// import ShopDetailsForm from './sections/ShopDetailsForm'
// import BankDetailsForm from './sections/BankDetailsForm'
// import SignupFormStep4 from './sections/SignupFormStep4'
import { Can, AbilityContext } from '../../../../configs/Ability-context'
import LoaderModal from '../../../misc/components/LoaderModal'
import getOptions from "../../../misc/components/Options";
// import { getPlansFeatures, getSellerPlans } from "../../../../services/users/seller-plan-service";
import { createUser } from "../../../../services/users/user-service";
import { Toast } from 'primereact/toast';
import { Link } from 'react-router-dom'
import { CustomInput, CustomButton } from '../../../misc/components/Inputs'



// const countriesList = getOptions('countries')

const INITIAL_STATE = {
    // currentStep: 1,
    // allPlans: [],
    // planFeatures: [],

    name: '',
    email: '',

    password: '',
    cnfrmPassword: '',


}
let _isMounted = true

const QuickSignUp = (props) => {

    const [{
        name, email, password, seller, validationMessage, showWelcomePopup, isLoading
    }, setState] = useState({ ...INITIAL_STATE, seller: '', validationMessage: '', showWelcomePopup: false })

    // let personalDetailsForm = useRef(null)

    const ability = useContext(AbilityContext)
    const quickSignupForm = useRef(null);
    const emailField = useRef(null);
    const passwordField = useRef(null);
    const nameField = useRef(null);
    let history = useHistory()
    let toast = useRef(null)

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }));
    }

    const validate = (e) => {
        e.preventDefault();
        if (!quickSignupForm.current.checkValidity()) {
            quickSignupForm.current.classList.add('was-validated');
            return
        }
        else {
            // setIsLoading(true)
            handleSubmit();
        }
    }

    const handleSubmit = (e) => {
        // console.log("form submitted");
        // e.preventDefault();
        createNewSeller()
    }
    const createNewSeller = async () => {
        changeValue('isLoading', true)
        let newSeller = {
            name: name, email: email,
            password: password,
        }
        // console.log(newSeller)
        try {
            const { success, message, data } = await createUser(newSeller, 'seller')
            if (success) {
                toast.current?.show({ severity: 'success', summary: 'Success', detail: message })
            }
            else {
                changeValue('validationMessage', message)
            }
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    return (
        // <div className={`screen-center ${(currentStep === 1 || currentStep === 3 || currentStep === 4) ? 'mt-4 m-sm-0 py-5 py-sm-0 h-sm-100vh' : 'h-100vh'}`}>
        // <div className={'screen-center mt-4 m-sm-0 py-5 py-sm-0 h-sm-100vh' }>
        <>
            <div className={'screen-center h-50vh'}>
                <img
                    src="/logo.jpg"
                    // width={'12%'}
                    className='p-m-0 p-p-0 form-box-logo'
                    style={{ cursor: 'pointer' }}
                    onClick={() => { window.open('/home', '_self') }}
                />
                <form
                    ref={quickSignupForm}
                    // p-m-0 p-px-4 p-p-sm-0  w-100 w-sm-75 w-md-50 w-lg-25
                    className='form-box-rounded p-d-flex p-flex-column shadow p-3 pt-5 pb-5'
                    onSubmit={validate}
                    noValidate
                // style={{ borderRadius: '8px' }}
                >
                    <h5 className='p-text-bold color-secondary p-mb-3 p-mb-sm-4'>Create Account</h5>
                    <CustomInput
                        label='Full Name'
                        name={`name`}
                        value={name}
                        handleInput={handleInput}
                        ref={nameField}
                        required
                    />
                    <CustomInput
                        label='Email'
                        name={`email`}
                        value={email}
                        handleInput={handleInput}
                        ref={emailField}
                        required
                    />
                    <CustomInput
                        type='password'
                        label='Password'
                        name={`password`}
                        value={password}
                        handleInput={handleInput}
                        ref={passwordField}
                        required
                    />
                    <CustomButton
                        label="Register"
                        bg="Secondary"
                        color='tertiary'
                        type="submit"
                        className='w-100 p-ml-auto p-button-rounded p-button-raised'
                    />
                    <small className="text-center mt-3">Already have account?
                        <Link to="/login" className='ml-1'>
                            Go to Login
                        </Link>
                    </small>
                    <label className='p-mt-4 p-p-0 p-text-center validating-label'>{validationMessage}&nbsp;</label>
                </form>


                {/* <QuickSignupForm
                    name={name}
                    email={email}
                    password={password}
                    cnfrmPassword={cnfrmPassword}
                    handleInput={handleInput}
                    handleSubmit={handleSubmit}
                /> */}

                <Can not I='update' a='userProfile'>
                    <WelcomeSellerModal
                        seller={seller}
                        {...props}
                        modalShow={showWelcomePopup}
                    />
                </Can>
                <LoaderModal modalShow={isLoading} />
                <Toast ref={toast} className='p-mt-5 p-mr-3' />
            </div>
        </>
    )
}

export default QuickSignUp;