import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllAttributes from './pages/AllAttributes';
import NotFound from '../../misc/pages/NotFound'
import { PrivateRoute } from '../../misc/PrivateRoute'


const Attributes = () => {

    return (
        <Switch>
            <PrivateRoute path="/products/attributes/all">
                <AllAttributes />
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default Attributes