import React, { useState } from "react";
import { MDBCollapse, MDBCard, MDBCardBody } from "mdbreact";
import getOptions from '../../../../misc/components/Options'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { CustomButton } from '../../../../misc/components/Inputs'

const AttributesAccordion = ({ attributes, allAttributeTerms, handleRemoveAttribute, handleTermsChange, handleCheckboxChange, isVariableProduct }) => {
    const [collapseID, setCollapseID] = useState('')

    const toggleCollapse = collapseId => {
        setCollapseID(collapseID !== collapseId ? collapseId : ""
        );
    }

    const addNewTerm = (id) => {
        let newTerm = prompt("Enter a name for the new attribute term:");
        newTerm && handleTermsChange(id)({ custom: true, label: newTerm })
    }

    const getTermOptions = id => {
        let termOptions = []
        let attributeTerms = allAttributeTerms.filter(term => term.parent_id == id)
        termOptions = getOptions('attributeTerms', attributeTerms)
        // console.log(termOptions)
        return termOptions
    }
    // console.log(attributes)

    return (
        attributes.map(attribute => {
            return (
                <MDBCard className="border-radius-0 border-none" key={attribute.id}>
                    <p
                        onClick={() => { toggleCollapse(`${attribute.id}`) }}
                        className="color-primary-light font-weight-bold small m-0 p-2"
                        style={{ cursor: 'all-scroll' }}
                    >
                        {attribute.name}
                        <a href="#" onClick={() => { handleRemoveAttribute(attribute.id) }} className='small mt-1 text-danger float-right ml-3'>Remove</a>
                        <i
                            className={`${collapseID === `${attribute.id}` ? "fa fa-caret-down fa-rotate-180" : "fa fa-caret-down"} float-right fa-lg mt-1 cursor-pointer`}
                            style={{ transition: '0.3s' }}
                        />
                    </p>
                    <MDBCollapse id={`${attribute.id}`} isOpen={collapseID}>
                        <MDBCardBody className='m-0 px-0 bg-grey'>
                            <div className='mb-3 px-2'>
                                <MyOwnSelect
                                    options={getTermOptions(attribute.id)}
                                    optionsHeading={attribute.options?.length ? 'Select terms' : ''}
                                    handleChange={handleTermsChange(attribute.id)}
                                    placeHolder='Select terms'
                                    selectedValue={attribute.options}
                                    withOutIcon
                                    isMulti
                                    allowSelectAll
                                />
                            </div>
                            <div className="custom-checkbox mb-2 pl-4 ml-2 d-block">
                                <input
                                    type="checkbox"
                                    name={`attr-visible`}
                                    className="custom-control-input"
                                    id={`attr-visible-${attribute.id}`}
                                    checked={attribute.visible}
                                    onChange={(e) => { handleCheckboxChange(`${attribute.id}`)(e) }}
                                />
                                <label className="custom-control-label color-primary" htmlFor={`attr-visible-${attribute.id}`}>Visible on the product page</label>
                            </div>
                            {isVariableProduct &&
                                <div className="custom-checkbox mb-3 pl-4 ml-2 d-inline-block">
                                    <input
                                        type="checkbox"
                                        name={`attr-variation`}
                                        className="custom-control-input"
                                        id={`attr-variation-${attribute.id}`}
                                        checked={attribute.variation}
                                        onChange={(e) => { handleCheckboxChange(`${attribute.id}`)(e) }}
                                    />
                                    <label className="custom-control-label color-primary" htmlFor={`attr-variation-${attribute.id}`}>Used for variations</label>
                                </div>
                            }
                            <CustomButton
                                onClick={() => { addNewTerm(attribute.id) }}
                                bg='Primary'
                                color='tertiary'
                                label="Add new"
                                type="button"
                                className='mx-2 mb-3 float-right'
                            />
                        </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>
            )
        })
    );
}


export default AttributesAccordion