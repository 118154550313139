import React, { useState } from 'react';
import { Input } from '../../../../misc/components/Inputs'
import CustomEditor from '../../../../misc/components/CustomEditor'
import { TabMenu } from 'primereact/tabmenu';

export const DescriptionDataStep = (props) => {
    const [activeItem, setActiveItem] = useState({ key: 1, label: 'Description' })
    let {
        APO_product,
        changeValue,
        currentStep,

        description,
        descriptionDataForm,
        shortDescription,
    } = props

    // if (currentStep !== 4) {
    //     return null
    // }
    return (
        <form ref={descriptionDataForm} className={`m-0 p-0 ${currentStep !== 4 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-12'>
                    <TabMenu
                        activeItem={activeItem}
                        className='custom-tabmenu border-radius-0'
                        model={[
                            { label: 'Description', className: activeItem.label === 'Description' ? 'active' : '' },
                            { label: 'Short description', className: activeItem.label === 'Short description' ? 'active' : '' }
                        ]}
                        onTabChange={(e) => { setActiveItem(e.value) }}
                    />
                    {activeItem.label === 'Description' &&
                        <>
                            <CustomEditor
                                handleChange={(html) => changeValue('description', html)}
                                initialStateFromValue={APO_product !== 'create'}
                                value={description}
                                wrapperClassName={`${APO_product === 'read' ? 'mb-0' : 'mb-3'} color-primary border-radius-0`}
                                height={APO_product === 'read' ? 375 : 330}
                                disabled={APO_product === 'read'}
                            />
                            {APO_product !== 'read' &&
                                <Input
                                    type='textarea'
                                    label='html'
                                    name={`description`}
                                    value={description}
                                    disabled={true}
                                    containerClass='mb-3 mb-sm-1'
                                    rows='4'
                                />
                            }
                        </>
                    }
                    {activeItem.label === 'Short description' &&
                        <>
                            <CustomEditor
                                handleChange={(html) => changeValue('shortDescription', html)}
                                initialStateFromValue={APO_product !== 'create'}
                                value={shortDescription}
                                wrapperClassName={`${APO_product === 'read' ? 'mb-0' : 'mb-3'} color-primary border-radius-0`}
                                height={APO_product === 'read' ? 375 : 330}
                                disabled={APO_product === 'read'}
                            />
                            {APO_product !== 'read' &&
                                <Input
                                    type='textarea'
                                    label='html'
                                    name={`shortDescription`}
                                    value={shortDescription}
                                    containerClass='mb-3 mb-sm-1'
                                    rows='4'
                                />
                            }
                        </>
                    }
                </div>
            </div >
        </form >
    )
}
