import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';


const NotFound = () => {


  return (
    <>
      <MDBRow className='m-0 p-0'>
        <MDBCol md='12' className='text-center float-md-none mx-auto pt-3'>
          <img
            src="/404.png"
            alt="Error 404"
            className="img-fluid  animated"
            style={{ animationName: 'shake', animationIterationCount: 1, animationDelay: '0.5s' }} />
        </MDBCol>
      </MDBRow>
      <MDBRow center className='m-0 p-0'>
        <MDBCol md='12' className="text-center mb-5">
          <h2
            className="h2-responsive mt-4 mb-0 colorBlue fontRaleway animated"
            style={{ fontWeight: 'bold', animationName: 'zoomIn', animationIterationCount: 1, animationDelay: '0.8s' }}
          >
            Oops! This obviously isn't a page you were looking for.
          </h2>
          {/* <i
            className="animated fontRaleway"
            style={{ fontSize: '1.25rem', fontWeight: 500, animationName: 'fadeIn', animationIterationCount: 1, animationDelay: '1.3s' }}
          >
            please use following link to navigate back to home page.
          </i>
          <MDBCol md='2' className='mx-auto' >
            <MDBNavLink style={{fontWeight:500,letterSpacing:'2px'}} to='/home'>Click here</MDBNavLink>
          </MDBCol> */}
        </MDBCol>
      </MDBRow >
    </>
  )
}


export default NotFound