import React, { useState } from 'react';
import Aside from './Aside';
import Main from './Main';

function Layout({ isLoggedIn, logOut }) {
    // const [collapsed, setCollapsed] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    // const [collapsed, setCollapsed] = useState((window.screen.width < 769 && window.screen.width > 576) ? true : false);
    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };
  
    return (
        <div className={`app`}>
            {isLoggedIn &&
                <Aside
                    collapsed={collapsed}
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                    logOut={logOut}
                />
            }
            <Main/>
        </div>
    );
}

export default Layout;