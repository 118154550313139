import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import LoaderModal from '../misc/components/LoaderModal'



const InitialSystemSettings = (props) => {
    let systemSettingsForm = useRef(null)
    const [{
        appName, storeURL, cKey, cSecret, dbName, dbUsername, dbPassword, dbHost, dbPort,
        validationMessage, isLoading
    }, setState] = useState({
        appName: '', storeURL: '', cKey: '', cSecret: '', dbName: 'bucket_erp_test', dbUsername: 'asas', dbPassword: 'asas1234', dbHost: 'localhost', dbPort: '3306',
        validationMessage: '', isLoading: false
    })
    // console.log(props.modalShow)

    const changeValue = (name, value) => {
        setState(prevState => ({
            ...prevState,
            [name]: value,
            validationMessage: name === 'validationMessage' ? value : ''
        }))
    }

    let appFields = [
        {
            label: 'App name',
            id: 'appName',
            value: appName,
            helpText: 'e.g. My App',
        },
        {
            label: 'Store url',
            id: 'storeURL',
            value: storeURL,
            helpText: 'e.g. https://example.com',
        },
        {
            label: 'Consumer key',
            id: 'cKey',
            value: cKey,
            helpText: 'From store woocommerece',
        },
        {
            label: 'Consumer secret',
            id: 'cSecret',
            value: cSecret,
            helpText: 'From store woocommerece',
        }
    ]

    let dbFields = [
        {
            label: 'DB name',
            id: 'dbName',
            value: dbName,
            helpText: 'Your database name',
        },
        {
            label: 'DB username',
            id: 'dbUsername',
            value: dbUsername,
            helpText: 'Your database username',
        },
        {
            label: 'DB password',
            id: 'dbPassword',
            value: dbPassword,
            helpText: 'Your database password',
        },
        {
            label: 'DB host',
            id: 'dbHost',
            value: dbHost,
            helpText: 'Your database host address',
        },
        {
            label: 'DB port',
            type: 'number',
            id: 'dbPort',
            value: dbPort,
            helpText: 'The port on which your database is running',
        }
    ]

    const handleSubmit = (e) => {
        e.preventDefault();
        // let allvalid = [...appFields, ...dbFields].map((field, index) => {
        //     let input = document.getElementById(`${field.id}`)
        //     let isValid = input.checkValidity()
        //     if (!isValid) {
        //         input.classList.add('p-invalid')
        //     }
        //     return isValid
        // })
        // if (allvalid.some(validInput => !validInput)) {
        //     return
        // }
        // else {
        changeValue('isLoading', true)
        let options = {
            method: 'POST',
            body: JSON.stringify(
                {
                    appSettings: [
                        {
                            key: 'store_url',
                            value: storeURL,
                        },
                        {
                            key: 'c_k_secret',
                            value: cKey,
                        },
                        {
                            key: 'c_s_key',
                            value: cSecret,
                        },
                        {
                            key: 'app_name',
                            value: appName,
                        }
                    ],
                    dbName: dbName,
                    dbUsername: dbUsername,
                    dbPassword: dbPassword,
                    dbHost: dbHost,
                    dbPort: dbPort
                }
            ),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        fetch('/connectNewDB', options)
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                if (json.success && !json.isUsingDemoDB) {
                    changeValue('isLoading', false)
                    props.changeValue('isUsingDemoDB', false)
                }
                else {
                    let message = ''
                    switch (json.err.name) {
                        case 'SequelizeConnectionError':
                            message = 'Wrong DB name or username.'
                            console.log(message)
                            break;
                        case 'SequelizeHostNotFoundError':
                            message = 'Host not found.'
                            break;
                        case 'SequelizeAccessDeniedError':
                            message = 'Access denied.'
                            break;
                        case 'SequelizeConnectionRefusedError':
                            message = 'Please make sure port you given is correct.'
                            break;
                        default:
                            message = 'Sorry, something went wrong.'
                            break;
                    }
                    changeValue('isLoading', false)
                    changeValue('validationMessage', message)
                }
            })
            .catch((err) => { changeValue('isLoading', false); changeValue('validationMessage', 'Sorry, something went wrong.'); console.log(err) })
    }



    return (
        <Dialog
            header={<p className='color-primary m-0 p-0'>{"Before you proceed, please enter following details"}</p>}
            visible={props.modalShow}
            maximizable
            modal
            style={{ width: '50rem' }}
            closable={false}
            onHide={() => { }}
        >
            <form ref={systemSettingsForm} className='m-0 p-0 text-center' onSubmit={handleSubmit} noValidate>
                <h5 className='mb-4 color-secondary'><b>{'App specific'}</b></h5>
                <div className='row m-0 p-0'>
                    {
                        getFieldsInputs(appFields, changeValue)
                    }
                </div>
                <h5 className='mb-4 color-secondary'><b>{'Database specific '}</b><small style={{ fontSize: '12px' }}>{' (only mysql)'}</small></h5>
                <div className='row m-0 p-0'>
                    {
                        getFieldsInputs(dbFields, changeValue)
                    }
                    <div className='m-0 col-sm-6 text-center'>
                        <Button label="Save" type='submit' className="p-button-raised action-button px-5 bg-Primary w-100" />
                        <label className='validating-label mt-2'>{validationMessage}</label>
                    </div>
                </div>
            </form>
            <LoaderModal modalShow={isLoading} />
        </Dialog>
    )
}

export default InitialSystemSettings

const getFieldsInputs = (fields, changeValue) => {
    return fields.map((field, index) => {
        return (
            <div className="p-field mb-4 col-sm-6 text-right" key={index + 1}>
                <span className="p-float-label ">
                    <InputText
                        type={field.type && field.type}
                        id={field.id}
                        value={field.value}
                        onChange={(e) => changeValue(field.id, e.target.value)}
                        className='w-100'
                        required
                        autoComplete='off'
                    />
                    <label
                        htmlFor={field.id}
                        className='color-primary-light'
                        style={{ fontWeight: 550, fontSize: '14px' }}
                    >
                        {field.label}
                    </label>
                </span>
                <small id={`${field.id}-help`} className="p-d-block">{`${field.helpText}`}</small>
            </div>
        )
    })
}