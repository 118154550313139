import React from 'react';
import Role from './pages/sections/Role';
import { Redirect } from 'react-router-dom'
import { Can } from '../../../configs/Ability-context'
import { UserContext } from '../../../contexts/UserContext'

const SecuredEditRole = (props) => {
    const { isLoggedIn } = React.useContext(UserContext)

    if (!isLoggedIn) {
        return <Redirect to='/login' />
    }
    else {
        return <Can I='update' a='roles'> <Role APO_role='update' {...props} /></Can>
    }

}



export default SecuredEditRole 