import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { getTags, getSyncMeta, syncTags } from '../../../../services/products/tag-service';
import { animateSyncIcon } from '../../../misc/components/Helpers'
import { CustomListing } from '../../../misc/components/CustomListing'


let _isMounted
const AllTags = () => {
    let dt = useRef(null)
    let toast = useRef(null)

    const [{
        allTags, isLoading, isSyncing, totalRecords, syncedRecords, syncedPercentage, refreshData
    }, setState] = useState({
        allTags: [], isLoading: false, isSyncing: false, syncedRecords: 0, syncedPercentage: 0, refreshData: false
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getTags()
                changeValue('allTags', data)
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleSync = async (e) => {
        animateSyncIcon('start', e)
        changeValue('isSyncing', true)
        // fetching total tags and pages
        try {
            const { success, tags, startingPage, pages } = await getSyncMeta()
            if (success) {
                let _totalRecords = tags
                changeValue('totalRecords', _totalRecords)
                if (Number(_totalRecords) > 0) {
                    for (let pg = startingPage; pg <= pages; pg++) {
                        // fetching new records and syncing
                        try {
                            const json = await syncTags(pg)
                            //separating new records
                            if (json.data) {
                                const idsOfComing = json.data.map(c => c.id)
                                const idsOfAvailable = allTags.map(c => c.id)
                                const newIds = idsOfComing.filter(id => !idsOfAvailable.includes(id))
                                const newTags = json.data.filter(c => newIds.includes(c.id))
                                _isMounted && setState(prevState => ({
                                    ...prevState,
                                    syncedPercentage: ((prevState.allTags.length + newTags.length) / _totalRecords) * 100,
                                    syncedRecords: prevState.allTags.length + newTags.length,
                                    allTags: [...prevState.allTags, ...newTags]
                                }))
                            }
                        }
                        catch (error) {
                            console.log(error);
                            //stoping animation of sync icon
                            animateSyncIcon('error', e)
                            setTimeout(() => { changeValue('isSyncing', false) }, 500)
                            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
                        }
                    }
                }
                else {
                    animateSyncIcon('stop', e) //No product to sync
                    setTimeout(() => { changeValue('isSyncing', false) }, 500)
                    toast.current.show({ severity: 'info', summary: 'Sorry', detail: `No tag exists on your store.`, life: 4000 })
                }
                animateSyncIcon('stop', e)
                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                setTimeout(() => {
                    setState(prevState => ({
                        ...prevState,
                        refreshData: !prevState.refreshData,
                        syncedPercentage: 0,
                        syncedRecords: 0,
                        totalRecords: undefined
                    }))
                }, 600)
            }
            else {
                animateSyncIcon('error', e)
                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
            }
        }
        catch (err) {
            console.log(err)
            // stoping animation of sync icon
            animateSyncIcon('error', e)
            setTimeout(() => { changeValue('isSyncing', false) }, 500)
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
        }
    }

    let columns = [
        {
            field: "name",
            header: "Name",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by name",
            filterMatchMode: "contains",
        },
        {
            field: "count",
            header: "No. of products",
            selectedByDefault: true,
            sortable: true,
        },
    ]

    return (
        <Can I='read' a='tags'>
            <CustomListing
                allRecords={allTags}
                heading={'Tags'}
                columns={columns}
                permissionSubject={'tags'}
                isLoading={isLoading}
                handleSync={handleSync}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                isSyncing={isSyncing}
                totalRecords={totalRecords}
                syncedRecords={syncedRecords}
                syncedPercentage={syncedPercentage}
                tableRef={dt}
                toastRef={toast}
            />
        </Can>
    );
}


export default AllTags