import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SecuredNewRole from './SecuredNewRole';
import SecuredAllRoles from './SecuredAllRoles';
import SecuredEditRole from './SecuredEditRole';
import NotFound from '../../misc/pages/NotFound'


const Roles = () => {



    return (
        <Switch>
            <Route path="/users/roles/new" component={SecuredNewRole} />
            <Route path="/users/roles/all" component={SecuredAllRoles} />
            <Route path="/users/roles/edit/:role" component={SecuredEditRole} />
            <Route render={() => <NotFound />} />
        </Switch>

    )
}


export default Roles