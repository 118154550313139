import React, { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import WelcomeSellerModal from '../WelcomeSellerModal'
import PersonalDetailsForm from './sections/PersonalDetailsForm'
import ShopDetailsForm from './sections/ShopDetailsForm'
import BankDetailsForm from './sections/BankDetailsForm'
import SignupFormStep4 from './sections/SignupFormStep4'
import { Can, AbilityContext } from '../../../../configs/Ability-context'
import LoaderModal from '../../../misc/components/LoaderModal'
import getOptions from "../../../misc/components/Options";
import { getPlansFeatures, getSellerPlans } from "../../../../services/users/seller-plan-service";
import { createUser } from "../../../../services/users/user-service";


const countriesList = getOptions('countries')

const INITIAL_STATE = {
    currentStep: 1,
    allPlans: [],
    planFeatures: [],

    name: '',
    email: '',
    cell: '',
    address: '',
    city: '',
    state: '',
    selectedCountry: '',
    postcode: '',
    cnic: '',
    username: '',
    password: '',
    cnfrmPassword: '',

    shopName: '',
    shopCell: '',
    differentShopAddress: false,
    shopAddress: '',
    marketPlace: '',
    selectedSellerType: '',

    selectedBank: '',
    accountTitle: '',
    accountNumber: '',
    ibanNumber: '',

    selectedPlan: '',

    isLoading: false
}
let _isMounted

const SignUp = (props) => {

    const [{
        currentStep, allPlans, planFeatures,
        name, email, cell, address, city, state, selectedCountry, postcode,
        cnic, username, password, cnfrmPassword,
        shopName, shopCell, differentShopAddress, shopAddress, marketPlace, selectedSellerType,
        selectedBank, accountTitle, accountNumber, ibanNumber,
        selectedPlan,
        seller, validationMessage, showWelcomePopup, isLoading
    }, setState] = useState({ ...INITIAL_STATE, seller: '', validationMessage: '', showWelcomePopup: false })

    let personalDetailsForm = useRef(null)
    let shopDetailsForm = useRef(null)
    let bankDetailsForm = useRef(null)
    const ability = useContext(AbilityContext)
    let history = useHistory()

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            try {
                const { data } = await getSellerPlans()
                changeValue('allPlans', data)
                changeValue('selectedPlan', data.find(plan => plan.is_default).id)
                const features = await getPlansFeatures()
                changeValue('planFeatures', features.data)
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }));
    }

    const handleSelectChange = name => (selectedOption) => {
        changeValue(name, selectedOption)
    }

    const handleNumberInputKeyPress = (e) => {
        // let { name, value } = e.target
        let array = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
        if (array.includes(e.which)) { return }
        else { e.preventDefault() }
    }

    const handleCheckboxChange = (e) => {
        let { name, checked } = e.target
        changeValue(name, checked)
    }

    const goToNextStep = () => {
        let nextStep = currentStep >= 3 ? 4 : currentStep + 1,
            currentForm = currentStep === 1 ? personalDetailsForm.current :
                currentStep === 2 ? shopDetailsForm.current :
                    bankDetailsForm.current

        if (checkFormValidity(currentForm)) {
            changeValue('currentStep', nextStep)
        }
    }

    const goToPrevStep = () => {
        let previousStep = currentStep <= 1 ? 1 : currentStep - 1
        changeValue('currentStep', previousStep)
    }

    const checkFormValidity = (currentForm) => {
        if (currentStep === 1) {
            if (selectedCountry === '' || !selectedCountry) {
                setState(prevState => ({ ...prevState, selectedCountry: null, validationMessage: 'Please select your country' }))
                return false
            }
        }
        else if (currentStep === 2) {
            if (selectedSellerType === '' || !selectedSellerType) {
                setState(prevState => ({ ...prevState, selectedSellerType: null, validationMessage: 'Please specify business domain' }))
                return false
            }
        }
        else if (currentStep === 3) {
            if (selectedBank === '' || !selectedBank) {
                setState(prevState => ({ ...prevState, selectedBank: null, validationMessage: 'Please select a bank name' }))
                return false
            }
        }
        if (!currentForm.checkValidity()) {
            currentForm.classList.add('was-validated')
            return false
        }
        changeValue('validationMessage', ``)
        return true
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createNewSeller()
    }

    const createNewSeller = async () => {
        changeValue('isLoading', true)
        let newSeller = {
            name: name, email: email, cell: cell, address: address,
            city: city, state: state, country: selectedCountry.value, postcode: postcode,
            cnic: cnic, username: username, password: password,
            shop_name: shopName, shop_cell: shopCell, shop_address: differentShopAddress ? shopAddress : address, market_place: marketPlace, seller_type: selectedSellerType.value,
            bank_name: selectedBank.value, ac_title: accountTitle, ac_id: accountNumber, iban_id: ibanNumber,
            seller_plan_id: selectedPlan
        }
        // console.log(newSeller)
        try {
            const { success, message, data } = await createUser(newSeller, 'seller')
            changeValue('validationMessage', message)
            if (success) {
                let seller = { ...data, plan: allPlans.find(p => p.id === selectedPlan) }
                setState(prevState => ({
                    ...prevState,
                    seller: seller,
                    ...INITIAL_STATE,
                    showWelcomePopup: true
                }));
                setTimeout(() => { ability.can('update', 'userProfile') && history.push('/sellers/all') }, 1000)
            }
        }
        catch (error) {
            console.log(error)
        }

        changeValue('isLoading', false)

    }

    return (
        <div className={`screen-center ${(currentStep === 1 || currentStep === 3 || currentStep === 4) ? 'mt-4 m-sm-0 py-5 py-sm-0 h-sm-100vh' : 'h-100vh'}`}>
            {currentStep === 1 && <div className='w-md-75 w-lg-50 m-0 p-0'>
                <h5 className='font-weight-bold mb-4 color-secondary text-center'>Personal details</h5>
                <PersonalDetailsForm
                    currentStep={currentStep}
                    personalDetailsForm={personalDetailsForm}
                    countriesList={countriesList}
                    name={name}
                    email={email}
                    cell={cell}
                    address={address}
                    city={city}
                    state={state}
                    selectedCountry={selectedCountry}
                    postcode={postcode}
                    cnic={cnic}
                    username={username}
                    password={password}
                    cnfrmPassword={cnfrmPassword}

                    goToNextStep={goToNextStep}
                    handleInput={handleInput}
                    handleSelectChange={handleSelectChange}
                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                />
            </div>}
            {currentStep === 2 && <div className='w-md-75 w-lg-50 m-0 p-0'>
                <h5 className='font-weight-bold mb-4 color-secondary text-center'>Business details</h5>
                <ShopDetailsForm
                    currentStep={currentStep}
                    shopDetailsForm={shopDetailsForm}
                    shopName={shopName}
                    differentShopAddress={differentShopAddress}
                    address={address}
                    shopAddress={shopAddress}
                    shopCell={shopCell}
                    marketPlace={marketPlace}
                    selectedSellerType={selectedSellerType}

                    goToNextStep={goToNextStep}
                    goToPrevStep={goToPrevStep}
                    handleInput={handleInput}
                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                    handleSelectChange={handleSelectChange}
                    handleCheckboxChange={handleCheckboxChange}
                />
            </div>}
            {currentStep === 3 && <div className='w-md-75 w-lg-50 m-0 p-0'>
                <h5 className='font-weight-bold mb-4 color-secondary text-center'>Bank details</h5>
                <BankDetailsForm
                    currentStep={currentStep}
                    bankDetailsForm={bankDetailsForm}
                    selectedBank={selectedBank}
                    accountTitle={accountTitle}
                    accountNumber={accountNumber}
                    ibanNumber={ibanNumber}

                    goToNextStep={goToNextStep}
                    goToPrevStep={goToPrevStep}
                    handleInput={handleInput}
                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                    handleSelectChange={handleSelectChange}
                />
            </div>}
            <SignupFormStep4
                currentStep={currentStep}
                selectedPlan={selectedPlan}
                allPlans={allPlans}
                planFeatures={planFeatures}

                changeValue={changeValue}
                goToPrevStep={goToPrevStep}
                handleSubmit={handleSubmit}
            />
            <label className='p-0 validating-label mt-3'>{validationMessage}&nbsp;</label>
            <Can not I='update' a='userProfile'>
                <WelcomeSellerModal
                    seller={seller}
                    {...props}
                    modalShow={showWelcomePopup}
                />
            </Can>
            <LoaderModal modalShow={isLoading} />
        </div>
    )
}



export default SignUp;