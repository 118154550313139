import React from 'react';

const getOptions = (name, Entities) => {
    let options
    switch (name) {
        case 'users':
        case 'sellers':
        case 'shopManagers':
        case 'clients':
        case 'roles':
        case 'companies':
        case 'sellerManagers':
        case 'priceRules':
        case 'planFeatures':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: name === 'sellers' ? entity.shop_name : entity.name,
                value: entity.id,
                cell: name === 'sellers' ? entity.cell : null
            }))
            return Array.isArray(options) && options.length > 0 ? name === 'sellers' ? options : options.map(o => { delete o.cell; return o }) : null

        case 'rolesForFilter':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: entity.name,
                value: entity.id,
            }))
            options = options.length && options.map(option => JSON.stringify(option))
            options = Array.isArray(options) && options.length > 0 ? [...new Set(options)] : null
            options = options.length && options.map(option => JSON.parse(option))
            options = options.length && options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
            return options

        case 'products':
        case 'attributes':
        case 'attributeTerms':
        case 'productCategories':
        case 'brands':
        case 'tags':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: entity.name,
                value: entity.bkt_id,
            }))
            return Array.isArray(options) && options.length > 0 ? options : null

        // case 'productCategoriesForFilter':
        //     options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
        //         key: entity.id,
        //         label: entity.name,
        //         value: entity.id,
        //     }))
        //     options = options.length && options.map(option => JSON.stringify(option))
        //     options = Array.isArray(options) && options.length ? [...new Set(options)] : null
        //     options = options.length && options.map(option => JSON.parse(option))
        //     options = options.length && options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        //     return options

        case 'permissions':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => entity.enabled &&
                ({
                    key: entity.id,
                    label: entity.slug,
                    action: entity.action,
                    subject: entity.subject,
                    value: entity.id,
                })
            )
            options = options.length ? options.filter(option => option) : null
            let groupedOptions = []
            options && options.map((option, index) => {
                if (index !== 0) {
                    if (index > 0 && index < 3) {
                        groupedOptions.push(option)
                    }
                    else {
                        index === 3 || !groupedOptions.find(groupedOption => groupedOption.label === option.subject) ?
                            groupedOptions.push({
                                label: option.subject,
                                options: [option]
                            })
                            :
                            groupedOptions.map((groupedOption) => {
                                if (groupedOption.label === option.subject) {
                                    groupedOption.options.push(option)
                                }
                                return groupedOption
                            })
                    }
                }
            })
            return groupedOptions

        case 'orderSources':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: <span>{entity.title}&nbsp;&nbsp;&nbsp;<small className="text-muted">{entity.link ? entity.link : entity.cell}</small></span>,
                value: entity.id,
                title: entity.title,
            }))
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'orderStatuses':
        case 'itemStatuses':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.filter(entity => entity.is_enabled)
            options = options.length ? options.map(entity => ({
                key: entity.id,
                label: `${entity.title}${entity.default ? ' (default)' : ''}`,
                value: entity.slug,
                // default: entity.is_default,
                entity: entity,
            }))
                :
                []
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'sellerItemStatuses':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.filter(entity => (entity.seller_usable && entity.is_enabled))
            options = options.length ? options.map(entity => ({
                key: entity.id,
                label: `${entity.title}${entity.default ? ' (default)' : ''}`,
                value: entity.slug,
                // default: entity.is_default,
                entity: entity,
            })) : []
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'orderProducts':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: entity.name,
                value: entity.bkt_id,
                rate: entity.regular_price,
                type: entity.type,
                variations: entity.variations,
                attributes: entity.attributes,
                salePrice: entity.sale_price,
                saleFrom: entity.date_on_sale_from,
                saleTo: entity.date_on_sale_to
            }))
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'variations':
            let selectedProduct = Entities
            let variations = selectedProduct.variations
            let productAttributes = selectedProduct.attributes
            variations = variations.map(variation => {
                let variationAttributes = variation.attributes
                let unspecifiedAttributes = productAttributes.map(attr => variationAttributes.map(varAttr => varAttr.name).includes(attr.name) ? '' : ({ name: attr.name, option: 'Any' }))
                unspecifiedAttributes = unspecifiedAttributes.filter(attr => attr)
                return ({
                    ...variation,
                    attributes: [...variation.attributes, ...unspecifiedAttributes]
                })
            })
            options = Array.isArray(variations) && variations.length > 0 && variations.map(variation => ({
                key: variation.id,
                label: variation.attributes.map(attr => `${attr.name}: ${attr.option}`).join(', '),
                value: variation.bkt_id,
                rate: variation.regular_price,
                salePrice: variation.sale_price,
                saleFrom: variation.date_on_sale_from,
                saleTo: variation.date_on_sale_to
            }))
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'sellerPlans':
        case 'paymentMethods':
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                key: entity.id,
                label: entity.title,
                value: entity.id,
            }))
            return Array.isArray(options) && options.length > 0 ? options : null

        case 'cities':
            // if (Entities === 'tpl') {
            //     Entities = ['HYDERABAD', 'KARACHI', 'FAISALABAD']
            // }
            // else {
            // }
            let sortedTcsCities = tcsCitiesList.sort((a, b) => a !== b ? a < b ? -1 : 1 : 0);
            Entities = sortedTcsCities
            options = Array.isArray(Entities) && Entities.length > 0 && Entities.map(entity => ({
                label: entity, value: entity
            }))
            return Array.isArray(options) && options.length > 0 ? options : null
            break;
        case 'states':
            return statesList[Entities]
        case 'productTypes':
            return productTypeOptions
        case 'productStatuses':
            return productStatusOptions
        case 'stockStatuses':
            return stockStatusOptions
        case 'backorders':
            return backordersOptions
        case 'voucherTypes':
            return voucherTypeOptions
        case 'voucherReceivers':
            return voucherReceiverOptions
        case 'banks':
            options = bankNames.map((bank, index) => ({
                key: index,
                label: bank,
                value: bank,
            }))
            return options
        case 'orderTypes':
            return orderTypeOptions
        case 'couriers':
            return courierOptions
        case 'countries':
            return countriesList
        case 'sellerTypes':
            return sellerTypeOptions
        case 'accountStatuses':
            return accountStatusOptions
        default:
            break;
    }
}

export default getOptions


const productTypeOptions = [
    { key: 1, label: 'Simple product', value: 'simple' },
    // { key: Math.random(), label: 'External/Affiliate product', value: 'external' },
    { key: 2, label: 'Variable product', value: 'variable' },
    // { key: 3, label: 'Grouped product', value: 'grouped' },
],
    productStatusOptions = [
        { key: 1, label: 'Pending', value: 'pending' },
        { key: 2, label: 'Published', value: 'publish' },
        { key: 3, label: 'Draft', value: 'draft' },
        { key: 4, label: 'Details required', value: 'required' },
        { key: 5, label: 'Rejected', value: 'rejected' },
    ],
    stockStatusOptions = [
        { key: 1, label: 'In stock', value: 'instock' },
        { key: 2, label: 'Out of stock', value: 'outofstock' },
        { key: 3, label: 'On backorder', value: 'onbackorder' },
    ],
    backordersOptions = [
        { key: 1, label: 'Do not allow', value: 'no' },
        { key: 2, label: 'Allow, but notify customer', value: 'notify' },
        { key: 3, label: 'Allow', value: 'yes' }
    ],
    voucherTypeOptions = [
        { key: 1, label: 'Billing invoice', value: "invoice" },
        { key: 2, label: 'Payment voucher', value: "voucher" }
    ],
    voucherReceiverOptions = [
        { key: 1, label: 'Ismail Sheikh', value: "Ismail Sheikh" },
        { key: 2, label: 'Akbar Hashmi', value: "Akbar Hashmi" }
    ],
    orderTypeOptions = [
        { key: 1, label: 'Sale', value: 'sale' },
        { key: 2, label: 'Query', value: 'query' },
        { key: 3, label: 'Return', value: 'return' },
        { key: 4, label: 'Replacement', value: 'replace' },
    ],
    courierOptions = [
        { key: 1, label: 'M&P', value: 'mnp' },
        { key: 2, label: 'TPL', value: 'tpl' },
        { key: 3, label: 'TCS', value: 'tcs' },
        { key: 4, label: 'bExpress', value: 'bexpress' },
        { key: 5, label: 'Local Pickup', value: 'local' },
    ],
    sellerTypeOptions = [
        { key: 1, label: 'Importer', value: 'imp' },
        { key: 2, label: 'Exporter', value: 'exp' },
        { key: 3, label: 'Wholesaler', value: 'wls' },
        { key: 4, label: 'Manufacturer', value: 'mnf' },
        { key: 5, label: 'Distributor', value: 'dst' },
        { key: 6, label: 'Retailer', value: 'rtl' },
    ],
    accountStatusOptions = [
        { key: 1, label: 'Pending Approval', value: "pending" },
        { key: 2, label: 'Approved', value: "approved" },
        { key: 3, label: 'Rejected', value: "rejected" }
    ],
    // bankNames = [
    //     'Al Baraka Bank (Pakistan) Limited', 'Allied Bank Limited', 'Askari Bank Limited', 'Bank Alfalah Limited',
    //     'Bank Al-Habib Limited', 'BankIslami Pakistan Limited', 'Burj Bank Limited', 'Citi Bank N.A', 'Deutsche Bank A.G',
    //     'Dubai Islamic Bank Pakistan Limited', 'Faysal Bank Limited', 'First Women Bank Limited', 'Habib Bank Limited',
    //     'Habib Metropolitan Bank Limited', 'Industrial and Commercial Bank of China', 'Industrial Development Bank of Pakistan',
    //     'JS Bank Limited', 'MCB Bank Limited', 'MCB Islamic Bank Limited', 'Meezan Bank Limited', 'National Bank of Pakistan',
    //     'NIB Bank Limited', 'S.M.E. Bank Limited', 'Samba Bank Limited', 'Silk Bank Limited', 'Sindh Bank Limited', 'Soneri Bank Limited',
    //     'Standard Chartered Bank (Pakistan) Limited', 'Summit Bank Limited', 'Telenor microfinance bank', 'The Bank of Khyber', 'The Bank of Punjab',
    //     'The Bank of Tokyo-Mitsubishi Limited', 'The Punjab Provincial Cooperative Bank Limited', 'United Bank Limited',
    //     'Zarai Taraqiati Bank Limited'
    // ],
    bankNames=[
            "Abu Dhabi Commercial Bank","Abu Dhabi Islamic Bank","Ajman Bank","Al Hilal Bank","Al Maryah Community Bank","Al Masraf Arab Bank for Investment & Foreign Trade",
            "Bank of Sharjah","Commercial Bank International","Commercial Bank of Dubai","Dubai Bank","Dubai Finance Bank","Dubai Islamic Bank","Emirates Investment Bank","Emirates Islamic",
            "Emirates NBD","First Abu Dhabi Bank","Invest Bank","Mashreq","National Bank of Fujairah","National Bank of Ras Al-Khaimah PJSC (RAKBANK)","National Bank of Umm Al-Quwain",
            "Noor Bank","Sharjah Islamic Bank","United Arab Bank","Wio Bank"
            ],
    tcsCitiesList = [
        'KARACHI', 'GHARO', 'GAWADAR', 'HYDERABAD', 'HUB', 'PANJGOOR', 'PASNI', 'THATTA', 'TURBAT', 'AHMED PUR LAMMA', 'AWARAN', 'BADIN', 'BARKHAN', 'BELA',
        'BHIRIA ROAD', 'CHAMAN', 'DERA ALLAHYAR', 'DADU', 'DHADAR', 'DIGRI', 'DAHARKI', 'DOKRI', 'DALBANDIN', 'DERAMURAD JAMAL', 'GAMBAT', 'GUDDU', 'GARI KHAIRO',
        'GOLARCHI', 'GHOUS PUR', 'GHOTKI', 'GAWADAR', 'GARI YASIN', 'HALA', 'ISLAMKOT', 'JARWAR', 'JACOBABAD', 'JAMALDIN WALI', 'JHAT PAT', 'KAMBAR ALI KHAN', 'KHAN BELA',
        'KOT GHULAM MUHD', 'KASHMORE', 'KHAIRPUR', 'KHARAN', 'KANDH KOT', 'KALAT', 'KANDYARO', 'KUNRI', 'KHAIRPUR NATHAN', 'KHONAZAI', 'KHIPRO', 'KOT SAMABAH',
        'QILLA SAIFULLAH', 'LARKANA', 'MUSLIM BAGH', 'LIAQUATPUR', 'LORA LAI', 'MIRWAH GORCHANI', 'MACHI GOTH', 'MEHAR', 'MITHI', 'MIRPUR KHAS', 'MIRPUR MATHELO',
        'MITYARI', 'MORO', 'MEHRAB PUR', 'MIRPUR SAKRO', 'MASTUNG', 'MATLI', 'MUCH', 'NASIRABAD', 'NAUDERO', 'NOSHERO FEROZ', 'NEW JATOI', 'NOSHKI', 'PISHIN',
        'KHANPUR', 'DOKRI', 'PACCA CHANG', 'PIRYALO', 'PANNU AKIL', 'QAZI AHMED', 'RADHAN STATION', 'RANIPUR', 'RATTO DERO', 'RAHIMYARKHAN', 'SAKRAND', 'SANGHAR',
        'SIBI', 'SADIQABAD', 'SHAHDAD KOT', 'SHIKARPUR', 'SINJHORO', 'SUKKUR', 'SHAHDADPUR', 'SUI', 'SUJAWAL', 'TANDO ALLAYAR', 'TANDO JAM', 'TANDO ADAM', 'THULL',
        'TANDO MOHD KHAN', 'KHUZDAR', 'QUETTA', 'DUKKI', 'UMERKOT', 'UBARO', 'USTA MOHAMMAD', 'UTHAL', 'WINDER', 'NAWABSHAH', 'WARAH', 'ZHOB', 'ZAHIRPEER', 'LAHORE',
        'ARIF WALA', 'ABDUL HAKIM', 'BAHWALPUR', 'BHAKKAR', 'BHAI PHARU', 'BUREWALA', 'CHICHAWATNI', 'CHISHTIAN', 'DERA ISMAIL KHA', 'FAISALABAD', 'HAROONABAD', 'HASILPUR',
        'JHANG', 'JHANIAN', 'JOUHARABAD', 'KABIR WALA', 'KALA SHAH KAKU', 'KANA NAU', 'KASUR', 'KHANEWAL', 'MINCHANABAD', 'MIAN CHANOO', 'MURIDKEY', 'MULTAN', 'MIANWALI',
        'BAHAWALNAGAR', 'NANKANA SAHIB', 'OKARA', 'PATOKI', 'RAIWAND', 'SARGODAH', 'SHEIKHUPURA', 'SUNDAR ADDA', 'SAHIWAL', 'VEHARI', 'ADDA ZAKHEERA', 'ALIPUR',
        'ADDA LAR', 'AMINPUR BANGLOW', 'AHMED PUR EAST', 'AHMED PUR SIAL', 'CHENAB NAGAR', '18 HAZARI', 'BHERA', 'BASIR PUR', 'BHAWANA', 'BHALWAL', 'CHOWK AZAM',
        'CHAK JHUMRA', 'SARWAR SHAHEED', 'DARY KHAN', 'DERA GHAZI KHAN', 'DIJKOT', 'DAUD KHEL', 'DAKOTA', 'DHODHAK', 'DEPAL PUR', 'DUNYA PUR', 'DHARANWALA',
        'ELLAH ABAD', 'NAUSHERA', 'FORTE ABBAS', 'FARROQABAD', 'FATEHPUR', 'FEROZ WATOWAN', 'FAZIL PUR', 'GHAZI ABAD', 'GAGGO MANDI', 'GARH MOR', 'BANGLOW GOGERA',
        'HARNOULI', 'HUJRA SHAMUQEEM', 'MURIDWALA', 'ISKANDARABAD', 'ISSA KHEL', 'IQBAL NAGAR', 'CHINIOT', 'JAMPUR', 'JALALPURPIRWALA', 'JATOI', 'JARANWALA',
        'KAROR LALESAN', 'KALA BAGH', 'KOT RADHA KISHA', 'KHIDDER WALA', 'KHANEWAL', 'KOT CHUTTA', 'KAMALIA', 'KAMER MOSHANI', 'KAMALIA', 'KOT MOMIN',
        'KOT ADDU', 'KAROR PAKKA', 'KASOWAL', 'KOT MITTHAN', 'KHAIPUR TAMEWAL', 'LAKI MARWAT', 'LALIAN', 'LODHRAN', 'LAYYAH', 'MAKHDOOM AALI', 'MINCHANABAD',
        'MAMUN KANJAN', 'MEHMOODKOT', 'MAILSI', 'MUZAFFARGARH', 'NARWALA BANGLA', 'NOORPUR', 'OKARA CANTT', 'KALOOR KOT', 'PAINSRA', 'PIPLAN', 'PEER MAHAL',
        'PAK PATTAN SHAR', 'QABOOLA', 'QADIRPUR RAWAN', 'RAJANA', 'RAJANPUR', 'RENALA KHURD', 'GOJRA', 'KHUSHAB', 'SAMANDRI', 'SAHIWAL - FSD', 'SHUJAABAD',
        'SANGLA HILL', 'SHAHKOT', 'CHASHMA', 'SILANWALI', 'SANAWAN', 'SHARAQPUR', 'SHORKOT', 'TANK', 'TIBBA SULTAN', 'TOBATEK-SINGH', 'TANDLIANWALA', 'UCH SHARIF',
        'WAN BACHRAN', 'YAZMAN MANDI', 'RAWALPINDI', 'ISLAMABAD', 'ABBOTABAD', 'CHAKWAL', 'DASKA', 'GUJRAT', 'GUJRANWALA', 'JHELUM', 'KAMOKE', 'KHARIAN',
        'MANDI BAHAUDDIN', 'NOWSHERA', 'PESHAWAR', 'MIRPUR A.K.', 'SIALKOT', 'WAH', 'WAZIRABAD', 'ATTOCK', 'BANNU', 'BARA KAHU', 'CHARSADDA', 'CHAWINDA',
        'DARA ADM KHEL', 'DINA', 'FATEH JANG', 'GADOON AMAZAI', 'GUJARKHAN', 'HAFIZABAD', 'LALAMUSA', 'MUZAFFARABAD AK', 'MARDAN', 'NAROWAL', 'KOHAT',
        'KHEWRA DANDOT', 'PASROOR', 'MURREE', 'SUMBRIAL', 'SHAKAR GARH', 'SHABQADAR', 'MINGORA', 'TALAGANG', 'BAGH', 'BAJWAR', 'BALAKOT', 'BHIMBER', 'BATKHELA',
        'BUNAIR', 'CHOA SYDEN SHAH', 'CHAK SAWARI', 'CHITRAL', 'DINGA', 'DARGAI', 'DADYAL (AK)', 'GHAKHAR MANDI', 'GHAKAR', 'GILGIT', 'HAIRA', 'HUMAK', 'HANGU',
        'HUNZA', 'HARIPUR', 'HASSAN ABDAL', 'HATTER', 'HAVELLIAN', 'HAZROO', 'JALALPUR UATTAN', 'JALALPUR JATTAN', 'JHANGIRA', 'SAKARDU', 'KOTLI-A.KASHMIR',
        'KAMRA', 'KHAPLU', 'KHARIAN CANTT', 'KARAK', 'KAHOTA', 'KOTLA', 'KHAZAKHELA', 'LANDIKOTAL', 'MANGLA',
        'MANSHERA', 'NOWSHERA VIRKAN', 'OGHI', 'OLE RWP', 'PUBBI', 'PHALIA', 'PALANDRI', 'QALANDRABAD', 'QILA DEDAR SING', 'SAKARDU', 'RAWALAKOT',
        'RAWAT', 'SRAI ALAMGEER', 'SERAYNORANG', 'SHINKIARI', 'SWABI', 'TALL', 'TAKHAT BAI', 'TEMARGARAH', 'TOPI', 'TARBELA',
        'TERNOL', 'TAXILA', 'UPPER DIR', 'VARI DIR', 'ZAFARWAL', 'TOPI', 'TARBELA', 'TERNOL', 'TAXILA', 'UPPER DIR', 'VARI DIR', 'ZAFARWAL'
    ]

const statesList = {
    'PK': [
        { key: 1, label: 'Azad Jammu & Kashmir', value: 'JK' },
        { key: 2, label: 'Balochistan', value: 'BA' },
        { key: 3, label: 'FATA', value: 'TA' },
        { key: 4, label: 'Gilgit Baltistan', value: 'GB' },
        { key: 5, label: 'Islamabad Capital Territory', value: 'IS' },
        { key: 6, label: 'Khyber Pakhtunkhwa', value: 'KP' },
        { key: 7, label: 'Punjab', value: 'PB' },
        { key: 8, label: 'Sindh', value: 'SD' },
    ],
    'UAE': [
        { key: 1, label: 'Abu Dhabi', value: 'AD' },
        { key: 2, label: 'Ajman', value: 'AJ' },
        { key: 3, label: 'Dubai', value: 'Dubai' },
        { key: 4, label: 'Fujairah', value: 'FJ' },
        { key: 5, label: 'Ras Al Khaimah', value: 'RK' },
        { key: 6, label: 'Sharjah', value: 'SH' },
        { key: 7, label: 'Umm Al Quwain', value: 'UQ' },
        { key: 8, label: 'Al Ain', value: 'AA' },
    ]
}
const countriesList = [
    { key: 1, label: "Afghanistan", value: "AF" },
    { key: 2, label: "Åland Islands", value: "AX" },
    { key: 3, label: "Albania", value: "AL" },
    { key: 4, label: "Algeria", value: "DZ" },
    { key: 5, label: "American Samoa", value: "AS" },
    { key: 6, label: "Andorra", value: "AD" },
    { key: 7, label: "Angola", value: "AO" },
    { key: 8, label: "Anguilla", value: "AI" },
    { key: 9, label: "Antarctica", value: "AQ" },
    { key: 10, label: "Antigua and Barbuda", value: "AG" },
    { key: 11, label: "Argentina", value: "AR" },
    { key: 12, label: "Armenia", value: "AM" },
    { key: 13, label: "Aruba", value: "AW" },
    { key: 14, label: "Australia", value: "AU" },
    { key: 15, label: "Austria", value: "AT" },
    { key: 16, label: "Azerbaijan", value: "AZ" },
    { key: 17, label: "Bahamas", value: "BS" },
    { key: 18, label: "Bahrain", value: "BH" },
    { key: 19, label: "Bangladesh", value: "BD" },
    { key: 20, label: "Barbados", value: "BB" },
    { key: 21, label: "Belarus", value: "BY" },
    { key: 22, label: "Belgium", value: "BE" },
    { key: 23, label: "Belize", value: "BZ" },
    { key: 24, label: "Benin", value: "BJ" },
    { key: 25, label: "Bermuda", value: "BM" },
    { key: 26, label: "Bhutan", value: "BT" },
    { key: 27, label: "Bolivia (Plurinational State of)", value: "BO" },
    { key: 28, label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
    { key: 29, label: "Bosnia and Herzegovina", value: "BA" },
    { key: 30, label: "Botswana", value: "BW" },
    { key: 31, label: "Bouvet Island", value: "BV" },
    { key: 32, label: "Brazil", value: "BR" },
    { key: 33, label: "British Indian Ocean Territory", value: "IO" },
    { key: 34, label: "United States Minor Outlying Islands", value: "UM" },
    { key: 35, label: "Virgin Islands (British)", value: "VG" },
    { key: 36, label: "Virgin Islands (U.S.)", value: "VI" },
    { key: 37, label: "Brunei Darussalam", value: "BN" },
    { key: 38, label: "Bulgaria", value: "BG" },
    { key: 39, label: "Burkina Faso", value: "BF" },
    { key: 40, label: "Burundi", value: "BI" },
    { key: 41, label: "Cambodia", value: "KH" },
    { key: 42, label: "Cameroon", value: "CM" },
    { key: 43, label: "Canada", value: "CA" },
    { key: 44, label: "Cabo Verde", value: "CV" },
    { key: 45, label: "Cayman Islands", value: "KY" },
    { key: 46, label: "Central African Republic", value: "CF" },
    { key: 47, label: "Chad", value: "TD" },
    { key: 48, label: "Chile", value: "CL" },
    { key: 49, label: "China", value: "CN" },
    { key: 50, label: "Christmas Island", value: "CX" },
    { key: 51, label: "Cocos (Keeling) Islands", value: "CC" },
    { key: 52, label: "Colombia", value: "CO" },
    { key: 53, label: "Comoros", value: "KM" },
    { key: 54, label: "Congo", value: "CG" },
    { key: 55, label: "Congo (Democratic Republic of the)", value: "CD" },
    { key: 56, label: "Cook Islands", value: "CK" },
    { key: 57, label: "Costa Rica", value: "CR" },
    { key: 58, label: "Croatia", value: "HR" },
    { key: 59, label: "Cuba", value: "CU" },
    { key: 60, label: "Curaçao", value: "CW" },
    { key: 61, label: "Cyprus", value: "CY" },
    { key: 62, label: "Czech Republic", value: "CZ" },
    { key: 63, label: "Denmark", value: "DK" },
    { key: 64, label: "Djibouti", value: "DJ" },
    { key: 65, label: "Dominica", value: "DM" },
    { key: 66, label: "Dominican Republic", value: "DO" },
    { key: 67, label: "Ecuador", value: "EC" },
    { key: 68, label: "Egypt", value: "EG" },
    { key: 69, label: "El Salvador", value: "SV" },
    { key: 70, label: "Equatorial Guinea", value: "GQ" },
    { key: 71, label: "Eritrea", value: "ER" },
    { key: 72, label: "Estonia", value: "EE" },
    { key: 73, label: "Ethiopia", value: "ET" },
    { key: 74, label: "Falkland Islands (Malvinas)", value: "FK" },
    { key: 75, label: "Faroe Islands", value: "FO" },
    { key: 76, label: "Fiji", value: "FJ" },
    { key: 77, label: "Finland", value: "FI" },
    { key: 78, label: "France", value: "FR" },
    { key: 79, label: "French Guiana", value: "GF" },
    { key: 80, label: "French Polynesia", value: "PF" },
    { key: 81, label: "French Southern Territories", value: "TF" },
    { key: 82, label: "Gabon", value: "GA" },
    { key: 83, label: "Gambia", value: "GM" },
    { key: 84, label: "Georgia", value: "GE" },
    { key: 85, label: "Germany", value: "DE" },
    { key: 86, label: "Ghana", value: "GH" },
    { key: 87, label: "Gibraltar", value: "GI" },
    { key: 88, label: "Greece", value: "GR" },
    { key: 89, label: "Greenland", value: "GL" },
    { key: 90, label: "Grenada", value: "GD" },
    { key: 91, label: "Guadeloupe", value: "GP" },
    { key: 92, label: "Guam", value: "GU" },
    { key: 93, label: "Guatemala", value: "GT" },
    { key: 94, label: "Guernsey", value: "GG" },
    { key: 95, label: "Guinea", value: "GN" },
    { key: 96, label: "Guinea-Bissau", value: "GW" },
    { key: 97, label: "Guyana", value: "GY" },
    { key: 98, label: "Haiti", value: "HT" },
    { key: 99, label: "Heard Island and McDonald Islands", value: "HM" },
    { key: 100, label: "Holy See", value: "VA" },
    { key: 101, label: "Honduras", value: "HN" },
    { key: 102, label: "Hong Kong", value: "HK" },
    { key: 103, label: "Hungary", value: "HU" },
    { key: 104, label: "Iceland", value: "IS" },
    { key: 105, label: "India", value: "IN" },
    { key: 106, label: "Indonesia", value: "ID" },
    { key: 107, label: "Côte d'Ivoire", value: "CI" },
    { key: 108, label: "Iran (Islamic Republic of)", value: "IR" },
    { key: 109, label: "Iraq", value: "IQ" },
    { key: 110, label: "Ireland", value: "IE" },
    { key: 111, label: "Isle of Man", value: "IM" },
    { key: 112, label: "Israel", value: "IL" },
    { key: 113, label: "Italy", value: "IT" },
    { key: 114, label: "Jamaica", value: "JM" },
    { key: 115, label: "Japan", value: "JP" },
    { key: 116, label: "Jersey", value: "JE" },
    { key: 117, label: "Jordan", value: "JO" },
    { key: 118, label: "Kazakhstan", value: "KZ" },
    { key: 119, label: "Kenya", value: "KE" },
    { key: 120, label: "Kiribati", value: "KI" },
    { key: 121, label: "Kuwait", value: "KW" },
    { key: 122, label: "Kyrgyzstan", value: "KG" },
    { key: 123, label: "Lao People's Democratic Republic", value: "LA" },
    { key: 124, label: "Latvia", value: "LV" },
    { key: 125, label: "Lebanon", value: "LB" },
    { key: 126, label: "Lesotho", value: "LS" },
    { key: 127, label: "Liberia", value: "LR" },
    { key: 128, label: "Libya", value: "LY" },
    { key: 129, label: "Liechtenstein", value: "LI" },
    { key: 130, label: "Lithuania", value: "LT" },
    { key: 131, label: "Luxembourg", value: "LU" },
    { key: 132, label: "Macao", value: "MO" },
    { key: 133, label: "Macedonia (the former Yugoslav Republic of)", value: "MK" },
    { key: 134, label: "Madagascar", value: "MG" },
    { key: 135, label: "Malawi", value: "MW" },
    { key: 136, label: "Malaysia", value: "MY" },
    { key: 137, label: "Maldives", value: "MV" },
    { key: 138, label: "Mali", value: "ML" },
    { key: 139, label: "Malta", value: "MT" },
    { key: 140, label: "Marshall Islands", value: "MH" },
    { key: 141, label: "Martinique", value: "MQ" },
    { key: 142, label: "Mauritania", value: "MR" },
    { key: 143, label: "Mauritius", value: "MU" },
    { key: 144, label: "Mayotte", value: "YT" },
    { key: 145, label: "Mexico", value: "MX" },
    { key: 146, label: "Micronesia (Federated States of)", value: "FM" },
    { key: 147, label: "Moldova (Republic of)", value: "MD" },
    { key: 148, label: "Monaco", value: "MC" },
    { key: 149, label: "Mongolia", value: "MN" },
    { key: 150, label: "Montenegro", value: "ME" },
    { key: 151, label: "Montserrat", value: "MS" },
    { key: 152, label: "Morocco", value: "MA" },
    { key: 153, label: "Mozambique", value: "MZ" },
    { key: 154, label: "Myanmar", value: "MM" },
    { key: 155, label: "Namibia", value: "NA" },
    { key: 156, label: "Nauru", value: "NR" },
    { key: 157, label: "Nepal", value: "NP" },
    { key: 158, label: "Netherlands", value: "NL" },
    { key: 159, label: "New Caledonia", value: "NC" },
    { key: 160, label: "New Zealand", value: "NZ" },
    { key: 161, label: "Nicaragua", value: "NI" },
    { key: 162, label: "Niger", value: "NE" },
    { key: 163, label: "Nigeria", value: "NG" },
    { key: 164, label: "Niue", value: "NU" },
    { key: 165, label: "Norfolk Island", value: "NF" },
    { key: 166, label: "Korea (Democratic People's Republic of)", value: "KP" },
    { key: 167, label: "Northern Mariana Islands", value: "MP" },
    { key: 168, label: "Norway", value: "NO" },
    { key: 169, label: "Oman", value: "OM" },
    { key: 170, label: "Pakistan", value: "PK" },
    { key: 171, label: "Palau", value: "PW" },
    { key: 172, label: "Palestine, State of", value: "PS" },
    { key: 173, label: "Panama", value: "PA" },
    { key: 174, label: "Papua New Guinea", value: "PG" },
    { key: 175, label: "Paraguay", value: "PY" },
    { key: 176, label: "Peru", value: "PE" },
    { key: 177, label: "Philippines", value: "PH" },
    { key: 178, label: "Pitcairn", value: "PN" },
    { key: 179, label: "Poland", value: "PL" },
    { key: 180, label: "Portugal", value: "PT" },
    { key: 181, label: "Puerto Rico", value: "PR" },
    { key: 182, label: "Qatar", value: "QA" },
    { key: 183, label: "Republic of Kosovo", value: "XK" },
    { key: 184, label: "Réunion", value: "RE" },
    { key: 185, label: "Romania", value: "RO" },
    { key: 186, label: "Russian Federation", value: "RU" },
    { key: 187, label: "Rwanda", value: "RW" },
    { key: 188, label: "Saint Barthélemy", value: "BL" },
    { key: 189, label: "Saint Helena, Ascension and Tristan da Cunha", value: "SH" },
    { key: 190, label: "Saint Kitts and Nevis", value: "KN" },
    { key: 191, label: "Saint Lucia", value: "LC" },
    { key: 192, label: "Saint Martin (French part)", value: "MF" },
    { key: 193, label: "Saint Pierre and Miquelon", value: "PM" },
    { key: 194, label: "Saint Vincent and the Grenadines", value: "VC" },
    { key: 195, label: "Samoa", value: "WS" },
    { key: 196, label: "San Marino", value: "SM" },
    { key: 197, label: "Sao Tome and Principe", value: "ST" },
    { key: 198, label: "Saudi Arabia", value: "SA" },
    { key: 199, label: "Senegal", value: "SN" },
    { key: 200, label: "Serbia", value: "RS" },
    { key: 201, label: "Seychelles", value: "SC" },
    { key: 202, label: "Sierra Leone", value: "SL" },
    { key: 203, label: "Singapore", value: "SG" },
    { key: 204, label: "Sint Maarten (Dutch part)", value: "SX" },
    { key: 205, label: "Slovakia", value: "SK" },
    { key: 206, label: "Slovenia", value: "SI" },
    { key: 207, label: "Solomon Islands", value: "SB" },
    { key: 208, label: "Somalia", value: "SO" },
    { key: 209, label: "South Africa", value: "ZA" },
    { key: 210, label: "South Georgia and the South Sandwich Islands", value: "GS" },
    { key: 211, label: "Korea (Republic of)", value: "KR" },
    { key: 212, label: "South Sudan", value: "SS" },
    { key: 213, label: "Spain", value: "ES" },
    { key: 214, label: "Sri Lanka", value: "LK" },
    { key: 215, label: "Sudan", value: "SD" },
    { key: 216, label: "Suriname", value: "SR" },
    { key: 217, label: "Svalbard and Jan Mayen", value: "SJ" },
    { key: 218, label: "Swaziland", value: "SZ" },
    { key: 219, label: "Sweden", value: "SE" },
    { key: 220, label: "Switzerland", value: "CH" },
    { key: 221, label: "Syrian Arab Republic", value: "SY" },
    { key: 222, label: "Taiwan", value: "TW" },
    { key: 223, label: "Tajikistan", value: "TJ" },
    { key: 224, label: "Tanzania, United Republic of", value: "TZ" },
    { key: 225, label: "Thailand", value: "TH" },
    { key: 226, label: "Timor-Leste", value: "TL" },
    { key: 227, label: "Togo", value: "TG" },
    { key: 228, label: "Tokelau", value: "TK" },
    { key: 229, label: "Tonga", value: "TO" },
    { key: 230, label: "Trinidad and Tobago", value: "TT" },
    { key: 231, label: "Tunisia", value: "TN" },
    { key: 232, label: "Turkey", value: "TR" },
    { key: 233, label: "Turkmenistan", value: "TM" },
    { key: 234, label: "Turks and Caicos Islands", value: "TC" },
    { key: 235, label: "Tuvalu", value: "TV" },
    { key: 236, label: "Uganda", value: "UG" },
    { key: 237, label: "Ukraine", value: "UA" },
    { key: 238, label: "United Arab Emirates", value: "AE" },
    { key: 239, label: "United Kingdom of Great Britain and Northern Ireland", value: "GB" },
    { key: 240, label: "United States of America", value: "US" },
    { key: 241, label: "Uruguay", value: "UY" },
    { key: 242, label: "Uzbekistan", value: "UZ" },
    { key: 243, label: "Vanuatu", value: "VU" },
    { key: 244, label: "Venezuela (Bolivarian Republic of)", value: "VE" },
    { key: 245, label: "Viet Nam", value: "VN" },
    { key: 246, label: "Wallis and Futuna", value: "WF" },
    { key: 247, label: "Western Sahara", value: "EH" },
    { key: 248, label: "Yemen", value: "YE" },
    { key: 249, label: "Zambia", value: "ZM" },
    { key: 250, label: "Zimbabwe", value: "ZW" }
]
