import React, { useState, useEffect } from 'react';
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { MDBCol } from 'mdbreact'
import { ActionButton, CustomButton } from '../../../../misc/components/Inputs'
import VariationsAccordion from '../sub-sections/VariationsAccordion'
import { deleteVariation } from '../../../../../services/products/product-service'


let addVariationOptions = [{ key: 1, label: 'Add variation', value: 'add' }]

export const VariationsDataStep = (props) => {

    const [{ variations, validationMessage,
    }, setState] = useState({
        variations: props.variations,
        validationMessage: ''
    })

    useEffect(() => {

        setState(prevState => ({ ...prevState, variations: props.variations }))
    }, [props.variations])

    const [varOption, setVarOption] = useState(addVariationOptions[0])
    let {
        _isMounted,
        APO_product,
        changeValue,
        currentStep,
        showSubmitButton,
        variationsDataForm,

        attributes,
        backordersOptions,
        stockStatusOptions,
        priceRule,
        remarks,
    } = props


    let noVariationAttributeExists = !attributes.find(attr => attr.variation)//if no variation attribute is present
    // console.log(noVariationAttributeExists)
    let productVariationAttributes = attributes.filter(attr => attr.variation)//variation attributes for this product
    let productVariationTerms = productVariationAttributes.map(attr => attr.options).flat()//term options of variation attributes
    let possibleVariationsLength = productVariationAttributes.length * productVariationTerms.length//number of possible combinations
    // console.log(possibleVariationsLength)

    const addNewVariation = () => {
        if (variations.length >= possibleVariationsLength) {
            _isMounted && setState(prevState => ({
                ...prevState,
                validationMessage: 'Maximum possible variations reached.'
            }))
            return
        }
        let newVariation = {
            fakeId: variations.length + 1,
            status: 'publish',

            is_cost_provided: false,
            regular_price: '',
            cost_price: '',
            sale_price: '',
            date_on_sale_from: '',
            date_on_sale_to: '',

            sku: '',
            manage_stock: false,
            stock_quantity: 1,
            stock_status: stockStatusOptions[0],
            backorders: backordersOptions[0],

            weight: '',
            dimensions: { length: '', width: '', height: '' },

            description: '',

            attributes: [],

            image: null,
        }

        _isMounted && setState(prevState => ({
            ...prevState,
            variations: [...prevState.variations, newVariation],
        }))
        changeValue('showSubmitButton', false)
    }

    const handleUpdateVariation = (index, name, value) => {
        // console.log(index, name, value)
        if (name === 'length' || name === 'width' || name === 'height') {
            _isMounted && setState(prevState => ({
                ...prevState,
                variations: prevState.variations.map((variatn, Index) => {
                    if (Index === index) {
                        variatn['dimensions'][name] = value
                    }
                    return variatn
                }),
            }))
            changeValue('showSubmitButton', false)
        }
        else {
            _isMounted && setState(prevState => ({
                ...prevState,
                variations: prevState.variations.map((variatn, Index) => {
                    if (Index == index) {
                        variatn[name] = value
                    }
                    return variatn
                }),
            }))
            changeValue('showSubmitButton', false)
        }
    }

    const handleRemoveVariation = async (index) => {
        if (variations[index].fakeId) {
            removeVariationFromSession(index)
        }
        else {
            let isConfirmed = window.confirm('Are you sure you want to permanently remove this variation? ')
            if (isConfirmed) {
                changeValue('isLoading', true);
                try {
                    const { success, message } = await deleteVariation(variations[index]?.bkt_id, variations[index]?.parent_id)
                    console.log(message)
                    success && removeVariationFromSession(index)
                }
                catch (err) {
                    console.log(err)
                }
                changeValue('isLoading', false)
            }
        }
    }

    const removeVariationFromSession = (index) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            variations: prevState.variations.filter((v, Index) => Index !== index),
            validationMessage: ''
        }))
        changeValue('showSubmitButton', false)
    }

    const saveVariations = async () => {
        changeValue('variations', [...variations])
        _isMounted && setState(prevState => ({
            ...prevState,
            validationMessage: ''
        }))
        changeValue('showSubmitButton', true)
    }

    // if (currentStep !== 7) {
    //     return null
    // }
    return (
        <form ref={variationsDataForm} className={`m-0 p-0 ${currentStep !== 7 ? 'd-none' : 'd-block'}`} noValidate >
            <div className='d-flex flex-column px-3'>
                {
                    noVariationAttributeExists ?
                        <h6
                            className='bg-tertiary text-left mx-3 py-2 px-3 small color-primary'
                            style={{ borderLeft: '8px solid #bf1515' }}
                        >
                            Before you can add a variation you need to add some variation attributes in the<strong> Attributes </strong>tab.
                            </h6>
                        :
                        <>
                            {APO_product !== 'read' &&
                                <div className='mb-3 mx-0 p-0 row'>
                                    <div className='col-sm-10 m-0 p-0'>
                                        <MyOwnSelect
                                            options={addVariationOptions}
                                            handleChange={setVarOption}
                                            selectedValue={varOption}
                                            className='border-radius-0'
                                            withOutIcon
                                        />
                                    </div>
                                    <div className='col-sm-2 m-0 p-0 pl-sm-2 align-self-center'>
                                        <CustomButton
                                            onClick={() => { addNewVariation() }}
                                            label="Go"
                                            color='tertiary'
                                            bg='primary-light'
                                            className="my-2 my-sm-0 w-100"
                                            type="button"
                                        />
                                    </div>
                                </div>
                            }
                            {
                                <VariationsAccordion
                                    APO_product={APO_product}
                                    backordersOptions={backordersOptions}
                                    handleRemoveVariation={handleRemoveVariation}
                                    handleUpdateVariation={handleUpdateVariation}
                                    priceRule={priceRule}
                                    remarks={remarks}
                                    productVariationAttributes={productVariationAttributes}
                                    stockStatusOptions={stockStatusOptions}
                                    variations={variations}
                                />
                            }
                            {!showSubmitButton && APO_product !== 'read' ?
                                <MDBCol middle className='mb-3 p-0 text-left'>
                                    <ActionButton
                                        onClick={saveVariations}
                                        value={'Save'}
                                        className="bg-primary-light border-radius-0"
                                        type="button"
                                    />
                                </MDBCol>
                                :
                                null
                            }
                        </>
                }
            </div>
            <label className="p-0 validating-label mx-3">{validationMessage}</label>
        </form >
    )
}
