import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { MDBRow, MDBCol } from 'mdbreact'
import { Chart } from 'primereact/chart';
import { Link } from 'react-router-dom'


const SellerDashboard = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [requestsCount, setRquestsCount] = useState(0);
    const [pendingProductsCount, setpendingProductsCount] = useState(0);
    const selectedStatus = 'pending'


    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'Orders',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: 'Sale',
                    backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        const fetchRequestCount = () => {
            // changeValue('isLoading', true)
            fetch('/api/seller_requests')
                .then(res => res.json())
                .then(json => {
                    let count = Object.keys(json.data).length;
                    setRquestsCount(count)
                })
                .catch(err => {
                    // changeValue('isLoading', false)
                    console.log(err)
                })
        }
        const fetchPendingProductsCount = () => {
            // changeValue('isLoading', true)
            fetch('/api/products/status_filter/pending')
                .then(res => res.json())
                .then(json => {
                    let count = Object.keys(json.data).length;
                    // console.log("pending items : " + count)
                    setpendingProductsCount(count)
                })
                .catch(err => {
                    // changeValue('isLoading', false)
                    console.log(err)
                })
        }
        fetchPendingProductsCount()
        fetchRequestCount()
        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className='grid'>
            <h3 className='text-center'>At a Glance</h3>
            <MDBRow>
                <MDBCol>
                    <MDBRow>
                        <MDBCol>
                            <Card className="mt-1 ml-1" subTitle="Marketplace" >
                                {/* <p className="m-0">Marketplace</p> */}
                                <i className="pi pi-globe" /> UAE
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1" subTitle="New Orders">
                                <p className="m-0"></p>
                                <i className="pi pi-shopping-cart" /> 10
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1" subTitle="Sales">
                                <i className="pi pi-wallet" /> AED 500.00
                            </Card>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        
                        <MDBCol>
                            <Card className="mt-1" subTitle="Products">
                                <Link to={{pathname:'/products/all', query: {selectedStatus}}} title='Pending Products' >
                                    <i className="pi pi-microsoft" /> {pendingProductsCount}
                                </Link>
                            </Card>
                        </MDBCol>
                    </MDBRow>

                </MDBCol>
                <MDBCol>
                    <div className="card flex justify-content-center">
                        <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    </div>
                </MDBCol>
            </MDBRow>


        </div >

    )
}


export default SellerDashboard

