import React, { useState, useEffect } from 'react'
import { Can } from '../../../configs/Ability-context'
import XLSX from 'xlsx';
import { CustomButton } from '../../misc/components/Inputs'
import CustomSelect from '../../misc/components/CustomSelect'
import getOptions from '../../misc/components/Options'
import LoaderModal from '../../misc/components/LoaderModal'
import { getOrderStatuses } from '../../../services/orders/status-service'
import { getOrdersWithSpecificStatus, exportOrders } from '../../../services/orders/order-service';
import { exportOrderItems } from '../../../services/orders/item-service';


let _isMounted
const INITIAL_STATE = {
    allStatuses: [],
    allOrders: [],
    selectedStatuses: [],
    selectedOrders: [],
    selectedCourier: [],
    exportForOrderSheet: true,
    isLoading: false
}

const ExportOrders = () => {
    const [{
        allStatuses, allOrders, selectedStatuses, selectedOrders, selectedCourier, exportForOrderSheet, isLoading, validationMessage
    }, setState] = useState({ ...INITIAL_STATE, validationMessage: '' })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getOrderStatuses()
                let defaultSelectedStatus = getOptions('orderStatuses', data).find(o => o.label === 'RTS')
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allStatuses: data.filter(s => s.usable_at_order || s.changeable_at_order),
                    selectedStatuses: defaultSelectedStatus ? [defaultSelectedStatus.value] : [],
                }))
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    useEffect(() => {
        const fetchMeta = async () => {
            try {
                const { success, data } = await getOrdersWithSpecificStatus(selectedStatuses)
                changeValue('allOrders', data)
                if (data.length)
                    changeValue('selectedOrders', data.map(o => o.bkt_order_id))
                else {
                    changeValue('selectedOrders', null)
                    selectedStatuses.length ?
                        changeValue('validationMessage', 'No order found for selected details')
                        :
                        changeValue('validationMessage', 'Please select atleast one status')
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        fetchMeta()
    }, [selectedStatuses])

    const courierOptions = getOptions('couriers')
    let orderNumberOptions = allOrders.map((o, ind) => ({ key: ind + 1, label: o.bkt_order_id, value: o.bkt_order_id }))
    let orderStatusOptions = getOptions('orderStatuses', allStatuses)

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: name === 'validationMessage' ? value : '' }))
    }
    const handleSelectChange = name => e => {
        // console.log(e)
        changeValue(name, e.value)
    }
    const handleCheckboxChange = () => {
        let defaultSelectedStatus = orderStatusOptions?.find(o => (exportForOrderSheet ? o.label === 'New order' : o.label === 'RTS'))?.value
        changeValue('selectedStatuses', [defaultSelectedStatus])
        changeValue('exportForOrderSheet', !exportForOrderSheet)
    }

    const handleExport = async () => {
        if (!selectedOrders || !selectedOrders.length) {
            changeValue('validationMessage', 'Please select atleast one order')
            return
        }
        else if (!exportForOrderSheet && (selectedCourier === '' || !selectedCourier)) {
            changeValue('validationMessage', 'Please select courier')
            return
        }
        else {
            try {
                const { success, data, message } = exportForOrderSheet ?
                    await exportOrderItems(selectedOrders)      //for order sheet
                    :
                    await exportOrders(selectedOrders, selectedCourier)     //for shipping labels
                changeValue('validationMessage', message)
                if (success) {
                    const wb = XLSX.utils.book_new();                     //create workbook
                    const ws = XLSX.utils.aoa_to_sheet(data);            //convert data to work-sheet
                    XLSX.utils.book_append_sheet(wb, ws, exportForOrderSheet ? 'New Orders' : selectedCourier)  //add sheet to workbook

                    const filename = exportForOrderSheet ? `orders-${new Date().toLocaleDateString()}.xlsx` : `${selectedCourier}-shipping-labels.xlsx`;
                    const wb_opts = { bookType: 'xlsx', type: 'binary' };   //workbook options
                    XLSX.writeFile(wb, filename, wb_opts);                //write workbook file
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <Can I={'export'} a={'orders'}>
            <div className='screen-center h-sm-100vh py-5 py-sm-0'>
                <h5 className='font-weight-bold text-center color-tertiary mt-5 mb-4'>Export orders</h5>
                <div className='m-0 w-100 w-sm-75 w-md-50 p-grid p-justify-center'>
                    <div className='p-col-11 p-sm-9 p-md-7'>
                        <CustomSelect
                            options={orderStatusOptions}
                            value={selectedStatuses}
                            handleChange={handleSelectChange('selectedStatuses')}
                            placeholder={`Select statuses`}
                            isMulti
                            className='w-100'
                        />
                    </div>
                    {
                        allOrders.length ?
                            <div className='p-col-11 p-sm-9 p-md-7'>
                                <CustomSelect
                                    options={orderNumberOptions}
                                    value={selectedOrders}
                                    handleChange={handleSelectChange('selectedOrders')}
                                    placeholder={`Select orders`}
                                    isMulti
                                    className='w-100'
                                />
                            </div>
                            :
                            null
                    }
                    <div className='p-col-11 p-sm-9 p-md-7'>
                        <div className="custom-control custom-checkbox text-left">
                            <input
                                type="checkbox"
                                name="exportForOrderSheet"
                                className="custom-control-input"
                                id="exportForOrderSheet"
                                checked={exportForOrderSheet}
                                onChange={handleCheckboxChange}
                            />
                            <label className="custom-control-label color-tertiary" htmlFor="exportForOrderSheet">Exporting for order sheet</label>
                        </div>
                    </div>
                    {
                        !exportForOrderSheet && <div className='p-col-11 p-sm-9 p-md-7'>
                            <CustomSelect
                                options={courierOptions}
                                value={selectedCourier}
                                handleChange={handleSelectChange('selectedCourier')}
                                placeholder={`Select courier`}
                                className='w-100 mb-3 color-primary-light'
                            />
                        </div>
                    }
                    <div className='p-col-11 p-sm-9 p-md-7 p-0 text-right'>
                        <CustomButton
                            label="Export"
                            className='mx-2 px-5'
                            bg='Secondary'
                            color='tertiary'
                            onClick={handleExport}
                        />
                    </div>
                </div>
                <LoaderModal modalShow={isLoading} />
                <label className='p-0 mt-3 validating-label'>{validationMessage}&nbsp;</label>
            </div>
        </Can>
    )

}

export default ExportOrders

// const getCustomOrderCities = async (selectedOption) => {
//     let cityList = []
//     let statusesToInclude = selectedOption.map(option => option.value)
//     await fetch(`/ getCustomOrdersCities ? statusesToInclude = ${ JSON.stringify(statusesToInclude) } `)
//         .then((response) => response.json())
//         .then(json => {
//             console.log(json)
//             cityList = json.data.length ? [...new Set(json.data)] : []
//             if (cityList.length) {
//                 cityList = cityList.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
//             }
//             // console.log(cityList)
//         })
//         .catch((err => console.log(err)))
//     return cityList
// }