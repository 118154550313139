import React, { useState, useEffect, useRef, useContext } from "react";
import { Can, AbilityContext } from "../../../configs/Ability-context";
import { CustomListing } from "../../misc/components/CustomListing";
import { Column } from "primereact/column";
import {
  getSettings,
  updateSetting,
} from "../../../services/misc/setting-service";
import { SettingModal } from "./sections/SettingModal";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { MDBPopper } from "mdbreact";
import PasswordConfirmationModal from "../../misc/components/PasswordConfirmationModal";
import CustomSelect from "../../misc/components/CustomSelect";
import { InputSwitch } from "primereact/inputswitch";

const AllSettingsForAdmin = () => {
  const dt = useRef(null);
  const [
    {
      allSettings,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
    },
    setState,
  ] = useState({
    allSettings: [],
    isLoading: false,
    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
  });

  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState({ id: null, value: "" });
  const ability = useContext(AbilityContext);
  const settingForm = useRef(null);
  let SettingTypes = allSettings.length ? ["app_setting", "admin_setting"] : [];
  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await getSettings();
        setState((prevState) => ({
          ...prevState,
          allSettings: data,
          isLoading: false,
        }));
      } catch (error) {
        console.log(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    fetchData();
  }, [refreshData]);
  const adminSettings = allSettings.filter(
    (setting) => setting.setting_type === "admin_setting"
  );
  const handleUpdate = async (id, newValue) => {
    try {
      const response = await updateSetting(id, { value: newValue });
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id ? { ...setting, value: newValue } : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateSettingType = async (id, setting_type) => {
    try {
      const response = await updateSetting(id, {
        // value: newValue,
        setting_type: setting_type,
      });
      console.log("setting_type", setting_type);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id
              ? { ...setting, setting_type: setting_type }
              : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdatePasswordProtection = async (id, passwordProtection) => {
    try {
      const response = await updateSetting(id, {
        // value: newValue,
        is_password_protected: passwordProtection,
      });
      console.log("passwordProtection", passwordProtection);
      console.log(response);
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          allSettings: prevState.allSettings.map((setting) =>
            setting.id === id
              ? { ...setting, is_password_protected: passwordProtection }
              : setting
          ),
        }));
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setState((prevState) => ({ ...prevState, editorText: e.target.value }));
  };

  const handleInlineUpdate = async (rowData) => {
    let form = settingForm.current;
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    }

    if (rowData.is_password_protected) {
      setPendingUpdate({ id: rowData.id, value: editorText });
      setIsConfirmingPassword(true);
    } else {
      handleUpdate(rowData.id, editorText);
      setState((prevState) => ({
        ...prevState,
        activeEditor: { field: "", id: "" },
        editorText: "",
      }));
    }
  };

  const handlePasswordConfirm = (verified) => {
    if (verified) {
      handleUpdate(pendingUpdate.id, pendingUpdate.value);
      setState((prevState) => ({
        ...prevState,
        activeEditor: { field: "", id: "" },
        editorText: "",
      }));
    }
    setIsConfirmingPassword(false);
    setPendingUpdate({ id: null, value: "" });
  };

  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const getEditor = (rowData, field) => (
    <InputText
      autoFocus
      value={editorText}
      onChange={handleInputChange}
      onBlur={() => handleInlineUpdate(rowData)}
    />
  );

  const inlineEditor = (field, rowData) => (
    <Inplace
      closable
      active={activeEditor.field === field && activeEditor.id === rowData.id}
      onOpen={setEditor(field, rowData.id, rowData[field])}
    >
      <InplaceDisplay>
        <MDBPopper domElement tag="span" placement="right">
          <span>{rowData[field]}</span>
          <span>{" Click to edit"}</span>
        </MDBPopper>
      </InplaceDisplay>
      <InplaceContent>{getEditor(rowData, field)}</InplaceContent>
    </Inplace>
  );
  const SettingTypeBody = (rowData) => {
    // let _seller = allSellers.find((s) => s.id === rowData.seller_id);
    let settingType = rowData?.setting_type?.toUpperCase();
    console.log("Setting Type:", settingType);
    return (
      ability.can("manage", "all") && (
        <div className="custom-select-container" style={{}}>
          <CustomSelect
            options={SettingTypes}
            value={rowData?.setting_type}
            style={{
              textTransform: "uppercase",
            }}
            placeholder="Select seller"
            handleChange={(e) => {
              handleUpdateSettingType(rowData.id, e.value);
            }}
          />
        </div>
      )
    );
  };
  const PasswordProtectionBody = (rowData) => {
    // const [checked, setChecked] = useState(false);

    return (
      ability.can("manage", "all") && (
        <div
          className="card flex justify-content-center"
          style={{ marginLeft: "170px" }}
        >
          <InputSwitch
            checked={rowData.is_password_protected}
            onChange={(e) =>
              handleUpdatePasswordProtection(rowData.id, e.value)
            }
          />
        </div>
      )
    );
  };
  const columns = [
    {
      field: "value",
      header: "Value",
      filter: true,
      body: (rowData) => inlineEditor("value", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },

    // {
    //   field: "setting_type",
    //   header: "Setting Type",
    //   body: SettingTypeBody,
    //   sortable: true,
    //   filter: true,
    //   filterPlaceholder: "Search by Setting Type",
    //   filterMatchMode: "contains",
    //   selectedByDefault: true,
    //   // filterElement: statusFilter,
    // },
    // {
    //   field: "is_password_protected",
    //   header: "Password Protection",
    //   body: PasswordProtectionBody,
    //   sortable: true,
    //   filter: true,
    //   filterPlaceholder: "Search by Setting Type",
    //   filterMatchMode: "contains",
    //   selectedByDefault: true,
    //   // filterElement: statusFilter,
    // },
    // {
    //   field: "setting_type",
    //   header: "Setting Type",
    //   sortable: true,
    //   filter: true,
    //   filterPlaceholder: "Search by Setting Type",
    //   filterMatchMode: "contains",
    //   selectedByDefault: true,
    //   headerStyle: { width: "140px" },
    // },
  ];

  return (
    <Can I="read" a="adminSettings">
      <form ref={settingForm} className="p-m-0 p-pt-4" noValidate>
        <CustomListing
          allRecords={adminSettings}
          heading={"Admin settings"}
          firstColumn={
            <Column
              field="key"
              header="Name"
              sortable={true}
              filter={true}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          permissionSubject={"adminSettings"}
          readPermissionSubject={() => "adminSettings"}
          updatePermissionSubject={() => "adminSettings"}
          isLoading={isLoading}
          handleRefresh={() =>
            setState((prevState) => ({
              ...prevState,
              refreshData: !prevState.refreshData,
            }))
          }
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
      <SettingModal
        modalShow={isUpdatingSetting}
        settingId={selectedSetting}
        hideModal={() =>
          setState((prevState) => ({
            ...prevState,
            isUpdatingSetting: false,
            selectedSetting: null,
          }))
        }
      />
      <PasswordConfirmationModal
        modalShow={isConfirmingPassword}
        handleVerifyStatus={handlePasswordConfirm}
        hideModal={() => setIsConfirmingPassword(false)}
      />
    </Can>
  );
};

export default AllSettingsForAdmin;
