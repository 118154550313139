import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';

const LoaderModal = (props) => {


    return (
        <Dialog
            visible={props.modalShow}
            // visible={true}
            showHeader={false}
            modal
            style={{ width: '30rem', height: '9vh' }}
            contentClassName='loading'
            className='p-text-center'
            closable={false}
            onHide={() => { }}
        >
            <ProgressSpinner style={{ width: '50px', height: '9vh' }} strokeWidth="6" animationDuration="2.5s" />
            {/* <MDBAnimation type="slideInLeft" infinite duration="4s">
                <img src="/favicon-96x96.png" alt="My Bucket" style={{height:'50px'}} className='p-my-auto b1' />
            </MDBAnimation> */}
        </Dialog>
    )
}


export default LoaderModal