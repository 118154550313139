
export const createProduct = async (newProduct, brandId, sellerId, shopManagerId, variations) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newProduct, brandId, sellerId, shopManagerId, variations }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProducts = async () => {
    try {
        const res = await fetch(`/api/products`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProductsWithData = async (limit, offset) => {
    try {
        const res = await fetch(`/api/products/data?limit=${limit}&off=${offset}`)
        const json = await res.json()
        // console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProductsTrash = async () => {
    try {
        const res = await fetch(`/api/products/trash`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProduct = async (id) => {
    try {
        const res = await fetch(`/api/products/${id}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProductByBktId = async (bktId) => {
    try {
        const res = await fetch(`/api/products/find/${bktId}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const restoreProduct = async (bktId) => {
    try {
        const res = await fetch(`/api/products/restore/${bktId}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getProductsMetaToSync = async () => {
    try {
        const res = await fetch(`/api/products/sync/meta`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const syncProducts = async (pg) => {
    try {
        const res = await fetch(`/api/products/sync/${pg}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateProducts = async (updatedRecords, updatedField, updatedValue) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedRecords, updatedField, updatedValue }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/batch`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateProductField = async (bktId, field, value, helperProps) => {
    console.log(value)
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ field, value, helperProps }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/field/${bktId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updateProduct = async (bktId, updatedProduct, brandId, sellerId, shopManagerId, variations) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedProduct, brandId, sellerId, shopManagerId, variations }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/${bktId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const moveProductsToTrash = async (deletedRecords) => {
    try {
        const options = {
            method: 'DELETE',
            body: JSON.stringify({ deletedRecords }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/trash/batch`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const moveProductToTrash = async (bktId) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/trash/${bktId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteProduct = async (bktId) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/${bktId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteVariation = async (bktId, parentId) => {
    try {
        const options = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/products/variation/${bktId}?product=${parentId}`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}