import React, { useState, useEffect, useContext, useRef } from "react";
import { Can, AbilityContext } from "../../../configs/Ability-context";
import { UserContext } from "../../../contexts/UserContext";
import { ViewOrderModal } from "./ViewOrderModal";
// import { EditOrderModal } from './EditOrderModal'
import { ViewProductModal } from "../../products/pages/ViewProductModal";
import StatusAfterEffectsModal from "./sections/StatusAfterEffectsModal";
import getOptions from "../../misc/components/Options";
import { CustomListing } from "../../misc/components/CustomListing";
import { CustomDatePicker } from "../../misc/components/CustomDatePicker";
import CustomSelect from "../../misc/components/CustomSelect";
import {
  getJsonData,
  formatDate,
  capitalize,
  isNextItemStatusAllowed,
} from "../../misc/components/Helpers";
import { CustomButton } from "../../misc/components/Inputs";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { InputSwitch } from "primereact/inputswitch";
import {
  checkSellerOrderExists,
  createSellerOrder,
  createSellerOrderDetails,
  getOrderItemsWithData,
  getProductSeller,
  setSellerOrderOnCreate,
  updateOrderItemsFields,
} from "../../../services/orders/item-service";
import { getOrderStatuses } from "../../../services/orders/status-service";
import {
  getCustomChilds,
  getCustomUsers,
} from "../../../services/users/user-service";

let _isMounted;
const AllOrderItems = (props) => {
  let dt = useRef(null);
  let toast = useRef(null);
  // let contextMenu = useRef(null)
  const { isSellerLoggedIn, isSellerManagerLoggedIn, isShopManagerLoggedIn } =
    useContext(UserContext);
  const ability = useContext(AbilityContext);

  const [
    {
      allItems,
      allItemStatuses,
      allOrderStatuses,
      allSellers,
      allShopManagers,
      isReadingOrder,
      isUpdatingOrder,
      isReadingItem,
      isStatusAfterEffects,
      isLoading,
      refreshData,
      selectedOrder, //to view and edit order
      selectedRecords,
      selectedOrderDate,
      selectedStatus,
      selectedSeller,
      selectedShopManager,
      selectedShippingDate,
      selectedCourier,
      selectedFollowUp, //selected values for column filters
      updatedStatus,
      selectedItem,
      selectedBulkOption,
      selectedBulkValueOption, //when working in bulk
      editorText,
      activeEditor,
      seller,
    },
    setState,
  ] = useState({
    allItems: [],
    allItemStatuses: [],
    allOrderStatuses: [],
    allSellers: [],
    allShopManagers: [],
    isReadingOrder: false,
    isUpdatingOrder: false,
    isReadingItem: false,
    isStatusAfterEffects: false,
    isLoading: false,
    refreshData: false,
    selectedOrder: "", //to view and edit order
    selectedRecords: [],
    selectedOrderDate: null,
    selectedStatus: null,
    selectedSeller: null,
    selectedShopManager: null,
    selectedShippingDate: null,
    selectedCourier: null,
    selectedFollowUp: null,
    updatedStatus: null,
    selectedItem: null,
    selectedBulkOption: null,
    selectedBulkValueOption: null,
    editorText: "",
    activeEditor: { field: "", id: "" },
    seller: null,
  });

  let itemStatusOptions = allItemStatuses.length
    ? getOptions("itemStatuses", allItemStatuses)
    : [];
  let itemStatusOptionsForSeller = allItemStatuses.length
    ? getOptions("sellerItemStatuses", allItemStatuses)
    : [];
  let sellerOptions = allSellers.length
    ? getOptions("sellers", allSellers)
    : [];
  let shopManagerOptions = allShopManagers.length
    ? getOptions("shopManagers", allShopManagers)
    : [];
  let { heading, rows } = props;
  const courierOptions = getOptions("couriers");

  useEffect(() => {
    _isMounted = true;
    const fetchInitialData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getOrderItemsWithData(100, 0);
        fetchData();
        let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
        changeValue("allItems", itemsWithJsons);
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    const fetchData = async () => {
      try {
        const { data } = await getOrderItemsWithData(0, 100);
        let itemsWithJsons = await getJsonData(data, [["product", ["images"]]]);
        _isMounted &&
          setState((prevState) => ({
            ...prevState,
            allItems: [...prevState.allItems, ...itemsWithJsons],
          }));
      } catch (error) {
        console.log(error);
      }
    };
    fetchInitialData();
    return () => {
      _isMounted = false;
    };
  }, [refreshData]);
  useEffect(() => {
    const fetchMeta = async () => {
      try {
        const Statuses = await getOrderStatuses();
        const statusesWithJson = await getJsonData(Statuses.data, [
          "input_needed",
        ]);
        let sellers =
          isSellerLoggedIn || isShopManagerLoggedIn
            ? []
            : isSellerManagerLoggedIn
            ? await getCustomChilds(0, "seller")
            : await getCustomUsers("seller");

        let shopManagers = isSellerLoggedIn
          ? await getCustomChilds(0, "shop_manager")
          : isShopManagerLoggedIn
          ? []
          : await getCustomUsers("shop_manager");
        _isMounted &&
          setState((prevState) => ({
            ...prevState,
            allItemStatuses: statusesWithJson.filter(
              (s) => s.usable_at_item || s.changeable_at_item
            ),
            allOrderStatuses: statusesWithJson,
            allSellers: Array.isArray(sellers) || !sellers ? [] : sellers.data,
            allShopManagers:
              Array.isArray(shopManagers) || !shopManagers
                ? []
                : shopManagers.data,
          }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchMeta();
  }, []);

  //apply colors on rows
  useEffect(() => {
    const getRule = (status) => {
      return `.bg-${status.slug}{
                background-color:#${
                  status.color ? status.color : "ffffff"
                } !important;
            }`;
    };
    var style = document.createElement("style");
    for (let i = 0; i < allOrderStatuses.length; i++) {
      let status = allOrderStatuses[i];
      style.innerHTML = `${style.innerHTML}${getRule(status)}`;
    }
    // console.log(style)
    document.body.appendChild(style);
  }, [allOrderStatuses]);

  const changeValue = (name, value) => {
    _isMounted && setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEdit = (orderId) => (e) => {
    window.open(`/orders/edit/${orderId}`, "_blank");

    // setState(prevState => ({
    //     ...prevState,
    //     selectedOrder: orderId,
    //     isEditing: true,
    // }))
  };
  const handleView = (orderId) => {
    setState((prevState) => ({
      ...prevState,
      selectedOrder: orderId,
      isReadingOrder: true,
    }));
  };
  const handleViewProduct = (productBktId) => {
    setState((prevState) => ({
      ...prevState,
      selectedItem: productBktId,
      isReadingItem: true,
    }));
  };

  const bulkOptions = [
    // { key: 1, label: 'Change status', value: 'status', permission: 'update' },
    {
      key: 2,
      label: "Change seller",
      value: "seller_id",
      permission: "linkSellers",
    },
    {
      key: 3,
      label: "Create Seller Orders", // New bulk action
      value: "create_seller_orders", // Value for the new bulk action
      permission: "linkSellers", // Permission for the new bulk action
    },
  ];
  // const handleCreateSellerOrders = async () => {
  //   if (selectedRecords.length) {
  //     // Logic to assign selected products to a seller
  //     for (let i = 0; i < selectedRecords.length; i++) {
  //       // sendDetailsToSeller(selectedRecords[i]);
  //       await createSellerOrder(selectedRecords[i]);
  //       await createSellerOrderDetails(selectedRecords[i]);
  //     }
  //     console.log(selectedRecords[0]);
  //     console.log(sellerOptions);
  //     // const sellerId = selectedSeller; // Assuming selectedSeller contains the seller ID
  //     // if (sellerId) {
  //     //   // Iterate over selected records and update the seller ID
  //     //   selectedRecords.forEach((record) => {
  //     //     handleUpdateField(record, "seller_id", sellerId);
  //     //   });
  //   } else {
  //     toast.current.show({
  //       severity: "warn",
  //       summary: "Please select a seller.",
  //     });
  //   }
  // };
  // const handleCreateSellerOrders = async () => {
  //   if (selectedRecords.length) {
  //     try {
  //       // Iterate over each selected record
  //       for (let i = 0; i < selectedRecords.length; i++) {
  //         const record = selectedRecords[i];
  //         // Create a seller order for the record
  //         const sellerOrder = await createSellerOrder(record);
  //         // Create seller order details for the record
  //         await createSellerOrderDetails(sellerOrder, record);
  //       }
  //       // Notify success or do additional operations if needed
  //       console.log(
  //         "All selected records assigned to seller orders successfully."
  //       );
  //     } catch (error) {
  //       console.error("Error assigning records to seller orders:", error);
  //       // Handle error, show error message, etc.
  //     }
  //   } else {
  //     toast.current.show({
  //       severity: "warn",
  //       summary: "Please select a seller.",
  //     });
  //   }
  // };
  // const handleCreateSellerOrders = async () => {
  //   let stop = false;
  //   console.log("selectedRecords");
  //   console.log(selectedRecords);
  //   let selectedorderids = selectedRecords.map((record) => record.order_id);
  //   console.log("selectedorderids", selectedorderids);
  //   // let sellerOrderExist = await checkSellerOrderExists(selectedorderids);

  //   // if (sellerOrderExist) {
  //   //   stop = true;
  //   //   toast.current.show({
  //   //     severity: "warn",
  //   //     summary: "Seller order already Created for selected records",
  //   //   });
  //   // }
  //   const checkForSellerIds = () => {
  //     selectedRecords.forEach((record) => {
  //       if (!record.seller_id) {
  //         toast.current.show({
  //           severity: "warn",
  //           summary: "All selected records must have seller value",
  //         });
  //         console.log("All selected records must have seller value");
  //         stop = true;
  //       }
  //     });
  //   };
  //   const checkForAlreadyExits = () => {
  //     selectedRecords.forEach((record) => {
  //       let sellerOrderExist = checkSellerOrderExists(record.bkt_order_id);
  //       if (sellerOrderExist) {
  //         stop = true;
  //         toast.current.show({
  //           severity: "warn",
  //           summary: "Seller order already Created for selected records",
  //         });
  //       }
  //       // if (!record.seller_id) {
  //       //   toast.current.show({
  //       //     severity: "warn",
  //       //     summary:
  //       //       "All selected records must have seller value and should not be already created",
  //       //   });
  //       //   console.log("All selected records must have seller value");
  //       // stop = true;
  //     });
  //   };
  //   checkForSellerIds();
  //   checkForAlreadyExits();
  //   if (!stop) {
  //     console.log("we are good to go");
  //     try {
  //       let recordIds = selectedRecords.map((record) => record.id);
  //       // console.log("recordIds:", recordIds);
  //       // await setSellerOrderOnCreate(recordIds);
  //       await createSellerOrder(recordIds)
  //         .then((res) => {
  //           console.log("recordIds:", recordIds);
  //           console.log("All selected records processed successfully", res);
  //           toast.current.show({
  //             severity: "success",
  //             summary: "All selected records processed successfully",
  //           });
  //         })
  //         .catch((err) => {
  //           console.log("Error processing records:", err);
  //         });
  //       toast.current.show({
  //         severity: "warn",
  //         summary: "All selected records processed successfully",
  //       });
  //     } catch (error) {
  //       console.error("Error processing records:", error);
  //     }
  //   } else {
  //     toast.current.show({
  //       severity: "warn",
  //       summary:
  //         "Some Issue Either The Selected Records haven't had the seller Assigned Or The The Seller Order is already created.",
  //     });
  //   }
  // };
  const handleCreateSellerOrders = async () => {
    let stop = false;
    let stopped;
    console.log("selectedRecords", selectedRecords);
    let selectedOrderIds = selectedRecords.map((record) => record.order_id);
    console.log("selectedOrderIds", selectedOrderIds);

    // Check for missing seller IDs
    const checkForSellerIds = () => {
      selectedRecords.forEach((record) => {
        if (!record.seller_id) {
          // toast.current.show({
          //   severity: "warn",
          //   summary: "All selected records must have seller value",
          // });
          console.log("Record missing seller_id:", record);
          stop = true;
          stopped = true;
          return;
        }
      });
      if (stopped) {
        toast.current.show({
          severity: "warn",
          summary: "All selected records must have seller value",
        });
      }
    };

    // Check if seller order already exists
    const checkForAlreadyExists = async () => {
      // for (const record of selectedRecords) {
      let bkt_ids = selectedRecords.map((record) => record.bkt_order_id);
      console.log("Checking seller order existence for records:", bkt_ids);
      let result = await checkSellerOrderExists(bkt_ids);
      console.log(`Seller order existence for ${bkt_ids}:`, result);
      if (result.success && result.data) {
        toast.current.show({
          severity: "warn",
          summary: "Seller order already created for selected records",
        });
        console.log("Seller order already created for some record:", bkt_ids);
        stop = true;
        stopped = true;
        return;
      }
    };

    // if (!stop) {
    !stop && (await checkForAlreadyExists());
    // }
    !stop && checkForSellerIds();

    if (!stop) {
      console.log("we are good to go");
      let recordIds = selectedRecords.map((record) => record.id);
      await createSellerOrder(recordIds)
        .then(() => {
          console.log("recordIds:", recordIds);
          console.log("All selected records processed successfully");
          toast.current.show({
            severity: "success",
            summary: "Seller order Created Successfully ",
          });
        })
        .catch((error) => {
          console.error("Error processing records:", error);
          toast.current.show({
            severity: "error",
            summary: "Error processing records",
            detail: error.message,
          });
        });
      // } catch (error) {
      // }
    } else {
      if (!stopped) {
        toast.current.show({
          severity: "warn",
          summary: "Some Issue",
          //  Either the selected records don't have a seller assigned or the seller order is already created.",
        });
      }
    }
  };

  const bulkValueOptions = () => {
    switch (selectedBulkOption) {
      // case 'status':
      //     return isSellerLoggedIn ? itemStatusOptionsForSeller : itemStatusOptions
      //     break;
      case "seller_id":
        return isSellerLoggedIn ? [] : sellerOptions;
      // break;
      case "create_seller_orders": // Handle the new bulk action
        return;
      // break;
      default:
        break;
    }
  };
  const handleBulkAction = () => {
    console.log("handleBulkAction");
    console.log(selectedRecords);
    if (selectedRecords.length) {
      switch (selectedBulkOption) {
        // case 'status_id':
        //     handleChangeStatus(undefined, selectedBulkValueOption)
        //     break;
        case "seller_id":
          const hasSellerOrderCreated = selectedRecords.some(
            (record) => record.is_created_seller_order === true
          );
          if (hasSellerOrderCreated) {
            toast.current.show({
              severity: "warn",
              summary:
                "Cannot change seller as seller order is created for some selected records.",
            });
            return; // Prevent further execution
          }
          handleUpdateField();
          break;
        case "create_seller_orders": // Handle the new bulk action
          handleCreateSellerOrders();
          break;
        default:
          break;
      }
    } else
      toast.current.show({
        severity: "warn",
        summary: "Please select some records.",
      });
  };

  const toggleStatusAfterEffectsModal = ({ modalShow, _status, item }) => {
    _isMounted &&
      setState((prevState) => ({
        ...prevState,
        updatedStatus: _status,
        selectedItem: item,
        isStatusAfterEffects: modalShow,
      }));
  };
  const handleChangeStatus = (item, nextStatus, previousStatus) => {
    if (isNextItemStatusAllowed(nextStatus, previousStatus)) {
      let _status = allItemStatuses.find((s) => s.slug === nextStatus);
      // console.log(item)
      if (_status.question_message || _status.input_needed) {
        toggleStatusAfterEffectsModal({ modalShow: true, _status, item });
      } else {
        handleUpdateField(item, "status", nextStatus);
      }
    } else
      toast.current.show({
        severity: "warn",
        summary: `You can't go to this status.`,
      });
  };
  const handleUpdateField = async (item, field, value) => {
    let itemsToUpdate = item ? [item] : selectedRecords;
    let itemsIdsToUpdate = itemsToUpdate.map((item) => item.id);
    let updatedColumn = field ? field : selectedBulkOption,
      updatedValue =
        value || typeof value === "boolean" ? value : selectedBulkValueOption;
    let updatedItem = { [updatedColumn]: updatedValue };
    updatedItem =
      field === "status" && item.status === "on_hold"
        ? { ...updatedItem, remarks: "" }
        : updatedItem;
    changeValue("isLoading", true);
    try {
      const { success, affectedCount, message, data } =
        await updateOrderItemsFields(itemsIdsToUpdate, updatedItem);
      toast.current.show(message);
      if (success && affectedCount > 0) {
        let updatedItems = allItems;
        await data.map((d) => {
          updatedItems.map((i) => {
            if (i.id === d.id) {
              Object.keys(d).map((key) => {
                i[key] = d[key];
              });
            }
            return i;
          });
        });
        item
          ? _isMounted &&
            setState((prevState) => ({
              ...prevState,
              allItems: updatedItems,
              editorText: "",
              activeEditor: { field: "", id: "" },
            }))
          : _isMounted &&
            setState((prevState) => ({
              ...prevState,
              allItems: updatedItems,
              selectedRecords: [],
              selectedBulkOption: null,
              selectedBulkValueOption: null,
            }));
      }
    } catch (err) {
      console.log(err);
    }
    changeValue("isLoading", false);
  };
  const handleUpdateItem = async (item, updatedDetails) => {
    let itemsToUpdate = item ? [item] : selectedRecords;
    let itemsIdsToUpdate = itemsToUpdate.map((item) => item.id);
    let updatedItem =
      Object.keys(updatedDetails).includes("status") &&
      item.status === "on_hold"
        ? { ...updatedDetails, remarks: "" }
        : updatedDetails;
    changeValue("isLoading", true);
    try {
      const { success, affectedCount, message, data } =
        await updateOrderItemsFields(itemsIdsToUpdate, updatedItem);
      toast.current.show(message);
      if (success && affectedCount > 0) {
        let updatedItems = allItems;
        await data.map((d) => {
          updatedItems.map((i) => {
            if (i.id === d.id) {
              Object.keys(d).map((key) => {
                i[key] = d[key];
              });
            }
            return i;
          });
        });
        item
          ? handleChange()
          : _isMounted &&
            setState((prevState) => ({
              ...prevState,
              allItems: updatedItems,
              selectedRecords: [],
              selectedBulkOption: null,
              selectedBulkValueOption: null,
            }));
      }
    } catch (err) {
      console.log(err);
    }
    changeValue("isLoading", false);
  };

  const sendDetailsToSeller = (orderItem) => {
    let _seller = sellerOptions.find(
      (option) => option.value === orderItem.seller_id
    );
    // console.log(_seller)
    // let _status = itemStatusOptions.find(option => option.value === orderItem.status_id)
    let permalink = orderItem.product?.permalink;
    let _image = orderItem.product
      ? orderItem.product.images
        ? orderItem.product.images[0]?.src
        : undefined
      : undefined;

    //without phone number
    let url = `https://wa.me/?text=${
      orderItem.status === "draft" ||
      orderItem.status === "awaiting_seller_response"
        ? `Query:%20${orderItem.bkt_order_id}`
        : `Pickup:%20${orderItem.bkt_order_id}`
    }%0aImage:%0a${encodeURIComponent(
      _image
    )}%0aProduct%20url:%0a${`${permalink}`}%0aQty:%20${orderItem.qty}`;

    //with phone number
    // let url = `https://wa.me/${_seller.length && _seller[0].cell
    //     }?text=${(_status[0].value == 1 || _status[0].label == 'Awaiting seller response') ?
    //         `Query:%20${orderItem.bkt_order_id}` :
    //         `Pickup:%20${orderItem.bkt_order_id}`
    //     }%0aImage:%0a${encodeURIComponent(_image)}%0aProduct%20url:%0a${`${permalink}`
    //     }%0aQty:%20${orderItem.qty}`

    if (_seller) {
      let newTab = window.open(url, "_blank");
      newTab.focus();
    } else {
      toast.current.show({
        severity: "info",
        summary: `You haven't assigned this order to any seller yet.`,
        detail: `Product: ${orderItem.name}`,
      });
    }
  };

  const onOrderDateChange = (value) => {
    dt.current.filter(value, "date", "custom");
    changeValue("selectedOrderDate", value);
  };
  const onShippingDateChange = (value) => {
    dt.current.filter(value, "order.shipping_date", "custom");
    changeValue("selectedShippingDate", value);
  };
  const setEditor = (field, id) => (e) => {
    _isMounted &&
      setState((prevState) => ({ ...prevState, activeEditor: { field, id } }));
  };
  const handleChange = () => {};
  const handleInput = (e) => {
    let { name, value } = e.target;
    changeValue(name, value);
  };
  const handleInputFocus = (value) => (e) => {
    changeValue("editorText", value);
  };

  //data cell templates
  const idBody = (rowData) => {
    return (
      <>
        <span className="p-column-title">Order Id</span>
        <CustomButton
          label={rowData.bkt_order_id}
          variant="text-button"
          color="primary-light"
          onClick={() => {
            handleView(rowData.order_id);
          }}
          title={"view order"}
        />
      </>
    );
  };
  const dateBody = (rowData) => {
    let date = formatDate(new Date(rowData.date));
    return (
      <>
        <span className="p-column-title">Order Date</span>
        {date}
      </>
    );
  };
  const productNameBody = (rowData) => {
    let _permalink = rowData.product ? rowData.product.permalink : undefined;
    return (
      <CustomButton
        label={capitalize(rowData.name)}
        title={_permalink ? "view product on store" : undefined}
        color="primary-light"
        variant="text-button"
        tooltip={
          _permalink
            ? undefined
            : "Looks like product has been deleted from store."
        }
        onClick={
          _permalink
            ? () => {
                let newTab = window.open(_permalink, "_blank");
                newTab.focus();
              }
            : null
        }
      />
    );
  };
  const statusBody = (rowData) => {
    // console.log(itemStatusOptions)
    let _status = allOrderStatuses.find((s) => s.slug === rowData.status);
    // let _status = itemStatusOptions.find(o => o.value === rowData.status_id)
    let options = isSellerLoggedIn
      ? itemStatusOptionsForSeller
      : itemStatusOptions;
    options = options.map((option) => ({
      ...option,
      disabled:
        (option.entity.changeable_at_item && !option.entity.usable_at_item) ||
        (isSellerLoggedIn && !option.entity.seller_usable),
    }));
    return (isSellerLoggedIn &&
      !_status?.seller_usable &&
      !_status?.seller_changeable) ||
      // (order_status && !_status) ||
      ability.cannot("updateStatus", "orderItems") ||
      !_status?.changeable_at_item ? (
      // _status?.entity.is_final
      _status?.title
    ) : (
      // _status.label
      // :
      // order_status.title
      <CustomSelect
        options={options}
        optionLabel="label"
        value={rowData.status}
        handleChange={(e) => {
          handleChangeStatus(rowData, e.value, rowData.status);
        }}
        filter
        resetFilterOnHide
      />
    );
  };
  const SelerBody = (rowData) => {
    let _seller = allSellers.find((s) => s.id === rowData.seller_id);
    return (
      ability.can("linkSellers", "orderItems") && (
        <span>{_seller ? _seller.name : "No seller found"} </span>
      )
    );
    // : (
    // <CustomSelect
    //   options={sellerOptions}
    //   value={_seller?.id}
    //   placeholder="Select seller"
    //   handleChange={(e) => {
    //     handleUpdateField(rowData, "seller_id", e.value);
    //   }}
    // />
    // <span>{_seller?.name}</span>
    // );
  };
  // const SellerBody = async (rowData) => {
  //   const product_id = rowData.productBktId;
  //   let sellerName;
  // await getProductSeller(sellerId)
  //   await getProductSeller(product_id)
  //     .then((res) => {
  //       return (sellerName = res.data.seller);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // const sellerName = sellerOptions.find(
  //   //   (seller) => seller.value === sellerId
  //   // )?.label;

  //   return <span>{sellerName}</span>;
  // };
  // const SellerBody = (rowData) => {
  //   // let seller = allSellers.find((s) => s.id === rowData.seller_id);
  //   let seller = rowData.seller_id
  //     ? getProductSeller(rowData.seller_id)
  //     : "Null";
  //   return (
  //     <>
  //       <span className="p-column-title">Seller</span>
  //       {seller?.name}
  //       {/* {seller.name} */}
  //     </>
  //   );
  //   // let seller = allSellers.find((s) => s.id === rowData.seller_id);
  // };
  const followUpBody = (rowData) => {
    return (
      <>
        <span className="p-column-title">Follow up ?</span>
        <InputSwitch
          checked={rowData.on_follow_up}
          onChange={(e) => {
            handleUpdateField(rowData, "on_follow_up", e.value);
          }}
        />
      </>
    );
  };
  const sellerBody = (rowData) => {
    let _seller = allSellers.find((s) => s.id === rowData.seller_id);
    return ability.can("linkSellers", "orderItems") &&
      !rowData.is_created_seller_order ? (
      <CustomSelect
        options={sellerOptions}
        value={_seller?.id}
        placeholder="Select seller"
        handleChange={(e) => {
          handleUpdateField(rowData, "seller_id", e.value);
        }}
      />
    ) : (
      // <span>{_seller?.name}</span>
      <span>{_seller?.shop_name}</span>
    );
  };
  const shopManagerBody = (rowData) => {
    let _shopManager = allShopManagers.find(
      (s) => s.id === rowData.shop_manager_id
    );
    let options = isSellerLoggedIn
      ? shopManagerOptions
      : getOptions(
          "shopManagers",
          allShopManagers.filter((s) => s.parent_id === rowData.seller_id)
        );
    return rowData.seller_id ? (
      ability.can("linkShopManagers", "orderItems") ? (
        <CustomSelect
          options={options}
          value={_shopManager?.id}
          placeholder="Select shop manager"
          handleChange={(e) => {
            handleUpdateField(rowData, "shop_manager_id", e.value);
          }}
        />
      ) : (
        <span>{_shopManager?.name}</span>
      )
    ) : (
      <span>{`No seller assigned`}</span>
    );
  };
  const shippingDateBody = (rowData) => {
    let date = rowData.order?.shipping_date
      ? formatDate(new Date(rowData.order?.shipping_date))
      : "";
    return (
      <>
        <span className="p-column-title">Shipping date</span>
        {date}
      </>
    );
  };
  const inlineEditor = (field, rowData) => {
    const editor = (
      <Inplace
        closable
        className="custom-inline-editor"
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id)}
        onToggle={handleChange}
        onClose={() => {
          handleUpdateField(rowData, field, editorText);
        }}
      >
        <InplaceDisplay>{rowData[field]}</InplaceDisplay>
        <InplaceContent>
          <InputText
            autoFocus
            name="editorText"
            value={editorText || ""}
            onInput={handleInput}
            onFocus={handleInputFocus(rowData[field])}
          />
        </InplaceContent>
      </Inplace>
    );
    const readOnly = <span>{rowData[field]}</span>;
    return ability.can("update", "orderItems") ? editor : readOnly;
  };

  //column filters
  const orderDateFilter = (
    <CustomDatePicker
      value={selectedOrderDate}
      handleChange={onOrderDateChange}
      placeholder="Select order date"
      isClearable
    />
  );
  const shippingDateFilter = (
    <CustomDatePicker
      value={selectedShippingDate}
      handleChange={onShippingDateChange}
      placeholder="Select shipping date"
      isClearable
    />
  );
  const statusFilter = (
    <Dropdown
      value={selectedStatus}
      options={allItemStatuses.map((status) => ({
        label: status.title,
        value: status.slug,
      }))}
      onChange={(e) => {
        dt.current.filter(e.value, "status", "equals");
        changeValue("selectedStatus", e.value);
      }}
      placeholder="Select a status"
      className="p-column-filter"
      showClear
    />
  );
  const sellerFilter = (
    <Dropdown
      value={selectedSeller}
      options={sellerOptions}
      onChange={(e) => {
        dt.current.filter(e.value, "seller_id", "equals");
        changeValue("selectedSeller", e.value);
      }}
      placeholder="Select a seller"
      className="p-column-filter"
      showClear
      filter
      resetFilterOnHide
    />
  );
  const shopManagerFilter = (
    <Dropdown
      value={selectedShopManager}
      options={shopManagerOptions}
      onChange={(e) => {
        dt.current.filter(e.value, "shop_manager_id", "equals");
        changeValue("selectedShopManager", e.value);
      }}
      placeholder="Select a shop manager"
      className="p-column-filter"
      showClear
      filter
      resetFilterOnHide
    />
  );
  const courierFilter = (
    <Dropdown
      value={selectedCourier}
      options={courierOptions}
      onChange={(e) => {
        dt.current.filter(e.value, "order.courier", "equals");
        changeValue("selectedCourier", e.value);
      }}
      placeholder="Select courier"
      className="p-column-filter"
      showClear
    />
  );
  const followUpFilter = (
    <Dropdown
      value={selectedFollowUp}
      options={[
        { key: 1, label: "ON", value: true },
        { key: 2, label: "OFF", value: false },
      ]}
      onChange={(e) => {
        dt.current.filter(e.value, "on_follow_up", "equals");
        changeValue("selectedFollowUp", e.value);
      }}
      placeholder="Select follow up mode"
      className="p-column-filter"
      showClear
    />
  );

  let columns = [
    {
      field: "date",
      header: "Order Date",
      body: dateBody,
      sortable: true,
      filter: true,
      filterElement: orderDateFilter,
      filterFunction: "filterDate",
      selectedByDefault: true,
      headerStyle: { width: "120px" },
    },
    {
      field: "name",
      header: "Product",
      body: productNameBody,
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by product",
      selectedByDefault: true,
      filterMatchMode: "contains",
      headerStyle: { width: "400px" },
    },
    {
      field: "qty",
      header: "Qty.",
      sortable: true,
      // filter: true,
      // filterPlaceholder: "Search by qty",
      // filterMatchMode: "equals",
      selectedByDefault: true,
      headerStyle: { width: "50px" },
    },
    {
      field: "price",
      header: "Price",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by price",
      filterMatchMode: "equals",
      selectedByDefault: true,
      headerStyle: { width: "100px" },
    },
    {
      field: "status",
      header: "Item Status",
      body: statusBody,
      sortable: true,
      filter: true,
      selectedByDefault: true,
      filterElement: statusFilter,
    },
    {
      field: "seller_id",
      header: "Seller",
      body: sellerBody,
      // sortable: true,
      // filter: true,
      selectedByDefault: true,
      filterElement: statusFilter,
    },
    // {
    //     field: "on_follow_up",
    //     header: "Follow up ?",
    //     body: followUpBody,
    //     sortable: true,
    //     filter: true,
    //     filterElement: followUpFilter,
    //     headerStyle: { width: '120px' }
    // },
    // {
    //     field: "order.courier",
    //     header: "Courier",
    //     body: (rowData) => { return <>{courierOptions.find(o => o.value === rowData.order?.courier)?.label}</> },
    //     sortable: true,
    //     filter: true,
    //     filterElement: courierFilter,
    //     headerStyle: { width: '110px' }
    // },
    // {
    //     field: "order.shipping_date",
    //     header: "Shipping Date",
    //     body: shippingDateBody,
    //     sortable: true,
    //     filter: true,
    //     filterElement: shippingDateFilter,
    //     filterFunction: 'filterDate',
    //     headerStyle: { width: '120px', fontSize: '14px' }
    // },
    // {
    //     field: "remarks",
    //     header: "Remarks",
    //     body: (rowData) => { return inlineEditor('remarks', rowData) },
    //     filter: true,
    //     filterPlaceholder: "Search by remarks",
    //     filterMatchMode: "contains",
    //     headerStyle: { width: '250px' }
    // },
    // {
    //     field: "customer_status",
    //     header: "Customer Status",
    //     body: (rowData) => { return inlineEditor('customer_status', rowData) },
    //     filter: true,
    //     filterPlaceholder: "Search by customer status",
    //     filterMatchMode: "contains",
    // },
    // {
    //     field: "discount.amount",
    //     header: "Discount",
    //     sortable: true,
    //     filter: true,
    //     filterPlaceholder: "Search by discount",
    //     filterMatchMode: "equals",
    //     headerStyle: { width: '100px' }
    // },

    // {
    //     field: "seller_id",
    //     header: "Seller",
    //     body: sellerBody,
    //     sortable: true,
    //     filter: true,
    //     filterElement: sellerFilter,
    //     permission: 'linkSellers'
    // },
    // {
    //     field: "shop_manager_id",
    //     header: "Shop manager",
    //     body: shopManagerBody,
    //     sortable: true,
    //     filter: true,
    //     filterElement: shopManagerFilter,
    //     permission: 'linkShopManagers'
    // },
  ];

  // let menuModel = [
  //     { label: 'View order', icon: 'pi pi-fw pi-eye color-primary-light lead', command: () => handleView(selectedItem.order_id) },
  //     { label: 'Edit order', icon: 'pi pi-fw pi-pencil color-primary-light lead', command: () => handleEdit(selectedItem.order_id) },
  //     {
  //         label: 'Send details to seller', icon: 'pi-fw fab fa-whatsapp fa-lg color-primary-light', command: () => {
  //             selectedItem.seller_id ?
  //                 sendDetailsToSeller(selectedItem)
  //                 :
  //                 toast.current.show({ severity: 'info', summary: `Can't whatsapp details.`, detail: 'No seller is available for this item.' });
  //         }
  //     },
  // ];
  {
    /* <div className="mt-5 pt-3">
                    <ContextMenu model={menuModel} className="forDatatable" ref={contextMenu} onHide={() => changeValue('selectedItem', null)} />
                    <div className="card mx-2 mt-2">
                        <DataTable
                            contextMenuSelection={selectedItem}
                            onContextMenuSelectionChange={e => changeValue('selectedItem', e.value)}
                            onContextMenu={e => contextMenu.current.show(e.originalEvent)}
                        >
                           
                        </DataTable>
                    </div>
                </div> */
  }

  return (
    <>
      <Can I="read" a={"orderItems"}>
        <CustomListing
          allRecords={allItems}
          heading={heading}
          rows={rows}
          firstColumn={
            <Column
              field="bkt_order_id"
              header="Order Id"
              body={idBody}
              sortable
              filter
              filterPlaceholder="Search by order-id"
              filterMatchMode="contains"
              headerStyle={{ width: "90px" }}
            />
          }
          columns={columns}
          permissionSubject={"orderItems"}
          isLoading={isLoading}
          handleRefresh={() => changeValue("refreshData", !refreshData)}
          // extraButton={(customButton, rowData) => {
          //     let _seller = sellerOptions && sellerOptions.length && sellerOptions.filter(option => option.value === rowData.seller_id)
          //     return (
          //         customButton('fab fa-whatsapp', () => { sendDetailsToSeller(rowData) }, !_seller, 'send details to seller')
          //     )
          // }}
          handleView={true}
          customViewButton={(customButton, rowData) => {
            let _order = rowData.order;
            return _order && _order.href && !isSellerLoggedIn ? (
              <a href={_order.href} target="_blank" title="edit order on store">
                {customButton("fas fa-share color-primary")}
              </a>
            ) : null;
          }}
          readPermissionSubject={() => "orders"}
          // handleEdit={handleEdit}
          editDataKey={"order_id"}
          editBtnTitle={"edit order"}
          updatePermissionSubject={() => "orders"}
          handleDelete={true}
          customDeleteButton={(customButton, rowData) => {
            return rowData.product_bkt_id
              ? customButton(
                  "pi pi-eye color-primary-light",
                  () => {
                    handleViewProduct(rowData.product_bkt_id);
                  },
                  false,
                  "view product locally"
                )
              : null;
          }}
          rowClassName={(rowData) => {
            let itemStatus = allItemStatuses.find(
              (s) => s.slug === rowData.status
            );
            if (itemStatus) {
              let lastUpdatedAt = new Date(rowData.updatedAt);
              lastUpdatedAt.setHours(
                lastUpdatedAt.getHours() + itemStatus?.threshold_hours
              );
              return {
                "bg-warn": new Date() > lastUpdatedAt,
                [`bg-${rowData.status}`]: true,
              };
            }
            return {
              [`bg-${rowData.status}`]: true,
            };
          }}
          // customPaginatorLeft={
          //     allItems.length ?
          //         <div className="p-d-inline-flex p-flex-column p-flex-sm-row p-flex-wrap p-jc-between p-ai-center">
          //             <div className='bg-warn px-2 mx-1 color-primary'>Status outdated</div>
          //         </div>
          //         : null
          // }
          selectedRecords={selectedRecords}
          selectionDataKey={"id"}
          selectedBulkOption={selectedBulkOption}
          selectedBulkValueOption={selectedBulkValueOption}
          bulkOptions={bulkOptions}
          bulkValueOptions={bulkValueOptions()}
          handleBulkAction={handleBulkAction}
          changeValue={changeValue}
          handleExport={() => {
            dt.current.exportCSV();
          }}
          sortField="on_follow_up"
          tableRef={dt}
          toastRef={toast}
          timeStamps={true}
        />
      </Can>
      <ViewProductModal
        modalShow={isReadingItem}
        productBktId={selectedItem}
        hideModal={() => {
          _isMounted &&
            setState((prevState) => ({
              ...prevState,
              isReadingItem: false,
              selectedItem: null,
            }));
        }}
      />
      <ViewOrderModal
        allOrderStatuses={allOrderStatuses}
        modalShow={isReadingOrder}
        orderId={selectedOrder}
        hideModal={() => {
          _isMounted &&
            setState((prevState) => ({
              ...prevState,
              isReadingOrder: false,
              selectedOrder: null,
            }));
        }}
      />
      {/* <EditOrderModal
                allOrderStatuses={allOrderStatuses}
                modalShow={isUpdatingOrder}
                orderId={selectedOrder}
                hideModal={() => {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        isUpdatingOrder: false,
                        selectedOrder: null
                    }))
                }}
                refreshData={() => { }}
            /> */}
      <StatusAfterEffectsModal
        modalShow={isStatusAfterEffects}
        status={updatedStatus}
        handleResponse={({ isConfirmed, input1, input2, input3 }) => {
          if (isConfirmed) {
            if (updatedStatus.slug === "item_not_available") {
              toast.current.show({
                severity: "info",
                summary: "Ok",
                detail: "please send alternate details to seller support.",
                life: 3000,
              });
            } else {
              if (updatedStatus.input_needed) {
                let updatedItem;
                switch (updatedStatus.input_needed[0]) {
                  case "Tr. date": //refund_transaction
                    updatedItem = {
                      status: updatedStatus.slug,
                      refund_tr_date: input1,
                      refund_tr_id: input2,
                      refund_tr_amount: input3,
                    };
                    break;
                  case "schedule list #": //cancel reason
                    updatedItem = {
                      status: updatedStatus.slug,
                      pickup_list_id: input1,
                    };
                    break;
                  case "Hold till": //remarks hold period and reason
                    updatedItem = {
                      status: updatedStatus.slug,
                      remarks: `Hold till: ${new Date(
                        input1
                      ).toDateString()}, Reason: ${input2}`,
                    };
                    break;
                  case "Cancel reason": //cancel reason
                    updatedItem = {
                      status: updatedStatus.slug,
                      cancel_reason: input1,
                    };
                    break;
                  default:
                    break;
                }
                handleUpdateItem(selectedItem, updatedItem);
              } else {
                handleUpdateField(selectedItem, "status", updatedStatus.slug);
              }
            }
          } else {
            updatedStatus.slug === "item_not_available" &&
              handleUpdateField(selectedItem, "status", updatedStatus.slug);
          }
          toggleStatusAfterEffectsModal({
            modalShow: false,
            _status: null,
            item: null,
          });
        }}
      />
    </>
  );
};

export default AllOrderItems;
