import React from "react";
import { Switch, Route } from "react-router-dom";
import { Can } from "../../configs/Ability-context";
import { PrivateRoute } from "../misc/PrivateRoute";
import AllSettings from "./pages/AllSettings";
import SellerPlans from "../sellerPlans/SellerPlans";
import NotFound from "../misc/pages/NotFound";
import { ShowSettingOptions } from "./pages/ShowSettingOptions";
import AllSettingsForAdmin from "./pages/AllSettingsForAdmin";

const AppSettings = () => {
  return (
    <Switch>
      <PrivateRoute path="/settings/all">
        <Can I="read" a="appSettings">
          <AllSettings />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/settings/options">
        <ShowSettingOptions />
      </PrivateRoute>
      <PrivateRoute path="/settings/admin/all">
        <Can I="read" a="adminSettings">
          <AllSettingsForAdmin />
        </Can>
      </PrivateRoute>
      <Route path="/settings/plans">
        <SellerPlans />
      </Route>

      {/* <Route path="/settings/all/admin">
        <AllSettingsForAdmin />
      </Route> */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppSettings;
