import React, { useState, useRef } from 'react';
import { Input, CustomButton } from '../../../../misc/components/Inputs'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import getOptions from '../../../../misc/components/Options'
import { handleNumberInputKeyPress } from '../../../../misc/components/Helpers'


export const ProductsDataStep = (props) => {

    let {
        _isMounted,
        APO_order,
        bkt_order_id,
        changeValue,
        currentStep,
        customerStatus,
        defaultStatus,
        extraAdded,
        handleCheckboxChange,
        handleNumberInputBlur,
        handleInput,
        handleSelectChange,
        itemDiscount,
        itemDiscountDetail,
        itemStatusOptions,
        orderDate,
        orderId,
        orderItems,
        orderProductOptions,
        productsDataForm,
        rate,
        qty,
        remarks,
        selectedItemStatus,
        selectedProduct,
        selectedVariation,
        setState,
    } = props;

    // const [validationMessage, setValidationMessage] = useState('')
    const [addNewItem, setAddNewItem] = useState(APO_order === 'create' ? true : false)
    const [itemNotExists, setItemNotExists] = useState(false)

    let addItemForm = useRef(null)
    let orderItemVariationOptions = selectedProduct ? selectedProduct.type === 'variable' ? getOptions('variations', selectedProduct) : [] : []

    const handleAddItem = e => {
        e.preventDefault();
        if (!itemNotExists) {
            if (!selectedProduct || selectedProduct === '') {
                changeValue('selectedProduct', null)
                changeValue('validationMessage', 'Please select a product')
                return
            }
            else if (selectedProduct.type === 'variable' && (!selectedVariation || selectedVariation === '')) {
                changeValue('selectedVariation', null)
                changeValue('validationMessage', 'Please select product variation')
                return
            }
        }
        if (!selectedItemStatus || selectedItemStatus === '') {
            changeValue('selectedItemStatus', null)
            changeValue('validationMessage', 'Please select item status')
            return
        }
        if (!addItemForm.current.checkValidity()) {
            return addItemForm.current.classList.add('was-validated')
        }
        else {
            // console.log(itemStatusOptions)
            const commmonProps = {
                bkt_order_id: APO_order === 'update' ? bkt_order_id : '',
                date: new Date(),
                qty: qty,
                discount: Number(itemDiscount),
                discount_detail: itemDiscountDetail,
                remarks: remarks,
                extra_added: extraAdded,
                customer_status: customerStatus,
                order_id: APO_order === 'update' ? orderId : '',
                status: selectedItemStatus.value,
                item_status: selectedItemStatus,

            }
            let newItem = itemNotExists ?
                {
                    ...commmonProps,
                    name: selectedProduct,
                    rate: Number(rate),
                    subTotal: rate * qty,
                    price: (rate * qty) - itemDiscount,
                }
                :
                {
                    ...commmonProps,
                    name: `${selectedVariation ? `${selectedProduct.label}-${selectedVariation.label}` : selectedProduct.label}`,
                    rate: getProductRate(selectedVariation ? selectedVariation : selectedProduct),
                    subTotal: getProductRate(selectedVariation ? selectedVariation : selectedProduct) * qty,
                    price: getProductPrice(selectedVariation ? selectedVariation : selectedProduct),
                    product_bkt_id: selectedProduct.value,
                    selectedProduct: selectedProduct,
                    variation_bkt_id: selectedVariation.value,
                    selectedVariation: selectedVariation,
                }
            console.log(newItem)
            _isMounted && setState(prevState => ({
                ...prevState,
                orderItems: [...prevState.orderItems, newItem],
                updatingOrderItems: true,
                itemDiscount: 0,
                itemDiscountDetails: '',
                qty: 1,
                rate: 1,
                remarks: '',
                extraAdded: false,
                customerStatus: '',
                selectedProduct: '',
                selectedVariation: '',
                selectedItemStatus: defaultStatus,
                validationMessage: ''
            }))
            itemNotExists && setItemNotExists(false)
            addItemForm.current.classList.remove('was-validated')
        }
    }

    const handleDeleteItem = (indexToBeRemoved) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            orderItems: prevState.orderItems.filter((item, index) => {
                return index !== indexToBeRemoved
            }),
            updatingOrderItems: true
        }))
    }

    const handleOrderItemBlur = (comingIndex, name) => e => {
        let el = e.target
        _isMounted && setState(prevState => {
            const orderItems = prevState.orderItems.map((item, index) => {
                if (index === comingIndex) {
                    let { rate } = item
                    if (name === 'qty') {
                        item.qty = el.innerHTML == 0 ? 1 : Number(el.innerHTML)
                        el.innerHTML = el.innerHTML == 0 ? 1 : Number(el.innerHTML)
                        item.subTotal = rate * Number(el.innerHTML)
                        item.price = item.subTotal - item.discount
                    }
                    else {
                        item[name] = el.innerHTML
                    }
                    return item
                }
                else {
                    return item
                }
            });
            return {
                ...prevState,
                orderItems,
                updatingOrderItems: true
            }
        })
    }

    const getProductRate = (item) => {
        // console.log(item)
        let rate = 0
        let dateToCheck = APO_order === 'create' ? orderDate : new Date()
        if (item) {
            let saleFrom = new Date(item.saleFrom),
                saleTo = new Date(item.saleTo)

            rate = item.salePrice ?
                item.saleFrom ?
                    (dateToCheck >= saleFrom && dateToCheck <= saleTo) ?
                        item.salePrice
                        :
                        item.rate
                    :
                    item.salePrice
                :
                item.rate
        }
        return Number(rate)
    }

    const getProductPrice = (item) => {
        let rate = getProductRate(item)
        let price = (rate * qty) - itemDiscount
        return price
    }

    const productInput = itemNotExists ?
        <Input
            label="Item name"
            name={`selectedProduct`}
            value={selectedProduct}
            handleInput={handleInput}
            required={itemNotExists}
            containerClass='mb-0'
            invalidMessage={`Please enter item name.`}
        />
        :
        <MyOwnSelect
            options={orderProductOptions}
            optionsHeading={selectedProduct ? 'Select product' : ''}
            handleChange={handleSelectChange('selectedProduct')}
            placeHolder={'Search for a product...'}
            selectedValue={selectedProduct}
            withOutIcon
            required
            async
            loadingMessage={"Searching..."}
            noOptionsMessage={"No product found."}
            minCharLength={3}
        />
    const itemNotExistsInput = <div className="custom-control custom-checkbox mt-1">
        <input
            type="checkbox"
            name="itemNotExists"
            className="custom-control-input"
            id="itemNotExists"
            checked={itemNotExists}
            onChange={(e) => {
                setItemNotExists(e.target.checked);
                changeValue('selectedProduct', '')
            }}
        />
        <label className="custom-control-label" htmlFor="itemNotExists">Item not exists ?</label>
    </div>
    const variationInput = <MyOwnSelect
        options={orderItemVariationOptions}
        optionsHeading={selectedVariation ? 'Select variation' : ''}
        handleChange={handleSelectChange('selectedVariation')}
        placeHolder={'Select variation'}
        selectedValue={selectedVariation}
        withOutIcon
        required={selectedProduct?.type === 'variable'}
    />
    const rateInput = <Input
        label="Rate"
        name={`rate`}
        value={
            itemNotExists ?
                rate
                :
                selectedVariation ?
                    getProductRate(selectedVariation)
                    :
                    selectedProduct ? getProductRate(selectedProduct) : ''
        }
        min={1}
        handleInput={handleInput}
        onBlur={handleNumberInputBlur}
        containerClass='mb-3'
        required={itemNotExists}
        invalidMessage={`Please enter a valid rate.`}
        disabled={!itemNotExists}
    />
    const qtyInput = <Input
        type='number'
        label={`Qty.`}
        name={`qty`}
        value={qty}
        min={1}
        handleInput={handleInput}
        onBlur={handleNumberInputBlur}
        containerClass='mb-3'
        required
        invalidMessage={`Please enter a valid qty.`}
    />
    const discountInput = <Input
        type='number'
        label={`Discount`}
        name={`itemDiscount`}
        value={itemDiscount}
        min={0}
        max={
            itemNotExists ?
                rate
                :
                selectedVariation ? getProductRate(selectedVariation) * qty
                    :
                    selectedProduct ? getProductRate(selectedProduct) * qty : ''
        }
        handleInput={handleInput}
        onBlur={handleNumberInputBlur}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        invalidMessage={`Please enter valid discount.`}
    />
    const discountDetailinput = <Input
        label={`Discount detail`}
        name={`itemDiscountDetail`}
        value={itemDiscountDetail}
        handleInput={handleInput}
        containerClass='mb-3'
        required={itemDiscount}
        invalidMessage={`Please enter discount reason.`}
    />
    const priceInput = <Input
        type="number"
        label="Price"
        value={
            itemNotExists ?
                ((rate * qty) - itemDiscount)
                :
                selectedVariation ?
                    getProductPrice(selectedVariation)
                    :
                    selectedProduct ? getProductPrice(selectedProduct) : ''
        }
        containerClass='mb-3'
        disabled
    />
    const itemStatusInput = <div className='mb-3 p-0'>
        <MyOwnSelect
            options={itemStatusOptions}
            optionsHeading={selectedProduct ? 'Select item status' : ''}
            handleChange={handleSelectChange('selectedItemStatus')}
            placeHolder={'Select item status'}
            selectedValue={selectedItemStatus}
            withOutIcon
            required
        />
    </div>
    const remarksInput = <Input
        label="Remarks"
        name={`remarks`}
        value={remarks}
        handleInput={handleInput}
        containerClass='mb-3'
    />
    const extraAddedInput = <div className="custom-control custom-checkbox">
        <input
            type="checkbox"
            name="extraAdded"
            className="custom-control-input"
            id="extraAdded"
            checked={extraAdded}
            onChange={handleCheckboxChange}
        />
        <label className="custom-control-label" htmlFor="extraAdded">Extra added ?</label>
    </div>

    return (
        <div className={`${currentStep === 3 ? 'd-block' : 'd-none'}`}>
            {APO_order !== 'read' && addNewItem &&
                <>
                    <form
                        ref={addItemForm}
                        onSubmit={handleAddItem}
                        className={`m-0 p-0`}
                        noValidate
                    >
                        <div className='m-0 p-0 p-grid p-jc-end'>
                            <div className='p-mb-2 p-col-12'>
                                {productInput}
                                {!selectedProduct && itemNotExistsInput}
                            </div>
                            {
                                selectedProduct &&
                                selectedProduct.type === 'variable' &&
                                <div className='mb-3 col-md-6'>
                                    {variationInput}
                                </div>
                            }
                            <div className='m-0 col-sm-6'>
                                {rateInput}
                            </div>
                            <div className='m-0 col-sm-6'>
                                {qtyInput}
                            </div>
                            <div className='m-0 col-sm-6'>
                                {discountInput}
                            </div>
                            {
                                itemDiscount != 0 &&
                                itemDiscount !== '' &&
                                <div className='m-0 col-sm-6'>{discountDetailinput}</div>
                            }
                            <div className='m-0 col-sm-6'>
                                {priceInput}
                            </div>
                            <div className='m-0 col-sm-6'>
                                {itemStatusInput}
                            </div>
                            <div className='m-0 col-sm-6'>
                                {remarksInput}
                            </div>
                            <div className='mb-3 col-sm-6'>
                                {extraAddedInput}
                            </div>
                            <div className='mb-3 col-sm-6 text-right'>
                                <CustomButton
                                    bg='primary-light'
                                    label='Add item'
                                    type='submit'
                                />
                            </div>
                        </div>
                    </form>
                </>
            }
            {/* add new product button when updating order */}
            {
                APO_order === 'update' && !addNewItem &&
                <div className="col mb-3">
                    <CustomButton
                        onClick={() => { setAddNewItem(true) }}
                        bg='primary-light'
                        label='Add item'
                    />
                </div>
            }
            {/* order products*/}
            <div className={`m-0 p-0 p-grid`}>
                {Array.isArray(orderItems) &&
                    orderItems.length > 0 &&
                    <div className='m-0 col-12 table-responsive'>
                        {getTableData(orderItems, APO_order, handleOrderItemBlur, handleDeleteItem)}
                    </div>
                }
            </div>
        </div >
    )
}

const getTableData = (orderItems, APO_order, handleOrderItemBlur, handleDeleteItem) => {
    //order-items table data
    let orderItemsColumns = [
        <tr key={Math.random()}>
            <th className='px-1' scope="col">#</th>
            <th className='px-1' scope="col">Item</th>
            <th className='px-1' scope="col">Rate</th>
            <th className='px-1' scope="col">Qty.</th>
            <th className='px-1' scope="col">Subtotal</th>
            <th className='px-1' scope="col">Discount</th>
            <th className='px-1' scope="col">Price</th>
            <th className='px-1' scope="col">Remarks</th>
            <th className='px-1' scope="col">Extra added</th>
            {/* <th className='px-1' scope="col">Customer status</th> */}
            <th className='px-1' scope="col">Item status</th>
            {
                APO_order !== 'read' &&
                <th scope="col">Action</th>
            }
        </tr >
    ], orderItemsRows = []

    Array.isArray(orderItems) && orderItems.length && orderItems.map((item, index) => {
        // console.log(item)
        orderItemsRows.push(
            <tr key={index + 1}>
                <th scope="row">{index + 1}</th>
                <td style={{ fontSize: '11px' }}>{item.name}</td>
                <td>{item.rate}</td>
                <td
                    contentEditable={true}
                    suppressContentEditableWarning
                    onKeyPress={handleNumberInputKeyPress}
                    onBlur={handleOrderItemBlur(index, 'qty')}
                    className='text-center'
                >
                    {item.qty}
                </td>
                <td className='text-center'>{item.rate * item.qty}</td>
                <td className='text-center'>
                    {`${item.discount} ${item.discount_detail ? `(${item.discount_detail})` : ''} `}
                </td>
                <td>
                    {(item.rate * item.qty) - item.discount}
                </td>
                <td
                    contentEditable={true}
                    suppressContentEditableWarning
                    onBlur={handleOrderItemBlur(index, 'remarks')}
                >
                    {item.remarks}
                </td>
                <td className='text-center'>
                    {item.extra_added ? '✔' : <span className='colorBlue font-weight-bold'>X</span>}
                </td>
                {/* <td
                    contentEditable={APO_order === 'update'}
                    suppressContentEditableWarning
                    onBlur={handleOrderItemBlur(index, 'customer_status')}
                >
                    {item.customer_status}
                </td> */}
                <td style={{ fontSize: '11px' }}>
                    {
                        item.item_status?.label ?
                            item.item_status?.label
                            :
                            item.item_status?.title
                    }
                </td>
                {/* <td className='text-center'>
                    {item.slips_printed ? 'Printed' : <span className='colorBlue font-weight-bold'>X</span>}
                </td> */}
                {APO_order !== 'read' &&
                    <td className='text-center'>
                        <button
                            style={{ fontSize: '18px', background: 'transparent', border: 'none' }}
                            onClick={() => { handleDeleteItem(index) }}
                            className='m-0 p-0'
                        >
                            <i className='fa fa-times color-secondary' />
                        </button >
                    </td>
                }
            </tr>
        )
    })

    return (
        <table id='orderItemsTable' className="table table-striped bg-tertiary table-bordered table-hover table-sm w-100">
            <thead className="color-tertiary bg-primary-light">
                {orderItemsColumns}
            </thead>
            <tbody>
                {orderItemsRows}
            </tbody>
        </table>
    )
}





