import React from 'react'
import { ActionButton } from '../../../../misc/components/Inputs'
import { MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact'
import { GoX, GoCheck } from 'react-icons/go';



export default function SignupFormStep4(props) {
    let {
        currentStep, APO_seller, isEditingSeller,
        allPlans, planFeatures, selectedPlan,
        changeValue, goToPrevStep, handleSubmit
    } = props

    const listItemStyle = { borderTop: 'none', borderLeft: 'none', borderRight: 'none' }

    if (currentStep !== 4) {
        return null
    }
    return (
        <>
            <form className={`m-0 p-0 ${APO_seller !== 'update' ? 'pt-sm-5 mt-sm-3' : ''} w-100 w-md-75 text-center color-tertiary mx-auto`} onSubmit={handleSubmit} noValidate>

                {(APO_seller !== 'update' || (APO_seller === 'update' && isEditingSeller)) &&
                    <>
                        {/* {APO_seller !== 'update' ?
                            <h5 className='font-weight-bold mb-4'>Pricing plans</h5> :
                            <>
                                <small className="d-block d-sm-none small mb-3"> Pricing plans</small>
                                <h6 className="d-none d-sm-block small mb-3"> Pricing plans</h6>
                            </>
                        }
                        <div className='p-d-flex p-flex-column p-flex-lg-row px-3'>
                            {allPlans.map((plan, index) => {
                                return (
                                    <MDBCol
                                        middle
                                        className='m-0 py-0' key={index + 1}
                                        style={{ cursor: 'pointer', minHeight: '55vh' }}
                                    >
                                        <MDBCard
                                            className={`${selectedPlan === plan.id ? 'active active-zoom' : ''} mb-4`}
                                            onClick={() => { changeValue('selectedPlan', plan.id) }}
                                        >
                                            <MDBCardHeader
                                                tag='h5'
                                                className='color-tertiary bg-primary-light font-weight-bold'
                                            >
                                                {plan.title}
                                                {plan.subtitle &&
                                                    <p className='small mt-1 mb-0' style={{ fontSize: '11px' }}>
                                                        {plan.subtitle}
                                                    </p>
                                                }
                                            </MDBCardHeader>
                                            <MDBCardBody
                                                className='m-0 py-3 color-primary'
                                            >
                                                <li className='list-group-item d-flex justify-content-center align-items-center py-0 mb-3'
                                                    style={{ ...listItemStyle, borderColor: '#0e5b96' }}
                                                >
                                                    {plan.price_monthly ?
                                                        <>
                                                            <div className='h5 m-0 p-0'>
                                                                Rs. {plan.price_monthly}<div className="h6">{'/month'}</div>
                                                            </div>
                                                            <div className='h5 m-0 p-0' style={{ fontSize: '30px' }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                            <div className='h5 m-0 p-0'>
                                                                Rs. {plan.price_annual}<div className="h6">{'/year'}</div>
                                                            </div>
                                                            <div className='h5 m-0 p-0' style={{ fontSize: '30px' }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </div>
                                                            <div className='h5 m-0 p-0'>
                                                                Rs. {plan.price_one_time}<div className="h6">{'/one time'}</div>
                                                            </div>
                                                        </>
                                                        :
                                                        'Free'
                                                    }
                                                </li>
                                                <ul className="list-group" key={index + 1}>
                                                    {planFeatures.map((feature, index) => {
                                                        return (
                                                            < li
                                                                key={index + 1}
                                                                className="list-group-item d-flex justify-content-between align-items-center py-2"
                                                                style={listItemStyle}
                                                            >
                                                                {feature.name}
                                                                {plan.plan_features.map(pFeature => pFeature.id).includes(feature.id) ?
                                                                    <GoCheck color='#00C851' /> :
                                                                    <GoX color='#ff4444' />
                                                                }
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                )
                            })}
                        </div> */}
                    </>
                }
                <MDBRow start={APO_seller === 'update' && !isEditingSeller} end={APO_seller !== 'update'} className='mx-0 my-2 p-0'>
                    <MDBCol size='6' className='m-0 text-left'>
                        {APO_seller !== 'update' &&
                            <ActionButton
                                onClick={goToPrevStep}
                                value="Previous"
                                className="bg-primary-light"
                                type="button"
                            />}
                    </MDBCol>
                    <MDBCol size={APO_seller !== 'update' ? '6' : isEditingSeller ? '6' : '4'} className='m-0 text-right'>
                        <ActionButton
                            type='submit'
                            value={APO_seller !== 'update' ? "Submit" : 'Update'}
                            className="bg-Secondary w-100 p-ml-auto p-button-rounded p-button-raised"
                        />
                    </MDBCol>
                </MDBRow>
            </form >
        </>
    )
}