import React from 'react'
import { ActionButton, Input } from '../../../../misc/components/Inputs'
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { Button } from 'primereact/button';
import { useEffect, useState, useRef, useContext } from 'react'
import ImageGallery from '../../../../misc/components/ImageGallery'
import LoaderModal from '../../../../misc/components/LoaderModal'

export default function PersonalDetailsForm(props) {
    let {
        personalDetailsForm, APO_seller, countriesList, statesList,
        profilePicture, name, email, cell, address, city, state, selectedCountry, selectedState, postcode, cnic, username, password, cnfrmPassword,
        isEditingProfile, isEditingSeller, isPasswordChanging,
        handleInput, handleNumberInputKeyPress,
        handleSelectChange, goToNextStep, changeValue
    } = props
    const [{ showImageGallery, isLoading }, setState] = useState({
        showImageGallery: false, isLoading: false, profilePicture: ''
    })
    const [modalShow, setModalShow] = useState(false)
    // const changeProfilePicture = (name, value) => {
    //     // console.log(name, value)
    //     setState(prevState => ({ ...prevState, [name]: value}));
    // }

    const nameInput = <Input
        label='Full name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        required
        className={'border-radius-5'}
        invalidMessage={`Please enter full name`}
    />
    const emailInput = <Input
        type="email"
        label='Email'
        name={`email`}
        value={email}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter a valid email`}
    />
    const cellInput = <Input
        label='Cell'
        name={`cell`}
        value={cell}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        hint='e.g. 971123456789'
        // pattern="^(971)[0-9]{9}$"
        // maxLength="12"
        required
        invalidMessage={`Please follow the pattern '971123456789'`}
    />
    const cnicInput = <Input
        // label='CNIC'
        label='Emirates ID'
        name={`cnic`}
        value={cnic}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        // hint='e.g. 0000000000000'
        // pattern="^[0-9]{13}"
        maxLength='13'
    // required
    // invalidMessage={`Please follow the pattern '0000000000000'`}
    />
    const addressInput = <Input
        label='Address'
        name={`address`}
        value={address}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter address`}
    />
    const cityInput = <Input
        label='City'
        name={`city`}
        value={city}
        // required
        handleInput={handleInput}
    // invalidMessage={`Please enter city`}
    />
    // const stateInput = <Input
    //     // label='State/Province'
    //     label='Emirates'
    //     name={`state`}
    //     value={state}
    //     required
    //     handleInput={handleInput}
    // // invalidMessage={`Please enter state/province`}
    // />
    const stateInput = <div className='mb-4 p-0'>
        <MyOwnSelect
            options={statesList}
            optionsHeading={selectedState ? 'Select Emirate' : ''}
            handleChange={handleSelectChange('selectedState')}
            placeHolder='Select Emirate'
            selectedValue={selectedState}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const selectCountryInput = <div className='mb-4 p-0'>
        <MyOwnSelect
            options={countriesList}
            optionsHeading={selectedCountry ? 'Select country' : ''}
            handleChange={handleSelectChange('selectedCountry')}
            placeHolder='Select country'
            selectedValue={selectedCountry}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const postcodeInput = <Input
        label='Postcode'
        name={`postcode`}
        value={postcode}
        handleInput={handleInput}
    // invalidMessage={`Please enter state/province`}
    />
    const usernameInput = <Input
        label='Username'
        name={`username`}
        value={username}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter a username`}
    />
    const passwordInput = <Input
        type='password'
        label='Password'
        name={`password`}
        value={password}
        handleInput={handleInput}
        hint={(isEditingSeller || isEditingProfile) ?
            isPasswordChanging ? 'Enter new password' : '********' : ''}
        pattern="^(?=.*\d)(?!.*[\s])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        maxLength="20"
        containerClass={'input-group'}
        required
        disabled={(isEditingSeller || isEditingProfile) ?
            isPasswordChanging ?
                false :
                true :
            false}
        invalidMessage={`Please enter a password between 8-20 characters with at least 1 uppercase, 1 lowercase and 1 number.`}
    >
        {(isEditingProfile || isEditingSeller) &&
            < div className="input-group-append">
                <span className="input-group-text border-radius-0">
                    <MDBTooltip domElement tag="span" placement="right" className='bg-Primary tooltip-width'>
                        <i
                            className={`${isPasswordChanging ?
                                'fa fa-times color-secondary' :
                                'fa fa-pencil-alt color-primary-light'} cursor-help`}
                            onClick={() => changeValue('isPasswordChanging', !isPasswordChanging)}
                        />
                        <i>
                            {isPasswordChanging ? 'Click here if you don`t want to change' : 'Click here to change password'}
                        </i>
                    </MDBTooltip>
                </span>
            </div>
        }
    </Input >
    const cnfrmPasswordInput = <Input
        type='password'
        label='Confirm password'
        name={`cnfrmPassword`}
        value={cnfrmPassword}
        handleInput={handleInput}
        pattern={`^${password}$`}
        required
        disabled={(isEditingSeller || isEditingProfile) ?
            password ? false : true : false}
        invalidMessage={`Password doesn't match`}
    />

    const profilePictureInput = <div className='text-left d-flex'>
        {/* {console.log(profilePicture)} */}
        {profilePicture && <img
            src={profilePicture.name ? `/images/${profilePicture.name}` : `/images/${profilePicture}`}
            width='100%'
            // height='30%'
            alt='profilePicture'
            style={{ borderRadius: '3px', border: '1px solid white', height: '150px', backgroundColor: 'white' }}
            onClick={() => setModalShow(true)}
        />}
        <Button
            label={profilePicture === '' || !profilePicture ? 'Choose Profile Picture' : 'Change Profile Picture'}
            icon={profilePicture ? 'pi pi-refresh' : "pi pi-plus"}
            type='button'
            className="p-button-sm w-100 py-1 px-3 bg-Primary  color-tertiary  font-weight-bold tertiary-border border-radius-0"
            onClick={() => setModalShow(true)}
        // onClick={() => setState(prevState => ({ ...prevState, showImageGallery: true }))}
        />
    </div>

    let fieldsToDisplay = [
         nameInput, emailInput, cellInput, addressInput, stateInput, cnicInput,
        usernameInput, passwordInput
    ]
    // postcodeInput,cityInput, ,selectCountryInput,
    fieldsToDisplay = (((isEditingSeller || isEditingProfile) && isPasswordChanging) || (!isEditingSeller && !isEditingProfile)) ?
        [...fieldsToDisplay, cnfrmPasswordInput] : fieldsToDisplay

    return (
        <>
            <form ref={personalDetailsForm} noValidate>
                <div className='mb-3 col-sm-12'>
                    {profilePictureInput}
                </div>
                <MDBRow className='m-0 p-0'>
                    {fieldsToDisplay.map((field, index) => {
                        return <MDBCol key={index + 1} middle sm='12' className='m-0'>{field}</MDBCol>
                    })}
                </MDBRow>
                {
                    APO_seller !== 'update' &&
                    <MDBRow end className='m-0 p-0'>
                        <MDBCol className='m-0 text-right'>
                            <ActionButton
                                onClick={goToNextStep}
                                value="Next"
                                className="bg-Secondary"
                                type="button"
                            />
                        </MDBCol>
                    </MDBRow>
                }
            </form >
            <ImageGallery
                addBtnHeading={'Choose'}
                modalHeading={'Add Media'}
                // modalShow={showImag\eGallery}
                modalShow={modalShow}
                reference='all'
                isProtected={true}
                handleSelectedFiles={(file) => {
                    delete file[0].selected
                    changeValue('profilePicture', file[0])
                    // changeProfilePicture('profilePicture', file[0])
                }}
                // hideImageGallery={() => { changeValue('showImageGallery', false) }}
                hideImageGallery={() => { setModalShow(false) }}
            />
            {/* <LoaderModal modalShow={isLoading} /> */}
        </>
    )
}