import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../misc/pages/NotFound';
import Methods from './methods/Methods';


const Payments = () => {

    return (
        <Switch>
            <Route path='/payments/methods' component={Methods} />
            <Route component={NotFound} />
        </Switch>

    )
}


export default Payments