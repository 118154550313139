import React, { useState, useEffect, useRef, useContext } from 'react'
import { CustomListing } from '../../../misc/components/CustomListing'
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Can, AbilityContext } from "../../../../configs/Ability-context";
import { UserContext } from "../../../../contexts/UserContext";
import { useHistory } from 'react-router-dom'

let _isMounted
export const AllSellerRequests = () => {
    let dt = useRef(null)
    let toast = useRef(null)
    let history = useHistory()
    const ability = useContext(AbilityContext)
    const { isSellerLoggedIn, isShopManagerLoggedIn } = useContext(UserContext)

    const [{ allRequests, isLoading, selectedStatus }, setState] = useState({ allRequests: [], isLoading: false, selectedStatus: null })

    useEffect(() => {
        _isMounted = true
        const fetchData = () => {
            changeValue('isLoading', true)
            fetch('/api/seller_requests')
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    setState(prevState => ({
                        ...prevState,
                        allRequests: json.data,
                        isLoading: false
                    }))
                })
                .catch(err => {
                    changeValue('isLoading', false)
                    console.log(err)
                })
        }
        fetchData()
    }, [])

    const changeValue = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }))
    }
    const handleEdit = (user) => (e) => {
        // checkUserRole(user, 'seller') ? 
        history.push(`/sellers/edit/${user.id}`)
            // : history.push(`/users/edit/${user.id}`)
    }


    const columns = [
        {
            field: "email",
            header: "Email",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by email",
            filterMatchMode: "contains",
        },
        {
            field: "cell",
            header: "Cell",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by cell",
            filterMatchMode: "contains",
        },
        {
            field: "address",
            header: "Address",
            sortable: true,
            filter: true,
            filterPlaceholder: "Search by address",
            filterMatchMode: "contains",
        },
    ]
    return (
        <Can I='read' a='sellerRequests'>
            <CustomListing
                allRecords={allRequests}
                heading={(isSellerLoggedIn || isShopManagerLoggedIn) ? 'My requests' : 'Seller requests'}
                firstColumn={<Column
                    field="name"
                    header="Name"
                    sortable={true}
                    filter={true}
                    filterPlaceholder="Search by name"
                    filterMatchMode="contains"
                    headerStyle={{ width: '170px' }}
                />}
                columns={columns}
                permissionSubject={'sellerRequests'}
                isLoading={isLoading}
                tableRef={dt}
                handleEdit={handleEdit}
                toastRef={toast}
                timeStamps={true}
            />
        </Can>

    )
}
