import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllBrands from './pages/AllBrands'
import NotFound from '../../misc/pages/NotFound'
import { PrivateRoute } from '../../misc/PrivateRoute'


const Brands = () => {

    return (
        <Switch>
            <PrivateRoute path="/products/brands/all">
                <AllBrands />
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default Brands