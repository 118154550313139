import React, { useState, useEffect } from 'react'
import { CustomButton, CustomInput } from '../../../misc/components/Inputs'
import getOptions from '../../../misc/components/Options'
import CustomSelect from '../../../misc/components/CustomSelect'
import { CustomDatePicker } from '../../../misc/components/CustomDatePicker'
import { Dialog } from 'primereact/dialog'

const StatusAfterEffectsModal = (props) => {

    let { status } = props
    const [isTakingInput, setIsTakingInput] = useState(false)
    const [isQuestioning, setIsQuestioning] = useState(false)
    const [input1, setInput1] = useState('')
    const [input2, setInput2] = useState('')
    const [input3, setInput3] = useState('')
    const [validationMesssage, setValidationMessage] = useState('')
    const [isAnswered, setIsAnswered] = useState(false)

    useEffect(() => {
        let takingInput = status?.input_needed
        let questioning = status?.question_message
        setIsTakingInput(takingInput)
        setIsQuestioning(questioning)
        setIsAnswered((questioning && takingInput) ? false : takingInput)
        setInput1('')
        setInput2('')
        setInput3('')
        setValidationMessage('')
    }, [props.modalShow])

    if (isTakingInput) {
        const courierOptions = getOptions('couriers')
        var firstInput = status?.input_needed[0]
        var secondInput = status?.input_needed[1]
        var thirdInput = status?.input_needed[2]
        var firstElement = (firstInput?.includes('date') || firstInput?.includes('till')) ?
            <CustomDatePicker
                label={firstInput}
                selectedValue={input1}
                handleChange={(value) => {
                    setInput1(value);
                }}
                placeholder='Select date...'
                isClearable
                isRequired
                isFormField
                className='m-0 mb-0 w-100'
            />
            :
            <CustomInput
                label={firstInput}
                value={input1}
                required
                handleInput={(e) => {
                    setInput1(e.target.value)
                }}
            />
        var secondElement = secondInput ? secondInput.includes('Courier') ?
            <CustomSelect
                value={input2}
                options={courierOptions}
                placeholder='Select courier'
                className='mb-4 color-primary-light'
                handleChange={(e) => { setInput2(e.value) }}
            />
            :
            <CustomInput
                label={secondInput}
                required
                value={input2}
                handleInput={(e) => {
                    setInput2(e.target.value)
                }}
            />
            :
            null
        var thirdElement = thirdInput ? <CustomInput
            label={thirdInput}
            required
            value={input3}
            handleInput={(e) => {
                setInput3(e.target.value)
            }}
        />
            :
            null
    }
    let headerTitle = (isAnswered) ? 'Please fill !' : 'Confirmation required !'
    const handleResponse = ({ isConfirmed }) => {
        if ((isQuestioning && !isTakingInput)) {
            props.handleResponse({ isConfirmed })
        }
        else {
            if (isAnswered) {
                let conditionFulfilled = status?.input_needed.length === 1 ?
                    input1
                    :
                    status?.input_needed.length === 2 ?
                        (input1 && input2)
                        :
                        (input1 && input2 && input3)
                if (!conditionFulfilled) {
                    setValidationMessage('All fields are mandatory !')
                }
                else props.handleResponse({ isConfirmed, input1, input2 })
            }
            else {
                setIsAnswered(true)
            }
        }
    }
    return (
        <Dialog
            // visible={true}
            visible={props.modalShow}
            header={<p className='text-info m-0 mr-3 p-0'>{headerTitle}</p>}
            modal
            className='text-center mx-4'
            style={{ width: '25vw' }}
            breakpoints={{ '960px': '50vw', '768px': '75vw', '576px': '100vw', }}
            closable
            onHide={() => { props.handleResponse({ isConfirmed: false }) }}
            contentClassName='overflow-visible'
        >
            {isQuestioning && !isAnswered &&
                <>
                    <i className="fas fa-question fa-6x animated rotateIn text-info mb-3" />
                    <h5 className='text-info' style={{ letterSpacing: '0.6px' }}>
                        {status?.question_message}
                    </h5>
                    <div className='p-d-flex p-flex-wrap p-jc-center p-jc-sm-around mt-4'>
                        {
                            status?.positive_response &&
                            <CustomButton
                                label={status?.positive_response}
                                onClick={() => { handleResponse({ isConfirmed: true }) }}
                                bg='Info'
                                className='w-25 mx-2 mx-sm-0'
                            />
                        }
                        {
                            status?.negative_response &&
                            <CustomButton
                                label={status?.negative_response}
                                onClick={() => { props.handleResponse({ isConfirmed: status.slug === 'reforwarded' }) }}
                                color='Info'
                                className='w-25 mx-2 mx-sm-0'
                                variant={'outlined-button'}
                            />
                        }
                    </div>
                </>
            }
            {isAnswered && isTakingInput &&
                <div className='p-d-flex p-flex-column text-left pt-4'>
                    {firstElement}
                    {secondElement}
                    {thirdElement}
                    <CustomButton
                        label={'Submit'}
                        onClick={() => { handleResponse({ isConfirmed: true }) }}
                        color='Info'
                        className='w-50 mx-auto'
                        variant={'outlined-button'}
                    />
                </div>
            }

            <label className='validating-label mt-2'>{validationMesssage}</label>
        </Dialog >

    );
}

export default StatusAfterEffectsModal;