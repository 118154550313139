import React from 'react';
import Header from './Header'
import AllRoutes from '../misc/AllRoutes'


const Main = () => {
    return (
        <main>
            {/* <img src='/background1.jpg' className='main-bg' /> */}
             <header>
                <Header/>
            </header>
            <div>
                <AllRoutes />
            </div>
        </main>
    );
};

export default Main;