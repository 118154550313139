import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { MDBRow, MDBCol } from 'mdbreact';
import { Can } from '../../../../../configs/Ability-context'
import LoaderModal from '../../../../misc/components/LoaderModal'
import { Input, ActionButton } from '../../../../misc/components/Inputs'
import getOptions from '../../../../misc/components/Options'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { getPaymentMethod, createPaymentMethod, updatePaymentMethod } from '../../../../../services/payments/method-service';

let _isMounted
let bankOptions = getOptions('banks')
const INITIAL_STATE = {
    title: '',
    selectedBank: '',
    account: '',
    acTitle: '',
    iban: '',
};
const PaymentMethod = (props) => {
    const [{ title, selectedBank, account, acTitle, iban, validationMessage, isLoading }, setState] = useState({
        ...INITIAL_STATE, validationMessage: '', isLoading: false
    })

    let paymentMethodForm = useRef(null)
    let { APO_method } = props
    let history = useHistory()
    let { method } = useParams()

    _isMounted = true
    useEffect(() => {
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getPaymentMethod(method)
                if (success) {
                    let { title, bank_name, account_id, account_title, iban_id } = data
                    setState(prevState => ({
                        ...prevState,
                        title: title,
                        selectedBank: bankOptions.find(bank => bank.value === bank_name),
                        account: account_id,
                        acTitle: account_title,
                        iban: iban_id,
                    }));
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value })) }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleSelectChange = selectedOption => {
        changeValue('selectedBank', selectedOption)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = paymentMethodForm.current
        if (selectedBank === '' || !selectedBank) {
            changeValue('selectedBank', null)
            return changeValue('validationMessage', 'Please select a bank')
        }
        else if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        else {
            let PaymentMethod = {
                title: title, value: title.toLowerCase(), bank_name: selectedBank.value, account_id: account, account_title: acTitle, iban_id: iban
            }
            try {
                const { success, message } = APO_method === 'create' ? await createPaymentMethod(PaymentMethod) : await updatePaymentMethod(method, PaymentMethod)
                changeValue('validationMessage', message)
                if (success) {
                    if (APO_method === 'create') {
                        _isMounted && setState(prevState => ({ ...prevState, ...INITIAL_STATE }))
                    }
                    setTimeout(() => {
                        history.push('/payment-methods/all')
                    }, 1500)
                    form.classList.remove('was-validated')
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }



    return (
        <Can I={APO_method === 'create' ? 'create' : APO_method === 'update' ? 'update' : 'false'} a='paymentMethods' >
            <div className='screen-center h-100vh'>
                <form ref={paymentMethodForm} className='m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='font-weight-bold mb-3 mb-sm-4'>{APO_method === 'create' ? 'New Payment Method' : 'Edit Payment Method'}</h5>
                    <MDBRow center className='m-0 p-0'>
                        <MDBCol middle sm='9' className='m-0'>
                            <div className='mb-4 p-0 mx-0'>
                                <MyOwnSelect
                                    options={bankOptions}
                                    optionsHeading={selectedBank ? 'Select bank' : ''}
                                    handleChange={handleSelectChange}
                                    placeHolder={'Select bank'}
                                    selectedValue={selectedBank}
                                    className='border-radius-0'
                                    withOutIcon
                                    required
                                />
                            </div>
                            <Input
                                label='Title'
                                name={`title`}
                                value={title}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter method title`}
                            />
                            <Input
                                type='number'
                                label='A/C no.'
                                name={`account`}
                                value={account || ''}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter a valid account no.`}
                            />
                            <Input
                                label='A/C title'
                                name={`acTitle`}
                                value={acTitle || ''}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter account title`}
                            />
                            <Input
                                label='IBAN'
                                name={`iban`}
                                value={iban || ''}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter IBAN no.`}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center className='mx-0 my-2 p-0'>
                        <MDBCol sm='9' className='m-0 p-0 row'>
                            <MDBCol size='6' className='m-0 text-left'>
                                <label className='p-0 validating-label'>{validationMessage}&nbsp;</label>
                            </MDBCol>
                            <MDBCol size='6' className='m-0 text-right'>
                                <ActionButton
                                    // icon={'arrow-right'}
                                    value={APO_method === 'create' ? "Create" : 'Update'}
                                    // name="next"
                                    className="bg-Secondary"
                                    type="submit"
                                />
                            </MDBCol>
                        </MDBCol>
                    </MDBRow>
                </form >
            </div >
            <LoaderModal modalShow={isLoading} />
        </Can >
    );
}


export default PaymentMethod