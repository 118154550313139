import React from 'react';
import { Input } from '../../../../misc/components/Inputs'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import getOptions from '../../../../misc/components/Options'
import { getCustomer } from '../../../../../services/users/user-service'

let cCellField, disableCustomerFields = false

export const CustomerDataStep = (props) => {
    let {
        _isMounted,
        APO_order,
        currentStep,
        cAddress,
        cCell,
        cCity,
        cCountry,
        cEmail,
        changeValue,
        cityOptions,
        cName,
        countryOptions,
        cPostcode,
        cState,
        cStateOptions,
        customerDataForm,
        customer_id,
        handleInput,
        handleSelectChange,
        isMakingAQuery,
        setState
    } = props;



    const getCustomerDetails = async () => {
        changeValue('isLoading', true)
        console.log(cCellField?.checkValidity())
        if (!cCellField?.checkValidity()) {
            disableCustomerFields = true
            cCellField?.value !== '' && customerDataForm.current.classList.add('was-validated')
            // isMakingAQuery && cCellField.value !== '' && cCellField.closest('form').classList.add('was-validated')
            changeValue('isLoading', false)
            return
        }
        else {
            customerDataForm.current.classList.remove('was-validated')
            try {
                const { data } = await getCustomer(cCell)
                if (data) {
                    let selectedCity = cityOptions.find(o => o.value === data.city)
                    let selectedCountry = countryOptions.find(o => o.value === data.country)
                    let StateOptions = getOptions('states', selectedCountry.value)
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        customer_id: data.id,
                        cName: data.name,
                        cEmail: data.email,
                        cAddress: data.address,
                        cCountry: selectedCountry,
                        cCity: selectedCity ? selectedCity : { label: data.city, value: data.city },
                        cPostcode: data.postcode,
                        cState: StateOptions ? StateOptions.find(o => o.value === data.state) : data.state,
                    }))
                }
                else {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        customer_id: null,
                        cName: '',
                        cEmail: '',
                        cAddress: '',
                        cCountry: '',
                        cCity: '',
                        cState: '',
                        cPostcode: ''
                    }))
                    disableCustomerFields = false
                }
            }
            catch (err) {
                console.log(err)
            }
        }
        changeValue('isLoading', false)
    }
    const cellInput = <Input
        label={`Phone no.`}
        name={`cCell`}
        value={cCell}
        handleInput={handleInput}
        onBlur={getCustomerDetails}
        hint={'e.g. 923011234567 or 03011234567'}
        pattern="(^(92)[0-9]{10}$|^(0)[0-9]{10})"
        maxLength="12"
        setRef={(ref) => cCellField = ref}
        containerClass='mb-3'
        required
        disabled={APO_order === 'read'}
        invalidFullWidth
        invalidMessage={`Please follow the pattern 923011234567 or 03011234567`}
    />
    const nameInput = <Input
        label={`Name`}
        name={`cName`}
        value={cName}
        handleInput={handleInput}
        containerClass='mb-3'
        required={!isMakingAQuery}
        disabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        invalidFullWidth
        invalidMessage={`Please enter a valid Name.`}
    />
    const emailInput = <Input
        type="email"
        label={`Email`}
        name={`cEmail`}
        value={cEmail}
        handleInput={handleInput}
        containerClass='mb-3'
        disabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        invalidFullWidth
        invalidMessage={`Please enter a valid Email.`}
    />
    const addressInput = <Input
        type={'textarea'}
        rows={3}
        label={`Address`}
        name={`cAddress`}
        value={cAddress}
        handleInput={handleInput}
        containerClass='mb-3'
        required={!isMakingAQuery}
        disabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        invalidFullWidth
        invalidMessage={`Please enter a valid Address.`}
    />
    const cityInput = <MyOwnSelect
        options={cityOptions}
        optionsHeading={cCity ? 'Select city' : ''}
        handleChange={handleSelectChange('cCity')}
        placeHolder={'Select city'}
        selectedValue={cCity}
        withOutIcon
        required
        creatable
        isDisabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        handleCreate={(newCityName) => {
            // cityOptions = [{ key: newCityName, label: newCityName, value: newCityName }, ...cityOptions]
            handleSelectChange('cCity')({ key: newCityName, label: newCityName, value: newCityName })
        }}
    />
    const stateInput = cStateOptions ?
        <MyOwnSelect
            options={cStateOptions}
            optionsHeading={cState ? 'State/Province' : ''}
            handleChange={handleSelectChange('cState')}
            placeHolder='State/Province'
            selectedValue={cState}
            className='border-radius-0 mb-3'
            withOutIcon
            required
            isDisabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        />
        :
        <Input
            label='State/Province'
            name={`cState`}
            value={cState}
            required
            containerClass='mb-3'
            handleInput={handleInput}
            disabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
            invalidMessage={`Please enter state/province`}
        />
    const selectCountryInput = <MyOwnSelect
        options={countryOptions}
        optionsHeading={cCountry ? 'Select country' : ''}
        handleChange={handleSelectChange('cCountry')}
        placeHolder='Select country'
        selectedValue={cCountry}
        className='border-radius-0'
        withOutIcon
        required
        isDisabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
    />
    const postcodeInput = <Input
        label='Postcode'
        name={`cPostcode`}
        value={cPostcode}
        containerClass='mb-3'
        handleInput={handleInput}
        disabled={APO_order === 'read' || (APO_order === 'create' && (customer_id !== null || disableCustomerFields))}
        invalidMessage={`Please enter postcode`}
    />

    return (
        <form ref={customerDataForm} className={`m-0 p-0 ${currentStep === 2 ? 'd-block' : 'd-none'}`} noValidate>
            <div className='p-grid m-0 p-0'>
                <div className='col-sm-6'>
                    {cellInput}
                </div>
                <div className='col-sm-6'>
                    {nameInput}
                </div>
                <div className='col-sm-6'>
                    {emailInput}
                </div>
                <div className='p-mb-3 col-sm-6'>
                    {selectCountryInput}
                </div>
                <div className='col-12'>
                    {addressInput}
                </div>
                <div className='col-sm-6'>
                    {stateInput}
                </div>
                <div className='p-mb-3 col-sm-6'>
                    {cityInput}
                </div>
                <div className='col-sm-6'>
                    {postcodeInput}
                </div>
            </div>
        </form>
    )

}
