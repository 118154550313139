import React, { useState, useEffect } from 'react';
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import getOptions from '../../../../misc/components/Options'
import AttributesAccordion from '../sub-sections/AttributesAccordion'
import { CustomButton } from '../../../../misc/components/Inputs'

export const AttributesDataStep = (props) => {
    let {
        _isMounted,
        APO_product,
        attributesDataForm,
        changeValue,
        currentStep,
        isVariableProduct,
        refreshData,
        showSubmitButton,

        allAttributes,
        allAttributeTerms,
        variations,
    } = props
    let attributeOptions = getOptions('attributes', allAttributes)

    const [{
        selectedAttribute, attributes, validationMessage
    }, setState] = useState({
        selectedAttribute: '', attributes: props.attributes, validationMessage: ''
    })

    useEffect(() => {
        if (APO_product === 'create') {
            _isMounted && setState(prevState => ({
                ...prevState,
                selectedAttribute: '',
                attributes: refreshData !== props.refreshData ? [] : props.attributes,
            }))
            changeValue('showSubmitButton', true)
        }
        if (APO_product === 'update') {
            _isMounted && setState(prevState => ({
                ...prevState,
                attributes: props.attributes,
            }))
            changeValue('showSubmitButton', true)
        }
    }, [props.attributes, refreshData])


    const handleSelectChange = (selectedOption) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            selectedAttribute: selectedOption,
            validationMessage: ''
        }))
    }

    const handleAddNewAttribute = () => {
        let { label, value } = selectedAttribute
        let newAttribute = {
            id: value, name: label, visible: true, variation: isVariableProduct ? true : false, options: []
        }
        _isMounted && setState(prevState => ({
            ...prevState,
            attributes: label ? [...prevState.attributes, newAttribute] : prevState.attributes,
            selectedAttribute: '',
            validationMessage: label ? '' : 'Please select any attribute first'
        }))
        changeValue('showSubmitButton', label ? false : true)
    }

    const handleTermsChange = (id) => async selectedOptions => {
        // console.log(selectedOptions)
        if (selectedOptions) {
            if (selectedOptions.custom) {
                let newTerm = await createNewTerm(id, selectedOptions.label)
                if (newTerm) {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        attributes: prevState.attributes.map(attribute => {
                            if (attribute.id === id) {
                                attribute.options = [...attribute.options, { key: newTerm.bkt_id, label: newTerm.name, value: newTerm.bkt_id }]
                            }
                            return attribute
                        }),
                    }))
                    changeValue('showSubmitButton', false)
                    changeValue('allAttributeTerms', [...allAttributeTerms, newTerm])
                }
            }
            else {
                _isMounted && setState(prevState => ({
                    ...prevState,
                    attributes: prevState.attributes.map(attribute => {
                        if (attribute.id === id) {
                            attribute.options = selectedOptions
                        }
                        return attribute
                    }),
                }))
                changeValue('showSubmitButton', false)
            }
        }
    }

    const createNewTerm = async (attributeId, termName) => {
        let newTerm;
        let newTermOptions = {
            method: 'POST',
            body: JSON.stringify({ name: termName }),
            headers: { 'Content-Type': 'application/json' }
        }
        await fetch(`/createNewTerm/${attributeId}`, newTermOptions)
            .then(res => res.json())
            .then(json => {
                console.log(json)
                newTerm = json.data
                json.message && alert(json.message)
            })
            .catch(err => console.log(err))
        return newTerm
    }

    const handleRemoveAttribute = (id) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            attributes: prevState.attributes.filter(attr => attr.id !== id),
            // showActionButtons: prevState.attributes.length <= 1 ? true : false
        }))
        changeValue('showSubmitButton', false)
    }

    const handleCheckboxChange = id => (e) => {
        // console.log(id)
        // console.log(e)
        let { name, checked } = e.target
        if (name === 'attr-visible') {
            _isMounted && setState(prevState => ({
                ...prevState,
                attributes: prevState.attributes.map(attribute => {
                    if (attribute.id == id) {
                        attribute.visible = checked
                    }
                    return attribute
                }),
            }))
            changeValue('showSubmitButton', false)
            return
        }
        else if (name === 'attr-variation') {
            _isMounted && setState(prevState => ({
                ...prevState,
                attributes: prevState.attributes.map(attribute => {
                    if (attribute.id == id) {
                        attribute.variation = checked
                    }
                    return attribute
                }),
            }))
            changeValue('showSubmitButton', false)
            return
        }
    }

    const saveAttributes = () => {
        // console.log('attributes', attributes)
        let updatedAttributes = attributes.filter(attr => attr.options.length > 0)
        // console.log('updatedAttributes', updatedAttributes)
        // console.log('variations', variations)
        let updatedVariations = variations.length ?
            variations.map(Var => {
                Var.attributes = Var.attributes.length ?
                    Var.attributes.map(attr => {
                        // console.log('attr', attr)
                        return updatedAttributes.map(attr => attr.id).includes(attr.id) ?
                            attr
                            :
                            null
                    }).filter(attr => attr)
                    :
                    Var.attributes
                return Var
            })
            :
            []
        // console.log(updatedVariations)
        changeValue('attributes', updatedAttributes)    //updating attributes in parent component
        changeValue('variations', updatedVariations)    //updating variaions in parent component
        // setState(prevState => ({
        //     ...prevState,
        //     attributes: prevState.attributes.filter(attr => attr.options.length > 0)
        // }))
        // changeValue('showSubmitButton', true)
        // console.log(attributes)
        // console.log(props.attributes)


        // if (currentStep !== 6) {
        //     return null
        // }
    }
    return (
        <form ref={attributesDataForm} className={`${currentStep !== 6 ? 'd-none' : 'd-block'}`} noValidate>
            {APO_product !== 'read' &&
                <div className='p-d-flex p-flex-column mb-3 px-3'>
                    <div className='p-0 w-75 w-sm-50'>
                        <MyOwnSelect
                            options={attributeOptions}
                            optionsHeading={selectedAttribute ? 'Select attribute' : ''}
                            handleChange={handleSelectChange}
                            placeHolder='Select attribute'
                            selectedValue={selectedAttribute}
                            withOutIcon
                            isOptionDisabled={option => attributes.find(attr => attr.id === option.value)}
                            isDisabled={APO_product === 'read'}
                        />
                    </div>
                    <CustomButton
                        onClick={handleAddNewAttribute}
                        bg='primary-light'
                        color='tertiary'
                        label="Add"
                        type="button"
                        className='w-50 w-sm-25 mt-3'
                    />
                </div>
            }
            <div className='p-d-flex p-flex-column px-3'>
                <div className='p-0 w-75 w-sm-50'>
                    <AttributesAccordion
                        attributes={attributes}
                        allAttributeTerms={allAttributeTerms}
                        handleTermsChange={handleTermsChange}
                        handleRemoveAttribute={handleRemoveAttribute}
                        handleCheckboxChange={handleCheckboxChange}
                        isVariableProduct={isVariableProduct}
                    />
                </div>
                {!showSubmitButton && APO_product !== 'read' ?
                    <CustomButton
                        onClick={saveAttributes}
                        bg='primary-light'
                        color='tertiary'
                        label="Save attributes"
                        type="button"
                        className='w-50 w-sm-25 mt-3'
                    />
                    :
                    null
                }
            </div>
            <label className='p-0 validating-label mt-3'>{validationMessage}&nbsp;</label><br />
        </form >
    )
}