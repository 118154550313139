
export const getPermissions = async () => {
    try {
        const res = await fetch(`/getAllPermissionsWithData`)
        const json = await res.json()
        // console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getPermission = async (id) => {
    try {
        const res = await fetch(`/getPermissionProfile/${id}`)
        const json = await res.json()
        // console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const updatePermission = async (id, updatedPermission) => {
    try {
        const options = {
            method: 'PUT',
            body: JSON.stringify({ updatedPermission }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/updatePermission/${id}`, options)
        const json = await res.json()
        // console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}