import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Can } from "../../../configs/Ability-context";
import OrderStatus from './pages/sections/OrderStatus';
import AllOrderStatuses from './pages/AllOrderStatuses';
import NotFound from '../../misc/pages/NotFound';
import { PrivateRoute } from '../../misc/PrivateRoute';

const OrderStatuses = () => {
    return (
        <Switch>
            <PrivateRoute path='/orders/statuses/new'>
                <Can I='create' a='orderStatuses'>
                    <OrderStatus APO_status='create' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/orders/statuses/edit/:status'>
                <Can I='update' a='orderStatuses'>
                    <OrderStatus APO_status='update' />
                </Can>
            </PrivateRoute>
            <PrivateRoute path='/orders/statuses/all'>
                <Can I='read' a='orderStatuses'>
                    <AllOrderStatuses />
                </Can>
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>
    )
}


export default OrderStatuses