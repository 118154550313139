import React from "react";
import { Switch, Route } from "react-router-dom";
import { Can } from "../../configs/Ability-context";
import { NewOrder } from "./pages/NewOrder";
import { EditOrder } from "./pages/EditOrder";
import AllOrders from "./pages/AllOrders";
import AllOrderItems from "./pages/AllOrderItems";
import PrintLocalSlips from "./pages/PrintLocalSlips";
import ExportOrders from "./pages/ExportOrders";
import OrderSources from "./sources/OrderSources";
import OrderStatuses from "./statuses/OrderStatuses";
import NotFound from "../misc/pages/NotFound";
import { PrivateRoute } from "../misc/PrivateRoute";
import AllSellerOrders from "./pages/AllSellerOrders";
import AllSellerOrderItems from "./pages/AllSellerOrderItems";

const Orders = () => {
  return (
    <Switch>
      <PrivateRoute path="/orders/new">
        <Can I="create" a="orders">
          <NewOrder />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/edit/:order">
        <Can I="update" a="orders">
          <EditOrder />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/all">
        <Can I="read" a="orders">
          <AllOrders />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/seller/all">
        <Can I="read" a="sellerOrders">
          <AllSellerOrders />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/detail/seller/all">
        <Can I="read" a="sellerOrders">
          <AllSellerOrderItems />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/export/data">
        <Can I="export" a="orders">
          <ExportOrders />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/slips/local">
        <Can I="printSlips" a="orders">
          <PrintLocalSlips />
        </Can>
      </PrivateRoute>
      <PrivateRoute path="/orders/items/all">
        <Can I="read" a="orderItems">
          <AllOrderItems heading={"Order Items"} />
        </Can>
      </PrivateRoute>
      <Route path="/orders/sources" component={OrderSources} />
      <Route path="/orders/statuses" component={OrderStatuses} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Orders;
