import React from 'react';
import SignUp from './pages/SignUp';
import QuickSignUp from './pages/QuickSignUp';
import {SellerEmailVerification,RequestEmailVerification} from './pages/SellerEmailVerification';
import { Redirect } from 'react-router-dom'
import { UserContext } from '../../../contexts/UserContext'



const SecuredSignupSeller = () => {
    const { isLoggedIn } = React.useContext(UserContext)


    if (isLoggedIn) {
        return <Redirect to='/home' />
    }
    else {
        return <SignUp />
    }
}

const QuickSignUpSeller = () => {
    const { isLoggedIn } = React.useContext(UserContext)


    if (isLoggedIn) {
        return <Redirect to='/home' />
    }
    else {
        return <QuickSignUp />
    }
}
const verifyEmail = () => {
    
    return <SellerEmailVerification />
}
const RequestEmailVerificationComponent = () => {
    
    return <RequestEmailVerification />
}


export {
    SecuredSignupSeller,
    QuickSignUpSeller,
    verifyEmail,
    RequestEmailVerificationComponent
}
