import React from 'react'
import { handleNumberInputKeyPress, calculatePrice, minusPercent, getPercent } from '../../../../misc/components/Helpers'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { Input, CustomInput } from '../../../../misc/components/Inputs'
import { CustomDatePicker } from '../../../../misc/components/CustomDatePicker'
import getOptions from '../../../../misc/components/Options'

export const GeneralDataStep = (props) => {
    let {
        ability,
        APO_product,
        changeValue,
        currentStep,
        handleCheckboxChange,
        handleDateChange,
        handleInput,
        handleSelectChange,
        isSellerLoggedIn,
        isShopManagerLoggedIn,
        isVariableProduct,
        setState,

        allSellers,
        costPrice,
        generalDataForm,
        isCostProvided,
        name,
        priceRule,
        productStatusOptions,
        productTypeOptions,
        regularPrice,
        remarks,
        selectedSeller,
        selectedStatus,
        selectedType,
        saleFrom,
        salePrice,
        saleTo,
        scheduleSale,
        sourceURL
    } = props

    const calculatePrices = async (e) => {
        changeValue('isLoading', true)
        try {
            switch (e.target.name) {
                case 'costPrice':
                    let newRegularPrice = await calculatePrice(priceRule, e.target.value, 'cost_price')
                    let newSalePrice = (salePrice && salePrice !== '') ? minusPercent(newRegularPrice, (100 - getPercent(regularPrice, salePrice))) : ''
                    changeValue('regularPrice', newRegularPrice)
                    APO_product === 'update' && changeValue('salePrice', newSalePrice)
                    break;
                case 'regularPrice':
                    if (!isCostProvided) {
                        let newCostPrice = await calculatePrice(priceRule, e.target.value, 'regular_price')
                        setState(prevState => ({
                            ...prevState,
                            costPrice: newCostPrice,
                            salePrice: ''
                        }))
                    }
                case 'salePrice':
                    if (!isCostProvided && e.target.value) {
                        let newCostPrice = await calculatePrice(priceRule, e.target.value, 'sale_price')
                        changeValue('costPrice', newCostPrice)
                    }
                    break;
            }
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }
    let sellerOptions = getOptions('sellers', allSellers)

    //form-fields
    const nameInput = <Input
        label='Product name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        containerClass='mb-3'
        required
        disabled={APO_product === 'read'}
        invalidMessage={`Please enter product name`}
    />
    const productStatusInput = <div className='mb-3 p-0'>
        <MyOwnSelect
            options={productStatusOptions}
            optionsHeading={selectedStatus ? 'Select product status' : ''}
            handleChange={handleSelectChange('selectedStatus')}
            placeHolder='Select product status'
            selectedValue={selectedStatus}
            className='border-radius-0 w-100'
            withOutIcon
            required
            isDisabled={APO_product === 'read'}
        />
    </div>
    const productTypeInput = <div className='mb-3 p-0'>
        <MyOwnSelect
            options={productTypeOptions.filter(option => option.value !== 'grouped')}
            optionsHeading={selectedType ? 'Select product type' : ''}
            handleChange={handleSelectChange('selectedType')}
            placeHolder='Select product type'
            selectedValue={selectedType}
            className='border-radius-0 w-100'
            withOutIcon
            required
            isDisabled={APO_product === 'update' || APO_product === 'read'}
        // isOptionDisabled={(option) => option.value === 'grouped'}
        />
    </div>
    const sellerInput = <div className='mb-3 p-0'>
        {/* <small className="d-block d-sm-none small mb-2">Seller</small> */}
        {/* <h6 className="d-none d-sm-block small mb-2">Seller</h6> */}
        <MyOwnSelect
            options={sellerOptions}
            optionsHeading={selectedSeller ? 'Select a seller' : ''}
            handleChange={handleSelectChange('selectedSeller')}
            placeHolder='Select a seller'
            selectedValue={selectedSeller}
            className='border-radius-0'
            withOutIcon
            required
            isDisabled={APO_product === 'read'}
        />
    </div>
    const isCostProvidedInput = <div className='p-d-flex p-jc-between p-flex-wrap'>
        <CustomInput
            type='checkbox'
            id={`isCostProvided`}
            handleInput={(e) => {
                setState(prevState => ({
                    ...prevState,
                    isCostProvided: e.checked,
                    costPrice: '',
                    regularPrice: '',
                    salePrice: ''
                }))
            }}
            label='Cost price ?'
            labelClass='mt-1 mb-0 small'
            checked={isCostProvided}
            // tooltip="Regular price will be entered later by bucket.pk"
            // tooltipOptions={{ position: 'top' }}
            noLabel
            disabled={(APO_product === 'update' && remarks !== 'You are new seller to this product.Please review') || APO_product === 'read'}
        />
        <CustomInput
            type='checkbox'
            id={`isCostProvided`}
            handleInput={(e) => {
                setState(prevState => ({
                    ...prevState,
                    isCostProvided: !e.checked,
                    costPrice: '',
                    regularPrice: '',
                    salePrice: ''
                }))
            }}
            label='Regular price ?'
            labelClass='mt-1 mb-0 small'
            checked={!isCostProvided}
            // tooltip="Cost price will be calculated automatically"
            // tooltipOptions={{ position: 'top' }}
            noLabel
            disabled={(APO_product === 'update' && remarks !== 'You are new seller to this product.Please review') || APO_product === 'read'}
        />
    </div>
    // (AED)
    const regularPriceInput = <Input
        type="number"
        label='Regular price'
        name={`regularPrice`}
        value={regularPrice}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        // required={!isCostProvided}
        required={true}
        // disabled={(
        //     APO_product === 'create' ?
        //         isCostProvided
        //         :
        //         isCostProvided ?
        //             (isSellerLoggedIn || isShopManagerLoggedIn)
        //             :
        //             (!isSellerLoggedIn && !isShopManagerLoggedIn)
        // ) || APO_product === 'read'
        // }
        invalidMessage={`Please enter regular price`}
    />
    const costPriceInput = <Input
        type="number"
        label='Cost price (Rs)'
        name={`costPrice`}
        value={costPrice || ''}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        required={isCostProvided}
        disabled={!isCostProvided || APO_product === 'read'}
        // disabled={APO_product === 'create' ? !isCostProvided : isCostProvided ? (!isSellerLoggedIn && !isShopManagerLoggedIn) : (isSellerLoggedIn || isShopManagerLoggedIn)}
        invalidMessage={`Please enter cost price`}
    />
    const salePriceInput = <Input
        type="number"
        label='Sale price'
        name={`salePrice`}
        value={salePrice}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        max={regularPrice - 1}
        required={scheduleSale}
        invalidMessage={`Invalid sale price`}
        disabled={(
            APO_product === 'create' ?
                isCostProvided
                :
                isCostProvided ?
                    (isSellerLoggedIn || isShopManagerLoggedIn)
                    :
                    (!isSellerLoggedIn && !isShopManagerLoggedIn)
        ) || APO_product === 'read'
        }
    />
    const scheduleSaleInput = <CustomInput
        type='checkbox'
        id={`scheduleSale`}
        handleInput={(e) => {
            changeValue('scheduleSale', e.checked);
            handleCheckboxChange({ target: { name: 'scheduleSale', checked: e.checked } })
        }}
        label='Schedule sale ?'
        labelClass='mt-1 mb-0 small'
        checked={scheduleSale}
        tooltip="Schedule your product's sale"
        tooltipOptions={{ position: 'top' }}
        noLabel
        disabled={APO_product === 'read' || !regularPrice}
    />
    const saleFromInput = <div className='mb-3 mx-0 p-0'>
        <CustomDatePicker
            selectedValue={saleFrom}
            placeholder='Sale from'
            handleChange={handleDateChange('saleFrom')}
            className='w-100 border-none border-radius-0'
            isRequired={scheduleSale}
            isClearable
            showTime
            isDisabled={isCostProvided || APO_product === 'read'}
        // minDate={new Date()}
        />
    </div>
    const saleToInput = <div className='mb-3 mx-0 p-0'>
        <CustomDatePicker
            selectedValue={saleTo}
            placeholder='Sale to'
            handleChange={handleDateChange('saleTo')}
            className='w-100 border-none border-radius-0'
            isRequired={scheduleSale}
            isClearable
            showTime
            isDisabled={isCostProvided || APO_product === 'read'}
        // minDate={new Date()}
        />
    </div>

    // if (currentStep !== 1) {
    //     return null
    // }
    return (
        <form ref={generalDataForm} className={`m-0 p-0 ${currentStep !== 1 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6'>
                    {nameInput}
                    {ability.can('publish', 'products') && productStatusInput}
                    {APO_product === 'create' ?
                        <>
                            {productTypeInput}
                            {/* {sellerInput} */}
                        </>
                        :
                        ability.can('updateSellers', 'products') && sellerInput
                    }
                    {/* {!isVariableProduct && (isSellerLoggedIn || isShopManagerLoggedIn || selectedSeller) &&
                        isCostProvidedInput
                    } */}
                </div>
                <div className='m-0 col-sm-6'>
                    {!isVariableProduct && (isSellerLoggedIn || isShopManagerLoggedIn || selectedSeller) &&
                        <>
                            {regularPriceInput}
                            {/* {costPriceInput} */}
                            {/* && !isCostProvided */}
                            {(APO_product === 'create' ? regularPrice > 0 : APO_product === 'update')  &&
                                <>
                                    {salePriceInput}
                                    {scheduleSaleInput}
                                    {scheduleSale &&
                                        <>
                                            {saleFromInput}
                                            {saleToInput}
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </form>
    )
}
