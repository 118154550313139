import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllProductCategories from './pages/AllProductCategories';
import NotFound from '../../misc/pages/NotFound'
import { PrivateRoute } from '../../misc/PrivateRoute'


const ProductCategories = () => {

    return (
        <Switch>
            <PrivateRoute path="/products/categories/all">
                <AllProductCategories />
            </PrivateRoute >
            <Route component={NotFound} />
        </Switch>

    )
}


export default ProductCategories