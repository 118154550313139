import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact';
import { Can, AbilityContext } from '../../../configs/Ability-context'
import MyOwnSelect from '../../misc/components/MyOwnSelect'
import getOptions from '../../misc/components/Options'
import { handleNumberInputKeyPress } from '../../misc/components/Helpers'
import { CustomInput, Input, SubmitButton } from '../../misc/components/Inputs'
import CustomSelect from '../../misc/components/CustomSelect'
import LoaderModal from '../../misc/components/LoaderModal'
import { getUser, updateUser, getCustomUsers } from '../../../services/users/user-service';
import { getRoles } from '../../../services/users/role-service';
import { getPriceRules } from '../../../services/users/price-rule-service';


const INITIAL_STATE = {
    allRoles: [],
    allPriceRules: [],
    allSellers: [],
    user_id: '',
    name: '',
    email: '',
    cell: '',
    address: '',
    city: '',
    state: '',
    selectedCountry: '',
    postcode: '',
    cnic: '',
    username: '',
    password: '',
    cnfrmPassword: '',
    selectedRole: '',
    selectedPriceRule: '',
    validationMessage: '',
    isLoading: false,
    isPasswordChanging: false,
    isEditingUser: false,
    isEditingProfile: false,
    isEditingClient: false,
    isClientSelected: false,
    isSellerManagerSelected: false,
    isShopManagerSelected: false,
    hasError: false
}
let _isMounted

const EditUser = (props) => {
    const [
        { allRoles, allPriceRules, allSellers, user_id,
            name, email, cell, address, city, state, selectedCountry, postcode, cnic,
            username, password, cnfrmPassword, selectedRole, selectedPriceRule, selectedSeller,
            isPasswordChanging, isEditingUser, isEditingProfile, isEditingClient, isClientSelected, isSellerManagerSelected, isShopManagerSelected,
            validationMessage, isLoading, hasError
        },
        setState] = useState({ ...INITIAL_STATE })

    const ability = useContext(AbilityContext)
    let editUserForm = useRef(null)
    let cellField = useRef(null)
    let usernameField = useRef(null)
    const countryOptions = getOptions('countries')

    let history = useHistory()
    let { user } = useParams()
    const isRouteMatched = useRouteMatch("/users/edit/:user");

    useEffect(() => {
        _isMounted = true
        const fetchUserData = async () => {
            changeValue('isLoading', true)
            try {
                user = user && user != 1 && ability.can('update', 'users') && isRouteMatched ? user : 0
                const { success, data } = await getUser(user)
                let eUser = data
                if (success && eUser) {
                    let { name, email, cell, address, city, state, country, postcode, cnic, username, role, price_rule, parent } = eUser
                    let isClientSelected = role?.name.toLowerCase() === 'client',
                        isSellerManagerSelected = role?.name.toLowerCase() === 'seller_manager',
                        isShopManagerSelected = role?.name.toLowerCase() === 'shop_manager'
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        user_id: eUser.id,

                        name, email, cell, address, city, state, postcode, cnic, username,
                        selectedRole: role ? { key: role.id + 1, label: role.name, value: role.id } : '',
                        selectedCountry: countryOptions.find(option => option.value === country),
                        selectedPriceRule: price_rule ? { key: price_rule.id, label: price_rule.name, value: price_rule.id } : '',
                        selectedSeller: parent ? { key: parent.id, label: parent.name, value: parent.id } : '',

                        isEditingUser: user === 0 ? false : true,
                        isEditingProfile: user === 0 ? true : false,
                        isEditingClient: isClientSelected,
                        isClientSelected: isClientSelected,
                        isSellerManagerSelected: isSellerManagerSelected,
                        isShopManagerSelected: isShopManagerSelected,
                    }));
                    isSellerManagerSelected && fetchPriceRules()
                    isShopManagerSelected && fetchSellers()
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchRoles = async () => {
            const { success, data } = await getRoles()
            changeValue('allRoles', data)
        }
        fetchUserData();
        fetchRoles();
        return () => {
            _isMounted = false
        }
    }, [])

    const fetchPriceRules = async () => {
        changeValue('isLoading', true)
        const { data } = await getPriceRules()
        _isMounted && setState(prevState => ({ ...prevState, allPriceRules: data, isLoading: false }))
    }

    const fetchSellers = async () => {
        changeValue('isLoading', true)
        const { data } = await getCustomUsers('seller')
        _isMounted && setState(prevState => ({ ...prevState, allSellers: data, isLoading: false }))
    }

    let roleOptions = allRoles.length &&
        getOptions('roles', allRoles).filter(option => option.label.toLowerCase() !== 'super_admin' && option.label.toLowerCase() !== 'seller')

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    // const handleNumberInputKeyPress = (e) => {
    //     // let { name, value } = e.target
    //     let array = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
    //     if (array.includes(e.which)) { return }
    //     else { e.preventDefault() }
    // }

    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value })); }

    const handleSelectChange = name => selectedOption => {
        if (name === 'selectedRole') {
            let isClientSelected = selectedOption.label.toLowerCase() === 'client'
            let isSellerManagerSelected = selectedOption.label.toLowerCase() === 'seller_manager'
            let isShopManagerSelected = selectedOption.label.toLowerCase() === 'shop_manager'
            _isMounted && setState(prevState => ({
                ...prevState,
                selectedRole: selectedOption,
                isClientSelected: isClientSelected,
                isSellerManagerSelected: isSellerManagerSelected,
                isShopManagerSelected: isShopManagerSelected,
                validationMessage: ''
            }))
            isSellerManagerSelected && !allPriceRules?.length && fetchPriceRules()
            isShopManagerSelected && !allSellers?.length && fetchSellers()
            return
        }
        changeValue(name, selectedOption)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = editUserForm.current

        if (!selectedRole) {
            _isMounted && setState(prevState => ({ ...prevState, selectedRole: null, validationMessage: 'Please select a user role' }))
            return
        }
        else if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        else if (!selectedCountry) {
            _isMounted && setState(prevState => ({ ...prevState, selectedCountry: null, validationMessage: 'Please select country' }))
            return
        }
        else if (isSellerManagerSelected && (!selectedPriceRule)) {
            _isMounted && setState(prevState => ({ ...prevState, selectedPriceRule: null, validationMessage: 'Please select a price-rule' }))
            return
        }
        else if (isShopManagerSelected && (!selectedSeller)) {
            _isMounted && setState(prevState => ({ ...prevState, selectedSeller: null, validationMessage: 'Please select a seller' }))
            return
        }
        else if (!isClientSelected && isPasswordChanging && (password !== cnfrmPassword)) {
            changeValue('validationMessage', `Passwords don't match`)
            return
        }
        else {
            form.classList.remove('was-validated')
            let updatedUser = {
                name: name, email: email, cell: cell, address: address, city: city, state: state,
                country: selectedCountry.value, postcode: postcode, cnic: cnic,
                username: isClientSelected ? null : username,
                password: (isEditingClient && !isClientSelected) || (!isEditingClient && isPasswordChanging && !isClientSelected) ? password : null,
                role_id: selectedRole.value,
                price_rule_id: isSellerManagerSelected ? selectedPriceRule.value : null,
                parent_id: isShopManagerSelected ? selectedSeller.value : null
            }
            // console.log(updatedUser)
            try {
                const { success, message } = await updateUser(user_id, updatedUser, isClientSelected ? 'client' : 'user')
                changeValue('validationMessage', message)
                success ? setTimeout(() => { history.push(isEditingUser ? '/users/all' : (isEditingProfile && '/dashboard')) }, 1500)
                    :
                    isClientSelected ? cellField.current.focus() : usernameField.current.focus()
            }
            catch (error) {
                console.log(error)
            }

        }
    }

    const selectRoleInput = <div className='p-mb-3 p-p-0'>
        {/* <CustomSelect
            label='Select role'
            options={roleOptions}
            // optionLabel={selectedRole ? 'Select role' : ''}
            handleChange={(e) => {
                console.log(e);
                // handleSelectChange('selectedRole')
            }}
            value={selectedRole}
            isRequired
            isFormField
            // isOptionDisabled={(option) => option.label.toLowerCase() === 'super_admin' || option.label.toLowerCase() === 'seller'}
        /> */}
        <MyOwnSelect
            options={roleOptions}
            optionsHeading={selectedRole ? 'Select role' : ''}
            handleChange={handleSelectChange('selectedRole')}
            placeHolder='Select role'
            selectedValue={selectedRole}
            className='border-radius-0'
            withOutIcon
            required
            isOptionDisabled={(option) => option.label.toLowerCase() === 'super_admin' || option.label.toLowerCase() === 'seller'}
        />
    </div>
    const nameInput = <Input
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter name`}
    />
    const emailInput = <Input
        type="email"
        label='Email'
        name={`email`}
        value={email}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter a valid email`}
    />
    const cellInput = <Input
        label='Cell'
        name={`cell`}
        value={cell}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        hint='e.g. 923331234567'
        pattern="^(92)[0-9]{10}$"
        maxLength="12"
        setRef={(ref) => cellField.current = ref}
        required
        invalidMessage={`Please follow the pattern '923331234567'`}
    />
    const cnicInput = <Input
        label='CNIC'
        name={`cnic`}
        value={cnic || ''}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        hint='e.g. 0000000000000'
        pattern="^[0-9]{13}"
        maxLength='13'
        required
        invalidMessage={`Please follow the pattern '0000000000000'`}
    />
    const addressInput = <Input
        label='Address'
        name={`address`}
        value={address}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter address`}
    />
    const cityInput = <Input
        label='City'
        name={`city`}
        value={city || ''}
        required
        handleInput={handleInput}
    // invalidMessage={`Please enter city`}
    />
    const stateInput = <Input
        label='State/Province'
        name={`state`}
        value={state || ''}
        required
        handleInput={handleInput}
    // invalidMessage={`Please enter state/province`}
    />
    const selectCountryInput = <div className='p-mb-3 p-p-0'>
        <MyOwnSelect
            options={getOptions('countries')}
            optionsHeading={selectedCountry ? 'Select country' : ''}
            handleChange={handleSelectChange('selectedCountry')}
            placeHolder='Select country'
            selectedValue={selectedCountry}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const postcodeInput = <Input
        label='Postcode'
        name={`postcode`}
        value={postcode || ''}
        handleInput={handleInput}
    // invalidMessage={`Please enter state/province`}
    />
    const selectPriceRuleInput = <div className='mb-4 p-0'>
        <MyOwnSelect
            options={getOptions('priceRules', allPriceRules)}
            optionsHeading={selectedPriceRule ? 'Select price-rule' : ''}
            handleChange={handleSelectChange('selectedPriceRule')}
            placeHolder='Select price-rule'
            selectedValue={selectedPriceRule}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const selectSellerInput = <div className='mb-4 p-0'>
        <MyOwnSelect
            options={getOptions('sellers', allSellers)}
            optionsHeading={selectedSeller ? 'Select seller' : ''}
            handleChange={handleSelectChange('selectedSeller')}
            placeHolder='Select seller'
            selectedValue={selectedSeller}
            className='border-radius-0'
            withOutIcon
            required
        />
    </div>
    const usernameInput = <Input
        label='Username'
        name={`username`}
        value={username || ''}
        handleInput={handleInput}
        setRef={(ref) => usernameField.current = ref}
        required
        invalidMessage={`Please enter a username`}
    />
    const passwordInput = <Input
        type='password'
        label='Password'
        name={`password`}
        value={password}
        handleInput={handleInput}
        hint={(isEditingClient) || (isPasswordChanging) ? 'Enter new password' : '*****'}
        pattern="^(?=.*\d)(?!.*[\s])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        maxLength="20"
        containerClass={'input-group'}
        required
        disabled={((isEditingUser && !isEditingClient) || isEditingProfile) ? isPasswordChanging ? false : true : false}
        invalidMessage={`Please enter a password between 8-20 characters with at least 1 uppercase, 1 lowercase and 1 number.`}
    >
        {!(isEditingClient && !isClientSelected) &&
            <div className="input-group-append">
                <span className="input-group-text border-radius-0">
                    {/* <MDBTooltip domElement tag="span" placement="right" className='bg-Primary tooltip-width'> */}
                    <i
                        className={`${isPasswordChanging ?
                            'fa fa-times color-secondary' :
                            'fa fa-pencil-alt color-primary-light fa-lg'} cursor-help`}
                        onClick={() => changeValue('isPasswordChanging', !isPasswordChanging)}
                    />
                    <i>
                        {isPasswordChanging ? 'Click here if you don`t want to change' : 'Click here to change password'}
                    </i>
                    {/* </MDBTooltip> */}
                </span>
            </div>
        }
    </Input >

    const cnfrmPasswordInput = < Input
        type='password'
        label='Confirm password'
        name={`cnfrmPassword`}
        value={cnfrmPassword}
        handleInput={handleInput}
        hint='Confirm password'
        pattern={`^${password}$`}
        required
        disabled={!password}
        invalidMessage={`Passwords don't match`}
    />

    const commonFields = [nameInput, emailInput, cellInput, addressInput, cityInput, stateInput, selectCountryInput, postcodeInput]
    const profileFields = (isPasswordChanging || (isEditingClient && !isClientSelected)) ?
        [cnicInput, usernameInput, passwordInput, cnfrmPasswordInput]
        :
        [cnicInput, usernameInput, passwordInput]
    const userFields = [selectRoleInput]
    const sellerManagerOnlyFields = [selectPriceRuleInput]
    const shopManagerOnlyFields = [selectSellerInput]
    const fieldsToDisplay = isEditingProfile ?
        isClientSelected ?
            [...commonFields]
            :
            isSellerManagerSelected ?
                [...commonFields, ...profileFields]
                :
                isShopManagerSelected ?
                    [...commonFields, ...shopManagerOnlyFields, ...profileFields]
                    :
                    [...commonFields, ...profileFields]
        :
        isClientSelected ?
            [...userFields, ...commonFields]
            :
            isSellerManagerSelected ?
                [...userFields, ...commonFields, ...sellerManagerOnlyFields, ...profileFields]
                :
                isShopManagerSelected ?
                    [...userFields, ...commonFields, ...shopManagerOnlyFields, ...profileFields]
                    :
                    [...userFields, ...commonFields, ...profileFields]


    if (hasError) throw new Error
    else return (
        <Can I='update' a={isEditingProfile ? 'userProfile' : 'users'}>
            <div className='screen-center h-sm-100vh mt-5 m-sm-0 py-5 py-sm-0'>
                <form ref={editUserForm} className='m-0 p-0 w-100 w-md-75 w-lg-50 text-center color-secondary mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='font-weight-bold mb-3 mb-sm-4'>{isEditingProfile ? 'Update your profile' : 'Edit user'}</h5>
                    <MDBRow center className='m-0 p-0'>
                        {fieldsToDisplay.map((field, index) => {
                            return <MDBCol key={index + 1} middle sm='6' className='m-0'>{field}</MDBCol>
                        })}
                        <MDBCol top sm='6' className='m-0 text-right'>
                            <SubmitButton
                                label="Update"
                                className="w-100"
                                color='secondary'
                                type="submit"
                            />
                        </MDBCol>
                        <MDBCol size='12' className='m-0 text-center'>
                            <label className='p-0 validating-label'>{validationMessage}&nbsp;</label>
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
            <LoaderModal modalShow={isLoading} />
        </Can >
    )
}


export default EditUser