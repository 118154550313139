import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { animateSyncIcon } from '../../../misc/components/Helpers'
import { CustomListing } from '../../../misc/components/CustomListing'
import { getAttributesWithData, syncAttributes } from '../../../../services/products/attribute-service'

let _isMounted
const AllAttributes = () => {
    let dt = useRef(null)
    let toast = useRef(null)

    const [{
        allAttributes, isLoading, refreshData,
    }, setState] = useState({
        allAttributes: [], isLoading: false, refreshData: false
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getAttributesWithData()
                changeValue('allAttributes', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleSync = async (e) => {
        animateSyncIcon('start', e)
        // fetching new records and syncing
        try {
            const { success, message } = await syncAttributes()
            if (success)
                changeValue('refreshData', !refreshData)    //to sync new data came from store
            else {
                animateSyncIcon('error', e)
                toast.current.show({ severity: 'error', summary: message.summary, detail: message.detail })
            }
        }
        catch (error) {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: 'Something went wrong.' })
            animateSyncIcon('error', e)
        }
        animateSyncIcon('stop', e)
    }

    const filterTerms = (value, filter) => {
        return value.some(term => term.name.toLowerCase().includes(filter))
    }

    const attrTemplate = (rowData) => {
        return <b>{rowData.name}</b>
    }

    const termsTemplate = (rowData) => {
        return <div className='py-2 m-0 attribute_terms'>
            {rowData.attribute_terms?.map(term => {
                return (
                    <div key={term.id} className={`small px-2 m-1 bg-grey term-chip d-inline-block`} style={{ borderRadius: '10px' }}>{term.name}</div>
                )
            })}
        </div>
    }


    let columns = [
        {
            field: "name",
            header: "Name",
            body: attrTemplate,
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by name",
            filterMatchMode: "contains",
        },
        {
            field: "attribute_terms",
            header: "Terms",
            body: termsTemplate,
            filter: true,
            filterPlaceholder: "Search by term",
            filterMatchMode: "custom",
            selectedByDefault: true,
            filterFunction: filterTerms,
            headerStyle: { width: allAttributes.length ? '1360px' : '136px' }
        }
    ]

    return (
        <Can I='read' a='attributes'>
            <CustomListing
                allRecords={allAttributes}
                heading={'Attributes'}
                columns={columns}
                permissionSubject={'attributes'}
                isLoading={isLoading}
                handleSync={handleSync}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                tableRef={dt}
                toastRef={toast}
                globalSearchDisabled={true}
            />
        </Can>
    );
}


export default AllAttributes