import { useState, useEffect, useRef } from 'react';
import { Can } from '../../../configs/Ability-context'
import { CustomButton } from '../../misc/components/Inputs'
import { getJsonData } from '../../misc/components/Helpers'
import getOptions from '../../misc/components/Options'
import LoaderModal from '../../misc/components/LoaderModal'
// import { Dialog } from 'primereact/dialog'
import { getPaymentMethods } from '../../../services/payments/method-service'
import { getOrderSources } from '../../../services/orders/source-service'
import { getOrderStatuses } from '../../../services/orders/status-service'
import { getProducts } from '../../../services/products/product-service'
import { createOrder } from '../../../services/orders/order-service'
import { OrderForm } from './sections/OrderForm'
import OrderConfirmationPopup from './sections/sub-sections/OrderConfirmationPopup'


const orderTypeOptions = getOptions('orderTypes'),
    courierOptions = getOptions('couriers')
let _isMounted

const INITIAL_STATE = {
    //general
    selectedOrderType: orderTypeOptions[0],
    selectedOrderSource: '',
    selectedOrderStatus: '',
    customerNote: '',
    orderNote: '',
    orderDiscount: 0,
    discountDetail: '',
    //customer
    cName: '',
    cEmail: '',
    cCell: '',
    cAddress: '',
    cCity: '',
    cState: '',
    cCountry: '',
    cPostcode: '',
    //payments
    selectedPaymentMethod: '',
    trId: '',
    trDate: null,
    trAmount: 0,
    trStatus: '',
    //refunds
    //query
    queryProduct: '',
    queryStatus: '',
    queryDetail: '',
    //shipping
    shippingMethod: '',
    selectedCourier: '',
    trackingId: '',
    shippingSameAsBilling: true,
    shippingDate: null,
    dAmount: 0,
    dName: '',
    dCell: '',
    dAddress: '',
    dCity: '',
    dState: '',
    dCountry: '',
    dPostcode: '',
    //products
    orderItems: [],
    itemDiscount: 0,
    itemDiscountDetail: '',
    rate: 1,
    qty: 1,
    remarks: '',
    selectedProduct: '',
    selectedVariation: '',
    selectedItemStatus: '',
    customerStatus: '',
    extraAdded: false,
}

export const NewOrder = () => {

    let generalDataForm = useRef(null),
        customerDataForm = useRef(null),
        queryDataForm = useRef(null),
        productsDataForm = useRef(null),
        shippingDataForm = useRef(null),
        paymentsDataForm = useRef(null),
        totalField = useRef(null)
    // const { allOrderStatuses } = props

    const [currentStep, setCurrentStep] = useState(1)
    const [isLoading, setIsLoading] = useState(false);

    const [
        {
            //general
            bkt_order_id, date, selectedOrderType, selectedOrderSource, selectedOrderStatus, defaultStatus,
            customerNote, orderNote, orderDiscount, discountDetail,
            //customer
            cName, cEmail, cCell, cAddress, cCity, cState, cCountry, cPostcode, customer_id,
            //payments
            selectedPaymentMethod, trId, trDate, trAmount, trStatus,
            //refunds
            //query
            queryProduct, queryStatus, queryDetail,
            shippingMethod, selectedCourier, trackingId, shippingSameAsBilling,
            shippingDate, dAmount, dName, dCell, dAddress, dCity, dState, dCountry, dPostcode,
            //products
            orderItems,
            itemDiscount, itemDiscountDetail, rate, qty, remarks, selectedProduct,
            selectedVariation, selectedItemStatus, extraAdded, customerStatus,
            //misc
            orderSourceOptions, allOrderStatuses, orderStatusOptions, itemStatusOptions, orderProductOptions, paymentMethodOptions,
            validationMessage, popupShow,
        },
        setState] = useState({
            ...INITIAL_STATE, bkt_order_id: '', date: new Date(), customer_id: null,
            validationMessage: '', popupShow: false,
            orderSourceOptions: [], allOrderStatuses: [], orderStatusOptions: [], itemStatusOptions: [],
            orderProductOptions: [], paymentMethodOptions: []
        })

    let isMakingAQuery = selectedOrderType.label === 'Query' ? true : false
    let cityOptions = getOptions('cities')
    let countryOptions = getOptions('countries')
    let cStateOptions = getOptions('states', cCountry.value)
    let dStateOptions = getOptions('states', dCountry.value)

    useEffect(() => {
        _isMounted = true
        const fetchMeta1 = async () => {
            try {
                const { data: allPaymentMethods } = await getPaymentMethods()
                const { data: allOrderSources } = await getOrderSources()
                const { data: allOrderStatuses } = await getOrderStatuses()

                let paymentMethodOptions = getOptions('paymentMethods', allPaymentMethods)
                let orderSourceOptions = getOptions('orderSources', allOrderSources)
                let orderStatusOptions = getOptions('orderStatuses', allOrderStatuses.filter(s => s.usable_at_order))
                let itemStatusOptions = getOptions('itemStatuses', allOrderStatuses.filter(s => s.usable_at_item))
                let DefaultStatus = allOrderStatuses.find(s => s.is_default)

                DefaultStatus = {
                    key: DefaultStatus?.id,
                    label: `${DefaultStatus?.title} (default)`,
                    value: DefaultStatus?.slug,
                    entity: DefaultStatus,
                }
                fetchMeta2()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    selectedItemStatus: DefaultStatus,
                    selectedOrderStatus: DefaultStatus,
                    defaultStatus: DefaultStatus,
                    paymentMethodOptions: paymentMethodOptions,
                    orderSourceOptions: orderSourceOptions,
                    allOrderStatuses: allOrderStatuses,
                    orderStatusOptions: orderStatusOptions,
                    itemStatusOptions: itemStatusOptions,
                }))
            }
            catch (err) {
                console.log(err)
            }
        }
        const fetchMeta2 = async () => {
            try {
                const { data: allProducts } = await getProducts()

                let orderProductOptions = getOptions('orderProducts', await getJsonData(
                    allProducts,
                    [
                        'attributes',
                        ['variations', ['attributes', 'dimensions', 'images']]
                    ]
                ))
                _isMounted && setState(prevState => ({
                    ...prevState,
                    orderProductOptions: orderProductOptions,
                }))
            }
            catch (err) {
                console.log(err)
            }
        }
        // setCurrentStep(1)
        fetchMeta1()
        return () => {
            _isMounted = false
        }
    }, [])
    const changeValue = (name, value) => {
        name === 'validationMessage'
            ? _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
            : _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: '' }))
    }
    const handleSubmitOrder = async () => {
        if (!selectedOrderSource) {
            _isMounted && setState(prevState => ({
                ...prevState,
                selectedOrderSource: null,
                validationMessage: 'Please select order source'
            }))
            setCurrentStep(1)
            return
        }
        let forms = isMakingAQuery ?
            [generalDataForm, customerDataForm, queryDataForm]
            :
            [generalDataForm, customerDataForm, shippingDataForm, paymentsDataForm]

        let isValidated
        for (let i = 0; i < forms.length; i++) {
            console.log(i)
            const form = forms[i];
            if (!form.current.checkValidity()) {
                setCurrentStep(i < 3 ? i + 1 : i + 2)
                setTimeout(() => {
                    form.current.classList.add('was-validated')
                }, 250);
                isValidated = false
                break;
            }
            isValidated = true
        }
        if (isValidated) {
            if ((Number(totalField.current.value) < 0)) {
                changeValue('validationMessage', 'Invalid COD.')
                return
            }
            if (!cCountry) {
                _isMounted && setState(prevState => ({
                    ...prevState,
                    cCountry: null,
                    validationMessage: 'Please select customer country'
                }))
                setCurrentStep(2)
                return
            }
            if (!isMakingAQuery && !orderItems.length) {
                changeValue('validationMessage', 'Please add some items in your order')
                setCurrentStep(3)
                return
            }
            if (!isMakingAQuery && !selectedPaymentMethod) {
                _isMounted && setState(prevState => ({
                    ...prevState,
                    selectedPaymentMethod: null,
                    validationMessage: 'Please select payment method'
                }))
                setCurrentStep(5)
                return
            }
            saveOrder()
        }
        else return
    }

    const saveOrder = async () => {
        let customerDetails = {
            name: cName,
            email: cEmail,
            cell: cCell,
            address: cAddress,
            city: cCity.value,
            country: cCountry,
            state: cState,
            postcode: cPostcode
        }
        let generalDetails = {
            bkt_order_id: `${selectedOrderSource.title}-${bkt_order_id}`,
            date: date,
            type: selectedOrderType.value,//sale , return, query or replacement
            customer_note: customerNote,
            order_note: orderNote,
            source_id: selectedOrderSource?.value,
            total: isMakingAQuery ? null : totalField.current.value,
            order_discount: isMakingAQuery ? null : orderDiscount,
            discount_detail: isMakingAQuery ? '' : discountDetail,
            status: selectedOrderStatus?.value
        }
        //shipping
        let shippingDetails = {
            shipping_method: shippingMethod,
            courier: selectedCourier?.value,
            tracking_id: trackingId,
            shipping_date: shippingDate,
            shipping_amount: dAmount,
            shipping_name: shippingSameAsBilling ? cName : dName,
            shipping_cell: shippingSameAsBilling ? cCell : dCell,
            shipping_address: shippingSameAsBilling ? cAddress : dAddress,
            shipping_city: shippingSameAsBilling ? cCity.value : dCity.value,
            shipping_country: shippingSameAsBilling ? cCountry.value : dCountry.value,
            shipping_state: shippingSameAsBilling ? cState.value : dState.value,
            shipping_postcode: shippingSameAsBilling ? cPostcode : dPostcode,
        }
        //payment
        let paymentDetails = {
            payment_method_id: selectedPaymentMethod?.value,
            tr_id: trId,
            tr_date: trDate,
            tr_amount: trAmount,
            tr_status: trStatus,
        }
        //query
        let queryDetails = {
            query_product: queryProduct,
            query_status: queryStatus,
            query_detail: queryDetail
        }
        // refunds: {}, //reason, amount, payment-method-details, date, tr.-id 

        let orderDetails = isMakingAQuery ?
            {
                ...generalDetails,
                ...queryDetails
            }
            :
            {
                ...generalDetails,
                ...shippingDetails,
                ...paymentDetails
            }

        console.log(orderItems)

        let order_items = isMakingAQuery ? [] : orderItems.map(({ selectedProduct, selectedVariation, item_status, ...item }) => item)
        console.log(customerDetails, orderDetails, order_items, orderItems, customer_id)
        setIsLoading(true)
        try {
            const { success, data, message } = await createOrder(customerDetails, orderDetails, order_items, customer_id)
            changeValue('validationMessage', message)
            if (success) {
                _isMounted && setState(prevState => ({
                    ...prevState,
                    bkt_order_id: data.bkt_order_id,
                    popupShow: true
                }))
                makeStateEmpty()
            }
        }
        catch (err) {
            console.log(err)
        }
        setIsLoading(false)
    }

    const makeStateEmpty = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            ...INITIAL_STATE
        }))
    }

    return (
        <Can I={'create'} a={'orders'}>
            {/* <Dialog
                header={<h2 className="m-0 p-0 color-tertiary font-weight-normal">{`New order`}</h2>}
                modal
                onHide={() => {
                    props.hideModal()
                    makeStateEmpty();
                }}
                className='mx-4 ml-sm-5 m-lg-0 bg-Primary h-75'
                style={{ width: '75vw' }}
                breakpoints={{ '960px': '100vw', }}
                visible={props.modalShow}
                contentClassName='h-75'
                footer={
                    <div className='p-grid m-0 p-justify-end'>
                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 pr-4'>
                            <CustomButton
                                label={'Close'}
                                onClick={() => {
                                    props.hideModal()
                                    makeStateEmpty();
                                }}
                                bg='Secondary'
                                color='tertiary'
                                className='w-100 my-3'
                            />
                        </div>
                    </div>
                }
            > */}
            <div className={`screen-center mt-4 py-5 m-sm-0 h-sm-100vh`}>
                <h2 className="mb-4 p-0 color-tertiary font-weight-normal">{`New Order`}</h2>
                <div className='p-d-flex p-flex-column pt-2 w-100 w-lg-75 px-4 px-sm-2 p-lg-0'>
                    {/* <div className='p-d-flex p-flex-column pt-2 h-100'> */}
                    <OrderForm
                        //universal
                        _isMounted={_isMounted}
                        APO_order={'create'}
                        currentStep={currentStep}
                        changeValue={changeValue}
                        cityOptions={cityOptions}
                        countryOptions={countryOptions}
                        defaultStatus={defaultStatus}
                        isMakingAQuery={isMakingAQuery}
                        setCurrentStep={setCurrentStep}
                        setIsLoading={setIsLoading}
                        setState={setState}
                        //general
                        allOrderStatuses={allOrderStatuses}
                        bkt_order_id={bkt_order_id}
                        customerNote={customerNote}
                        date={date}
                        discountDetails={discountDetail}
                        orderDiscount={orderDiscount}
                        orderNote={orderNote}
                        orderSourceOptions={orderSourceOptions}
                        orderStatusOptions={orderStatusOptions}
                        orderTypeOptions={orderTypeOptions}
                        selectedOrderSource={selectedOrderSource}
                        selectedOrderStatus={selectedOrderStatus}
                        selectedOrderType={selectedOrderType}
                        totalField={totalField}
                        //customer
                        cAddress={cAddress}
                        cCell={cCell}
                        cCity={cCity}
                        cCountry={cCountry}
                        cEmail={cEmail}
                        cName={cName}
                        cPostcode={cPostcode}
                        cState={cState}
                        cStateOptions={cStateOptions}
                        customer_id={customer_id}
                        //query
                        queryDetail={queryDetail}
                        queryProduct={queryProduct}
                        queryStatus={queryStatus}
                        //products
                        customerStatus={customerStatus}
                        extraAdded={extraAdded}
                        itemDiscount={itemDiscount}
                        itemDiscountDetail={itemDiscountDetail}
                        itemStatusOptions={itemStatusOptions}
                        orderItems={orderItems}
                        orderProductOptions={orderProductOptions}
                        rate={rate}
                        qty={qty}
                        remarks={remarks}
                        selectedItemStatus={selectedItemStatus}
                        selectedProduct={selectedProduct}
                        selectedVariation={selectedVariation}
                        //shipping
                        courierOptions={courierOptions}
                        dAddress={dAddress}
                        dAmount={dAmount}
                        dCell={dCell}
                        dCity={dCity}
                        dCountry={dCountry}
                        dName={dName}
                        dPostcode={dPostcode}
                        dState={dState}
                        dStateOptions={dStateOptions}
                        selectedCourier={selectedCourier}
                        shippingDate={shippingDate}
                        shippingMethod={shippingMethod}
                        shippingSameAsBilling={shippingSameAsBilling}
                        trackingId={trackingId}
                        //payments
                        paymentMethodOptions={paymentMethodOptions}
                        selectedPaymentMethod={selectedPaymentMethod}
                        trAmount={trAmount}
                        trDate={trDate}
                        trId={trId}
                        trStatus={trStatus}
                        //form-refs
                        customerDataForm={customerDataForm}
                        generalDataForm={generalDataForm}
                        paymentsDataForm={paymentsDataForm}
                        productsDataForm={productsDataForm}
                        queryDataForm={queryDataForm}
                        shippingDataForm={shippingDataForm}
                    />
                    <div className='p-grid mt-4 w-100 mx-0'>
                        <div className='p-col-12 p-md-6 text-left p-0'>
                            <label className='p-0 validating-label mt-2'>{validationMessage}&nbsp;</label><br />
                        </div>
                        <div className='p-col-12 p-md-6 text-right p-0'>
                            <CustomButton
                                label={'Create'}
                                onClick={handleSubmitOrder}
                                bg='Secondary'
                                color='tertiary'
                                className='w-100 my-3'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* </Dialog> */}
            <LoaderModal
                modalShow={isLoading}
            />
            <OrderConfirmationPopup
                popupShow={popupShow}
                bktOrderId={bkt_order_id}
                toggle={() => {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        popupShow: false
                    }))
                }}
            />
        </Can>
    )
}


