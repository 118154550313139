export const getTags = async () => {
    try {
        const res = await fetch(`/api/tags`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const createTag = async (newTag) => {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify({ newTag }),
            headers: { 'Content-Type': 'application/json' }
        }
        const res = await fetch(`/api/tags`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}


export const syncTags = async (page) => {
    try {
        const res = await fetch(`/api/tags/sync/${page}`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getSyncMeta = async () => {
    try {
        const res = await fetch(`/api/tags/sync/meta`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}