import React, { useState, useContext } from "react";
import { MDBRow, MDBCol, MDBCloseIcon } from "mdbreact";
import { CustomDatePicker } from '../../../../misc/components/CustomDatePicker'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { Input, CustomInput } from "../../../../misc/components/Inputs"
import { handleNumberInputKeyPress, handleNumberInputBlur, calculatePrice, minusPercent, getPercent } from "../../../../misc/components/Helpers"
import ImageGallery from '../../../../misc/components/ImageGallery'
// import { AbilityContext } from "../../../../../configs/Ability-context";
import { UserContext } from '../../../../../contexts/UserContext';



const VariationForm = ({ index, variation, APO_product, handleUpdateVariation, stockStatusOptions, backordersOptions, priceRule, remarks }) => {
    const [scheduleSale, setScheduleSale] = useState((variation.date_on_sale_from || variation.date_on_sale_to) ? true : false)
    const [showImageGallery, setShowImageGallery] = useState(false)
    // const ability = useContext(AbilityContext)
    const { isSellerLoggedIn, isShopManagerLoggedIn } = useContext(UserContext)
    let {
        status,
        regular_price,
        cost_price,
        sale_price,
        date_on_sale_from,
        date_on_sale_to,
        sku,
        manage_stock,
        stock_quantity,
        stock_status,
        backorders,
        weight,
        dimensions,
        description,
        image,
        is_cost_provided
    } = variation

    const changeValue = (name, value) => {
        handleUpdateVariation(index, name, value)
    }

    const handleInput = (e) => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleCheckboxChange = e => {
        let { name, checked } = e.target
        if (name === 'scheduleSale') {
            if (checked) {
                let date = new Date()
                date.setDate(date.getDate() + 1)
                changeValue('date_on_sale_from', new Date())
                changeValue('date_on_sale_to', date)
            }
            else {
                changeValue('date_on_sale_from', '')
                changeValue('date_on_sale_to', '')
            }
        }
        if (name === 'status')
            changeValue(name, checked ? 'publish' : 'pending')
        else
            changeValue(name, checked)
    }

    const handleSelectChange = name => (selectedOption) => {
        changeValue(name, selectedOption)
    }

    const handleDateChange = name => value => {
        // console.log(value)
        changeValue(name, value)
    }

    const calculatePrices = async (e) => {
        try {
            switch (e.target.name) {
                case 'cost_price':
                    let newRegularPrice = await calculatePrice(priceRule, e.target.value, 'cost_price')
                    let newSalePrice = (sale_price && sale_price !== '') ? minusPercent(newRegularPrice, (100 - getPercent(regular_price, sale_price))) : ''
                    changeValue('regular_price', newRegularPrice)
                    APO_product === 'update' && changeValue('sale_price', newSalePrice)
                    break;
                case 'regular_price':
                    if (!is_cost_provided) {
                        let newCostPrice = await calculatePrice(priceRule, e.target.value, 'regular_price')
                        changeValue('cost_price', newCostPrice)
                        changeValue('sale_price', '')
                    }
                case 'sale_price':
                    if (!is_cost_provided && e.target.value) {
                        let newCostPrice = await calculatePrice(priceRule, e.target.value, 'sale_price')
                        changeValue('cost_price', newCostPrice)
                    }
                    break;
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const isCostProvidedInput = <div className='p-d-flex p-jc-between p-flex-wrap'>
        <CustomInput
            type='checkbox'
            id={`is_cost_provided`}
            handleInput={(e) => { changeValue('is_cost_provided', e.checked); changeValue('cost_price', ''); changeValue('regular_price', ''); changeValue('sale_price', '') }}
            label='Cost price ?'
            labelClass='mt-1 mb-0 small'
            checked={is_cost_provided}
            tooltip="Regular price will be entered later by bucket.pk"
            tooltipOptions={{ position: 'top' }}
            noLabel
            disabled={APO_product === 'update' && remarks !== 'You are new seller to this product.Please review'}
        />
        <CustomInput
            type='checkbox'
            id={`is_cost_provided`}
            handleInput={(e) => { changeValue('is_cost_provided', !e.checked); changeValue('cost_price', ''); changeValue('regular_price', ''); changeValue('sale_price', '') }}
            label='Regular price ?'
            labelClass='mt-1 mb-0 small'
            checked={!is_cost_provided}
            tooltip="Cost price will be calculated automatically"
            tooltipOptions={{ position: 'top' }}
            noLabel
            disabled={APO_product === 'update' && remarks !== 'You are new seller to this product.Please review'}
        />
    </div>
    const regularPriceInput = <Input
        type="number"
        label='Regular price (Rs)'
        name={`regular_price`}
        value={regular_price}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        required={!is_cost_provided}
        disabled={APO_product === 'create' ? is_cost_provided : is_cost_provided ? (isSellerLoggedIn || isShopManagerLoggedIn) : (!isSellerLoggedIn && !isShopManagerLoggedIn)}
        invalidMessage={`Please enter regular price`}
    />
    const costPriceInput = <Input
        type="number"
        label='Cost price (Rs)'
        name={`cost_price`}
        value={cost_price || ''}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        required={is_cost_provided}
        disabled={!is_cost_provided}
        // disabled={APO_product === 'create' ? !is_cost_provided : is_cost_provided ? (!isSellerLoggedIn && !isShopManagerLoggedIn) : (isSellerLoggedIn || isShopManagerLoggedIn)}
        invalidMessage={`Please enter cost price`}
    />
    const salePriceInput = <Input
        type="number"
        label='Sale price (Rs)'
        name={`sale_price`}
        value={sale_price}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        onBlur={calculatePrices}
        containerClass='mb-3'
        max={regular_price - 1}
        required={scheduleSale}
        invalidMessage={`Invalid sale price`}
        disabled={APO_product === 'create' ? is_cost_provided : is_cost_provided ? (isSellerLoggedIn || isShopManagerLoggedIn) : (!isSellerLoggedIn && !isShopManagerLoggedIn)}
    />
    return (
        <MDBRow center className='m-0 bg-grey'>
            <MDBCol sm='6' className='m-0 pt-3 pb-1 px-0 text-center'>

                <MDBRow className="m-0 p-0">
                    <MDBCol sm='6' className='m-0 px-0 pl-sm-0'>
                        {regularPriceInput}
                        {costPriceInput}
                        {isCostProvidedInput}
                        {(APO_product === 'create' ? regular_price : APO_product === 'update') &&
                            <>
                                {salePriceInput}
                                <CustomInput
                                    type='checkbox'
                                    id={`scheduleSale`}
                                    handleInput={(e) => {
                                        setScheduleSale(e.checked);
                                        handleCheckboxChange({ target: { name: 'scheduleSale', checked: e.checked } })
                                    }}
                                    label='Schedule ?'
                                    labelClass='mt-1 mb-0 small'
                                    checked={scheduleSale}
                                    tooltip="Schedule your variation's sale"
                                    tooltipOptions={{ position: 'top' }}
                                    noLabel
                                />
                                {scheduleSale &&
                                    <>
                                        <div className='mb-3 mx-0 p-0'>
                                            <CustomDatePicker
                                                selectedValue={date_on_sale_from}
                                                placeholder='Sale start date'
                                                handleChange={handleDateChange('date_on_sale_from')}
                                                isClearable
                                                isDisabled={is_cost_provided}
                                                isRequired={scheduleSale}
                                                showTime
                                                className='w-100 border-none border-radius-0'
                                            />
                                        </div>
                                        <div className='mb-3 mx-0 p-0'>
                                            <CustomDatePicker
                                                selectedValue={date_on_sale_to}
                                                placeholder='Sale end date'
                                                handleChange={handleDateChange('date_on_sale_to')}
                                                isClearable
                                                isDisabled={is_cost_provided}
                                                isRequired={scheduleSale}
                                                showTime
                                                className='w-100 border-none border-radius-0'
                                            />
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <div className='p-d-flex p-jc-between p-flex-wrap'>
                            <CustomInput
                                type='checkbox'
                                id={`manage_stock-${index}`}
                                handleInput={(e) => {
                                    changeValue('manage_stock', e.checked)
                                }}
                                label='Manage stock ?'
                                labelClass='mt-1 mb-0 small'
                                checked={manage_stock}
                                tooltip="Enable stock management at variation level"
                                tooltipOptions={{ position: 'top' }}
                                noLabel
                            />
                            <CustomInput
                                type='checkbox'
                                id={`status-${index}`}
                                handleInput={(e) => {
                                    handleCheckboxChange({ target: { name: 'status', checked: e.checked } })
                                }}
                                label='Enabled:'
                                labelClass='mt-1 mb-0 small'
                                checked={status === 'publish'}
                                tooltip="Publish this variation"
                                tooltipOptions={{ position: 'top' }}
                                noLabel
                            />
                        </div>
                    </MDBCol>
                    <MDBCol sm='6' className='m-0 px-0 px-sm-1'>
                        <Input
                            label='SKU'
                            name={`sku`}
                            value={sku}
                            handleInput={handleInput}
                            containerClass='mb-3'
                        />
                        {!manage_stock ?
                            <div className='mb-3 p-0'>
                                <MyOwnSelect
                                    options={stockStatusOptions}
                                    handleChange={handleSelectChange('stock_status')}
                                    placeHolder='Select stock status'
                                    selectedValue={stock_status}
                                    withOutIcon
                                />
                            </div>
                            :
                            <>
                                <Input
                                    type="number"
                                    label='Stock quantity'
                                    name={`stock_quantity`}
                                    value={stock_quantity || ''}
                                    handleInput={handleInput}
                                    onKeyPress={handleNumberInputKeyPress}
                                    onBlur={e => handleNumberInputBlur(e, changeValue)}
                                    containerClass='mb-3'
                                />
                                <div className='mb-3 p-0'>
                                    <MyOwnSelect
                                        options={backordersOptions}
                                        optionsHeading={backorders ? 'Allow backorders ?' : ''}
                                        handleChange={handleSelectChange('backorders')}
                                        placeHolder='Allow backorders ?'
                                        selectedValue={backorders}
                                        withOutIcon
                                    />
                                </div>
                            </>
                        }
                        {image ?
                            (
                                <MDBCol key={1.2} className='m-0 px-0 pt-3' style={{ height: '130px' }}>
                                    <MDBCloseIcon
                                        className='closeIcon'
                                        onClick={() => { handleUpdateVariation(index, 'image', null) }}
                                    />
                                    {image.type ?
                                        <img
                                            src={`/getImage/${image.name}`} alt='Image not found.'
                                            className='w-75 h-75 rounded shadow'
                                            style={{ border: '1px solid #669999' }}
                                        />
                                        :
                                        <img
                                            src={image.src} alt={image.alt}
                                            className='w-75 h-75 rounded shadow'
                                            style={{ border: '1px solid #669999' }}
                                        />
                                    }
                                </MDBCol >
                            )
                            :
                            <button type='button' className='action-button bg-tertiary color-primary mt-2' onClick={() => { setShowImageGallery(true); }}>
                                {`Upload an image`}
                            </button>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol sm='6' className='m-0 pt-3 pb-1 px-0'>
                <Input
                    type='textarea'
                    label='Description'
                    hint={'Enter an optional description for this variation'}
                    name={`description`}
                    value={description}
                    handleInput={handleInput}
                    containerClass='mb-3 px-0 pl-sm-1'
                    rows='6'
                />
                <MDBRow className="m-0 p-0">
                    <MDBCol sm='6' className='m-0 px-0 px-sm-1'>
                        <Input
                            type="number"
                            label='Weight (kg)'
                            name={`weight`}
                            value={weight}
                            handleInput={handleInput}
                            onKeyPress={handleNumberInputKeyPress}
                            containerClass='mb-3'
                        />
                        <Input
                            type="number"
                            label='Length (cm)'
                            name={`length`}
                            value={dimensions.length}
                            handleInput={handleInput}
                            onKeyPress={handleNumberInputKeyPress}
                            containerClass='mb-3'
                        />
                    </MDBCol>
                    <MDBCol sm='6' className='m-0 px-0 pl-sm-1'>
                        <Input
                            type="number"
                            label='Width (cm)'
                            name={`width`}
                            value={dimensions.width}
                            handleInput={handleInput}
                            onKeyPress={handleNumberInputKeyPress}
                            containerClass='mb-3'
                        />
                        <Input
                            type="number"
                            label='Height (cm)'
                            name={`height`}
                            value={dimensions.height}
                            handleInput={handleInput}
                            onKeyPress={handleNumberInputKeyPress}
                            containerClass='mb-3'
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <ImageGallery
                addBtnHeading={'Choose'}
                modalHeading={'Add Media'}
                modalShow={showImageGallery}
                reference='product_image'
                handleSelectedFiles={(file) => {
                    delete file[0].selected
                    handleUpdateVariation(index, 'image', file[0])
                }}
                // hideImageGallery={() => { setState(prevState => ({ ...prevState, showImageGallery: false })) }}
                hideImageGallery={() => { setShowImageGallery(false) }}
                isMultiple={false}
            />
        </MDBRow>
    )
}

export default VariationForm