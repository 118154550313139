import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Can } from '../../../configs/Ability-context'
import { Toast } from 'primereact/toast';
import PasswordConfirmationModal from '../../misc/components/PasswordConfirmationModal'
import LoaderModal from '../../misc/components/LoaderModal'
import { UpdatePageModal } from './sections/UpdatePageModal'
import { CustomButton } from '../../misc/components/Inputs';

let _isMounted
export const ShowSettingOptions = (props) => {
    let history = useHistory()
    const [{
        isUpdatingPage, pageToUpdate, isConfirmingPassword, isLoading
    }, setState] = useState({
        isUpdatingPage: false, pageToUpdate: '', isConfirmingPassword: false, isLoading: false
    })
    let toast = useRef(null)

    useEffect(() => {
        _isMounted = true
        return () => {
            _isMounted = false
        }
    }, [])
    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value })) }
    const style = { width: '250px', height: '150px', fontWeight: '600', fontSize: '17px' }
    const resetDatabase = () => {
        changeValue('isLoading', true)
        fetch('/resetDatabase')
            .then(res => res.json())
            .then(json => {
                console.log(json)
                setTimeout(() => {
                    window.location.reload()
                }, 750)
                toast.current?.show({ severity: 'success', summary: 'Success', detail: `Database moved to default.` })
                changeValue('isLoading', false)
            })
            .catch(err => {
                toast.current?.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
                changeValue('isLoading', false)
                console.log(err)
            })
    }

    const showOptions = (n) => {
        const options = {
            1: [
                { label: "Store Setup", onClick: () => history.push('/settings/all'), permission: 'update', subject: 'appSettings' },
                { label: "Reset database", onClick: () => changeValue('isConfirmingPassword', true), permission: 'reset', subject: 'database' },
                { label: "Seller plans", onClick: () => history.push('/settings/plans/all'), permission: 'read', subject: 'sellerPlans' },
            ],
            2: [
                {
                    label: "Update About page", onClick: () => {
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            isUpdatingPage: true,
                            pageToUpdate: 'About'
                        }))
                    }, permission: 'update', subject: 'publicPages'
                },
                {
                    label: "Update Contact page", onClick: () => {
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            isUpdatingPage: true,
                            pageToUpdate: 'Contact'
                        }))
                    }, permission: 'update', subject: 'publicPages'
                },
                {
                    label: "Update Terms & Conditions page", onClick: () => {
                        _isMounted && setState(prevState => ({
                            ...prevState,
                            isUpdatingPage: true,
                            pageToUpdate: 'Terms & Conditions'
                        }))
                    }, permission: 'update', subject: 'publicPages'
                },
            ]
        }
        return (
            <React.Fragment>
                {options[n].map((option, index) => (
                    <Can I={option.permission} a={option.subject} key={index + 1}>
                        <CustomButton
                            label={option.label}
                            style={style}
                            onClick={option.onClick}
                            isRounded
                            bg={n === 1 ? 'Secondary' : 'primary-light'}
                            color='tertiary'
                            className='p-m-2'
                        />
                    </Can>
                ))}
            </React.Fragment>
        )
    }

    return (
        <>
            <div className='screen-center h-100vh p-mt-4 p-pt-5 p-m-sm-0 p-p-sm-0'>
                <div className='p-d-flex p-flex-wrap p-jc-center w-100'>
                    {showOptions(1)}
                </div>
                <div className='p-d-flex p-flex-wrap p-jc-center w-100'>
                    {showOptions(2)}
                </div>
            </div>
            <Toast ref={toast} className='p-mt-5 p-mr-3' />
            <UpdatePageModal
                modalShow={isUpdatingPage}
                pageToUpdate={pageToUpdate}
                hideModal={() => changeValue('isUpdatingPage', false)}
                {...props}
            />
            <PasswordConfirmationModal
                modalShow={isConfirmingPassword}
                handleVerifyStatus={(verified) => {
                    verified && resetDatabase() && changeValue('isConfirmingPassword', false)
                }}
                hideModal={() => changeValue('isConfirmingPassword', false)}
            />
            <LoaderModal modalShow={isLoading} />
        </>
    )
}
