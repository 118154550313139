export const createResource = async (fd) => {
    try {
        const options = {
            method: 'POST',
            body: fd,
        }
        const res = await fetch(`/api/resources`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const getResources = async () => {
    try {
        const res = await fetch(`/api/resources`)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}

export const deleteResources = async (values) => {
    try {
        const options = {
            method: 'DELETE',
            body: JSON.stringify({ values }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const res = await fetch(`/api/resources`, options)
        const json = await res.json()
        console.log(json)
        return json
    }
    catch (error) {
        console.log(error)
    }
}