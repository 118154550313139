import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllPriceRules from './pages/AllPriceRules';
import PriceRule from './pages/sections/PriceRule';
import NotFound from '../misc/pages/NotFound';
import { Can } from '../../configs/Ability-context'
import { PrivateRoute } from '../misc/PrivateRoute'


const PriceRules = () => {

    return (
        <Switch>
            <PrivateRoute path="/price-rules/new" >
                <Can I='create' a='priceRules'>
                    <PriceRule APO_method={'create'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/price-rules/edit/:rule">
                <Can I='update' a='priceRules'>
                    <PriceRule APO_method={'update'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/price-rules/all" >
                <Can I='read' a='priceRules'>
                    <AllPriceRules />
                </Can>
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default PriceRules