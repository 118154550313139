import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SecuredAllPermissions from './SecuredAllPermissions';
import SecuredEditPermission from './SecuredEditPermission';
import NotFound from '../../misc/pages/NotFound'


const Permissions = () => {



    return (
        <Switch>
            <Route path="/users/permissions/all" component={SecuredAllPermissions} />
            <Route path="/users/permissions/edit/:permission" component={SecuredEditPermission} />
            <Route render={() => <NotFound />} />
        </Switch>

    )
}


export default Permissions