import React, { useState, useEffect, useRef } from "react";
import { Can } from "../../../../configs/Ability-context";
import { CustomInput, CustomButton } from "../../../misc/components/Inputs";
import {
  getSetting,
  updateSetting,
} from "../../../../services/misc/setting-service";
import { Dialog } from "primereact/dialog";
import LoaderModal from "../../../misc/components/LoaderModal";
import PasswordConfirmationModal from "../../../misc/components/PasswordConfirmationModal";

export const SettingModal = (props) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [requiresPassword, setRequiresPassword] = useState(false);
  let settingForm = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await getSetting(props.settingId);
        setKey(data.key);
        setValue(data.value);
        setRequiresPassword(data.is_password_protected);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    props.modalShow && fetchData();
    setValidationMessage("");
  }, [props.modalShow]);

  const handleInput = (e) => {
    let { value } = e.target;
    setValue(value);
  };

  const handleSubmit = () => {
    let form = settingForm.current;
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    }
    if (requiresPassword) {
      setIsConfirmingPassword(true);
    } else {
      handleUpdate(); //We Will Directly update if password is not required
    }
    // setIsConfirmingPassword(true);
  };
  // let is_password_protected = true;
  const handleUpdate = async () => {
    let updatedSetting = {
      value: value,
    };
    try {
      const { success, message } = await updateSetting(
        props.settingId,
        updatedSetting
      );
      setValidationMessage(message);
      success &&
        setTimeout(() => {
          props.hideModal();
        }, 1250);
    } catch (error) {
      console.log(error);
    }
  };
  // const handleChangePasswordProtection = async () => {
  //   let updatedSetting = {
  //     is_password_protected: is_password_protected,
  //   };
  //   try {
  //     const { success, message } = await updateSetting(
  //       props.settingId,
  //       updatedSetting
  //     );
  //     setValidationMessage(message);
  //     success &&
  //       setTimeout(() => {
  //         props.hideModal();
  //       }, 1250);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <Can I={"update"} a="appSettings">
      <Dialog
        header={
          <h2 className="p-m-0 p-p-0 color-tertiary p-text-normal">{`Update setting`}</h2>
        }
        modal
        onHide={() => props.hideModal()}
        className="p-text-center p-mx-4 p-mx-md-0"
        style={{ width: "30vw" }}
        breakpoints={{
          "1200px": "40vw",
          "992px": "50vw",
          "768px": "75vw",
          "576px": "100vw",
        }}
        visible={props.modalShow}
        contentClassName="p-pb-0"
        footer={
          <div className="p-grid p-m-0 p-justify-end">
            <div className="p-col-6 p-md-4 p-px-4 p-py-0">
              <CustomButton
                label={"Update"}
                onClick={() => handleSubmit()}
                bg="Secondary"
                color="tertiary"
                className="w-100 p-mb-3"
              />
            </div>
          </div>
        }
      >
        <form ref={settingForm} className="p-m-0 p-pt-4" noValidate>
          <div className="p-m-0 p-d-flex p-flex-column">
            <CustomInput label="Name" name={`key`} value={key} disabled />
            <CustomInput
              label="Value"
              name={`value`}
              value={value}
              handleInput={handleInput}
              required
              marginBottom={"2"}
            />
          </div>
          <label className="p-p-0 validating-label">
            {validationMessage}&nbsp;
          </label>
        </form>
        {/* <button onClick={handleChangePasswordProtection}>
          Change password protection
        </button> */}
      </Dialog>
      <LoaderModal modalShow={isLoading} />
      <PasswordConfirmationModal
        modalShow={isConfirmingPassword}
        handleVerifyStatus={(verified) => {
          verified && handleUpdate() && setIsConfirmingPassword(false);
        }}
        hideModal={() => setIsConfirmingPassword(false)}
      />
    </Can>
  );
};
