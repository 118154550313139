import React from 'react';
import { Input } from '../../../../misc/components/Inputs'
import { handleNumberInputKeyPress } from '../../../../misc/components/Helpers'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { CustomDatePicker } from '../../../../misc/components/CustomDatePicker'
import getOptions from '../../../../misc/components/Options'


export const ShippingDataStep = (props) => {

    let {
        APO_order,
        courierOptions,
        currentStep,
        cityOptions,
        countryOptions,
        dAddress,
        dAmount,
        dCell,
        dCity,
        dCountry,
        dName,
        dPostcode,
        dState,
        dStateOptions,
        handleCheckboxChange,
        handleInput,
        handleNumberInputBlur,
        handleSelectChange,
        shippingSameAsBilling,
        selectedCourier,
        shippingDataForm,
        shippingDate,
        shippingMethod,
        trackingId,
    } = props

    const shippingMethodInput = <Input
        label="Shipping method"
        name={`shippingMethod`}
        value={shippingMethod || ''}
        handleInput={handleInput}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
    />
    const shippingDateInput = <CustomDatePicker
        selectedValue={shippingDate}
        handleChange={(value) => {
            handleSelectChange('shippingDate')(value);
        }}
        label='Shipping date'
        isClearable
        // isRequired
        isFormField
        isDisabled={APO_order === 'read'}
    />
    const courierInput = <MyOwnSelect
        options={courierOptions}
        optionsHeading={selectedCourier ? 'Select courier' : ''}
        handleChange={handleSelectChange('selectedCourier')}
        placeHolder={'Select courier'}
        selectedValue={selectedCourier}
        withOutIcon
        // required
        isDisabled={APO_order === 'read'}
    />
    const cityInput = <MyOwnSelect
        options={cityOptions}
        optionsHeading={dCity ? 'Select city' : ''}
        handleChange={handleSelectChange('dCity')}
        placeHolder={'Select city'}
        selectedValue={dCity}
        withOutIcon
        // required
        creatable
        isDisabled={APO_order === 'read'}
        handleCreate={(newCityName) => {
            // cityOptions = [{ key: newCityName, label: newCityName, value: newCityName }, ...cityOptions]
            handleSelectChange('dCity')({ key: newCityName, label: newCityName, value: newCityName })
        }}
    />
    const trackingIdInput = <Input
        label="Tracking id"
        name={`trackingId`}
        value={trackingId || ''}
        handleInput={handleInput}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
    />
    const dAmountInput = <Input
        type='number'
        label="Charges"
        name={`dAmount`}
        value={dAmount || ''}
        min={0}
        handleInput={handleInput}
        onBlur={handleNumberInputBlur}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
    />
    const nameInput = <Input
        label="Name"
        name={`dName`}
        value={dName || ''}
        handleInput={handleInput}
        required={!shippingSameAsBilling}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
        invalidMessage={`Please enter name`}
    />
    const cellInput = <Input
        label="Phone no."
        name={`dCell`}
        value={dCell || ''}
        pattern="(^(92)[0-9]{10}$|^(0)[0-9]{10})"
        maxLength="12"
        handleInput={handleInput}
        required={!shippingSameAsBilling}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
        // invalidFullWidth
        invalidMessage={`Please follow the pattern 923011234567 or 03011234567`}
    />
    const addressInput = <Input
        label="Address"
        type='textarea'
        rows={2}
        name={`dAddress`}
        value={dAddress || ''}
        handleInput={handleInput}
        required={!shippingSameAsBilling}
        containerClass='mb-3'
        disabled={APO_order === 'read'}
        invalidMessage={`Please enter address`}
    />
    const stateInput = dStateOptions ?
        <MyOwnSelect
            options={dStateOptions}
            optionsHeading={dState ? 'State/Province' : ''}
            handleChange={handleSelectChange('dState')}
            placeHolder='State/Province'
            selectedValue={dState}
            className='border-radius-0 mb-3'
            withOutIcon
            required
            isDisabled={APO_order === 'read'}
        />
        :
        <Input
            label='State/Province'
            name={`dState`}
            value={dState || ''}
            containerClass='mb-3'
            handleInput={handleInput}
            required={!shippingSameAsBilling}
            disabled={APO_order === 'read'}
            invalidMessage={`Please enter state/province`}
        />
    const selectCountryInput = <MyOwnSelect
        options={countryOptions}
        optionsHeading={dCountry ? 'Select country' : ''}
        handleChange={handleSelectChange('dCountry')}
        placeHolder='Select country'
        selectedValue={dCountry}
        className='border-radius-0'
        withOutIcon
        required={!shippingSameAsBilling}
        isDisabled={APO_order === 'read'}
    />
    const postcodeInput = <Input
        label='Postcode'
        name={`dPostcode`}
        value={dPostcode || ''}
        containerClass='mb-3'
        handleInput={handleInput}
        disabled={APO_order === 'read'}
        invalidMessage={`Please enter postcode`}
    />
    const shippingSameAsBillingInput = <div className="custom-control custom-checkbox text-left">
        <input
            type="checkbox"
            name="shippingSameAsBilling"
            className="custom-control-input"
            id="shippingSameAsBilling"
            checked={!shippingSameAsBilling}
            onChange={handleCheckboxChange}
            disabled={APO_order === 'read'}
        />
        <label className="custom-control-label" htmlFor="shippingSameAsBilling">Ship to a different address ?</label>
    </div>
    return (
        <form
            ref={shippingDataForm}
            className={`m-0 p-0 ${currentStep === 4 ? 'd-block' : 'd-none'}`}
            noValidate
        >
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6'>
                    {shippingDateInput}
                </div>
                <div className='m-0 col-sm-6'>
                    {shippingMethodInput}
                </div>
                <div className='mb-3 col-sm-6'>
                    {courierInput}
                </div>
                {/* <div className='mb-3 col-sm-6'>
                    {cityInput}
                </div> */}
                <div className='m-0 col-sm-6'>
                    {dAmountInput}
                </div>
                <div className='m-0 col-sm-6'>
                    {trackingIdInput}
                </div>
                {
                    APO_order !== 'read' &&
                    <div className='mb-3 px-3 p-col p-sm-6 p-align-center'>{shippingSameAsBillingInput}</div>
                }
            </div>
            {!shippingSameAsBilling &&
                <div className='m-0 p-0 p-grid'>
                    <div className='m-0 col-sm-6'> {nameInput}</div>
                    <div className='m-0 col-sm-6'>{cellInput}</div>
                    <div className='m-0 col-12'> {addressInput}</div>
                    <div className='mb-3 col-sm-6'>{selectCountryInput}</div>
                    <div className='m-0 col-sm-6'>{stateInput}</div>
                    <div className='mb-3 col-sm-6'>{cityInput}</div>
                    <div className='m-0 col-sm-6'>{postcodeInput}</div>
                </div>
            }
        </form>
    )
}
