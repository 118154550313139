import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { MDBRow, MDBCol } from 'mdbreact'
import { Chart } from 'primereact/chart';
import { Link } from 'react-router-dom'
import AllOrderItems from '../../orders/pages/AllOrderItems'


const Dashboard = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [requestsCount, setRquestsCount] = useState(0);
    const [sellersCount, setSellersCount] = useState(0);
    const [ordersCount, setordersCount] = useState(0);
    const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
    const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
    const [pendingOrdersAmount, setPendingOrdersAmount] = useState(0);
    const [completedOrdersAmount, setCompletedOrdersAmount] = useState(0);
    const [topCategories, setTopCategories] = useState([]);
    const [topCategoriesCount, setTopCategoriesCount] = useState([]);
    const [pendingProductsCount, setpendingProductsCount] = useState(0);
    const selectedStatus = 'pending'
    // let topCategories


    useEffect(() => {
        const fetchProductsSummary = async () => {
            fetch('/api/products_summary')
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    let categories = json.top_categories.map(a => a.name);
                    let categories_count = json.top_categories.map(a => a.count);

                    setTopCategories(categories);
                    setTopCategoriesCount(categories_count);
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchProductsSummary()



        // const fetchRequestCount = () => {
        //     // changeValue('isLoading', true)
        //     fetch('/api/seller_requests')
        //         .then(res => res.json())
        //         .then(json => {
        //             let count = Object.keys(json.data).length;
        //             setRquestsCount(count)
        //         })
        //         .catch(err => {
        //             // changeValue('isLoading', false)
        //             console.log(err)
        //         })
        // }
        const fetchSellersCount = () => {
            fetch('/api/sellers/count')
                .then(res => res.json())
                .then(json => {
                    let count = json.sellers_count
                    setSellersCount(count)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        const fetchOrdersCount = () => {
            fetch('/api/orders_summary')
                .then(res => res.json())
                .then(json => {
                    // console.log(json);
                    let ordersCount = json.orders_count
                    let pendingCount = json.pending_count
                    let completedCount = json.completed_count
                    let pendingOrdersAmount = json.pending_orders_amount
                    let completedOrdersAmount = json.completed_orders_amount
                    setordersCount(ordersCount)
                    setPendingOrdersCount(pendingCount)
                    setCompletedOrdersCount(completedCount)
                    setPendingOrdersAmount(pendingOrdersAmount)
                    setCompletedOrdersAmount(completedOrdersAmount)
                })
                .catch(err => {
                    console.log(err)
                })
        }


        // const fetchPendingProductsCount = () => {
        //     // changeValue('isLoading', true)
        //     fetch('/api/products/status_filter/pending')
        //         .then(res => res.json())
        //         .then(json => {
        //             let count = Object.keys(json.data).length;
        //             // console.log("pending items : " + count)
        //             setpendingProductsCount(count)
        //         })
        //         .catch(err => {
        //             // changeValue('isLoading', false)
        //             console.log(err)
        //         })
        // }
        // fetchPendingProductsCount()
        // fetchRequestCount()

        fetchSellersCount()
        fetchOrdersCount()
    }, []);

    useEffect(() => {

        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: topCategories,
            datasets: [
                {
                    data: topCategoriesCount,
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500'),
                        documentStyle.getPropertyValue('--pink-500'),
                        documentStyle.getPropertyValue('--orange-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--pink-400'),
                        documentStyle.getPropertyValue('--orange-400')
                    ],
                }
            ]
        };
        const options = {
            cutout: '60%'
        };
        setChartData(data);
        setChartOptions(options);
    }, [topCategories, topCategoriesCount]);

    return (
        <div className='container'>
            <h3 className='text-center'>Admin Dashboard</h3>
            <MDBRow>
                <MDBCol>
                    <Card className="mt-1 ml-1">
                        <h3>{sellersCount}</h3>
                        <i className="pi pi-user" /> Total Sellers
                    </Card>
                </MDBCol>
                <MDBCol>
                    <Card className="mt-1 ml-1">
                        <p >0</p>
                        <i className="pi pi-globe" /> Website Visits
                    </Card>
                </MDBCol>
                <MDBCol>
                    <Card className="mt-1 ml-1">
                        <p >0</p>
                        <i className="pi pi-star" /> Reviews & Ratings
                    </Card>
                </MDBCol>
                <MDBCol>
                    <Card className="mt-1 ml-1 mb-1">
                        <h3>{ordersCount}</h3>
                        <i className="pi pi-file" /> Total Orders
                    </Card>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <MDBRow>
                        {/* <MDBCol>
                            <Card className="mt-1 ml-1" subTitle="Marketplace" >
                                <p className="m-0">Marketplace</p>
                                <i className="pi pi-globe" /> UAE
                            </Card>
                        </MDBCol> */}
                        <MDBCol>
                            <Card className="mt-1" >
                                <h5>Total Sales <span style={{ color: '#EEAE1E', fontSize: '80%' }}>(AED)</span></h5>
                                <h3>{completedOrdersAmount}</h3>
                                <span style={{ fontSize: "70%" }}>Total number of completed orders: {completedOrdersCount}</span>
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1" >
                                <h5>Pending Orders <span style={{ color: '#EEAE1E', fontSize: '80%' }}>(AED)</span></h5>
                                <h3>{pendingOrdersAmount}</h3>
                                <span style={{ fontSize: "70%" }}>Total number of pending orders: {pendingOrdersCount}</span>
                            </Card>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Card className="mt-1 ml-1" subTitle="Wallet Balance">
                                <i className="pi pi-wallet" /> 0
                            </Card>
                        </MDBCol>
                        <MDBCol>
                            <Card className="mt-1" subTitle="User Reviews">
                                <i className="pi pi-star" /> 0

                            </Card>
                        </MDBCol>
                        {/* <MDBCol>
                            <Card className="mt-1" subTitle="Pending Products">
                                <Link to={{ pathname: '/products/all', query: { selectedStatus } }} title='Pending Products' >
                                    <i className="pi pi-microsoft" /> {pendingProductsCount}
                                </Link>
                            </Card>
                        </MDBCol> */}
                    </MDBRow>

                </MDBCol>
                <MDBCol >
                    <div className="card flex justify-content-center" style={{ height: '100%' }}>
                        <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className='mt-1'>
                    <Card className='recent-orders'>
                        <strong>Recent Orders</strong>
                        <AllOrderItems
                            heading={''}
                            rows={5}
                        />
                    </Card>
                </MDBCol>
            </MDBRow>


        </div >

    )
}


export default Dashboard

