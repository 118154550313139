export const createOrder = async (
  customerDetails,
  orderDetails,
  order_items,
  customer_id
) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({
        customerDetails,
        orderDetails,
        order_items,
        customer_id,
      }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getUniqueOrderId = async (source) => {
  try {
    const res = await fetch(`/api/orders/id/${source}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getOrdersWithData = async (limit, offset) => {
  try {
    const res = await fetch(`/api/orders/data?limit=${limit}&off=${offset}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getSellerOrdersWithData = async (limit, offset) => {
  try {
    const res = await fetch(
      `/api/orders/seller/data?limit=${limit}&off=${offset}`
      // `/api/orders/seller/data?limit=${limit}&off=${offset}&seller_id=${seller_id}`
    );
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getOrder = async (id) => {
  try {
    const res = await fetch(`/api/orders/${id}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrdersFields = async (orders, updatedOrder) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ orders, updatedOrder }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/field`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrderOnlyDetails = async (
  orderId,
  customerDetails,
  orderDetails
) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ orderId, customerDetails, orderDetails }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/details`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrder = async (
  orderId,
  customerDetails,
  orderDetails,
  order_items
) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ customerDetails, orderDetails, order_items }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/${orderId}`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

// export const updateOrdersField = async (orders, updatedColumn, updatedValue) => {
//     try {
//         const options = {
//             method: 'PUT',
//             body: JSON.stringify({ orders, updatedValue, updatedColumn }),
//             headers: { 'Content-Type': 'application/json' }
//         }
//         const res = await fetch(`/api/orders/field`, options)
//         const json = await res.json()
//         console.log(json)
//         return json
//     }
//     catch (error) {
//         console.log(error)
//     }
// }

export const getOrdersMetaToSync = async () => {
  try {
    const res = await fetch(`/api/orders/sync/meta`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const syncOrders = async (pg) => {
  try {
    const res = await fetch(`/api/orders/sync/${pg}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const printLocalSlips = async (orders) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ orders }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/labels/print`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getOrdersWithSpecificStatus = async (statuses) => {
  try {
    const res = await fetch(`/api/orders?statuses=${JSON.stringify(statuses)}`);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const exportOrders = async (orders, courier) => {
  const options = {
    method: "POST",
    body: JSON.stringify({ orders, courier }),
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await fetch(`/api/orders/export`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
