export const getOrderItemsWithData = async (limit, offset) => {
  try {
    const res = await fetch(
      `/api/orders/items/data?limit=${limit}&off=${offset}`
    );
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getSellerOrderItemsWithData = async (order_id) => {
  console.log("order_id");
  console.log(order_id);
  try {
    const res = await fetch(
      `/api/orders/seller/details/data?order_id=${order_id}`
    );
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const updateOrderItemsFields = async (items, updatedFields) => {
  try {
    const options = {
      method: "PUT",
      body: JSON.stringify({ items, updatedFields }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/items`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getProductSeller = async (product_id) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ product_id }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch("/api/orders/items/seller", options);
    // options
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
// export const createSellerOrder = async (orderItems) => {
//   try {
//     const promises = orderItems.map(async (orderItem) => {
//       const options = {
//         method: "POST",
//         body: JSON.stringify({ orderitem: orderItem }),
//         headers: { "Content-Type": "application/json" },
//       };
//       const res = await fetch("/api/orders/seller/order", options);
//       return res.json();
//     });

//     const responses = await Promise.all(promises);
//     console.log(responses); // Log all responses
//     return responses;
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const createSellerOrderDetails = async (orderItems) => {
//   try {
//     const promises = orderItems.map(async (orderItem) => {
//       const options = {
//         method: "POST",
//         body: JSON.stringify({ orderitem: orderItem }),
//         headers: { "Content-Type": "application/json" },
//       };
//       const res = await fetch("/api/orders/seller/order/details", options);
//       return res.json();
//     });

//     const responses = await Promise.all(promises);
//     console.log(responses); // Log all responses
//     return responses;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const createSellerOrder = async (orderItemIds) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ orderItemIds }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch("/api/orders/seller/order", options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const setSellerOrderOnCreate = async (orderItemIds) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ orderItemIds }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(
      "/api/orders/set-seller-order/order-details",
      options
    );
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const createSellerOrderDetails = async (orderitems) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ orderitems }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch("/api/orders/seller/order/details", options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const checkSellerOrderExists = async (order_id) => {
  console.log("order_id", order_id);
  try {
    const options = {
      method: "POST",
      // body: JSON.stringify({ order_id }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(
      `/api/orders/seller/order/check?order_id=${order_id}`,
      options
    );
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const exportOrderItems = async (orders) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ orders }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/orders/items/export`, options);
    const json = await res.json();
    console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
