import React from 'react';
import AllPermissions from './pages/AllPermissions';
import { Redirect } from 'react-router-dom'
import { Can } from '../../../configs/Ability-context'
import { UserContext } from '../../../contexts/UserContext'

const SecuredAllPermissions = (props) => {
    const { isLoggedIn } = React.useContext(UserContext)

    if (!isLoggedIn) {
        return <Redirect to='/login' />
    }
    else {
        return <Can I='read' a='permissions'> <AllPermissions {...props} /></Can>
    }

}



export default SecuredAllPermissions