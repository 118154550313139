import { useState, useEffect, useRef } from "react";
import { Can } from "../../../configs/Ability-context";
import { CustomButton } from "../../misc/components/Inputs";
import getOptions from "../../misc/components/Options";
import LoaderModal from "../../misc/components/LoaderModal";
import { Dialog } from "primereact/dialog";
import { getPaymentMethods } from "../../../services/payments/method-service";
import { getOrderSources } from "../../../services/orders/source-service";
import { getOrder } from "../../../services/orders/order-service";
import { OrderForm } from "./sections/OrderForm";

const orderTypeOptions = getOptions("orderTypes"),
  courierOptions = getOptions("couriers"),
  countryOptions = getOptions("countries");
let _isMounted;

export const ViewOrderModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [orderSourceOptions, setOrderSourceOptions] = useState([]);
  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [order, setOrder] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let generalDataForm = useRef(null),
    customerDataForm = useRef(null),
    queryDataForm = useRef(null),
    productsDataForm = useRef(null),
    shippingDataForm = useRef(null),
    paymentsDataForm = useRef(null),
    totalField = useRef(null);
  const { orderId, allOrderStatuses } = props;
  useEffect(() => {
    _isMounted = true;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { order: _order } = await getOrder(orderId);
        let orderStatusOptions = getOptions("orderStatuses", allOrderStatuses);
        fetchMeta();
        setOrderStatusOptions(orderStatusOptions);
        setOrder(_order);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    const fetchMeta = async () => {
      try {
        const { data: allPaymentMethods } = await getPaymentMethods();
        const { data: allOrderSources } = await getOrderSources();

        let paymentMethodOptions = getOptions(
          "paymentMethods",
          allPaymentMethods
        );
        let orderSourceOptions = getOptions("orderSources", allOrderSources);

        setPaymentMethodOptions(paymentMethodOptions);
        setOrderSourceOptions(orderSourceOptions);
      } catch (err) {
        console.log(err);
      }
    };
    if (orderId) {
      props.modalShow && fetchData();
    }
    setModalShow(props.modalShow);
    setCurrentStep(1);
    return () => {
      _isMounted = false;
    };
  }, [props.modalShow]);

  if (order) {
    // console.log(order)
    var {
      bkt_order_id,
      date,
      type,
      order_discount,
      discount_detail,
      shipping_method,
      courier,
      tracking_id,
      shipping_date,
      shipping_amount,
      shipping_name,
      shipping_cell,
      shipping_address,
      shipping_city,
      shipping_state,
      shipping_country,
      shipping_postcode,
      customer_note,
      order_note,
      payment_method_id,
      tr_id,
      tr_date,
      tr_amount,
      tr_status,
      query_product,
      query_status,
      query_detail,
      customer,
      order_details,
      source_id,
      status,
    } = order;
    var paymentMethod = paymentMethodOptions.find(
      (option) => option.value === payment_method_id
    );
    var selectedOrderType = orderTypeOptions.find(
      (option) => option.value === type
    );
    var APO_order = "read";
    var isMakingAQuery = selectedOrderType.label === "Query" ? true : false;
    var shippingSameAsBilling = false;

    var orderItems = order_details.map((item) => ({
      bkt_order_id: item.bkt_order_id,
      date: new Date(item.date),
      name: item.name,
      rate: item.rate,
      qty: item.qty,
      subtotal: item.subtotal,
      discount: item.discount,
      discount_detail: item.discount_detail,
      price: item.price,
      remarks: item.remarks,
      extra_added: item.extra_added,
      customer_status: item.customer_status,
      order_id: item.order_id,
      product_bkt_id: item.product_bkt_id,
      variation_bkt_id: item.variation_bkt_id,
      status: item.status,
    }));

    var cStateOptions = getOptions("states", customer?.country);
    var dStateOptions = getOptions("states", shipping_country);
  }
  console.log(cStateOptions);
  console.log(customer?.country);
  console.log(customer?.state);
  console.log(cStateOptions?.find((s) => s.value === customer?.state));

  if (order)
    return (
      <Can I={"read"} a="orders">
        <Dialog
          header={
            <h2 className="m-0 p-0 color-tertiary font-weight-normal">{`View order`}</h2>
          }
          position="right"
          modal
          onHide={() => {
            setModalShow(false);
            setTimeout(() => {
              props.hideModal();
            }, 200);
          }}
          className="mx-4 ml-sm-5 m-lg-0 bg-Primary h-75"
          style={{ width: "75vw" }}
          breakpoints={{ "960px": "100vw" }}
          visible={modalShow}
          contentClassName="h-75"
          footer={
            <div className="p-grid m-0 p-justify-end">
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 pr-4">
                <CustomButton
                  label={"Close"}
                  onClick={() => {
                    setModalShow(false);
                    setTimeout(() => {
                      props.hideModal();
                    }, 200);
                  }}
                  bg="Secondary"
                  color="tertiary"
                  className="w-100 my-3"
                />
              </div>
            </div>
          }
        >
          <div className="p-d-flex p-flex-column pt-2 h-100">
            <OrderForm
              //universal
              position="right"
              _isMounted={_isMounted}
              APO_order={APO_order}
              currentStep={currentStep}
              isMakingAQuery={isMakingAQuery}
              setCurrentStep={setCurrentStep}
              //general
              bkt_order_id={bkt_order_id}
              customerNote={customer_note}
              date={new Date(date)}
              discountDetail={discount_detail}
              orderDiscount={order_discount}
              orderNote={order_note}
              orderSourceOptions={orderSourceOptions}
              orderStatusOptions={orderStatusOptions}
              orderTypeOptions={orderTypeOptions}
              selectedOrderSource={orderSourceOptions.find(
                (option) => option.value === source_id
              )}
              selectedOrderStatus={orderStatusOptions.find(
                (option) => option.value === status
              )}
              selectedOrderType={selectedOrderType}
              totalField={totalField}
              //customer
              cAddress={customer?.address}
              cCell={customer?.cell}
              cCity={{
                label: customer?.city.toUpperCase(),
                value: customer?.city.toUpperCase(),
              }}
              cCountry={countryOptions.find(
                (c) => c.value === customer?.country
              )}
              cEmail={customer?.email}
              cName={customer?.name}
              cPostcode={customer?.postcode}
              cStateOptions={cStateOptions}
              cState={cStateOptions?.find((s) => s.value === customer?.state)}
              //query
              queryDetail={query_detail}
              queryProduct={query_product}
              queryStatus={query_status}
              //products
              itemStatusOptions={orderStatusOptions}
              orderItems={orderItems}
              //shipping
              courierOptions={courierOptions}
              dAddress={shipping_address}
              dAmount={shipping_amount}
              dCell={shipping_cell}
              dCity={{
                label: shipping_city.toUpperCase(),
                value: shipping_city.toUpperCase(),
              }}
              dName={shipping_name}
              dStateOptions={dStateOptions}
              dState={dStateOptions?.find((s) => s.value === shipping_state)}
              dCountry={countryOptions.find(
                (c) => c.value === shipping_country
              )}
              dPostcode={shipping_postcode}
              selectedCourier={courierOptions.find(
                (option) => option.value === courier
              )}
              shippingDate={shipping_date ? new Date(shipping_date) : ""}
              shippingMethod={shipping_method}
              shippingSameAsBilling={shippingSameAsBilling}
              trackingId={tracking_id}
              //payments
              paymentMethodOptions={paymentMethodOptions}
              selectedPaymentMethod={paymentMethod}
              trAmount={tr_amount}
              trDate={tr_date ? new Date(tr_date) : ""}
              trId={tr_id}
              trStatus={tr_status}
              //form-refs
              customerDataForm={customerDataForm}
              generalDataForm={generalDataForm}
              paymentsDataForm={paymentsDataForm}
              productsDataForm={productsDataForm}
              queryDataForm={queryDataForm}
              shippingDataForm={shippingDataForm}
            />
          </div>
        </Dialog>
        <LoaderModal modalShow={isLoading} />
      </Can>
    );
  else return null;
};
