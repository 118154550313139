import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllMethods from './pages/AllMethods';
import Method from './pages/sections/Method';
import NotFound from '../../misc/pages/NotFound';
import { Can } from '../../../configs/Ability-context'
import { PrivateRoute } from '../../misc/PrivateRoute'


const Methods = () => {

    return (
        <Switch>
            <PrivateRoute path="/payments/methods/new" >
                <Can I='create' a='paymentMethods'>
                    <Method APO_method={'create'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/payments/methods/edit/:method">
                <Can I='update' a='paymentMethods'>
                    <Method APO_method={'update'} />
                </Can>
            </PrivateRoute>
            <PrivateRoute path="/payments/methods/all" >
                <Can I='read' a='paymentMethods'>
                    <AllMethods />
                </Can>
            </PrivateRoute>
            <Route component={NotFound} />
        </Switch>

    )
}


export default Methods