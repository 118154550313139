import React from 'react'
import { handleNumberInputKeyPress } from '../../../../misc/components/Helpers'
import { Input } from '../../../../misc/components/Inputs'



export const ShippingDataStep = (props) => {
    let {
        APO_product,
        currentStep,
        handleInput,

        dimensions,
        shippingDataForm,
        weight,

    } = props

    //form-fields
    const weightInput = <Input
        type="number"
        label='Weight (kg)'
        name={`weight`}
        value={weight}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        required
        disabled={APO_product === 'read'}
        invalidMessage={'Please enter product weight'}
    />
    const lengthInput = <Input
        type="number"
        label='Length (cm)'
        name={`length`}
        value={dimensions.length}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        // required
        disabled={APO_product === 'read'}
        invalidMessage={'Please enter product length'}
    />
    const widthInput = <Input
        type="number"
        label='Width (cm)'
        name={`width`}
        value={dimensions.width}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        // required
        disabled={APO_product === 'read'}
        invalidMessage={'Please enter product width'}
    />
    const heightInput = <Input
        type="number"
        label='Height (cm)'
        name={`height`}
        value={dimensions.height}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        containerClass='mb-3'
        // required
        disabled={APO_product === 'read'}
        invalidMessage={'Please enter product height'}
    />

    // if (currentStep !== 3) {
    //     return null
    // }
    return (
        <form ref={shippingDataForm} className={`m-0 p-0 ${currentStep !== 3 ? 'd-none' : 'd-block'}`} noValidate>
            <div className='m-0 p-0 p-grid'>
                <div className='m-0 col-sm-6'>
                    {weightInput}
                </div>
                <div className='m-0 col-sm-6'>
                    {lengthInput}
                </div>
                <div className='m-0 col-sm-6'>
                    {widthInput}
                </div>
                <div className='m-0 col-sm-6'>
                    {heightInput}
                </div>
            </div>
        </form>
    )
}
