import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Can } from '../../../configs/Ability-context'
import getOptions from '../../misc/components/Options'
import CustomSelect from '../../misc/components/CustomSelect'
import { handleNumberInputKeyPress } from '../../misc/components/Helpers'
import { CustomButton, CustomInput } from '../../misc/components/Inputs'
import LoaderModal from '../../misc/components/LoaderModal'
import { getRoles } from '../../../services/users/role-service'
import { getPriceRules } from '../../../services/users/price-rule-service'
import { createUser, getCustomUsers } from '../../../services/users/user-service';


const INITIAL_STATE = {
    name: '',
    email: '',
    cell: '',
    address: '',
    city: '',
    state: '',
    selectedCountry: '',
    postcode: '',
    cnic: '',
    username: '',
    password: '',
    cnfrmPassword: '',
    selectedRole: '',
    selectedPriceRule: '',
    selectedSeller: '',
    isClientSelected: false,
    isSellerManagerSelected: false,
    isShopManagerSelected: false,
}

const NewUser = () => {
    const [
        { name, email, cell, address, city, state, selectedCountry, postcode, cnic,
            username, password, cnfrmPassword, selectedRole, selectedPriceRule, selectedSeller,
            isClientSelected, isSellerManagerSelected, isShopManagerSelected,
            allRoles, allPriceRules, allSellers, validationMessage, isLoading },
        setState] = useState({ ...INITIAL_STATE, allRoles: [], allPriceRules: [], allSellers: [], validationMessage: '', isLoading: false })

    let newUserForm = useRef(null)
    let cellField = useRef(null)
    let usernameField = useRef(null)
    let history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getRoles()
                changeValue('allRoles', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
    }, [])

    const fetchPriceRules = async () => {
        changeValue('isLoading', true)
        try {
            const { data } = await getPriceRules()
            changeValue('allPriceRules', data)
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    const fetchSellers = async () => {
        changeValue('isLoading', true)
        try {
            const { data } = await getCustomUsers('seller')
            changeValue('allSellers', data)
        }
        catch (error) {
            console.log(error)
        }
        changeValue('isLoading', false)
    }

    const changeValue = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value, validationMessage: '' }));
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const handleSelectChange = name => selectedOption => {
        // console.log(selectedOption)
        if (name === 'selectedRole') {
            let isClientSelected = selectedOption.label.toLowerCase() === 'client'
            let isSellerManagerSelected = selectedOption.label.toLowerCase() === 'seller_manager'
            let isShopManagerSelected = selectedOption.label.toLowerCase() === 'shop_manager'
            setState(prevState => ({
                ...prevState,
                selectedRole: selectedOption,
                isClientSelected: isClientSelected,
                isSellerManagerSelected: isSellerManagerSelected,
                isShopManagerSelected: isShopManagerSelected,
                validationMessage: ''
            }))
            isSellerManagerSelected && fetchPriceRules()
            isShopManagerSelected && fetchSellers()
            return
        }
        changeValue(name, selectedOption)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = newUserForm.current

        if (!selectedRole) {
            setState(prevState => ({ ...prevState, selectedRole: null, validationMessage: 'Please select a user role' }))
            return
        }
        else if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        else if (!selectedCountry) {
            setState(prevState => ({ ...prevState, selectedCountry: null, validationMessage: 'Please select country' }))
            return
        }
        else if (isSellerManagerSelected && (!selectedPriceRule)) {
            setState(prevState => ({ ...prevState, selectedPriceRule: null, validationMessage: 'Please select a price-rule' }))
            return
        }
        else if (isShopManagerSelected && (!selectedSeller)) {
            setState(prevState => ({ ...prevState, selectedSeller: null, validationMessage: 'Please select a seller' }))
            return
        }
        else if (!isClientSelected && (password !== cnfrmPassword)) {
            changeValue('validationMessage', `Passwords don't match`)
            return
        }
        else {
            form.classList.remove('was-validated')
            let newUser = {
                name: name, email: email, cell: cell, address: address, city: city, state: state,
                country: selectedCountry.value, postcode: postcode, cnic: cnic ? cnic : null,
                username: isClientSelected ? null : username,
                password: isClientSelected ? null : password,
                role_id: selectedRole.value,
                price_rule_id: selectedPriceRule?.value,
                parent_id: selectedSeller?.value
            }

            // console.log(newUser)
            const { success, message } = await createUser(newUser, isClientSelected ? 'client' : 'user')
            changeValue('validationMessage', message)
            if (success) {
                setState(prevState => ({ ...prevState, ...INITIAL_STATE }))
                setTimeout(() => { history.push('/users/all') }, 1500)
            }
            else {
                isClientSelected ? cellField.current.focus() : usernameField.current.focus()
            }

        }
    }

    let roleOptions = allRoles.length ?
        getOptions('roles', allRoles).filter(option => option.label.toLowerCase() !== 'super_admin' && option.label.toLowerCase() !== 'seller')
        : []
    roleOptions = allRoles.length ? roleOptions.map(o => ({ label: o.label, value: { label: o.label, value: o.value } })) : []
    // console.log(selectedRole)

    const createSellerLink = <Can I='create' a='sellers'>
        <h6
            className={`bg-tertiary p-mb-3 p-mb-sm-4 p-text-left p-py-2 p-px-3`}
            style={{ borderLeft: '10px solid #0e5b96', fontWeight: 400 }}
        >
            <Link to='/sellers/new' className='p-m-0 p-p-0 color-primary' title='click here to add new seller'>
                {'Want to add new Seller ?'}
            </Link>
        </h6>
    </Can>
    const selectRoleInput = <CustomSelect
        label='Select role'
        options={roleOptions}
        handleChange={(e) => { handleSelectChange('selectedRole')(e.value) }}
        value={selectedRole}
        isRequired
        isFormField
    // isOptionDisabled={(option) => option.label.toLowerCase() === 'super_admin' || option.label.toLowerCase() === 'seller'}
    />
    const nameInput = <CustomInput
        label='Name'
        name={`name`}
        value={name}
        handleInput={handleInput}
        required
    //     invalidMessage={`Please enter name`}
    />

    const emailInput = <CustomInput
        type="email"
        label='Email'
        name={`email`}
        value={email}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter a valid email`}
    />
    const cellInput = <CustomInput
        label='Cell'
        name={`cell`}
        value={cell}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        // hint='e.g. 923331234567'
        pattern="^(92)[0-9]{10}$"
        ref={cellField}
        maxLength="12"
        required
        invalidMessage={`Please follow the pattern '923331234567'`}
    />
    const cnicInput = <CustomInput
        label='CNIC'
        name={`cnic`}
        value={cnic}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        // hint='e.g. 0000000000000'
        pattern="^[0-9]{13}"
        maxLength='13'
        required
        invalidMessage={`Please follow the pattern '0000000000000'`}
    />
    const addressInput = <CustomInput
        label='Address'
        name={`address`}
        value={address}
        handleInput={handleInput}
        required
    //     invalidMessage={`Please enter address`}
    />
    const cityInput = <CustomInput
        label='City'
        name={`city`}
        value={city}
        required
        handleInput={handleInput}
    // invalidMessage={`Please enter city`}
    />
    const stateInput = <CustomInput
        label='State/Province'
        name={`state`}
        value={state}
        required
        handleInput={handleInput}
    // invalidMessage={`Please enter state/province`}
    />
    const selectCountryInput = <CustomSelect
        label='Select country'
        options={getOptions('countries')}
        handleChange={(e) => { handleSelectChange('selectedCountry')(e.value) }}
        value={selectedRole}
        // isRequired
        isFormField
    />
    const postcodeInput = <CustomInput
        label='Postcode'
        name={`postcode`}
        value={postcode}
        handleInput={handleInput}
    // invalidMessage={`Please enter postcode`}
    />
    const selectPriceRuleInput = <CustomSelect
        label='Select price-rule'
        options={getOptions('priceRules', allPriceRules)}
        handleChange={(e) => { handleSelectChange('selectedPriceRule')(e.value) }}
        value={selectedRole}
        isRequired
        isFormField
    />
    const selectSellerInput = <CustomSelect
        label='Select seller'
        options={getOptions('sellers', allSellers)}
        handleChange={(e) => { handleSelectChange('selectedSeller')(e.value) }}
        value={selectedRole}
        isRequired
        isFormField
    />
    const usernameInput = <CustomInput
        label='Username'
        name={`username`}
        value={username}
        handleInput={handleInput}
        ref={usernameField}
        required={!isClientSelected}
        disabled={isClientSelected}
    // invalidMessage={`Please enter a username`}
    />
    const passwordInput = <CustomInput
        type='password'
        label='Password'
        name={`password`}
        value={password}
        handleInput={handleInput}
        // hint={'Enter password'}
        pattern="^(?=.*\d)(?!.*[\s])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        maxLength="20"
        required={!isClientSelected}
        disabled={isClientSelected}
        invalidMessage={`Please enter a password between 8-20 characters with at least 1 uppercase, 1 lowercase and 1 number.`}
    />
    const cnfrmPasswordInput = <CustomInput
        type='password'
        label='Confirm password'
        name={`cnfrmPassword`}
        value={cnfrmPassword}
        handleInput={handleInput}
        // hint='Confirm password'
        pattern={`^${password}$`}
        required={!isClientSelected}
        disabled={isClientSelected || !password}
        invalidMessage={`Passwords don't match`}
    />

    const commonFields = [selectRoleInput, nameInput, emailInput, cellInput, addressInput, cityInput, stateInput, selectCountryInput, postcodeInput]
    const userFields = [cnicInput, usernameInput, passwordInput, cnfrmPasswordInput]
    const sellerManagerOnlyFields = [selectPriceRuleInput]
    const shopManagerOnlyFields = [selectSellerInput]
    const fieldsToDisplay = isClientSelected ?
        commonFields
        :
        isSellerManagerSelected ?
            [...commonFields, ...sellerManagerOnlyFields, ...userFields]
            :
            isShopManagerSelected ?
                [...commonFields, ...shopManagerOnlyFields, ...userFields]
                :
                [...commonFields, ...userFields]

    return (
        <Can I='create' a='users'>
            <div className='screen-center h-sm-100vh p-mt-5 p-m-sm-0 p-py-5 p-py-sm-0'>
                <form ref={newUserForm} className='p-m-0 p-p-0 w-100 w-md-75 w-lg-50 p-text-center color-tertiary p-mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='p-text-bold p-mb-3 p-mb-sm-4'>New user</h5>
                    <div className='p-grid p-m-0 p-p-0'>
                        <div className='p-m-0 p-col-12'>
                            {createSellerLink}
                        </div>
                        {fieldsToDisplay.map((field, index) => <div key={index + 1} className='p-m-0 p-col-12 p-sm-6'>{field}</div>)}
                        <div className='p-m-0 p-col-12 p-sm-6 p-text-right'>
                            <CustomButton
                                label="Create User"
                                bg='Secondary'
                                color='tertiary'
                                className='w-100'
                                type='submit'
                            />
                        </div>
                        <div className='p-m-0 p-col-12 p-text-center'>
                            <label className='p-p-0 validating-label'>{validationMessage}&nbsp;</label>
                        </div>
                    </div>
                </form>
            </div>
            <LoaderModal modalShow={isLoading} />
        </Can >
    );
}


export default NewUser