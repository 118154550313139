import React, { useState, useEffect, useRef, useContext } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { animateSyncIcon } from '../../../misc/components/Helpers'
import { CustomListing } from '../../../misc/components/CustomListing'
import SellerRequestModal from '../../../misc/components/SellerRequestModal'
import { AppContext } from "../../../../contexts/AppContext";
import { getCategories, getSyncMeta, syncCategories } from '../../../../services/products/category-service';

let _isMounted
const AllProductCategories = () => {
    let dt = useRef(null)
    let toast = useRef(null)

    const { storeURL } = useContext(AppContext)
    const [{
        allCategories, isLoading, refreshData, modalShow, isSyncing, totalRecords, syncedRecords, syncedPercentage
    }, setState] = useState({
        allCategories: [], isLoading: false, refreshData: false, modalShow: false, isSyncing: false, syncedRecords: 0, syncedPercentage: 0
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getCategories()
                changeValue('allCategories', data)
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleRequest = () => {
        changeValue('modalShow', true)
    }

    const handleSync = async (e) => {
        //animating sync button icon
        animateSyncIcon('start', e)
        changeValue('isSyncing', true)
        // fetching total categories and pages
        try {
            const { success, categories, startingPage, pages } = await getSyncMeta()
            if (success) {
                let _totalRecords = categories
                changeValue('totalRecords', _totalRecords)
                if (Number(_totalRecords) > 0) {
                    for (let pg = startingPage; pg <= pages; pg++) {
                        // fetching new records and syncing
                        try {
                            const json = await syncCategories(pg)
                            //separating new records
                            if (json.data) {
                                const idsOfComing = json.data.map(c => c.id)
                                const idsOfAvailable = allCategories.map(c => c.id)
                                const newIds = idsOfComing.filter(id => !idsOfAvailable.includes(id))
                                const newCategories = json.data.filter(c => newIds.includes(c.id))
                                _isMounted && setState(prevState => ({
                                    ...prevState,
                                    syncedPercentage: ((prevState.allCategories.length + newCategories.length) / _totalRecords) * 100,
                                    syncedRecords: prevState.allCategories.length + newCategories.length,
                                    allCategories: [...prevState.allCategories, ...newCategories]
                                }))
                            }
                        }
                        catch (error) {
                            console.log(error);
                            //stoping animation of sync icon
                            animateSyncIcon('error', e)
                            setTimeout(() => { changeValue('isSyncing', false) }, 500)
                            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
                        }
                    }
                }
                else {
                    animateSyncIcon('stop', e) //No product to sync
                    setTimeout(() => { changeValue('isSyncing', false) }, 500)
                    toast.current.show({ severity: 'info', summary: 'Sorry', detail: `No category exists on your store.`, life: 4000 })
                }
                animateSyncIcon('stop', e)
                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                setTimeout(() => {
                    setState(prevState => ({
                        ...prevState,
                        refreshData: !prevState.refreshData,
                        syncedPercentage: 0,
                        syncedRecords: 0,
                        totalRecords: undefined
                    }))
                }, 600)
            }
            else {
                animateSyncIcon('error', e)
                setTimeout(() => { changeValue('isSyncing', false) }, 500)
                toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
            }
        }
        catch (err) {
            console.log(err)
            // stoping animation of sync icon
            animateSyncIcon('error', e)
            setTimeout(() => { changeValue('isSyncing', false) }, 500)
            toast.current.show({ severity: 'error', summary: 'Sorry', detail: `something went wrong.` })
        }
    }

    let columns = [
        {
            field: "name",
            header: "Name",
            sortable: true,
            filter: true,
            selectedByDefault: true,
            filterPlaceholder: "Search by name",
            filterMatchMode: "contains",
        },
        {
            field: "count",
            selectedByDefault: true,
            header: "No. of products",
            sortable: true,
        },
    ]

    return (
        <Can I='read' a='productCategories'>
            <CustomListing
                allRecords={allCategories}
                heading={'Product categories'}
                columns={columns}
                permissionSubject={'productCategories'}
                isLoading={isLoading}
                isSyncing={isSyncing}
                totalRecords={totalRecords}
                syncedRecords={syncedRecords}
                syncedPercentage={syncedPercentage}
                handleSync={handleSync}
                handleRequest={handleRequest}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                handleView={true}
                customViewButton={(customButton, rowData) => {
                    return <a href={`${storeURL}product-category/${rowData.slug}/`} target="blank">
                        {customButton('pi pi-eye')}
                    </a>
                }}
                tableRef={dt}
                toastRef={toast}
            />
            <SellerRequestModal
                modalShow={modalShow}
                entity={'Product category'}
                hideModal={() => changeValue('modalShow', false)}
            />
        </Can>
    );
}


export default AllProductCategories
