import React from 'react';
import AllRoles from './pages/AllRoles';
import { Redirect } from 'react-router-dom'
import { Can } from '../../../configs/Ability-context'
import { UserContext } from '../../../contexts/UserContext'

const SecuredAllRoles = (props) => {
    const { isLoggedIn } = React.useContext(UserContext)


    if (!isLoggedIn) {
        return <Redirect to='/login' />
    }
    else {
        return <Can I='read' a='roles'> <AllRoles {...props} /></Can>
    }

}



export default SecuredAllRoles