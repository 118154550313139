import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { useHistory } from 'react-router-dom'
import { CustomListing } from '../../../misc/components/CustomListing'
import getOptions from '../../../misc/components/Options'
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { deletePaymentMethod, getPaymentMethods } from '../../../../services/payments/method-service';
import { isConfirmed } from '../../../misc/components/Helpers'

let _isMounted
const AllMethods = () => {

    let dt = useRef(null)
    let toast = useRef(null)
    let history = useHistory()

    const [{
        allMethods, isLoading, refreshData,
        selectedBank
    }, setState] = useState({
        allMethods: [], isLoading: false, refreshData: false,
        selectedBank: null
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getPaymentMethods()
                changeValue('allMethods', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value })) }

    const handleEdit = (id) => (e) => {
        history.push(`/payments/methods/edit/${id}`)
    }

    const handleCreate = () => {
        history.push('/payments/methods/new')
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete', e, accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = await deletePaymentMethod(id)
                    console.log(message)
                    let updatedMethods = allMethods.filter(method => method.id !== id)
                    if (success && affectedCount) {
                        toast.current.show(message);
                        changeValue('allMethods', updatedMethods)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const bankFilter = <Dropdown
        value={selectedBank}
        options={getOptions('banks')}
        onChange={(e) => { dt.current.filter(e.value, 'bank_name', 'equals'); changeValue('selectedBank', e.value) }}
        placeholder="Select bank"
        className="p-column-filter"
        showClear
        filter
    />

    let columns = [

        {
            field: "bank_name",
            header: "Bank",
            filter: true,
            filterElement: bankFilter
        },
        {
            field: "account_id",
            header: "A/C #",
            filter: true,
            filterPlaceholder: 'Search by A/C #',
            filterMatchMode: "contains"
        },
        {
            field: "account_title",
            header: "A/C title",
            filter: true,
            filterPlaceholder: 'Search by A/C title',
            filterMatchMode: "contains"
        },
        {
            field: "iban_id",
            header: "IBAN",
            filter: true,
            filterPlaceholder: 'Search by IBAN',
            filterMatchMode: "contains"
        },
    ]


    return (
        <Can I='read' a='paymentMethods'>
            <CustomListing
                allRecords={allMethods}
                heading={'Payment methods'}
                firstColumn={<Column
                    field="title"
                    header="Title"
                    sortable={true}
                    filter={true}
                    filterPlaceholder="Search by title"
                    filterMatchMode="contains"
                    headerStyle={{ width: '170px' }}
                />}
                columns={columns}
                permissionSubject={'paymentMethods'}
                isLoading={isLoading}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                handleCreate={handleCreate}
                handleEdit={true}
                editDataKey={'id'}
                handleDelete={true}
                deleteDataKey={'id'}
                customEditButton={(customButton, rowData) => {
                    return (
                        !rowData.is_default && customButton('pi pi-pencil', handleEdit(rowData.id), false, 'edit')
                    )
                }}
                customDeleteButton={(customButton, rowData) => {
                    return (
                        !rowData.is_default && customButton('pi pi-trash', handleDelete(rowData.id), false, 'delete')
                    )
                }}
                tableRef={dt}
                toastRef={toast}
                timeStamps={true}
            />
        </Can>
    );
}


export default AllMethods