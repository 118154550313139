import React from 'react';
import Login from './pages/Login';
import { Redirect } from 'react-router-dom'
import { Can } from '../../configs/Ability-context'


const SecuredLogin = ({ isLoggedIn, emailVerified }) => {

    return isLoggedIn ?
        emailVerified ?
            <>
                <Can I='read' a='adminDashboard'>
                    <Redirect to='/dashboard' />
                </Can>
                <Can I='update' a='sellerProfile'>
                    <Redirect to='/sellers/myProfile' />
                </Can>
            </>
            :
            <Redirect to='/sellers/request_email_verification' />
        :
        <Login />
}



export default SecuredLogin