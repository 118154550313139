import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { MDBRow, MDBCol } from 'mdbreact';
import { Can } from '../../../../../configs/Ability-context'
import LoaderModal from '../../../../misc/components/LoaderModal'
import { Input, ActionButton } from '../../../../misc/components/Inputs'
import PasswordConfirmationModal from '../../../../misc/components/PasswordConfirmationModal'
import { getPermission, updatePermission } from '../../../../../services/users/permission-service';


let slugField
const INITIAL_STATE = {
    action: '',
    subject: '',
    slug: '',
    enabled: false,
}

const Permission = (props) => {
    const [{
        action, subject, slug, enabled,
        validationMessage, isLoading, isConfirmingPassword
    }, setState] = useState({
        ...INITIAL_STATE,
        validationMessage: '', isLoading: false, isConfirmingPassword: false
    })

    let permissionForm = useRef(null)
    let { APO_Permission } = props
    let { permission } = useParams()
    let history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            if (APO_Permission === 'update') {
                changeValue('isLoading', true)
                const { data } = permission != 1 && await getPermission(permission)
                const { action, subject, slug, enabled } = data
                setState(prevState => ({ ...prevState, action, subject, slug, enabled, isLoading: false }))
            }
        }
        fetchData()
    }, [])

    const changeValue = (name, value) => setState(prevState => ({ ...prevState, [name]: value }))

    const handleInput = e => {
        let { name } = e.target
        name === 'slug' ? changeValue('slug', e.target.value) : changeValue('enabled', e.target.checked)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let form = permissionForm.current

        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        changeValue('isConfirmingPassword', true);
    }

    const handleUpdate = async () => {
        let updatedPermission = { slug: slug, enabled: enabled }
        const { success, message } = await updatePermission(permission, updatedPermission)
        changeValue('validationMessage', message)
        !success && slugField.focus();
        setTimeout(() => {
            success && history.push('/users/permissions/all')
        }, 1500)
    }


    return (
        <Can I={'update'} a='permissions' >
            <div className='screen-center h-100vh'>
                <form ref={permissionForm} className='m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='font-weight-bold mb-3 mb-sm-4'>{'Edit permission'}</h5>
                    <MDBRow center className='m-0 p-0'>
                        <MDBCol middle sm='10' className='m-0'>
                            <Input
                                label='Permission'
                                name={`action`}
                                value={action}
                                disabled
                            />
                            <Input
                                label='Subject'
                                name={`subject`}
                                value={subject}
                                disabled
                            />
                            <Input
                                label='Slug'
                                name={`slug`}
                                value={slug}
                                handleInput={handleInput}
                                setRef={(ref) => slugField = ref}
                                required
                                invalidMessage={`Please enter slug for this permission`}
                            />
                            <div className="custom-control custom-checkbox text-left mb-3">
                                <input
                                    type="checkbox"
                                    name="enabled"
                                    className="custom-control-input"
                                    checked={enabled}
                                    id={`enabled`}
                                    onChange={(e) => { handleInput(e) }}
                                />
                                <label className="custom-control-label" htmlFor={`enabled`}><small>Enabled ?</small></label>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow center className='mx-0 my-2 p-0'>
                        <MDBCol sm='10' className='m-0 p-0 row'>
                            <MDBCol size='6' className='m-0 text-left'>
                                <label className='p-0 validating-label'>{validationMessage}&nbsp;</label>
                            </MDBCol>
                            <MDBCol size='6' className='m-0 text-right'>
                                <ActionButton
                                    value={'Update'}
                                    className="bg-Secondary"
                                    type="submit"
                                />
                            </MDBCol>
                        </MDBCol>
                    </MDBRow>
                </form>
            </div>
            <LoaderModal modalShow={isLoading} />
            <PasswordConfirmationModal
                modalShow={isConfirmingPassword}
                handleVerifyStatus={(verified) => {
                    verified && handleUpdate();
                    changeValue('isConfirmingPassword', false);
                }}
            />
        </Can >
    );
}


export default Permission