import React, { useState, useEffect, useRef } from 'react';
import { Can } from '../../../configs/Ability-context'
import { getResources, deleteResources, createResource } from '../../../services/misc/resource-service';
import { Dialog } from 'primereact/dialog'
import CustomSelect from './CustomSelect'
import { CustomButton } from './Inputs'
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { CustomFileUpload } from './CustomFileUpload'

let filterReferenceOptions = [
    { key: 1, label: 'All images', value: 'all' },
    { key: 2, label: 'Product images', value: 'product_image' },
    // { key: 3, label: 'Video', value: 'video' },
    { key: 4, label: 'CNIC', value: 'cnic' },
    { key: 5, label: 'Brand logo', value: 'brand_logo' },
]
let imageReferences = ['product_image', 'brand_logo', 'cnic']
let filterMonthOptions = [{ key: 12, label: 'All dates', value: 'all' }],
    d = new Date();

for (let i = 0; i < 30; i++) {
    d.setMonth(new Date().getMonth() - i)
    filterMonthOptions.push(
        {
            key: i,
            label: `${d.toLocaleString('default', { month: 'long' })} ${d.getFullYear()}`,
            value: d,
        }
    )
    d = new Date()
}

const INITIAL_STATE = {
    modalShow: false,
    allResources: [],
    filterMonth: filterMonthOptions[0].value,
    view: 'library',
    imageAddress: '',
    validationMessage: ''
}

let _isMounted
const ImageGallery = (props) => {
    const [{
        modalShow, allResources, filterReference, filterMonth, view, imageAddress, validationMessage
    }, setState] = useState({
        ...INITIAL_STATE, filterReference: filterReferenceOptions.find(o => o.value === props.reference)?.value
    })

    let fakeForm = useRef(null)
    let fileUploadRef = useRef(null)
    let toast = useRef(null)
    let { modalHeading, addBtnHeading, isMultiple, reference, isProtected } = props

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { data } = await getResources()
                _isMounted && setState(prevState => ({
                    ...prevState,
                    modalShow: props.modalShow,
                    allResources: data?.map(resource => ({ ...resource, selected: false }))
                }))
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        props.modalShow ?
            fetchData()
            :
            _isMounted && setState(prevState => ({
                ...prevState,
                modalShow: props.modalShow
            }))
        return () => {
            _isMounted = false
        }
    }, [props.modalShow])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const hideImageGallery = () => {
        _isMounted && setState(prevState => ({ ...prevState, ...INITIAL_STATE }))
        props.hideImageGallery()
    }

    const handleChange = name => e => {
        changeValue(name, e.value)
    }

    const handleSelectImage = (selectedId) => {
        let updatedResources = [...allResources]
        updatedResources = updatedResources.map(r => {
            if (r.id == selectedId) {
                r.selected = !r.selected
            }
            return r
        })
        // updatedResources[index].selected = !updatedResources[index].selected
        changeValue('allResources', updatedResources)
    }

    const sendResponseBack = () => {
        let selectedResources = allResources.filter(resource => resource.selected)
        if (selectedResources.length < 1) {
            changeValue('validationMessage', 'No file selected.')
            return
        }
        else if (selectedResources.length > 1 && !isMultiple) {
            changeValue('validationMessage', 'Multiple files not allowed.')
            return
        }
        props.handleSelectedFiles(selectedResources)
        hideImageGallery()
    }

    const handleDelete = async () => {
        let selectedResources = allResources.filter(resource => resource.selected),
            values = selectedResources.map(resource => resource.id)
        try {
            const { success, data } = await deleteResources(values)
            if (success) {
                //separating resources data
                let oldData = data,
                    oldResources = oldData.map(data => data.success && data.data)
                // console.log(oldResources);
                //refreshing state
                _isMounted && setState(prevState => ({
                    ...prevState,
                    allResources: prevState.allResources.filter((resource) => {
                        return !oldResources.includes(resource.id)
                    }),
                }))
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const uploadImages = async e => {
        let { files } = e,
            fd = new FormData(fakeForm.current)
        //checking files validity
        for (let i = 0; i < files.length; i++) {
            let file = files[i],
                fileSizeValidity = checkFileValidity(file)
            console.log(file)
            fileSizeValidity && fd.append('images', file)
            fd.append('type', 'image')
            fd.append('reference', reference)
            fd.append('protected', isProtected)
        }

        if (fd.get('images') !== null) {
            //uploading
            try {
                const { success, data } = await createResource(fd)

                if (success) {
                    //separating resources data
                    let newData = data,
                        newResources = newData.map(data => {
                            if (data.success)
                                return { ...data.data, selected: true }
                        })
                    // console.log(newResources);
                    //refreshing state
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        allResources: [...newResources, ...prevState.allResources],
                        view: 'library',
                        imageAddress: '',
                    }))
                }
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    // console.log(allResources)
    let resourceGallery = allResources && allResources.filter(resource => {
        const referenceMatched = resource.reference === filterReference || filterReference === 'all'
        const dateMatched = filterMonth === 'all' ||
            (
                (new Date(resource.createdAt).getMonth()) === (new Date(filterMonth).getMonth()) &&
                (new Date(resource.createdAt).getFullYear()) === (new Date(filterMonth).getFullYear())
            )
        return referenceMatched && dateMatched
    })
    //sort by createdt
    resourceGallery = resourceGallery && resourceGallery.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });

    let selectedFiles = resourceGallery && resourceGallery.filter(resource => resource.selected)
    // console.log(selectedFiles);
    // console.log(validationMessage)
    return (
        <Dialog
            header={
                <p className='color-tertiary p-m-0'>{modalHeading}</p>
            }
            modal
            onHide={hideImageGallery}
            className='p-mx-3 p-ml-sm-6 p-pt-3 p-pt-sm-0'
            style={{ width: '90vw' }}
            breakpoints={{ '576px': '100vw' }}
            // visible={true}
            visible={modalShow}
            contentClassName='p-py-0'
            footer={
                view === 'library' &&
                <div className='p-d-flex p-flex-row p-py-4 p-px-sm-4 p-jc-end'>
                    <div>
                        <label className='validating-label p-mr-2 p-mr-sm-4 p-mt-1'>{validationMessage}</label>
                        <CustomButton
                            label={addBtnHeading}
                            onClick={sendResponseBack}
                            color='tertiary'
                            bg="Secondary"
                            className='p-px-5'
                        />
                    </div>
                </div>
            }
        >
            <Toast ref={toast} className='p-mt-5' />
            {view === 'library' ?
                <>
                    <div className='p-m-0 p-py-0 bg-Primary'>
                        <div className='p-m-0 p-p-0 p-grid'>
                            <h5 className='color-tertiary p-mb-3 p-col-12 p-p-1'>{'Filter media'}</h5>
                            <div className='p-p-1 p-col-12 p-sm-6 p-lg-2'>
                                <CustomSelect
                                    isFormField
                                    label={'Reference'}
                                    options={filterReferenceOptions}
                                    handleChange={handleChange('filterReference')}
                                    value={filterReference}
                                />
                            </div>
                            <div className='p-p-1 p-col-12 p-sm-6 p-lg-2'>
                                <CustomSelect
                                    isFormField
                                    label={'Upload date'}
                                    options={filterMonthOptions}
                                    handleChange={handleChange('filterMonth')}
                                    value={filterMonth}
                                />
                            </div>
                            {/* <Can I='create' a='resources'> */}
                            <div className='p-p-1 p-mb-1 p-m-sm-0 p-col-12 p-sm-4 p-lg-3 p-xl-2 p-text-left'>
                                <CustomButton
                                    label={`Upload ${isMultiple ? 'files' : 'file'}`}
                                    icon="pi pi-cloud-upload"
                                    variant='with-icon'
                                    bg='tertiary'
                                    color='Primary'
                                    onClick={() => { changeValue('view', 'upload') }}
                                />
                            </div>
                            {/* </Can> */}
                            <div className='p-px-1 p-pt-2 p-text-left p-ai-end p-col-12 p-sm-8 p-lg-3'>
                                {selectedFiles?.length > 0 &&
                                    <React.Fragment>
                                        {selectedFiles.length > 1 ?
                                            <span className='validating-label color-tertiary'>{selectedFiles.length} {'files selected.'}</span> :
                                            <span className='validating-label color-tertiary'>{selectedFiles.length} {'file selected.'}</span>
                                        }
                                        &nbsp; &nbsp;&nbsp; &nbsp;
                                        <Can I='delete' a='resources'>
                                            <span
                                                className='validating-label cursor-pointer'
                                                onClick={handleDelete}
                                            >
                                                {'Delete permanently.'}
                                            </span>
                                        </Can>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className='p-my-2 p-p-0 p-mx-0 p-grid resource-gallery'>
                            {resourceGallery && resourceGallery.map((resource, index) =>
                                <div className='p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-p-1' style={{ height: '170px' }} key={resource.id}>
                                    {resource.selected && <Badge value={<i className="pi pi-check" />} className='p-p-1 custom-selected-image-badge' />}
                                    {/* {imageReferences.includes(resource.reference) && */}
                                    <img
                                        src={`/api/resources/${resource.name}`}
                                        alt='Image not found.'
                                        className='w-100 h-100 rounded shadow'
                                        style={{ border: '1px solid #669999' }}
                                        onClick={() => { handleSelectImage(resource.id) }}
                                    />
                                    {/* } */}
                                </div>
                            )}
                        </div>
                    </div>
                </>
                :
                // <Can I='create' a='resources'>
                <div className='p-m-0 p-px-3 p-py-3 bg-Primary' style={{ height: '500px' }}>
                    <div className='p-d-flex p-jc-end'>
                        <CustomButton
                            label={`Media library`}
                            icon="pi pi-images"
                            variant='with-icon'
                            bg='tertiary'
                            color='Primary'
                            onClick={() => { changeValue('view', 'library') }}
                        />
                    </div>
                    <div className='screen-center' style={{ height: '90%' }}>
                        <CustomFileUpload
                            fileUploadRef={fileUploadRef}
                            toastRef={toast}
                            accept="image/*"
                            maxSize={4000000}
                            chooseLabel="Choose file"
                            validExtensions={['jpg', 'jpeg', 'png', 'bmp', 'gif']}
                            handleUpload={uploadImages}
                            customUpload={true}
                            multiple={isMultiple}
                            className='w-100'
                        />
                        <form ref={fakeForm} encType='multipart/form-data' />
                    </div>
                </div>
                // </Can >
            }
        </Dialog >
    );
}


let checkFileValidity = (file) => {
    let fileSize = file.size / 1024 / 1024 // in MB
    // fileName = file.name,
    // fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2),
    // validExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'gif']

    if (fileSize > 4) {
        alert(`${file.name} exceeds the maximum upload size for this site.`);
        return false
    }
    // else if (!validExtensions.includes(fileExtension)) {
    //     alert(`Sorry, ${file.name} is invalid, allowed extensions are: ${validExtensions.join(", ")}`);
    //     return false
    // }
    else {
        return true
    }
}

export default ImageGallery;