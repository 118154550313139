import React, { useState, useEffect, useContext, useRef } from 'react';
import { Steps } from 'primereact/steps';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Can, AbilityContext } from '../../../../configs/Ability-context'
import getOptions from '../../../misc/components/Options'
import LoaderModal from '../../../misc/components/LoaderModal'
import MyOwnSelect from '../../../misc/components/MyOwnSelect'
import PersonalDetailsForm from './sections/PersonalDetailsForm'
import ShopDetailsForm from './sections/ShopDetailsForm'
import BankDetailsForm from './sections/BankDetailsForm'
import SignupFormStep4 from './sections/SignupFormStep4'
import { MDBCol, MDBRow } from 'mdbreact';
import { getCustomUsers, getUser, updateUser, updateSellerAccountStatus } from '../../../../services/users/user-service';
import { getPriceRules } from '../../../../services/users/price-rule-service';
import { getPlansFeatures, getSellerPlans } from '../../../../services/users/seller-plan-service';
import { Input } from '../../../misc/components/Inputs'
import { checkUserRole } from '../../../misc/components/Helpers';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { AppContext } from "../../../../contexts/AppContext";
import classNames from 'classnames';


const countriesList = getOptions('countries')
const statesList = getOptions('states', 'UAE')
// console.log(statesList);
// const emiratesList = statesList['UAE']
const sellerTypeOptions = getOptions('sellerTypes')
const accountStatusOptions = getOptions('accountStatuses')
let stepNumber = 0

const INITIAL_STATE = {
    allPlans: [],
    planFeatures: [],
    allPriceRules: [],
    allSellerManagers: [],
    userId: '',
    seller_id: '',
    wp_seller_id: null,

    profilePicture: '',
    name: '',
    email: '',
    emailVerified: null,
    cell: '',
    address: '',
    city: '',
    state: '',
    selectedCountry: '',
    selectedState: '',
    postcode: '',
    cnic: '',
    username: '',
    password: '',
    cnfrmPassword: '',
    isPasswordChanging: false,

    shopName: '',
    shopUrl: '',
    shopBanner: '',
    storeTerms: '',
    showTerms: false,
    vocationMode: '',
    shopCell: '',
    differentShopAddress: false,
    shopAddress: '',
    marketPlace: '',
    selectedSellerType: '',

    selectedBank: '',
    accountTitle: '',
    accountNumber: '',
    ibanNumber: '',
    bankAddress: '',
    routingNumber: '',
    swiftCode: '',
    paypalEmail: '',

    selectedPlan: '',
    selectedPriceRule: '',
    selectedSellerManager: '',
    selectedAccountStatus: '',
    oldAccountStatus: '',
    rejectionReason: '',
    createdAt: null,

    isLoading: false,
}
let step_items = [
    {
        label: 'Personal details'
    },
    {
        label: 'Business details'
    },
    {
        label: 'Bank details'
    }
];

// export function SellerProfileSteps(items) {
//     const [activeIndex, setActiveIndex] = useState(0);
//     let { steps, handleStepSelect } = items

//     return (
//         <div className="card">
//             <Steps model={steps} activeIndex={activeIndex} onSelect={(e) => handleStepSelect(e.index) && setActiveIndex(e.index)} readOnly={false} />
//         </div>
//     )
// }

// const profileSteps =

let _isMounted


const EditSeller = (props) => {

    const ability = useContext(AbilityContext)
    let history = useHistory()
    let { seller } = useParams()
    let toast = useRef(null)
    const isRouteMatched = useRouteMatch("/sellers/edit/:seller");
    seller = seller && seller != 1 && ability.can('update', 'sellers') && isRouteMatched ? seller : 0

    let personalDetailsForm = useRef(null)
    let shopDetailsForm = useRef(null)
    let bankDetailsForm = useRef(null)
    const { storeURL } = useContext(AppContext)

    const [{
        allPlans, planFeatures, allPriceRules, allSellerManagers, userId, seller_id, wp_seller_id,
        profilePicture, name, email, emailVerified, cell, address, city, state, selectedState, selectedCountry, postcode, cnic, username, password, cnfrmPassword, isPasswordChanging,
        shopName, shopUrl, shopBanner, storeTerms, showTerms, vocationMode, shopCell, differentShopAddress, shopAddress, marketPlace, selectedSellerType,
        selectedBank, accountTitle, accountNumber, ibanNumber, bankAddress, routingNumber, swiftCode, paypalEmail,
        selectedPlan, selectedPriceRule, selectedSellerManager, selectedAccountStatus, oldAccountStatus, rejectionReason,
        validationMessage, isLoading, isEditingSeller, isEditingProfile, createdAt
    }, setState] = useState({
        ...INITIAL_STATE,
        isEditingSeller: seller === 0 ? false : true,
        isEditingProfile: seller === 0 ? true : false,

    })
    // const [activeIndex, //setActiveIndex] = useState(0);
    // console.log(rejectionReason)
    // console.log(isEditingSeller)

    useEffect(() => {
        _isMounted = true

        const fetchUserData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getUser(seller)
                if (success) {
                    let eSeller = data
                    const {
                        id, profile_picture_url, name, email, email_verified, cell, address, city, state, country, postcode, cnic, username,
                        shop_name, store_url, shop_banner, store_terms, show_terms, vocation_mode, shop_cell, shop_address, market_place, seller_type,
                        bank_name, ac_title, ac_id, iban_id, bank_address, routing_number, swift_code, paypal_email,
                        seller_id, wp_seller_id, account_status, rejection_reason,
                        plan, price_rule, parent, created_at
                    } = eSeller
                    _isMounted && eSeller && setState(prevState => ({
                        ...prevState,
                        userId: id,
                        seller_id, wp_seller_id: wp_seller_id, oldAccountStatus: account_status,

                        name, email, cell, address, city, state, postcode, cnic, username,
                        profilePicture: profile_picture_url,
                        emailVerified: email_verified,
                        createdAt: created_at,
                        selectedCountry: countriesList.filter(option => option.value === country),
                        selectedState: statesList.filter(option => option.value === state),

                        shopName: shop_name,
                        shopUrl: store_url,
                        shopBanner: shop_banner,
                        storeTerms: store_terms,
                        showTerms: show_terms,
                        vocationMode: vocation_mode,
                        shopAddress: shop_address,
                        differentShopAddress: shop_address === address ? false : true,
                        shopCell: shop_cell,
                        marketPlace: market_place,
                        selectedSellerType: sellerTypeOptions.find(option => option.value === seller_type),

                        selectedBank: bank_name ? { label: bank_name, value: bank_name } : '',
                        accountTitle: ac_title,
                        accountNumber: ac_id,
                        ibanNumber: iban_id,
                        bankAddress: bank_address,
                        routingNumber: routing_number,
                        swiftCode: swift_code,
                        paypalEmail: paypal_email,


                        selectedPlan: plan ? isEditingProfile ? plan.title : plan.id : '',
                        selectedPriceRule: isEditingProfile ? null : price_rule ? { label: price_rule.name, value: price_rule.id } : '',
                        selectedSellerManager: parent ? isEditingProfile ? parent.name : { label: parent.name, value: parent.id } : '',

                        selectedAccountStatus: accountStatusOptions.find(option => option.value === account_status),
                        rejectionReason: rejection_reason
                    }))
                }
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        const fetchMetaData = async () => {
            const priceRules = await getPriceRules()
            changeValue('allPriceRules', priceRules.data)
            const plans = await getSellerPlans()
            changeValue('allPlans', plans.data)
            const features = await getPlansFeatures()
            changeValue('planFeatures', features.data)
            const sellerManagers = await getCustomUsers('seller_manager')
            changeValue('allSellerManagers', sellerManagers.data)
        }

        fetchUserData();
        isEditingSeller && fetchMetaData()

    }, [])

    const submitReviewRequest = async () => {

        let updatedData = {
            account_status: "pending",
        }
        console.log(userId)
        changeValue("isLoading", true)
        const { message, success } = await updateUser(userId, updatedData, 'seller')
        if (success) {
            changeValue("isLoading", false)
            toast.current?.show({ severity: 'success', summary: 'Success', detail: `Review Request Submitted` })
            window.location.reload();
        }
    }
    const rejectionReasonBox = (reason) => {

        const footer = (
            <>
                <span>
                    If you have made required changes, you can submit review request!
                    <Button label="Review Request" onClick={submitReviewRequest} icon="pi pi-sign-in" className='float-right m-2' />
                </span>
            </>
        );

        return (
            <div className="card flex justify-content-center m-2">
                <Card title="Rejection Reason" footer={footer} >
                    <p className="m-0">
                        {reason}
                    </p>
                </Card>
            </div>
        )
    }

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const changeValue = (name, value) => {
        // console.log(name, value)
        _isMounted && setState(prevState => ({ ...prevState, [name]: value, validationMessage: name === 'validationMessage' ? value : '' }));
    }

    const handleSelectChange = name => (selectedOption) => {
        changeValue(name, selectedOption)
    }

    const handleNumberInputKeyPress = (e) => {
        // let { name, value } = e.target
        let array = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
        if (array.includes(e.which)) { return }
        else { e.preventDefault() }
    }

    const handleCheckboxChange = (e) => {
        let { name, checked } = e.target
        changeValue(name, checked)
    }
    // const handleStepSelect = (activeIndex) => {
    //     stepNumber = activeIndex
    //     console.log(stepNumber + " = step")
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        // if (!selectedSellerManager) {
        //     setState(prevState => ({ ...prevState, selectedSellerManager: null, validationMessage: 'Please select seller manager' }))
        //     return
        // }
        // else if (!isEditingProfile && !selectedPriceRule) {
        //     setState(prevState => ({ ...prevState, selectedPriceRule: null, validationMessage: 'Please select price rule' }))
        //     return
        // }
        // else 
        if (selectedAccountStatus.value != "rejected") {
            if (!selectedAccountStatus) {
                setState(prevState => ({ ...prevState, selectedAccountStatus: null, validationMessage: 'Please select account status' }))
                return
            }
            else if (!selectedCountry) {
                setState(prevState => ({ ...prevState, selectedCountry: null, validationMessage: 'Please select country' }))
                return
            }
            else if (!selectedState) {
                setState(prevState => ({ ...prevState, selectedState: null, validationMessage: 'Please select state' }))
                return
            }
            else if (!personalDetailsForm.current.checkValidity()) {
                personalDetailsForm.current.classList.add('was-validated')
                //setActiveIndex(0);
                return
            }
            // else if (!selectedSellerType) {
            //     setState(prevState => ({ ...prevState, selectedSellerType: null, validationMessage: 'Please specify business domain' }))
            //     return
            // }
            else if (!shopDetailsForm.current.checkValidity()) {
                shopDetailsForm.current.classList.add('was-validated')
                //setActiveIndex(1);
                return
            }
            // else if (!selectedBank) {
            //     setState(prevState => ({ ...prevState, selectedBank: null, validationMessage: 'Please select a bank name' }))
            //     //setActiveIndex(2);
            //     return false
            // }
            // else if (!bankDetailsForm.current.checkValidity()) {
            //     bankDetailsForm.current.classList.add('was-validated')
            //     //setActiveIndex(2);
            //     return
            // }
        }
        // else {
        // console.log(selectedSellerType)
        updateSeller()
        // }
    }

    const updateSeller = async () => {

        let updatedSeller = {
            name: name, email: email, email_verified: emailVerified, created_at: createdAt, cell: cell, address: address, wp_seller_id: wp_seller_id,
            city: city, state: selectedState.value, country: selectedCountry.value, postcode: postcode,
            cnic: cnic, username: username, password: isPasswordChanging ? password : null,
            shop_name: shopName, store_url: shopUrl, shop_cell: shopCell, shop_address: differentShopAddress ? shopAddress : address,
            // market_place: marketPlace, seller_type: selectedSellerType.value,
            shop_banner: shopBanner ? shopBanner.name : false,
            store_terms: storeTerms ? storeTerms : false,
            show_terms: showTerms ? showTerms : false,
            vocation_mode: vocationMode ? vocationMode : false,

            bank_name: selectedBank.value, ac_title: accountTitle, ac_id: accountNumber, iban_id: ibanNumber,
            bank_address: bankAddress, routing_number: routingNumber, swift_code: swiftCode, paypal_email: paypalEmail,
            seller_plan_id: isEditingProfile ? undefined : selectedPlan.value,
            price_rule_id: isEditingProfile ? undefined : selectedPriceRule.value,
            parent_id: isEditingProfile ? undefined : selectedSellerManager.value,
            account_status: isEditingProfile ? oldAccountStatus : selectedAccountStatus.value,
            rejection_reason: rejectionReason ? rejectionReason : "",
            profile_picture_url: profilePicture ? profilePicture.name : false
        }
        // console.log("rejectionReason " + rejectionReason);
        try {
            const { message, success } = await updateUser(userId, updatedSeller, 'seller')
            if (success) {
                toast.current?.show({ severity: 'success', summary: 'Success', detail: message })
            }
            else {
                changeValue('validationMessage', message)
            }
            setTimeout(() => {
                success &&
                    isEditingSeller ?
                    history.push('/sellers/all')
                    :
                    // history.push('/sellers/myProfile')
                    window.location.reload();

            }, 1000)
        }
        catch (error) {
            console.log(error)
        }
    }

    let APO_seller = 'update'
    // console.log(selectedAccountStatus)
    return (

        <Can I='update' a={isEditingSeller ? 'sellers' : isEditingProfile ? 'sellerProfile' : 'false'}>
            <div className='container'>
                <h5 className='font-weight-bold color-secondary mt-5'>{isEditingSeller ? 'Edit seller' : (isEditingProfile && 'Profile Settings')}</h5>
                <div >
                    <Card className='mb-2'>
                        <MDBRow className='container-fluid'>
                            {/* account details column */}
                            <MDBCol className='border-right text-left col-6'>
                                <MDBRow>
                                    <MDBCol className='col-4 text-right' >
                                        <img
                                            // src={profilePicture ? `/images/${profilePicture}` : '/image-not-found.png'}
                                            src={profilePicture ? profilePicture.name ? `/images/${profilePicture.name}` : `/images/${profilePicture}` : '/image-not-found.png'}
                                            height={'100px'}
                                            // alt={rowData.name}
                                            className='text-muted p-shadow-8'
                                            style={{
                                                width: '100px',
                                                filter: (profilePicture) ? '' : 'grayscale(100%)',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol className='col-8'>
                                        {/* w-100 w-md-75 w-lg-50 */}
                                        {/* <div className='m-0 p-0'> */}

                                        <h3 className='text-capitalize'>{name}</h3>
                                        {emailVerified ?
                                            <span><img
                                                src={'/verified.png'}
                                                height={'20px'}
                                                width={'20px'}
                                                // alt={rowData.name}
                                                // className='text-muted p-shadow-8'
                                                style={{
                                                    // width: '10px',
                                                    // filter: 'grayscale(100%)',
                                                    bsorderRadius: '50%'
                                                }}
                                            />
                                                <small className='ml-1'>Email Verified</small>
                                            </span>
                                            :
                                            <small className='ml-1'>Not Verified</small>

                                        }
                                        <br></br>
                                        <small><strong>Member Since:</strong> {createdAt}</small>
                                        <br></br>
                                        <small><strong>Seller ID:</strong> {seller_id}</small>
                                        <br></br>
                                        <small><strong>Vocation Mode:</strong> {vocationMode ? "Enabled" : "Disabled"}</small>
                                        <br></br>
                                        <small><strong>Account Status:</strong> {selectedAccountStatus.value}</small>
                                        {(selectedAccountStatus.value == 'approved' && shopUrl) &&

                                            <a href={`${storeURL}seller/${shopUrl}`} className='p-button p-button-rounded bg-Primary white-text bg-hover-tertiary hover-primary primary-border' target="_blank">View Shop</a>
                                        }

                                        {(isEditingProfile && selectedAccountStatus.value == "rejected") ?
                                            // <>
                                            //     <h6>Reason</h6>
                                            //     <span>
                                            //         {rejectionReason ? rejectionReason : "Not Feasible"}
                                            //     </span>
                                            // </>
                                            rejectionReasonBox(rejectionReason)
                                            : ""
                                        }

                                        {/* </div> */}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            {/* summary column */}
                            <MDBCol className='col-6'>
                                <MDBRow>
                                    <MDBCol>
                                        <Card className="mb-2 shadow" style={{ whiteSpace: 'nowrap' }}>
                                            <span>Total Products</span>
                                        </Card>

                                    </MDBCol>
                                    <MDBCol>
                                        <Card className='shadow' style={{ whiteSpace: 'nowrap' }}>
                                            <span>Total Ordes</span>
                                        </Card>
                                    </MDBCol>
                                    <MDBCol>
                                        <Card className='shadow' style={{ whiteSpace: 'nowrap' }}>
                                            <span>Total Sales <small>(AED)</small></span>
                                        </Card>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </Card>

                    {isEditingSeller &&
                        <MDBRow center className='m-0 p-0 w-100 w-md-75 w-lg-50'>
                            <MDBCol sm='4' className='m-0'>
                                <div className='mb-4 p-0'>
                                    <MyOwnSelect
                                        options={getOptions('accountStatuses')}
                                        optionsHeading={selectedAccountStatus ? 'Select account status' : ''}
                                        handleChange={handleSelectChange('selectedAccountStatus')}
                                        placeHolder='Select account status'
                                        selectedValue={selectedAccountStatus}
                                        className='border-radius-0'
                                        withOutIcon
                                        required
                                    />
                                </div>
                            </MDBCol>
                            {
                                selectedAccountStatus.value == 'rejected' ?
                                    <MDBRow>
                                        <MDBCol style={{ width: '50vw' }}>
                                            <Input
                                                label='Rejection Reason'
                                                name={`rejectionReason`}
                                                value={rejectionReason}
                                                handleInput={handleInput}
                                            // required
                                            // invalidMessage={`Please enter a rejection reason`}

                                            />
                                        </MDBCol>
                                    </MDBRow> : ""

                            }
                        </MDBRow>}
                    {/* <div className='w-100 '>
                        <div className="card">
                            <Steps model={step_items} activeIndex={activeIndex} onSelect={(e) => { setActiveIndex(e.index); }} readOnly={false} />
                        </div>
                    </div> */}
                    {/* {console.log(activeIndex)} */}
                    {/* <div style={{ display: activeIndex != 0 ? 'none' : 'block' }}> */}
                    <MDBRow>
                        <MDBCol className='col-6'>
                            <Card>
                                {/* <div className={activeIndex == 0 ? 'w-md-75 w-lg-50 m-0 p-0' : 'hidden'}> */}
                                <small className="d-block d-sm-none small mb-4 text-dark text-center"> Personal Information</small>
                                <div className='row'>
                                    <div className='col-6'>
                                        <h4 className="text-dark mb-3"> Personal Information</h4>
                                    </div>
                                </div>
                                <PersonalDetailsForm
                                    currentStep={1}
                                    personalDetailsForm={personalDetailsForm}
                                    APO_seller={APO_seller}
                                    isEditingProfile={isEditingProfile}
                                    isEditingSeller={isEditingSeller}
                                    seller_id={seller_id}
                                    profilePicture={profilePicture}
                                    name={name}
                                    email={email}
                                    cell={cell}
                                    address={address}
                                    // city={city}
                                    // state={state}
                                    statesList={statesList}
                                    countriesList={countriesList}
                                    selectedCountry={selectedCountry}
                                    selectedState={selectedState}
                                    // postcode={postcode}
                                    cnic={cnic}
                                    username={username}
                                    password={password}
                                    cnfrmPassword={cnfrmPassword}
                                    // allPlans={allPlans}
                                    selectedPlan={selectedPlan}
                                    selectedSellerManager={selectedSellerManager}
                                    isPasswordChanging={isPasswordChanging}

                                    changeValue={changeValue}
                                    handleInput={handleInput}
                                    handleSelectChange={handleSelectChange}
                                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                                />
                            </Card>
                            {/* <Card className='mt-2'> */}
                            {/* <div className={activeIndex == 2 ? 'w-50 m-0 p-0' : 'hidden'}> */}
                            {/* <small className="d-block d-sm-none small mb-3 text-dark text-center"> Bank details</small>
                                <h6 className="text-dark text-center mt-3 mb-3"> Bank details</h6>
                                <BankDetailsForm
                                    currentStep={3}
                                    bankDetailsForm={bankDetailsForm}
                                    APO_seller={APO_seller}
                                    selectedBank={selectedBank}
                                    accountTitle={accountTitle}
                                    accountNumber={accountNumber}
                                    ibanNumber={ibanNumber}
                                    bankAddress={bankAddress}
                                    routingNumber={routingNumber}
                                    swiftCode={swiftCode}
                                    paypalEmail={paypalEmail}

                                    handleInput={handleInput}
                                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                                    handleSelectChange={handleSelectChange}
                                    handleSubmit={handleSubmit}
                                /> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </Card> */}
                        </MDBCol>
                        <MDBCol className='col-6'>
                            <Card>
                                {/* </div> */}
                                {/* <div className={activeIndex == 1 ? 'w-md-75 w-lg-50 m-0 p-0 d-flex' : 'hidden'}> */}
                                {/* <div className={activeIndex == 1 ? 'w-50 m-0 p-0' : 'hidden'}> */}
                                <small className="d-block d-sm-none small mb-3 text-dark text-center"> Business Detail</small>
                                <h4 className="text-dark mb-3"> Business Detail</h4>
                                <ShopDetailsForm
                                    currentStep={2}
                                    shopDetailsForm={shopDetailsForm}
                                    APO_seller={APO_seller}
                                    shopName={shopName}
                                    shopBanner={shopBanner}
                                    storeTerms={storeTerms}
                                    showTerms={showTerms}
                                    vocationMode={vocationMode}
                                    differentShopAddress={differentShopAddress}
                                    address={address}
                                    shopAddress={shopAddress}
                                    shopCell={shopCell}
                                    marketPlace={marketPlace}
                                    selectedSellerType={selectedSellerType}

                                    handleInput={handleInput}
                                    changeValue={changeValue}
                                    handleSelectChange={handleSelectChange}
                                    handleNumberInputKeyPress={handleNumberInputKeyPress}
                                    handleCheckboxChange={handleCheckboxChange}
                                />
                                {/* </div> */}
                            </Card>
                        </MDBCol>
                    </MDBRow>


                    {/* d-none d-sm-block small mb-3 text-dark text-center */}

                    {/* </div> */}

                    <SignupFormStep4
                        currentStep={4}
                        APO_seller={APO_seller}
                        allPlans={allPlans}
                        selectedPlan={selectedPlan}
                        planFeatures={planFeatures}
                        isEditingSeller={isEditingSeller}

                        handleCheckboxChange={handleCheckboxChange}
                        handleSubmit={handleSubmit}
                        changeValue={changeValue}
                    />
                    <label className='p-0 validating-label mt-3'>{validationMessage}&nbsp;</label>
                </div>
            </div>
            <LoaderModal modalShow={isLoading} />
            <Toast ref={toast} className='p-mt-5 p-mr-3' />
        </Can>
    )
}


export default EditSeller;