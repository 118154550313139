import React from 'react'
import { Input, ActionButton } from '../../../../misc/components/Inputs'
import getOptions from '../../../../misc/components/Options'
import { MDBRow, MDBCol } from 'mdbreact'
import MyOwnSelect from '../../../../misc/components/MyOwnSelect'
import { useEffect, useState, useRef, useContext } from 'react'
import ImageGallery from '../../../../misc/components/ImageGallery'
import { Button } from 'primereact/button';
import CustomEditor from '../../../../misc/components/CustomEditor'

const sellerTypes = getOptions('sellerTypes')

export default function ShopDetailsForm(props) {
    let {
        shopDetailsForm, APO_seller,
        shopName, shopBanner, storeTerms, showTerms, vocationMode, differentShopAddress, address, shopAddress, shopCell, marketPlace, selectedSellerType,
        handleInput, handleNumberInputKeyPress, handleCheckboxChange, handleSelectChange,
        goToPrevStep, goToNextStep, changeValue
    } = props
    const [modalShow, setModalShow] = useState(false)
    // const [shopBannerState, setShopBannerState] = useState(false)

    const shopBannerInput = <div className='text-left'>
        {/* {console.log("shopBanner"+shopBanner)} */}
        {shopBanner && <img
            src={shopBanner.name ? `/images/${shopBanner.name}` : (shopBanner != 0 ? `/images/${shopBanner}` : '/banner_placeholder.png')}
            width='100%'
            alt='shopBanner'
            style={{ borderRadius: '3px', border: '1px solid white', height: '221px', backgroundColor: 'white' }}
        />}
        <Button
            label={shopBanner === '' || !shopBanner ? 'Choose Shop Banner' : 'Change Banner'}
            icon={shopBanner ? 'pi pi-refresh' : "pi pi-plus"}
            type='button'
            className="p-button-sm w-100 py-1 px-3 bg-Primary  color-tertiary  font-weight-bold tertiary-border border-radius-0"
            onClick={() => setModalShow(true)}
        // onClick={() => setState(prevState => ({ ...prevState, showImageGallery: true }))}
        />
    </div>
    const vocationModeCheckbox = <div className={`custom-control custom-checkbox mb-2 text-white text-left`}>
        <input
            type="checkbox"
            name="vocationMode"
            className={`custom-control-input`}
            id="vocationMode"
            checked={vocationMode}
            onChange={handleCheckboxChange}
        />
        <label className="custom-control-label text-dark" htmlFor="vocationMode">Enable Vocation Mode ?</label>
    </div>

    const shopNameInput = <Input
        label='Business name'
        name={`shopName`}
        value={shopName}
        handleInput={handleInput}
        required
        invalidMessage={`Please enter your shop name`}
    />
    const shopPhoneInput = <Input
        label='Business phone'
        name={`shopCell`}
        value={shopCell}
        handleInput={handleInput}
        onKeyPress={handleNumberInputKeyPress}
        // hint='e.g. 923331234567 or 924230000000'
        // pattern="^(92)[0-9]{10}$"
        hint='e.g. 971123456789'
        // pattern="^(971)[0-9]{9}$"
        // maxLength="12"
        required
        // invalidMessage={`Please follow the pattern '923331234567 or 924230000000'`}
        invalidMessage={`Please follow the pattern '971123456789'`}
    />
    const shopAddressInput = <Input
        label='Shop address'
        name={`shopAddress`}
        value={differentShopAddress ? shopAddress : address}
        handleInput={handleInput}
        containerClass={differentShopAddress ? 'mb-4' : 'mb-2'}
        required
        disabled={!differentShopAddress}
        invalidMessage={`Please enter your shop address`}
    />
    const differentAddressCheckbox = <div className={`custom-control custom-checkbox mb-2 text-white text-left`}>
        <input
            type="checkbox"
            name="differentShopAddress"
            className={`custom-control-input`}
            id="differentShopAddress"
            checked={differentShopAddress}
            onChange={handleCheckboxChange}
        />
        <label className="custom-control-label text-dark" htmlFor="differentShopAddress">Different shop address ?</label>
    </div>
    const showTermsCheckbox = <div className={`custom-control custom-checkbox mb-2 text-white text-left`}>
        <input
            type="checkbox"
            name="showTerms"
            className={`custom-control-input`}
            id="showTerms"
            checked={showTerms}
            onChange={handleCheckboxChange}
        />
        <label className="custom-control-label text-dark" htmlFor="showTerms">Show Terms on Store ?</label>
    </div>

    const storeTermsInput = <CustomEditor
        value={storeTerms}
        handleChange={(html) => changeValue('storeTerms', html)}
        editorClassName="bg-tertiary color-primary px-2 border-radius-0"
        wrapperClassName="color-primary border-radius-0"
        // initialStateFromValue={APO_brand === 'update'}
        toolbar={{
            options: [
                'inline', 'blockType', 'fontSize', 'fontFamily', 'list',
                'textAlign', 'colorPicker', 'history'
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
        }}
        placeholder="Store Terms..."
    />
    // const marketInput = <Input
    //     label='Market place'
    //     name={`marketPlace`}
    //     value={marketPlace || ''}
    //     handleInput={handleInput}
    //     invalidMessage={`Please enter market name`}
    // />
    // const sellerTypeSelect = <div className='mb-4 p-0'>
    //     <MyOwnSelect
    //         options={sellerTypes}
    //         optionsHeading={selectedSellerType ? 'Select your business domain' : ''}
    //         handleChange={handleSelectChange('selectedSellerType')}
    //         placeHolder='Select your business domain'
    //         selectedValue={selectedSellerType}
    //         className='border-radius-0'
    //         withOutIcon
    //         required
    //     />
    // </div>

    // 

    const fieldsToDisplay = [vocationModeCheckbox,  shopNameInput, shopPhoneInput,
        differentAddressCheckbox, shopAddressInput, showTermsCheckbox, storeTermsInput
    ]
    // ,marketInput, sellerTypeSelect
    return (
        <form ref={shopDetailsForm} noValidate>
            <ImageGallery
                addBtnHeading={'Choose Banner'}
                modalHeading={'Add Media'}
                modalShow={modalShow}
                reference='all'
                isProtected={true}
                handleSelectedFiles={(file) => {
                    delete file[0].selected
                    changeValue('shopBanner', file[0])
                }}
                hideImageGallery={() => { setModalShow(false) }}
            />
            <div className='mb-3 col-sm-12'>
                {shopBannerInput}
            </div>
            <MDBRow center className='m-1 p-1'>
                {fieldsToDisplay.map((field, index) => {
                    return <MDBCol key={index + 1} middle sm={APO_seller === 'update' ? '12' : '8'} className='m-0'>{field}</MDBCol>
                })}
            </MDBRow>

            {APO_seller !== 'update' &&
                <MDBCol middle sm='8' className='d-flex flex-wrap justify-content-between mx-auto'>
                    <ActionButton
                        onClick={goToPrevStep}
                        value="Previous"
                        className="bg-primary-light"
                        type="button"
                    />
                    <ActionButton
                        onClick={goToNextStep}
                        value="Next"
                        className="bg-Secondary"
                        type="button"
                    />
                </MDBCol>
            }
        </form>

    )
}