import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../../configs/Ability-context";
import { CustomListing } from '../../../misc/components/CustomListing'
import CustomSelect from '../../../misc/components/CustomSelect'
import { isConfirmed } from '../../../misc/components/Helpers'
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom'
import { deleteOrderSource, getOrderSources } from '../../../../services/orders/source-service'


let _isMounted
const AllOrderSources = () => {
    let dt = useRef(null)
    let toast = useRef(null)
    let history = useHistory()

    const [{
        allSources, isLoading, refreshData, selectedEnabled
    }, setState] = useState({
        allSources: [], isLoading: false, refreshData: false, selectedEnabled: null
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getOrderSources()
                changeValue('allSources', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }))
    }

    const handleEdit = (id) => (e) => {
        history.push(`/orders/sources/edit/${id}`)
    }

    const handleCreate = () => {
        history.push('/orders/sources/new')
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete', e, accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = await deleteOrderSource(id)
                    console.log(message)
                    let updatedSources = allSources.filter(source => source.id !== id)
                    if (success && affectedCount) {
                        toast.current.show(message);
                        changeValue('allSources', updatedSources)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }

    const isEnabledBody = (rowData) => {
        return (
            <React.Fragment>
                <span className='p-column-title'>Enabled ?</span>
                <span className={rowData.is_enabled ? 'pi pi-check' : 'pi pi-times'}></span>
            </React.Fragment>
        )
    }
    const isEnabledFilter = <CustomSelect
        value={selectedEnabled}
        options={[{ label: 'Enabled', value: true }, { label: 'Not enabled', value: false }]}
        handleChange={(e) => {
            dt.current.filter(e.value, 'is_enabled', 'equals');
            changeValue('selectedEnabled', e.value)
        }}
        placeholder="Enabled/Not enabled"
        className="p-column-filter"
        showClear
    />

    let defaultSources = ['bucket.pk', 'WA', 'WA2', 'FB', 'INS']
    let columns = [
        {
            field: "link",
            header: "Link",
            filter: true,
            filterPlaceholder: "Search by link",
            filterMatchMode: "contains"
        },
        {
            field: "cell",
            header: "Phone",
            filter: true,
            filterPlaceholder: "Search by phone",
            filterMatchMode: "contains"
        },
        {
            field: "is_enabled",
            header: "Enabled ?",
            body: isEnabledBody,
            filter: true,
            filterElement: isEnabledFilter
        },
    ]

    return (
        <Can I='read' a='orderSources'>
            <CustomListing
                allRecords={allSources}
                heading={'Order sources'}
                firstColumn={<Column
                    field="title"
                    header="Title"
                    sortable={true}
                    filter={true}
                    filterPlaceholder="Search by title"
                    filterMatchMode="contains"
                    headerStyle={{ width: '170px' }}
                />}
                columns={columns}
                permissionSubject={'orderSources'}
                isLoading={isLoading}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
                editDataKey={'id'}
                handleDelete={true}
                customDeleteButton={(customButton, rowData) => {
                    return (
                        !defaultSources.includes(rowData.title) &&
                        customButton('pi pi-trash', handleDelete(rowData.id))
                    )
                }}
                tableRef={dt}
                toastRef={toast}
                timeStamps={true}
            />
        </Can>
    );
}


export default AllOrderSources