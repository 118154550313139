import React, { useState, useEffect, useRef } from 'react';
import { Can } from "../../../configs/Ability-context";
import { CustomListing } from '../../misc/components/CustomListing'
import getOptions from '../../misc/components/Options'
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { getPriceRulesWithData, deletePriceRule } from '../../../services/users/price-rule-service';
import { isConfirmed } from '../../misc/components/Helpers'
import PriceRule from './sections/PriceRule'

let _isMounted
const AllPriceRules = () => {

    let dt = useRef(null)
    let toast = useRef(null)

    const [{
        allRules, isLoading, refreshData,
        selectedRule, APO_rule, modalShow
    }, setState] = useState({
        allRules: [], isLoading: false, refreshData: false,
        selectedRule: null, APO_rule: 'create', modalShow: false
    })

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getPriceRulesWithData()
                changeValue('allRules', data)
            }
            catch (error) {
                console.log(error)
            }
            changeValue('isLoading', false)
        }
        fetchData()
        return () => {
            console.log('object')
            _isMounted = false
        }
    }, [refreshData])

    const changeValue = (name, value) => { _isMounted && setState(prevState => ({ ...prevState, [name]: value })) }

    const handleEdit = (id) => (e) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            APO_rule: 'update',
            modalShow: true,
            selectedRule: id
        }))
    }

    const handleView = (id) => {
        _isMounted && setState(prevState => ({
            ...prevState,
            APO_rule: 'read',
            modalShow: true,
            selectedRule: id
        }))
    }

    const handleCreate = () => {
        _isMounted && setState(prevState => ({
            ...prevState,
            APO_rule: 'create',
            modalShow: true,
        }))
    }

    const handleDelete = (id) => (e) => {
        isConfirmed({
            type: 'delete', e, accept: async () => {
                changeValue('isLoading', true)
                try {
                    const { success, message, affectedCount } = await deletePriceRule(id)
                    console.log(message)
                    let updatedRules = allRules.filter(rule => rule.id !== id)
                    if (success && affectedCount) {
                        toast.current.show({ severity: 'warn', summary: `Rule deleted successfully.` });
                        changeValue('allRules', updatedRules)
                    }
                }
                catch (error) {
                    console.log(error)
                }
                changeValue('isLoading', false)
            }
        })
    }
    // console.log(selectedRule)
    const ruleFilter = <Dropdown
        value={selectedRule}
        options={getOptions('priceRules', allRules)}
        onChange={(e) => { dt.current.filter(e.value, 'name', 'equals'); changeValue('selectedRule', e.value) }}
        optionValue='label'
        placeholder="Select rule"
        className="p-column-filter"
        showClear
        filter
    />

    let columns = []


    return (
        <Can I='read' a='priceRules'>
            <CustomListing
                allRecords={allRules}
                heading={'Price rules'}
                firstColumn={<Column
                    field={"name"}
                    header={"Name"}
                    filter={true}
                    filterElement={ruleFilter}
                    headerStyle={{ width: '170px' }}
                />}
                columns={columns}
                permissionSubject={'priceRules'}
                isLoading={isLoading}
                handleCreate={handleCreate}
                handleView={handleView}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                viewDataKey={'id'}
                editDataKey={'id'}
                deleteDataKey={'id'}
                handleRefresh={() => changeValue('refreshData', !refreshData)}
                tableRef={dt}
                toastRef={toast}
                timeStamps={true}
            />
            <PriceRule
                modalShow={modalShow}
                rule={selectedRule}
                APO_rule={APO_rule}
                hideModal={(refresh) => {
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        modalShow: false,
                        refreshData: refresh ? !prevState.refreshData : prevState.refreshData
                    }))
                }}
            />
        </Can>
    );
}


export default AllPriceRules