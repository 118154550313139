import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { Can } from '../../../../../configs/Ability-context'
import LoaderModal from '../../../../misc/components/LoaderModal'
import { Input, CustomButton } from '../../../../misc/components/Inputs'
import { handleNumberInputKeyPress } from '../../../../misc/components/Helpers'
import { createOrderSource, getOrderSource, updateOrderSource } from '../../../../../services/orders/source-service'
import { Toast } from 'primereact/toast';


const INITIAL_STATE = {
    title: '',
    link: '',
    cell: '',
    checked: false,
    isEnabled: false,
};
let _isMounted
const OrderSource = (props) => {
    const [{ title, link, cell, checked, isEnabled, validationMessage, isLoading }, setState] = useState({
        ...INITIAL_STATE, validationMessage: '', isLoading: false
    })

    let orderSourceForm = useRef(null)
    let toast = useRef(null)
    let { APO_source } = props
    let { source } = useParams()
    let history = useHistory()

    useEffect(() => {
        _isMounted = true
        const fetchData = async () => {
            changeValue('isLoading', true)
            try {
                const { success, data } = await getOrderSource(source)
                if (success) {
                    const { title, link, cell, is_enabled } = data
                    _isMounted && setState(prevState => ({
                        ...prevState,
                        title: title,
                        link: link,
                        cell: cell,
                        checked: link ? true : false,
                        isEnabled: is_enabled
                    }));
                }
            }
            catch (err) {
                console.log(err)
            }
            changeValue('isLoading', false)
        }
        source && fetchData()
        return () => {
            _isMounted = false
        }
    }, [])

    const handleInput = e => {
        let { name, value } = e.target
        changeValue(name, value)
    }

    const changeValue = (name, value) => {
        _isMounted && setState(prevState => ({ ...prevState, [name]: value }));
    }

    const handleCheckboxChange = name => (e) => {
        changeValue(name, name === 'checked' ? !checked : e.target.checked)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = orderSourceForm.current
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return
        }
        else {
            let orderSource = {
                title: title, link: checked ? link : undefined, cell: !checked ? cell : undefined
            }
            try {
                const { success, message } = APO_source === 'create' ? await createOrderSource(orderSource) : await updateOrderSource(source, orderSource)
                toast.current.show(message)
                if (success) {
                    if (APO_source === 'create') {
                        _isMounted && setState(prevState => ({ ...prevState, ...INITIAL_STATE }))
                    }
                    form.classList.remove('was-validated')
                    setTimeout(() => {
                        history.push('/orders/sources/all')
                    }, 500)
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    let defaultSources = ['bucket.pk', 'WA', 'WA2', 'FB']

    return (
        <Can I={APO_source} a='orderSources'>
            <div className='screen-center h-100vh'>
                <form ref={orderSourceForm} className='m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto' onSubmit={handleSubmit} noValidate>
                    <h5 className='font-weight-bold mb-3 mb-sm-4'>{APO_source === 'create' ? 'New Order Source' : 'Edit Order Source'}</h5>
                    <div className='p-d-flex p-flex-column px-3 px-sm-5'>
                        <Input
                            label={'Title'}
                            name={`title`}
                            value={title}
                            handleInput={handleInput}
                            required
                            disabled={APO_source === 'update' && defaultSources.includes(title)}
                            invalidMessage={`Please enter source title`}
                        />
                        <div className='p-d-flex p-flex-column m-0 p-0'>
                            <div className="mb-3 text-left pl-4 custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`cellCheckbox`}
                                    checked={!checked}
                                    onChange={handleCheckboxChange('checked')}
                                />
                                <label className="custom-control-label" htmlFor={`cellCheckbox`}>Phone no.</label>
                            </div>
                            <div className="mb-4 text-left pl-4 custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`linkCheckbox`}
                                    checked={checked}
                                    onChange={handleCheckboxChange('checked')}
                                />
                                <label className="custom-control-label" htmlFor={`linkCheckbox`}>Link</label>
                            </div>
                        </div>
                        {checked ?
                            <Input
                                label={'Source link'}
                                name={`link`}
                                value={link || ''}
                                handleInput={handleInput}
                                required
                                invalidMessage={`Please enter a valid source link`}
                            />
                            :
                            <Input
                                id='phone'
                                label='Phone no.'
                                name={`cell`}
                                value={cell || ''}
                                handleInput={handleInput}
                                onKeyPress={handleNumberInputKeyPress}
                                // hint='e.g. 923331234567'
                                pattern="^(92)[0-9]{10}$"
                                maxLength="12"
                                required
                                invalidMessage={`Please follow the pattern '923331234567'`}
                            />
                        }
                        <div className="custom-control mb-3 custom-checkbox text-left">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                name={'isEnabled'}
                                id={`isEnabledCheckbox`}
                                checked={isEnabled}
                                onChange={handleCheckboxChange('isEnabled')}
                            />
                            <label className="custom-control-label" htmlFor={`isEnabledCheckbox`}>Enabled ?</label>
                        </div>
                        <div className='p-d-flex p-jc-between p-flex-wrap m-0 mt-2 p-0'>
                            <label className='p-0 validating-label'>{validationMessage}&nbsp;</label>
                            <CustomButton
                                label={APO_source === 'create' ? "Create" : 'Update'}
                                color='tertiary'
                                bg='Secondary'
                                type="submit"
                                className='px-5'
                            />
                        </div>
                    </div>
                </form>
            </div>
            <Toast ref={toast} className='mt-5' />
            <LoaderModal modalShow={isLoading} />
        </Can >
    );


}


export default OrderSource